const childRoute = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dealer_management_dashboard',
    meta: { auth: true },
    component: () => import('../pages/Dashboard.vue')
  },
  {
    path: 'dealer-profile-management',
    name: prop + '.dealer_profile_management',
    meta: { auth: true },
    component: () => import('../pages/dealer-profile-management/List.vue')
  },
  {
    path: 'sales-manage',
    name: prop + '.sales_manage',
    meta: { auth: true },
    component: () => import('../pages/sales-manage/List.vue')
  },
  {
    path: 'requisition',
    name: prop + '.requisition',
    meta: { auth: true },
    component: () => import('../pages/requisition/List.vue')
  },
  {
    path: 'dealer-report',
    name: prop + '.dealer_report',
    meta: { auth: true },
    component: () => import('../pages/dealer-report/List.vue')
  },
  {
    path: 'fertilizer-allotment-report',
    name: prop + '.dealer_report',
    meta: { auth: true },
    component: () => import('../pages/fertilizer-allotment-report/List.vue')
  },
  {
    path: 'fertilizer-requisition-report',
    name: prop + '.fertilizer_requisition_report',
    meta: { auth: true },
    component: () => import('../pages/fertilizer-requisition-report/List.vue')
  },
  {
    path: 'fertilizer-sales-report',
    name: prop + '.fertilizer-sales-report',
    meta: { auth: true },
    component: () => import('../pages/fertilizer-sales-report/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/fertilzer/dealer-management',
    name: 'seeds_fertilizer_service.fertilzer.dealer_management',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.fertilzer.dealer-management')
  }
]

export default routes
