const lcrpnPanel = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../pages/Dashboard.vue')
  },
  {
    path: 'profile',
    name: prop + '.profile',
    meta: { auth: true },
    component: () => import('../pages/profile/Form.vue')
  },
  {
    path: 'application',
    name: prop + '.application',
    meta: { auth: true },
    component: () => import('../pages/application/List.vue')
  },
  {
    path: 'application/:status',
    name: prop + '.application.status',
    meta: { auth: true },
    component: () => import('../pages/application/List.vue')
  },
  {
    path: 'application-form',
    name: prop + '.application_form',
    meta: { auth: true },
    component: () => import('../pages/application/Form.vue')
  },
  {
    path: 'application-form-edit',
    name: prop + '.application_form_edit',
    meta: { auth: true },
    component: () => import('../pages/application/Edit.vue')
  },
  {
    path: 'application-form-resubmit',
    name: prop + '.application_form_edit',
    meta: { auth: true },
    component: () => import('../pages/application/Resubmit.vue')
  },
  {
    path: 'application-form-view',
    name: prop + '.application_form_view',
    meta: { auth: true },
    component: () => import('../pages/application/View.vue')
  },
  {
    path: 'document-view',
    name: prop + '.document_view',
    meta: { auth: true },
    component: () => import('../pages/application/DocumentView.vue')
  },
  {
    path: 'foreign-enlistment',
    name: prop + '.foreign_enlisthment',
    meta: { auth: true },
    component: () => import('../pages/foreign-enlistment/List.vue')
  },
  {
    path: 'received-notifications',
    name: prop + 'received_notifications',
    meta: { auth: true },
    component: () => import('../pages/received-notifications/List.vue')
}
]

const routes = [
  {
    path: '/lrcpn',
    name: 'external_user.lrcpn',
    component: () => import('@/layouts/ExternalUserLayout.vue'),
    meta: { auth: true },
    children: lcrpnPanel('external_user.lrcpn')
  }
]

export default routes
