const childRoutes = (prop) => [
    {
        path: 'configuration/testing-lab-info',
        name: 'configuration/testing-lab-info',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/testing-lab-info/List.vue')
    },
    {
        path: 'reports/class-wise-seed-tested-report',
        name: 'reports/class-wise-seed-tested-report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/reports/class-wise-seed-tested-report/List')
    },
    {
        path: 'reports/seed-test-report',
        name: 'reports/seed-test-report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/reports/seed-test-report/List')
    },
    {
        path: 'reports/seed-tested-amount-report',
        name: 'reports/seed-tested-amount-report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/reports/seed-tested-amount-report/List')
    },
    {
        path: 'dashboard',
        name: 'dashboard',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/dashboard/Dashboard')
    },
    {
        path: 'sample-collection-schedule-confirmation',
        name: 'sample-collection-schedule-confirmation',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/sample-collection-schedule-confirmation/List')
    },
    {
        path: 'sample-test-result-submission',
        name: 'sample-test-result-submission',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/sample-test-result-submission/List')
    },
    {
        path: 'sample-test-result-submission-application',
        name: 'sample-test-result-submission-application',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/sample-test-result-submission/Application')
    },
    {
        path: 'sample-collection',
        name: 'sample-collection',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/sample-collection/List')
    },
    {
        path: 'test-result-tag-approval',
        name: 'test-result-tag-approval',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/test-result-tag-approval/List')
    },
    {
        path: 'approved-application',
        name: 'approved-application',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/approved-application/List')
    },
    {
        path: 'sample-registration-and-testing',
        name: 'sample-registration-and-testing',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/sample-registration-and-testing/List')
    },
    {
        path: 'tag-print',
        name: prop + '.tag-print',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/tag-print/List')
    }
]
const routes = [
    {
        path: '/agri-research-service/testing-service-management',
        name: 'agri_research_service.testing-service-management',
        component: () => import('@/layouts/AgriResearchLayout.vue'),
        meta: { auth: true },
        children: childRoutes('agri_research_service.testing-service-management')
    }
]

export default routes
