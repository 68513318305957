const childRoutes = (prop) => [
  {
    path: 'chat',
    name: 'chat',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/chat/Form.vue')
  },
  {
    path: 'report-heading-list',
    name: 'report-heading-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/report-heading/List.vue')
  },
  {
    path: 'project-entry-list',
    name: 'project-entry-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/project-entry/List.vue')
  },
  {
    path: 'contractor-list',
    name: 'contractor-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/contractor/List.vue')
  },
  {
    path: 'equipment-type-list',
    name: 'equipment-type-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/equipment-type/List.vue')
  },
  {
    path: 'scheme-type-list',
    name: 'scheme-type-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/scheme-type/List.vue')
  },
  {
    path: 'sub-scheme-type-list',
    name: 'sub-scheme-type-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/sub-scheme-type/List.vue')
  },
  {
    path: 'pump-capacity',
    name: 'pump_capacity',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/pump-capacity/List.vue')
  },
  {
    path: 'water-testing-parameter',
    name: 'water-testing-parameter',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/water-testing-parameter/List.vue')
  },
  {
    path: 'laboratory-list',
    name: 'laboratory-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/laboratory/List.vue')
  },
  {
    path: 'payment-type-list',
    name: 'payment-type-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/payment-type/List.vue')
  },
  {
    path: 'scheme-form-affidavit-list',
    name: 'scheme-form-affidavit-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/scheme-form-affidavit/List.vue')
  },
  {
    path: 'pump-type-list',
    name: 'pump-type-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/pump-type/List.vue')
  },
  {
    path: 'pump-installation-progress-type-list',
    name: 'pump-installation-progress-type-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/pump-installation-progress-type/List.vue')
  },
  {
    path: 'item-category-list',
    name: 'item-category-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/item-category/List.vue')
  },
  {
    path: 'measurement-unit-list',
    name: 'measurement-unit-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/measurement-unit/List.vue')
  },
  {
    path: 'item-sub-category-list',
    name: 'item-sub-category-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/item-sub-category/List.vue')
  },
  {
    path: 'store-item-list',
    name: 'store-item-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/store-item/List.vue')
  },
  {
    path: 'horse-power-list',
    name: 'horse-power-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/horse-power/List.vue')
  },
    {
    path: 'circle-area-list',
    name: 'circle-area-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/circle-area/List.vue')
  },
  {
    path: 'payment',
    name: 'payment',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/payment/List.vue')
  },
  {
    path: 'refund-deduction',
    name: 'refund-deduction',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/refund-deduction/refundDeductionList.vue')
  },
  {
    path: 'excel-import',
    name: 'excel-import',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/excel-import/List.vue')
  },
  {
    path: 'received-notifications',
    name: 'received-notifications',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/received-notifications/List.vue')
  },
  {
    path: 'mouza-list',
    name: 'mouza_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/mouza/List.vue')
  }
]
const dashboardRoutes = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../pages/dashboard.vue')
  }
]
const routes = [
  {
    path: '/irrigation-scheme-service/configuration',
    name: 'irrigation_scheme_service.configuration',
    component: () => import('@/layouts/IrrigationSchemeLayout.vue'),
    meta: { auth: true },
    children: childRoutes('irrigation_scheme_service.configuration')
  },
  {
    path: '/irrigation-scheme-service',
    name: 'irrigation_scheme_service.dashboard',
    component: () => import('@/layouts/IrrigationSchemeLayout.vue'),
    meta: { auth: true },
    children: dashboardRoutes('irrigation_scheme_service.dashboard')
  }
]

export default routes
