const childRoute = (prop) => [
  {
    path: 'germplasm-collection-report',
    name: prop + '.germplasm_collection_report',
    meta: { auth: true },
    component: () => import('../pages/germplasm-collection-report/List.vue')
  },
  {
    path: 'germplasm-production-report',
    name: prop + '.germplasm_production_report',
    meta: { auth: true },
    component: () => import('../pages/germplasm-production-report/List.vue')
  },
  {
    path: 'germplasm-distribution-report',
    name: prop + '.germplasm_distribution_report',
    meta: { auth: true },
    component: () => import('../pages/germplasm-distribution-report/List.vue')
  },
  {
    path: 'germplasm-crop-excel',
    name: prop + '.germplasm_crop_excel',
    meta: { auth: true },
    component: () => import('../pages/germplasm-crop-excel/List.vue')
  },
  {
    path: 'germplasm-check-status-report',
    name: prop + '.germplasm_check_status_report',
    meta: { auth: true },
    component: () => import('../pages/germplasm-check-status-report/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/germplasm-report',
    name: 'seeds_fertilizer_service.germplasm-report',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.germplasm-report')
  }
]

export default routes
