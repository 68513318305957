const dashboardRoute = (prop) => [
    {
        path: 'profile',
        name: prop + '.profile',
        meta: { auth: true },
        component: () => import('../pages/profile/Show.vue')
    },
    {
        path: 'profile-update',
        name: prop + '.profile_update',
        meta: { auth: true },
        component: () => import('../pages/profile/Form.vue')
    }
]
const seedCertificationRoute = (prop) => [
    {
        path: 'field-certification-application',
        name: prop + '.field_certification_application',
        meta: { auth: true },
        component: () => import('../pages/field-certification-application/ApplicationForm')
    },
    {
        path: 'field-application-list',
        name: prop + '.field_application_list',
        meta: { auth: true },
        component: () => import('../pages/field-application-list/List.vue')
    },
    {
        path: 'lot-offer',
        name: prop + '.lot_offer',
        meta: { auth: true },
        component: () => import('../pages/lot-offer/List.vue')
    },
    {
        path: 'field-application-list/:status',
        name: prop + '.field_application_list',
        meta: { auth: true },
        component: () => import('../pages/field-application-list/List.vue')
    },
    {
        path: 'request-testing-tag',
        name: prop + '.request_testing_tag',
        meta: { auth: true },
        component: () => import('../pages/request-testing-tag/List.vue')
    },
    {
        path: 'lot-offer',
        name: prop + '.request_testing_tag',
        meta: { auth: true },
        component: () => import('../pages/field-application-list/test.vue')
    }
]

const publicationManagementRoute = (prop) => [
    {
        path: 'publication-proposal-submission',
        name: prop + '.publication_proposal_submission',
        meta: { auth: true },
        component: () => import('../pages/publication-proposal-submission/List.vue')
    },
    {
        path: 'content-submission-list',
        name: prop + '.content_submission_list',
        meta: { auth: true },
        component: () => import('../pages/content-submission-list/List.vue')
    },
    {
        path: 'publication-subscription-form',
        name: prop + '.publication_subscription_form',
        meta: { auth: true },
        component: () => import('../pages/publication-subscription-form/List.vue')
    },
    {
        path: 'subscription-list/:status',
        name: prop + '.subscription_list.status',
        meta: { auth: true },
        component: () => import('../pages/subscription-list/List.vue')
    },
    {
        path: 'subscription-list',
        name: prop + '.subscription_list',
        meta: { auth: true },
        component: () => import('../pages/subscription-list/List.vue')
    }

]
const requestTagRoute = (prop) => [
    {
        path: 'request-testing-tag-without-field-certification',
        name: prop + '.request-testing-tag-without-field-certification',
        meta: { auth: true },
        component: () => import('../pages/request-testing-tag-without-field-certification/List.vue')
    },
    {
        path: 'request-testing-tag-without-field-certification/:status',
        name: prop + '.request-testing-tag-without-field-certification',
        meta: { auth: true },
        component: () => import('../pages/request-testing-tag-without-field-certification/List.vue')
    },
    {
        path: 'request-testing-tag-without-field-certification-application',
        name: prop + '.request-testing-tag-without-field-certification-application',
        meta: { auth: true },
        component: () => import('../pages/request-testing-tag-without-field-certification/Application.vue')
    },
    {
        path: 'request-testing-tag-with-field-certification',
        name: prop + '.request_testing_tag_with_field_certification',
        meta: { auth: true },
        component: () => import('../pages/request-testing-tag-with-field-certification/List.vue')
    },
    {
        path: 'request-testing-tag-with-field-certification/:status',
        name: prop + '.request_testing_tag_with_field_certification',
        meta: { auth: true },
        component: () => import('../pages/request-testing-tag-with-field-certification/List.vue')
    },
    {
        path: 'test-result-and-tag-requisition',
        name: prop + '.test-result-and-tag-requisition',
        meta: { auth: true },
        component: () => import('../pages/test-result-and-tag-requisition/List.vue')
    },
    {
        path: 'test-result-and-tag-requisition/:status',
        name: prop + '.test-result-and-tag-requisition',
        meta: { auth: true },
        component: () => import('../pages/test-result-and-tag-requisition/List.vue')
    }
]

const researchManagementRoute = (prop) => [
    {
        path: 'proposal-circular-list',
        name: prop + '.proposal_circular_list',
        meta: { auth: true },
        component: () => import('../pages/research-management/proposal-circular-list/List.vue')
    },
    {
        path: 'proposal-submission-form',
        name: prop + '.proposal_submission_form',
        meta: { auth: true },
        component: () => import('../pages/research-management/proposal-submission/Form.vue')
    },
    {
        path: 'proposal-submission-entry',
        name: prop + '.proposal_submission_entry',
        meta: { auth: true },
        component: () => import('../pages/research-management/proposal-circular-list/Form.vue')
    },
    {
        path: 'proposal-submission',
        name: prop + '.proposal_submission',
        meta: { auth: true },
        component: () => import('../pages/research-management/proposal-submission/List.vue')
    },
    {
        path: 'activity-plan',
        name: prop + '.activity_plan',
        meta: { auth: true },
        component: () => import('../pages/research-management/activity-plan/List.vue')
    },
    {
        path: 'research-report-submission',
        name: prop + '.research_report_submission',
        meta: { auth: true },
        component: () => import('../pages/research-management/research-report-submission/List.vue')
    },
    {
        path: 'activity-plan-activities',
        name: prop + '.activity_plan_activities',
        meta: { auth: true },
        component: () => import('../pages/research-management/activity-plan/Activity.vue')
    },
    {
        path: 'fund-request',
        name: prop + '.fund_request',
        meta: { auth: true },
        component: () => import('../pages/research-management/fund-request/List.vue')
    },
    {
        path: 'research-budget-ledger',
        name: prop + '.research_budget_ledger',
        meta: { auth: true },
        component: () => import('../pages/research-management/research-budget-ledger/List.vue')
    },
    {
        path: 'monitoring-schedule',
        name: prop + '.monitoring_schedule',
        meta: { auth: true },
        component: () => import('../pages/research-management/monitoring-schedule/List.vue')
    },
    {
        path: 'monitoring-report',
        name: prop + '.monitoring_report',
        meta: { auth: true },
        component: () => import('../pages/research-management/monitoring-report/List.vue')
    }
]

const routes = [
    {
        path: '/research-farmer',
        name: 'external_user',
        component: () =>
            import('@/layouts/ExternalUserLayout.vue'),
        meta: { auth: true },
        children: dashboardRoute('external_user.research_farmer')
    },
    {
        path: '/research-farmer/seed-certification',
        name: 'external_user.seed_certification',
        component: () =>
            import('@/layouts/ExternalUserLayout.vue'),
        meta: { auth: true },
        children: seedCertificationRoute('external_user.research_farmer.seed_certification')
    },
    {
        path: '/research-farmer/request-testing-tag',
        name: 'external_user.request_testing_tag',
        component: () =>
            import('@/layouts/ExternalUserLayout.vue'),
        meta: { auth: true },
        children: requestTagRoute('external_user.research_farmer.request-testing-tag')
    },
    {
        path: '/research-farmer/publication-management',
        name: 'external_user.publication_management',
        component: () =>
            import('@/layouts/ExternalUserLayout.vue'),
        meta: { auth: true },
        children: publicationManagementRoute('external_user.research_farmer.publication_management')
    },
    {
        path: '/research-farmer/research-management',
        name: 'external_user.research_management',
        component: () =>
            import('@/layouts/ExternalUserLayout.vue'),
        meta: { auth: true },
        children: researchManagementRoute('external_user.research_farmer.research_management')
    }
]

export default routes
