const researchTypeList = [
    { value: 1, text_en: 'Individual Research', text_bn: 'ব্যক্তিগত গবেষণা' },
    { value: 2, text_en: 'Coordinate Research', text_bn: 'সমন্বয় গবেষণা' },
    { value: 3, text_en: 'Divisional Research', text_bn: 'বিভাগীয় গবেষণা' }
]
const activityStatusList = [
    { value: 0, text_en: 'Not Started', text_bn: 'এখনো শুরু হয়নি' },
    { value: 1, text_en: 'Ongoing', text_bn: 'চলমান' },
    { value: 2, text_en: 'Completed', text_bn: 'সম্পন্ন' },
    { value: 3, text_en: 'Completed (Waiting for admin)', text_bn: 'সম্পন্ন (এডমিনের অপেক্ষায়)' }
]
const orgTypeList = [
    { value: 1, text_en: 'Govt', text_bn: 'সরকারী' },
    { value: 2, text_en: 'Private', text_bn: 'ব্যক্তিগত' }
]

const organizationType = [
    {
        value: 1,
        text_en: 'Ministry',
        text_bn: 'মন্ত্রণালয়'
    },
    {
        value: 3,
        text_en: 'University',
        text_bn: 'বিশ্ববিদ্যালয়'
    },
    {
        value: 2,
        text_en: 'Others',
        text_bn: 'অন্যান্য'
    }
]

const publicationFeeTypeList = [
    { value: 1, text_en: '1-19', text_bn: '১-১৯' },
    { value: 2, text_en: 'Above 19', text_bn: '১৯ এর উপরে' }
]

export default {
    researchTypeList,
    activityStatusList,
    orgTypeList,
    organizationType,
    publicationFeeTypeList
}
