const childRoutes = (prop) => [
    {
        path: 'mapping-parameter-list',
        name: prop + '.mapping_parameter_list',
        component: () => import('../pages/mapping-parameter/List.vue')
    },
    {
        path: 'mapping-parameter-form/:parameter_id?',
        name: prop + '.mapping_parameter_form',
        component: () => import('../pages/mapping-parameter/Form.vue'),
        props: true
    },
    {
        path: 'pusti-mapping-form',
        name: prop + '.pusti_mapping_form',
        component: () => import('../pages/pusti-mapping-form/List.vue')
    },
    {
        path: 'pusti-mapping-form-entry/:mapping_id?',
        name: prop + '.pusti_mapping_form_entry',
        component: () => import('../pages/pusti-mapping-form/PustiMappingForm.vue'),
        props: true
    },
    {
        path: 'mapping-parameter-pdf',
        name: prop + 'mapping_parameter_pdf',
        meta: { auth: true },
        component: () => import('../pages/MappingParameterPdf.vue')
    },
    {
        path: 'mapping-report',
        name: prop + 'mapping_report',
        meta: { auth: true },
        component: () => import('../pages/MappingParameterReport.vue')
    },
    {
        path: 'mapping-report-visual',
        name: prop + 'mapping_report_visual',
        meta: { auth: true },
        component: () => import('../pages/MappingParameterReportVisual.vue')
    }
]
const routes = [
    {
    path: '/agri-marketing-service/pusti-mapping',
    name: 'agri_marketing_service.pusti_mapping',
    component: () => import('@/layouts/AgriMarketingLayout.vue'),
    meta: { auth: true },
    children: childRoutes('agri_marketing_service.pusti_mapping')
  }
]
export default routes
