export default {
  mutateLicenseRegistrationProperties ({ commit }, payload) {
      commit('mutateLicenseRegistrationProperties', payload)
  },
  changeLicenseRegistrationDropdown ({ commit }, payload) {
      commit('changeLicenseRegistrationDropdown', payload)
  },
  setLaodList ({ commit }, payload) {
    commit('setLaodList', payload)
}
}
