import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
const mergeLocaleMessage = (localeMessages) => {
  const messages = {}

  localeMessages.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = localeMessages(key)
    }
  })

  return messages
}

function loadLocaleMessages () {
  let locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const defaultMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/portal/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const portalMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/auth-service/auth/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const authMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/auth-service/user-management/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const userManagementMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/common-config/org-profile/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const orgProfileMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/common-config/organogram/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const organogramMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/common-config/complain/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const complainMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/common-config/notification/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const notificationMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/common-config/payment-management/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const paymentManagementMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/irrigation-scheme-service/task/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const irrigationSchemeTaskMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/irrigation-scheme-service/water-testing/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const irrigationSchemeWaterTestingMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/irrigation-scheme-service/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const irrigationSchemeMessagesConfiquration = mergeLocaleMessage(locales)
  locales = require.context('./modules/external-user-service/irrigation/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const externalUserIrrigationMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/irrigation-scheme-service/pump-installation/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const pumpInstallation = mergeLocaleMessage(locales)
  locales = require.context('./modules/common-config/document/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const documentMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/warehouse-service/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const warehouseConfigMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/warehouse-service/information/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const warehouseInfoMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/warehouse-service/ware-service/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const warehouseServiceMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/common-config/committee/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const committeeMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/common-config/data-archive/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const dataArchiveMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/irrigation-scheme-service/pump-information/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const irriPumpInformation = mergeLocaleMessage(locales)
  locales = require.context('./modules/irrigation-scheme-service/pump-maintenance/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const irriPumpMaintenance = mergeLocaleMessage(locales)
  locales = require.context('./modules/irrigation-scheme-service/farmer-operator-panel/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const farmerOperatorPanel = mergeLocaleMessage(locales)
  locales = require.context('./modules/irrigation-scheme-service/card-payment/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const irrigationSchemeCardPaymentManagementMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/warehouse-service/report/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const warehouseReportMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/warehouse-service/service-performance/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const warehouseServicePerformanceMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/warehouse-service/info-service/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const warehouseInfoServiceMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/warehouse-service/warehouse-management/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const warehouseWarehuseManagementMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/common-config/info-service-management/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const infoServiceMgmtMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/seeds/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const seedsConfigMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/crop/crop-info/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const cropInfoMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/fertilizer/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const fertilizerConfigMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/fertilizer/procurement/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const fertilizerProcurementMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/fertilizer/bcic/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const fertilizerBCICMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/fertilizer/movement/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const fertilizerMovementMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/seeds/seeds-seeds/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const seedsSeedsMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/seeds/application-allocation/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const seedsApplicationAllocationMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/seeds/seeds-reports/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const seedsReportsMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/fertilizer/fertilizer-reports/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const fertilizerReportsMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/fertilizer/dealer-management/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const fertilizerDealerManagement = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-marketing/master/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriMarketingMaster = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-marketing/cotton/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriMarketingCottonConfiguration = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-marketing/cotton/ginner-grower/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriMarketingCottonGinnerGrower = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-marketing/cotton/reports/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriMarketingCottonReports = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/fertilizer/sales/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const fertilizerSalesMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-marketing/crop-price-info/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriMarketingCropPriceInfoConfiguration = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-marketing/e-pusti/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriMarketingEPustiConfiguration = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-marketing/e-pusti/birtan/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriMarketingEPustiBirtan = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-marketing/pusti-mapping/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriMarketingPustiMapping = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-marketing/market-linkage/linkage/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriMarketingMarketLinkageLinkage = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-marketing/market-linkage/config/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriMarketingMarketLinkageconfig = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/germplasm/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const seedsFerGermplasmConfigurationMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/germplasm/report/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const seedsFerGermplasmReportMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/seeds-fertilizer-service/germplasm/germplasm/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const seedsFerGermplasmGermplasmMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-marketing/crop-price-info/price-information/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const cropPriceInfoPrice = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-marketing/crop-price-info/reports/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const cropPriceReports = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-marketing/market-linkage/report/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const marketLinkageReport = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-marketing/market-linkage/linkage/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const marketLinkage = mergeLocaleMessage(locales)

  // Incentive Grant Service Started from here
  locales = require.context('./modules/incentive-grant-service/master/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveGrantMaster = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/barc/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveGrantBarcConfiguration = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/barc/grant-distribution/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveGrantBarcGrantDistribution = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/barc/report/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveGrantBarcReport = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/bsri/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveGrantBSRIConfiguration = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/bsri/demand-distribution/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveGrantBSRIDemandDistribution = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/bsri/reports/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveGrantBSRIReport = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/dae/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveGrantDAEConfiguration = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/dae/grant-allocation-distribution/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveGrantDAEGrantAllocationDistribution = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/dae/purchase-inventory/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveGrantDAESubsidy = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/dae/subsidy/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveGrantDAEpurchInventory = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/dae/disburse-distribution/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveGrantDAEDisburseDistribution = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/common-configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveGrantDAEReports = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/dae/reports/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveGrantCommonConfiguration = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/dae/allocation/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveDaeAllocation = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/dae/financial-aid/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveDaeFinancialAid = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/farm-machinery/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveFarmMachinery = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/farm-machinery/allocation/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveFarmMachineryAllocation = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/farm-machinery/application-management/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveFarmMachineryApplicationManaement = mergeLocaleMessage(locales)
  locales = require.context('./modules/incentive-grant-service/farm-machinery/reports/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const incentiveFarmMachineryReports = mergeLocaleMessage(locales)

  // Training Learning Service
  locales = require.context('./modules/training-e-learning-service/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const trainingElearningConfiguration = mergeLocaleMessage(locales)
  locales = require.context('./modules/training-e-learning-service/accommodation-management/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const trainingElearningAccommodationManagement = mergeLocaleMessage(locales)
  locales = require.context('./modules/training-e-learning-service/iabm/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const trainingElearningIabm = mergeLocaleMessage(locales)
  locales = require.context('./modules/training-e-learning-service/tpm/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const trainingElearningTpm = mergeLocaleMessage(locales)
  locales = require.context('./modules/training-e-learning-service/venue/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const trainingElearningVenue = mergeLocaleMessage(locales)
  locales = require.context('./modules/training-e-learning-service/report/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const trainingElearningReport = mergeLocaleMessage(locales)
  locales = require.context('./modules/external-user-service/training/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const externalUserTrainingMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/external-user-service/incentive/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const externalUserIncentiveMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/training-e-learning-service/tim/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const trainingElearningTim = mergeLocaleMessage(locales)
  locales = require.context('./modules/training-e-learning-service/elearning-management/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const trainingElearningManagement = mergeLocaleMessage(locales)
  // License Registration Service Started from here
  locales = require.context('./modules/license-registration-service/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const licenseRegistrationConfiguration = mergeLocaleMessage(locales)
  locales = require.context('./modules/license-registration-service/license-step/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const licenseRegistrationLicenseStep = mergeLocaleMessage(locales)
  locales = require.context('./modules/external-user-service/lrcpn/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const externalLrcpn = mergeLocaleMessage(locales)
  locales = require.context('./modules/license-registration-service/foreign-enlistment/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const licenseRegistrationLicenseForeignEnlistment = mergeLocaleMessage(locales)
  /** agri research */
  locales = require.context('./modules/external-user-service/agri-research/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const externalUserResearchMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-research/seed-certification/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriResearchSeedCertificationMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-research/testing-service/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriResearchTestingServiceMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-research/research-management/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriResearchManagementMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-research/publication-management/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriPublicationManagementMessages = mergeLocaleMessage(locales)
  locales = require.context('./modules/agri-research/national-crop-data-bank/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriResearchNcdbMessages = mergeLocaleMessage(locales)
  /** Virtual Museum Service */
  locales = require.context('./modules/virtual-museum-service/configuration/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const VirtualMuseumConfiguration = mergeLocaleMessage(locales)
  locales = require.context('./modules/virtual-museum-service/museum-information-management/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const VirtualMuseumManagement = mergeLocaleMessage(locales)
  locales = require.context('./modules/virtual-museum-service/soil-management-system/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const VirtualSoilManagement = mergeLocaleMessage(locales)
  locales = require.context('./modules/virtual-museum-service/content-management-system/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const VirtualContentManagement = mergeLocaleMessage(locales)
  locales = require.context('./modules/common-config/portal/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const agriPortalLocale = mergeLocaleMessage(locales)
  locales = require.context('./modules/virtual-museum-service/pest-management/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const virtualMuseumPestManageLocale = mergeLocaleMessage(locales)

  locales = require.context('./modules/virtual-museum-service/tour-management/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const virtualMuseumTOurManageLocale = mergeLocaleMessage(locales)
  const dateTimeFormats = {
    en: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric'
      },
      time: {
        hour: 'numeric',
        minute: 'numeric'
      }
    },
    bn: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      },
      time: {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }
    }
  }
  const allMessages = {
    bn: Object.assign(
      {},
      dateTimeFormats.bn,
      defaultMessages.bn,
      portalMessages.bn,
      authMessages.bn,
      userManagementMessages.bn,
      orgProfileMessages.bn,
      organogramMessages.bn,
      complainMessages.bn,
      notificationMessages.bn,
      irrigationSchemeMessagesConfiquration.bn,
      irrigationSchemeTaskMessages.bn,
      irrigationSchemeWaterTestingMessages.bn,
      externalUserIrrigationMessages.bn,
      pumpInstallation.bn,
      irriPumpMaintenance.bn,
      documentMessages.bn,
      paymentManagementMessages.bn,
      warehouseConfigMessages.bn,
      committeeMessages.bn,
      irriPumpInformation.bn,
      farmerOperatorPanel.bn,
      warehouseInfoMessages.bn,
      warehouseServiceMessages.bn,
      irrigationSchemeCardPaymentManagementMessages.bn,
      warehouseReportMessages.bn,
      warehouseServicePerformanceMessages.bn,
      warehouseInfoServiceMessages.bn,
      warehouseWarehuseManagementMessages.bn,
      infoServiceMgmtMessages.bn,
      seedsConfigMessages.bn,
      fertilizerConfigMessages.bn,
      dataArchiveMessages.bn,
      infoServiceMgmtMessages.bn,
      fertilizerProcurementMessages.bn,
      fertilizerBCICMessages.bn,
      seedsSeedsMessages.bn,
      seedsApplicationAllocationMessages.bn,
      seedsReportsMessages.bn,
      fertilizerReportsMessages.bn,
      fertilizerDealerManagement.bn,
      fertilizerMovementMessages.bn,
      agriMarketingMaster.bn,
      agriMarketingCottonConfiguration.bn,
      agriMarketingCottonReports.bn,
      agriMarketingEPustiConfiguration.bn,
      agriMarketingEPustiBirtan.bn,
      agriMarketingCottonGinnerGrower.bn,
      fertilizerSalesMessages.bn,
      agriMarketingCottonGinnerGrower.bn,
      agriMarketingCropPriceInfoConfiguration.bn,
      agriMarketingPustiMapping.bn,
      agriMarketingMarketLinkageLinkage.bn,
      agriMarketingMarketLinkageconfig.bn,
      seedsFerGermplasmConfigurationMessages.bn,
      seedsFerGermplasmReportMessages.bn,
      seedsFerGermplasmGermplasmMessages.bn,
      cropPriceInfoPrice.bn,
      cropPriceReports.bn,
      marketLinkageReport.bn,
      marketLinkage.bn,
      incentiveGrantMaster.bn,
      incentiveGrantBarcConfiguration.bn,
      incentiveGrantBSRIConfiguration.bn,
      incentiveGrantDAEConfiguration.bn,
      incentiveGrantDAEGrantAllocationDistribution.bn,
      incentiveGrantDAESubsidy.bn,
      incentiveGrantDAEpurchInventory.bn,
      incentiveGrantDAEDisburseDistribution.bn,
      incentiveGrantDAEReports.bn,
      trainingElearningConfiguration.bn,
      trainingElearningAccommodationManagement.bn,
      incentiveGrantBarcGrantDistribution.bn,
      trainingElearningIabm.bn,
      trainingElearningTpm.bn,
      trainingElearningVenue.bn,
      trainingElearningReport.bn,
      externalUserTrainingMessages.bn,
      incentiveGrantBarcReport.bn,
      incentiveGrantCommonConfiguration.bn,
      trainingElearningTim.bn,
      trainingElearningManagement.bn,
      incentiveGrantBSRIDemandDistribution.bn,
      trainingElearningTim.bn,
      licenseRegistrationConfiguration.bn,
      licenseRegistrationLicenseStep.bn,
      externalLrcpn.bn,
      incentiveGrantBSRIReport.bn,
      agriPortalLocale.bn,
      externalUserIncentiveMessages.bn,
      incentiveDaeAllocation.bn,
      VirtualMuseumConfiguration.bn,
      VirtualMuseumManagement.bn,
      virtualMuseumPestManageLocale.bn,
      incentiveDaeFinancialAid.bn,
      VirtualSoilManagement.bn,
      VirtualContentManagement.bn,
      externalUserResearchMessages.bn,
      agriResearchSeedCertificationMessages.bn,
      agriResearchTestingServiceMessages.bn,
      agriResearchNcdbMessages.bn,
      agriResearchManagementMessages.bn,
      agriPublicationManagementMessages.bn,
      virtualMuseumTOurManageLocale.bn,
      licenseRegistrationLicenseForeignEnlistment.bn,
      cropInfoMessages.bn,
      incentiveFarmMachinery.bn,
      incentiveFarmMachineryAllocation.bn,
      incentiveFarmMachineryApplicationManaement.bn,
      incentiveFarmMachineryReports.bn
      ),
    en: Object.assign(
      {},
      dateTimeFormats.en,
      defaultMessages.en,
      portalMessages.en,
      authMessages.en,
      userManagementMessages.en,
      orgProfileMessages.en,
      organogramMessages.en,
      complainMessages.en,
      notificationMessages.en,
      irrigationSchemeTaskMessages.en,
      irrigationSchemeWaterTestingMessages.en,
      irrigationSchemeMessagesConfiquration.en,
      externalUserIrrigationMessages.en,
      pumpInstallation.en,
      irriPumpMaintenance.en,
      documentMessages.en,
      paymentManagementMessages.en,
      warehouseConfigMessages.en,
      committeeMessages.en,
      irriPumpInformation.en,
      farmerOperatorPanel.en,
      warehouseInfoMessages.en,
      warehouseServiceMessages.en,
      irrigationSchemeCardPaymentManagementMessages.en,
      warehouseReportMessages.en,
      warehouseServicePerformanceMessages.en,
      warehouseInfoServiceMessages.en,
      warehouseWarehuseManagementMessages.en,
      infoServiceMgmtMessages.en,
      seedsConfigMessages.en,
      fertilizerConfigMessages.en,
      dataArchiveMessages.en,
      infoServiceMgmtMessages.en,
      fertilizerProcurementMessages.en,
      fertilizerBCICMessages.en,
      seedsSeedsMessages.en,
      seedsApplicationAllocationMessages.en,
      seedsReportsMessages.en,
      fertilizerReportsMessages.en,
      fertilizerDealerManagement.en,
      fertilizerMovementMessages.en,
      agriMarketingMaster.en,
      agriMarketingCottonConfiguration.en,
      agriMarketingCottonReports.en,
      agriMarketingEPustiConfiguration.en,
      agriMarketingEPustiBirtan.en,
      agriMarketingCottonGinnerGrower.en,
      fertilizerSalesMessages.en,
      agriMarketingCottonGinnerGrower.en,
      agriMarketingCropPriceInfoConfiguration.en,
      agriMarketingPustiMapping.en,
      agriMarketingMarketLinkageLinkage.en,
      agriMarketingMarketLinkageconfig.en,
      seedsFerGermplasmConfigurationMessages.en,
      seedsFerGermplasmReportMessages.en,
      seedsFerGermplasmGermplasmMessages.en,
      cropPriceReports.en,
      marketLinkageReport.en,
      marketLinkage.en,
      incentiveGrantMaster.en,
      incentiveGrantBarcConfiguration.en,
      incentiveGrantBSRIConfiguration.en,
      incentiveGrantDAEConfiguration.en,
      incentiveGrantDAEGrantAllocationDistribution.en,
      incentiveGrantDAESubsidy.en,
      incentiveGrantDAEpurchInventory.en,
      incentiveGrantDAEDisburseDistribution.en,
      incentiveGrantDAEReports.en,
      trainingElearningConfiguration.en,
      trainingElearningAccommodationManagement.en,
      cropPriceInfoPrice.en,
      incentiveGrantBarcGrantDistribution.en,
      trainingElearningIabm.en,
      trainingElearningTpm.en,
      trainingElearningVenue.en,
      trainingElearningReport.en,
      externalUserTrainingMessages.en,
      incentiveGrantBarcReport.en,
      incentiveGrantCommonConfiguration.en,
      trainingElearningTim.en,
      trainingElearningManagement.en,
      incentiveGrantBSRIDemandDistribution.en,
      trainingElearningTim.en,
      licenseRegistrationConfiguration.en,
      licenseRegistrationLicenseStep.en,
      externalLrcpn.en,
      incentiveGrantBSRIReport.en,
      agriPortalLocale.en,
      externalUserIncentiveMessages.en,
      incentiveDaeAllocation.en,
      VirtualMuseumConfiguration.en,
      VirtualMuseumManagement.en,
      virtualMuseumPestManageLocale.en,
      incentiveDaeFinancialAid.en,
      VirtualSoilManagement.en,
      VirtualContentManagement.en,
      externalUserResearchMessages.en,
      agriResearchSeedCertificationMessages.en,
      agriResearchTestingServiceMessages.en,
      agriResearchNcdbMessages.en,
      agriResearchManagementMessages.en,
      agriPublicationManagementMessages.en,
      virtualMuseumTOurManageLocale.en,
      licenseRegistrationLicenseForeignEnlistment.en,
      cropInfoMessages.en,
      incentiveFarmMachinery.en,
      incentiveFarmMachineryAllocation.en,
      incentiveFarmMachineryApplicationManaement.en,
      incentiveFarmMachineryReports.en
    )
  }
  return allMessages
}
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
