const childRoutes = (prop) => [
  {
    path: 'training-calendar',
    name: prop + 'training_calendar',
    meta: { auth: true },
    component: () => import('../pages/training-calendar/List.vue')
  },
  {
    path: 'training-calendar-form',
    name: prop + 'training_calendar_form',
    meta: { auth: true },
    component: () => import('../pages/training-calendar/Form.vue')
  },
  {
    path: 'trainer-document',
    name: prop + 'trainer_document',
    meta: { auth: true },
    component: () => import('../pages/trainer-profile/List.vue')
  },
  {
    path: 'course-managment',
    name: prop + 'course_managment',
    meta: { auth: true },
    component: () => import('../pages/course-managment/List.vue')
  },
  {
    path: 'course-managment-form',
    name: prop + 'course_managment_form',
    meta: { auth: true },
    component: () => import('../pages/course-managment/Form.vue')
  },
  {
    path: 'course-module',
    name: prop + 'course_module',
    meta: { auth: true },
    component: () => import('../pages/course-module/List.vue')
  },
  {
    path: 'course-module-form',
    name: prop + 'course_module_form',
    meta: { auth: true },
    component: () => import('../pages/course-module/Form.vue')
  },
  {
    path: 'training-schedule',
    name: prop + 'training_schedule',
    meta: { auth: true },
    component: () => import('../pages/training-schedule/List.vue')
  },
  {
    path: 'routine',
    name: prop + 'routine',
    meta: { auth: true },
    component: () => import('../pages/training-schedule/routine.vue')
  },
  {
    path: 'training-schedule-form',
    name: prop + 'training_schedule-form',
    meta: { auth: true },
    component: () => import('../pages/training-schedule/Form.vue')
  },
  {
    path: 'trainer-sorting',
    name: prop + 'trainer_sorting',
    meta: { auth: true },
    component: () => import('../pages/trainer-sorting/List.vue')
  },
  {
    path: 'trainer-sorting-form',
    name: prop + 'trainer_sorting_form',
    meta: { auth: true },
    component: () => import('../pages/trainer-sorting/Form.vue')
  },
  {
    path: 'trainer-selection',
    name: prop + 'trainer_selection',
    meta: { auth: true },
    component: () => import('../pages/trainer-selection/List.vue')
  },
  {
    path: 'trainer-selection-form',
    name: prop + 'trainer_selection',
    meta: { auth: true },
    component: () => import('../pages/trainer-selection/Form.vue')
  },
  {
    path: 'training-attendance-sheet',
    name: prop + 'training_attendance_sheet',
    meta: { auth: true },
    component: () => import('../pages/training-attendance-sheet/Attendance.vue')
  },
  {
    path: 'other-trainee-trainer-registration-list',
    name: prop + 'other_trainee_trainer_registration_list',
    meta: { auth: true },
    component: () => import('../pages/other-trainee-trainer-registration/List.vue')
  },
  {
    path: 'other-training-calender-list',
    name: prop + 'other_training_calender_list',
    meta: { auth: true },
    component: () => import('../pages/other-training-calender/List.vue')
  },
  {
    path: 'other-trainee-trainer-registration-details',
    name: prop + 'other_trainee_trainer_registration_details',
    meta: { auth: true },
    component: () => import('../pages/other-trainee-trainer-registration/Details.vue')
  },
  {
    path: 'other-training-calender-details',
    name: prop + 'other_training_calender_details',
    meta: { auth: true },
    component: () => import('../pages/other-training-calender/Details.vue')
  },
  {
    path: 'other-trainee-trainer-registration-form',
    name: prop + 'other_trainee_trainer_registration_form',
    meta: { auth: true },
    component: () => import('../pages/other-trainee-trainer-registration/Form.vue')
  },
  {
    path: 'other-training-calender-form',
    name: prop + 'other_training_calender_form',
    meta: { auth: true },
    component: () => import('../pages/other-training-calender/Form.vue')
  },
  {
    path: 'attendance-upload',
    name: prop + 'attendance_upload',
    meta: { auth: true },
    component: () => import('../pages/attendance-upload/List.vue')
  },
  {
    path: 'driver-assign',
    name: prop + 'driver_assign',
    meta: { auth: true },
    component: () => import('../pages/driver-assign/List.vue')
  },
  {
    path: 'driver-assign-form',
    name: prop + 'driver_assign_form',
    meta: { auth: true },
    component: () => import('../pages/driver-assign/Form.vue')
  },
  {
    path: 'other-training-schedule',
    name: prop + 'other_training_schedule',
    meta: { auth: true },
    component: () => import('../pages/other-training-schedule/List.vue')
  },
  {
    path: 'other-training-schedule-form',
    name: prop + 'other_training_schedule-form',
    meta: { auth: true },
    component: () => import('../pages/other-training-schedule/Form.vue')
  },
  {
    path: 'hand-note',
    name: prop + 'hand_note',
    meta: { auth: true },
    component: () => import('../pages/hand-note/List.vue')
  },
  {
    path: 'hand-note-entry-list',
    name: prop + 'hand_note_entry_list',
    meta: { auth: true },
    component: () => import('../pages/hand-note/TrainerList.vue')
  }
]

const routes = [
    {
      path: '/training-e-learning-service/tpm',
      name: 'training_e_learning_service.tpm',
      component: () => import('@/layouts/TrainingElearningLayout.vue'),
      meta: { auth: true },
      children: childRoutes('training_e_service.barc.tpm')
    }
]

export default routes
