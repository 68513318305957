const childRoutes = (prop) => [
    {
        path: 'agriculture-metarial-type-setup',
        name: prop + 'agriculture_metarial_type_setup',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/agriculture-metarial-type-setup/List.vue')
    },
    {
        path: 'agriculture-material-setup',
        name: prop + 'agriculture_material_setup',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/agriculture-material-setup/List.vue')
    },
    {
        path: 'crop-wise-agricultural-material-mapping',
        name: prop + 'crop_wise_agricultural_material_mapping',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/crop-wise-agricultural-material-mapping/List.vue')
    },
    {
        path: 'supplier-information-setup',
        name: prop + 'supplier_information_setup',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/supplier-information-setup/List.vue')
    },
    {
        path: 'subsidy-rate',
        name: prop + 'subsidy_rate',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/subsidy-rate/List.vue')
    },
    {
        path: 'agri-instrument',
        name: prop + 'agri_instrument',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/agri-instrument/List.vue')
    },
    {
        path: 'distribution-policy',
        name: prop + 'distribution_policy',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/distribution-policy/List.vue')
    },
    {
        path: 'circular-setup',
        name: prop + 'circular_setup',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/circular-setup/List.vue')
    }
]

const routes = [
    {
        path: '/incentive-grant-service/dae/configuration',
        name: 'incentive_grant_service.dae.configuration',
        component: () => import('@/layouts/IncentiveGrantLayout.vue'),
        meta: { auth: true },
        children: childRoutes('incentive_grant_service.dae.configuration')
    }
]

export default routes
