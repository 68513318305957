export const mutations = {
    setUserTypeList (state, payload) {
        state.userTypeList = payload
    },
    addUser (state, user) {
        state.users.push(user)
    },
    // Set Farmer Details List
    setFarmerDetails (state, payload) {
        state.farmerUserDetails = payload
    }
}
