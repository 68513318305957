const childRoutes = (prop) => [
  {
    path: 'market-daily-price-report',
    name: prop + 'market_daily_price_report',
    meta: {
      auth: false,
      name: 'Editable'
    },
    component: () => import('../pages/market-daily-price-report/List.vue')
  },
  {
    path: 'monthly-average-price-report',
    name: prop + 'monthly_average_price_report',
    meta: {
        auth: false,
        name: 'Editable'
    },
    component: () => import('../pages/mothly-average-market-price-report/List.vue')
  },
  {
    path: 'product-wise-market-price-report',
    name: prop + 'product_wise_market_price_report',
    meta: {
      auth: false,
      name: 'Editable'
    },
    component: () => import('../pages/product-wise-market-price-report/List.vue')
  },
  {
    path: 'weekly-market-wise-price-report',
    name: prop + 'weekly_market_wise_price_report',
    meta: {
        auth: false,
        name: 'Editable'
    },
    component: () => import('../pages/weekly-market-wise-price-report/List.vue')
  },
  {
    path: 'yearly-average-market-price-report',
    name: prop + 'yearly_average_market_price_report',
    meta: {
        auth: false,
        name: 'Editable'
    },
    component: () => import('../pages/yearly-average-market-price-report/List.vue')
  },
  {
    path: 'division-wise-average-market-price-report',
    name: prop + 'division_wise_average_market_price_report',
    meta: {
        auth: false,
        name: 'Editable'
    },
    component: () => import('../pages/division-wise-average-market-price-report/List.vue')
  },
  {
    path: 'market-directory-report',
    name: prop + 'market_directory_report',
    meta: {
        auth: false,
        name: 'Editable'
    },
    component: () => import('../pages/market-directory-report/List.vue')
  },
  {
    path: 'weekly-average-price-report',
    name: prop + 'weekly_average_price_report',
    meta: {
        auth: false,
        name: 'Editable'
    },
    component: () => import('../pages/weekly-average-price-report/List.vue')
  },
  {
    path: 'grower-avg-price-report',
    name: prop + 'grower_avg_price_report',
    meta: {
        auth: false,
        name: 'Editable'
    },
    component: () => import('../pages/grower-avg-price-report/List.vue')
  },
  {
    path: 'comparative-market-price',
    name: prop + 'comparative_market_price',
    meta: {
        auth: false,
        name: 'Editable'
    },
    component: () => import('../pages/comparative-market-price/List.vue')
  },
  {
    path: 'price-graphical-report',
    name: prop + 'price_graphical_report',
    meta: {
        auth: false,
        name: 'Editable'
    },
    component: () => import('../pages/price-graphical-report/List.vue')
  }
]
const routes = [{
  path: '/agri-marketing/crop-price-info/reports',
  name: 'agri-marketing.crop_price_info.reports',
  component: () => import('@/layouts/AgriMarketingLayout.vue'),
  meta: { auth: false },
  children: childRoutes('agri-marketing.crop_price_info.reports')
}]

export default routes
