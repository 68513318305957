export default {
  userFind: (state) => (id) => state.users.find(user => user.id === id),
  projectFind: (state) => (id) => state.projects.find(project => project.id === id),
  equipmentTypeFind: (state) => (id) => state.equipmentTypes.find(equipmentType => equipmentType.id === id),
  paymentTypeFind: (state) => (id) => state.paymentTypes.find(payment => payment.id === id),
  schemeTypeFind: (state) => (id) => state.schemeTypes.find(schemeType => schemeType.id === id),
  pumpTypeFind: (state) => (id) => state.pumpTypes.find(pumpType => pumpType.id === id),
  laboratoryFind: (state) => (id) => state.laboratories.find(laboratory => laboratory.id === id),
  contractorFind: (state) => (id) => state.contractors.find(contractor => contractor.id === id)
}
