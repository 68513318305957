export default {
    // New Application Request
    addNewApplicationRequest ({ commit }, payload) {
        commit('addNewApplicationRequest', payload)
    },
    addNewApplicationRequestList ({ commit }, payload) {
        commit('addNewApplicationRequestList', payload)
    },
    updateNewApplicationRequest ({ commit }, payload) {
        commit('updateNewApplicationRequest', payload)
    },
    toggleNewApplicationRequestStatus ({ commit }, payload) {
        commit('toggleNewApplicationRequestStatus', payload)
    },
    // Reissue Application Request
    addReissueApplicationRequest ({ commit }, payload) {
        commit('addReissueApplicationRequest', payload)
    },
    addReissueApplicationRequestList ({ commit }, payload) {
        commit('addReissueApplicationRequestList', payload)
    },
    updateReissueApplicationRequest ({ commit }, payload) {
        commit('updateReissueApplicationRequest', payload)
    },
    toggleReissueApplicationRequestStatus ({ commit }, payload) {
        commit('toggleReissueApplicationRequestStatus', payload)
    },
    // Smart Card Approves Request
    addSmartCardApprove ({ commit }, payload) {
        commit('addSmartCardApprove', payload)
    },
    addSmartCardApproveList ({ commit }, payload) {
        commit('addSmartCardApproveList', payload)
    },
    updateSmartCardApprove ({ commit }, payload) {
        commit('updateSmartCardApprove', payload)
    },
    toggleSmartCardApproveStatus ({ commit }, payload) {
        commit('toggleSmartCardApproveStatus', payload)
    },
    // Smart Card Approves Request
    addSmartCard ({ commit }, payload) {
        commit('addSmartCard', payload)
    },
    addSmartCardList ({ commit }, payload) {
        commit('addSmartCardList', payload)
    },
    updateSmartCard ({ commit }, payload) {
        commit('updateSmartCard', payload)
    },
    toggleSmartCardStatus ({ commit }, payload) {
        commit('toggleSmartCardStatus', payload)
    },
    // Smart-card-application
    getSMCApplicationList ({ commit }, payload) {
        commit('getSMCApplicationList', payload)
    }
}
