const dashboardRoute = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.component_dashboard',
    meta: { auth: true },
    component: () => import('../pages/component-dashboard/index.vue')
  }
]
const childRoute = (prop) => [
  {
    path: 'component-dashboard',
    name: prop + '.component_dashboard',
    meta: { auth: true },
    component: () => import('../pages/component-dashboard/index.vue')
  },
  {
    path: 'user-list',
    name: prop + '.user_list',
    meta: { auth: true },
    component: () => import('../pages/user/List.vue')
  },
  {
    path: 'test',
    name: prop + '.test',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/sub-group/List.vue')
  },

  {
    path: 'sub-group',
    name: prop + '.commodity.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/sub-group/List.vue')

  },
  {
    path: 'warehouse-type',
    name: prop + '.warehouse-type.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/warehouse-type/List.vue')

  },
  {
    path: 'crop-type',
    name: prop + '.crop-type.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/crop-type/List.vue')
  },
  {
    path: 'crop-name',
    name: prop + '.crop-name.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/crop-name/List.vue')
  },
  {
    path: 'commodity-designation',
    name: prop + '.commodity-designation.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/designation/List.vue')
  },
  {
    path: 'commodity-market-Price',
    name: prop + '.commodity-market-Price.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/market-price/List.vue')
  },
  {
    path: 'warehouse-information',
    name: prop + '.warehouse-information.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/warehouse-information/List.vue')
  },
  {
    path: 'commodity-fiscal-year',
    name: prop + '.commodity-fiscal-year.list',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/commodity-fiscal-year/List.vue')
  },
  {
    path: 'loan-rate',
    name: prop + '.loan-rate.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/loan-rate/List.vue')
  },
  {
    path: 'bank-loan-calculator',
    name: prop + '.bank-loan-calculator.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/bank-loan-calculator/calculator.vue')
  },
  {
    path: 'region',
    name: prop + '.region.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/region/List.vue')
  },
  {
    path: 'warehouse-level',
    name: prop + '.warehouse-level.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/warehouse-level/List.vue')
  },
  {
    path: 'bank-loan-facility',
    name: prop + '.bank-loan-facility.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/bank-loan-facility/List.vue')
  },
  {
    path: 'warehouse-rent',
    name: prop + '.warehouse-rent.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/warehouse-rent/List.vue')
  },
  {
    path: 'warehouse-services',
    name: prop + '.warehouse-service.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/warehouse-service/List.vue')
  },
  {
    path: 'warehouse-opening-balance',
    name: prop + '.warehouse-opening-balance.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/warehouse-opening-balance/List.vue')
  },
  {
    path: 'ware-report-heading',
    name: prop + '.ware-report-heading.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/report-heading/List.vue')
  },
  {
    path: 'excel-import',
    name: prop + '.excel-import.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/excel-import/List.vue')
  },
  {
    path: 'service-type',
    name: prop + '.service-type.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/service-type/List.vue')
  },
  {
    path: 'pre-reqisite-check-list',
    name: prop + '.pre-reqisite-check-list.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/pre-reqisite-check-list/List.vue')
  },
  {
    path: 'category',
    name: prop + '.category.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/warehouse-category/List.vue')
  },
  {
    path: 'market-infos',
    name: prop + '.marketInfos.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/market-infos/List.vue')
  }
]

const routes = [
  {
    path: '/warehouse/master',
    name: 'warehouse.master',
    component: () => import('@/layouts/WarehouseLayout.vue'),
    meta: { auth: true },
    children: childRoute('warehouse.master')
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    component: () => import('@/layouts/WarehouseLayout.vue'),
    meta: { auth: true },
    children: dashboardRoute('warehouse')
  }
]

export default routes
