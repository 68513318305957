const childRoute = (prop) => [
  {
    path: 'complain-type-list',
    name: prop + '.complain-type-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/complain-type/List.vue')
  },
  {
    path: 'submit-complain-list',
    name: prop + '.submit-complain-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/submit-complain/List.vue')
  },
  {
    path: 'complain-designation-list',
    name: prop + '.complain-designation.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/complain-designation/List.vue')
  },
  {
    path: 'complain-chain-list',
    name: prop + '.complain-chain-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/complain-chain/List.vue')
  },
  {
    path: 'complain-request-list',
    name: prop + '.complain-request.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/complain-request/List.vue')
  },
  {
    path: 'complain-receive-list',
    name: prop + '.complain-receive.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/complain-receive/List.vue')
  },
  {
    path: 'add-committee/:complainId',
    name: prop + '.add-committee',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/complain-request/AddCommittee.vue')
  },
  {
    path: 'complain-report-list',
    name: prop + '.complain-report.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/complain-report/List.vue')
  }
]

const routes = [
  {
    path: '/complain',
    name: 'complain',
    component: () => import('../../../../layouts/VerticleLayout.vue'),
    meta: { auth: true },
    children: childRoute('complain')
  }
]

export default routes
