const childRoutes = (prop) => [
  {
    path: 'farmer-information',
    name: prop + 'farmer_information',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/farmer-information/List.vue')
  },
  {
      path: 'farmer-information-form',
      name: prop + 'farmer_information_form',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/farmer-information/Form.vue')
  },
  {
    path: 'report-heading',
    name: prop + 'report_heading',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/report-heading/List.vue')
  }
]

const routes = [
    {
        path: '/incentive-grant-service/common-configuration',
        name: 'incentive_grant_service.common_configuration',
        component: () => import('@/layouts/IncentiveGrantLayout.vue'),
        meta: { auth: true },
        children: childRoutes('incentive_grant_service.common-configuration')
    }
]

export default routes
