const paymentType = [
    {
        value: 'Trainer',
        text_en: 'Trainer',
        text_bn: 'প্রশিক্ষক'
    },
    {
        value: 'Trainee',
        text_en: 'Trainee',
        text_bn: 'প্রশিক্ষণার্থী'
    }
]
const committeeType = [
    {
        value: 1,
        text_en: 'Course Management Team',
        text_bn: 'কোর্স ম্যানেজমেন্ট টিম'
    },
    {
        value: 2,
        text_en: 'Venue Management Team',
        text_bn: 'ভেন্যু ম্যানেজমেন্ট টিম'
    },
    {
        value: 3,
        text_en: 'Support Service Team',
        text_bn: 'সাপোর্ট সার্ভিস টিম '
    }
]
const sheduleType = [
    {
        value: 'Other',
        text_en: 'Other',
        text_bn: 'অন্যান্য'
    },
    {
        value: 'Trainer',
        text_en: 'Trainer',
        text_bn: 'প্রশিক্ষক'
    }
]
const jobTypeList = [
    {
        value: '1',
        text_en: 'Cader',
        text_bn: 'ক্যাডার'
    },
    {
        value: '2',
        text_en: 'Non-Cader',
        text_bn: 'নন-ক্যাডার'
    }
]
const userPriceTypeList = [
    {
        value: 1,
        text_en: 'Own Organization',
        text_bn: 'নিজস্ব প্রতিষ্ঠানের'
    },
    {
        value: 2,
        text_en: 'Govt',
        text_bn: 'সরকারী'
    },
    {
        value: 3,
        text_en: 'Private',
        text_bn: 'ব্যক্তিগত'
    },
    {
        value: 4,
        text_en: 'Domestic and Foreign Consultants',
        text_bn: 'দেশী-বিদেশী পরামর্শক'
    }
]
const userRentTypeList = [
    {
        value: 1,
        text_en: 'NARS Institutes Government Employee(Scientist & 1st Class Officer Up To 9th Grade',
        text_bn: 'NARS ভুক্ত ইনস্টিটিউট/সরকারি কর্মকর্তা (বিজ্ঞানী ও ১ম শ্রেণীর কর্মকর্তা, ৯ম গ্রেড ও তদুর্ধ্ব)'
    },
    {
        value: 2,
        text_en: 'Government University Teacher & Equivalent Officer',
        text_bn: 'সরকারি বিশ্ববিদ্যালয়ের শিক্ষক/সমমান কর্মকর্তা'
    },
    {
        value: 3,
        text_en: 'Non Govt Offier up to 9th grade',
        text_bn: 'বেসরকারি কর্মকর্তা/কর্মচারী ৯ম গ্রেড ও তদুর্ধ্ব'
    },
    {
        value: 4,
        text_en: 'Domestic and Foreign Consultants',
        text_bn: 'দেশী-বিদেশী পরামর্শক'
    },
    {
        value: 5,
        text_en: 'NARS Institutes Government Employee(Scientist & 1st Class Officer 10th Grade and Lower)',
        text_bn: 'NARS ভুক্ত ইনস্টিটিউট/সরকারি কর্মকর্তা (বিজ্ঞানী ও ১ম শ্রেণীর কর্মকর্তা, ১০ম গ্রেড ও নিম্ন)'
    },
    {
        value: 6,
        text_en: 'Non Govt Offier/Employee',
        text_bn: 'বেসরকারি কর্মকর্তা/কর্মচারী'
    },
    {
        value: 7,
        text_en: 'Farmer/Agriculture Laboratory Labourer',
        text_bn: 'কৃষক/কৃষি গবেষণাগার শ্রমিক'
    }
]
const subCenterUserRentTypeList = [
    {
        value: 1,
        text_en: 'NARS Institutes Government Employee(Scientist & 1st Class Officer Up To 9th Grade',
        text_bn: 'NARS ভুক্ত ইনস্টিটিউট/সরকারি কর্মকর্তা (বিজ্ঞানী ও ১ম শ্রেণীর কর্মকর্তা, ৯ম গ্রেড ও তদুর্ধ্ব)'
    },
    {
        value: 2,
        text_en: 'Government University Teacher & Equivalent Officer',
        text_bn: 'সরকারি বিশ্ববিদ্যালয়ের শিক্ষক/সমমান কর্মকর্তা'
    },
    {
        value: 3,
        text_en: 'Non Govt Offier up to 9th grade',
        text_bn: 'বেসরকারি কর্মকর্তা/কর্মচারী ৯ম গ্রেড ও তদুর্ধ্ব'
    },
    {
        value: 4,
        text_en: 'Domestic and Foreign Consultants',
        text_bn: 'দেশী-বিদেশী পরামর্শক'
    }
]
const genderList = [
    {
        value: '1',
        text_en: 'Male',
        text_bn: 'পুরুষ'
    },
    {
        value: '2',
        text_en: 'Female',
        text_bn: 'মহিলা'
    }
]
const roomUserTypeList = [
    {
        value: 1,
        text_en: 'Own Organization',
        text_bn: 'নিজস্ব প্রতিষ্ঠান'
    },
    {
        value: 2,
        text_en: 'Govt',
        text_bn: 'সরকারী'
    },
    {
        value: 3,
        text_en: 'Private',
        text_bn: 'ব্যক্তিগত'
    },
    {
        value: 4,
        text_en: 'Domestic and Foreign Consultants',
        text_bn: 'দেশী-বিদেশী পরামর্শক'
    }
]

export default {
    paymentType,
    committeeType,
    jobTypeList,
    userPriceTypeList,
    userRentTypeList,
    subCenterUserRentTypeList,
    genderList,
    sheduleType,
    roomUserTypeList
}
