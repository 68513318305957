const childRoute = (prop) => [
  {
    path: 'cultivation-achievement',
    name: prop + '.cultivation_achievement',
    meta: { auth: true },
    component: () => import('../pages/cultivation-achievement/List.vue')
  },
  {
    path: 'damage-information',
    name: prop + '.damage_information',
    meta: { auth: true },
    component: () => import('../pages/damage-information/List.vue')
  },
  {
    path: 'crop-harvesting-and-production-achievement',
    name: prop + '.crop_harvesting_and_production_ achievement',
    meta: { auth: true },
    component: () => import('../pages/crop-harvesting-and-production-achievement/List.vue')
  },
  {
    path: 'cultivation-production-target',
    name: prop + '.cultivation_production_target',
    meta: { auth: true },
    component: () => import('../pages/cultivation-production-target/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/crop-info',
    name: 'seeds_fertilizer_service.crop_info',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.crop-info')
  }
]

export default routes
