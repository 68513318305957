import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'
import Custom from './custom'

const state = {
    activePanel: null,
    agriResearchCommonObj: {
      hasDropdownLoaded: false,
      seedNameList: [],
      seedTypeNameList: [],
      seedVarietyList: [],
      seedClassList: [],
      publicationTypeList: [],
      publicationTitleList: [],
      publicationFeeList: [],
      qualityUnitList: [],
      productionSeasonList: [],
      seedPacketList: [],
      serviceList: [],
      projectList: [],
      subProjectList: [],
      sectorList: [],
      researchDivisionList: [],
      publicationContentAreaList: [],
      researchTypeList: Custom.researchTypeList,
      circularList: [],
      thematicAreaList: [],
      subSectorList: [],
      activityStatusList: Custom.activityStatusList,
      orgTypeList: Custom.orgTypeList,
      budgetHeadList: [],
      budgetTypeList: [],
      componentOrgList: [],
      feePolicy: [],
      organizationType: Custom.organizationType,
      divisionalResearchList: [],
      publicationFeeTypeList: Custom.publicationFeeTypeList,
      schemeList: [],
      seedProducersList: [],
      seedProcessingCentersList: [],
      monitoringTeamList: [],
      roleInformationList: []
    },
    commonObjCommonConfig: {
      hasDropdownLoaded: false,
      bankList: [],
      branchList: []
    }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
