const childRoutes = (prop) => [
  {
      path: 'dashboard',
      name: prop + '.dashboard',
      component: () => import('../pages/dashboard/index')
  }
]
const routes = [
  {
    path: '/agri-marketing-service',
    name: 'agri_marketing',
    component: () => import('@/layouts/AgriMarketingLayout.vue'),
    children: childRoutes('agri_marketing'),
    meta: { auth: true }
  }
]

export default routes
