const childRoutes = (prop) => [
  {
    path: 'manage-soil',
    name: prop + '.manage_soil',
    component: () => import('../pages/manage-soil/List')
  }
]
const routes = [
  {
    path: '/virtual-museum-service/soil-management-system/',
    name: 'virtual_museum_service.soil_management_system',
    component: () => import('@/layouts/VirtualMuseumLayout.vue'),
    children: childRoutes('virtual_museum_service.soil_management_system'),
    meta: { auth: true }
  }
]

export default routes
