// monthList
const monthList = [
    { value: 1, text_en: 'January', text_bn: 'জানুয়ারি' },
    { value: 2, text_en: 'February', text_bn: 'ফেব্রুয়ারি' },
    { value: 3, text_en: 'March', text_bn: 'মার্চ' },
    { value: 4, text_en: 'April', text_bn: 'এপ্রিল' },
    { value: 5, text_en: 'May', text_bn: 'মে' },
    { value: 6, text_en: 'June', text_bn: 'জুন' },
    { value: 7, text_en: 'July', text_bn: 'জুলাই' },
    { value: 8, text_en: 'August', text_bn: 'আগস্ট' },
    { value: 9, text_en: 'September', text_bn: 'সেপ্টেম্বর' },
    { value: 10, text_en: 'October', text_bn: 'অক্টোবর' },
    { value: 11, text_en: 'November', text_bn: 'নভেম্বর' },
    { value: 12, text_en: 'December', text_bn: 'ডিসেম্বর' }
]
const monthListfiscalYear = [
  {
    value: 7,
    text_en: 'July',
    text_bn: 'জুলাই'
  },
  {
    value: 8,
    text_en: 'August',
    text_bn: 'আগস্ট'
  },
  {
    value: 9,
    text_en: 'September',
    text_bn: 'সেপ্টেম্বর'
  },
  {
    value: 10,
    text_en: 'October',
    text_bn: 'অক্টোবর'
  },
  {
    value: 11,
    text_en: 'November',
    text_bn: 'নভেম্বর'
  },
  {
    value: 12,
    text_en: 'December',
    text_bn: 'ডিসেম্বর'
  },
  {
    value: 1,
    text_en: 'January',
    text_bn: 'জানুয়ারী'
  },
  {
    value: 2,
    text_en: 'February',
    text_bn: 'ফেব্রুয়ারী'
  },
  {
    value: 3,
    text_en: 'March',
    text_bn: 'মার্চ'
  },
  {
    value: 4,
    text_en: 'April',
    text_bn: 'এপ্রিল'
  },
  {
    value: 5,
    text_en: 'May',
    text_bn: 'মে'
  },
  {
    value: 6,
    text_en: 'June',
    text_bn: 'জুন'
  }
]
const trainingTypeList = [
    {
        value: 1,
        text_en: 'Short Term',
        text_bn: 'স্বল্প মেয়াদ'
    },
    {
        value: 2,
        text_en: 'Long Term',
        text_bn: 'দীর্ঘ মেয়াদী'
    }
]

const trainingTitleList = [
    {
        value: 1,
        text_en: 'Foundation Training',
        text_bn: 'ফাউন্ডেশন প্রশিক্ষণ'
    },
    {
        value: 2,
        text_en: 'Other Training',
        text_bn: 'অন্যান্য প্রশিক্ষণ'
    }
]

const authorTypeList = [
    {
      value: 1,
      text_en: 'Principal Author',
      text_bn: 'অধ্যক্ষ লেখক'
    },
    {
      value: 2,
      text_en: 'Associate Author',
      text_bn: 'সহযোগী লেখক'
    }
]

const publicationTypeList = [
    {
      value: 1,
      text_en: 'Periodicals',
      text_bn: 'সাময়িকী'
    },
    {
      value: 2,
      text_en: 'Scientific Note',
      text_bn: 'বৈজ্ঞানিক নোট'
    },
    {
      value: 3,
      text_en: 'Technical Paper',
      text_bn: 'টেকনিক্যাল পেপার'
    }
  ]

export default {
    monthList,
    monthListfiscalYear,
    trainingTypeList,
    trainingTitleList,
    authorTypeList,
    publicationTypeList
}
