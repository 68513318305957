const pestType = [
    {
      value: 1,
      text_en: 'Insect',
      text_bn: 'কীট'
    },
    {
      value: 2,
      text_en: 'Disease',
      text_bn: 'রোগ'
    }
]
export default {
  pestType
}
