const childRoutes = (prop) => [
  {
    path: 'application-management-form',
    name: prop + '.application_management_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/application/Form.vue')
  },
  {
    path: 'reject-application',
    name: prop + '.reject-application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/application/Reject.vue')
  },
  {
    path: 'withdraw-application',
    name: prop + '.withdraw_application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/application/withdrawList.vue')
  },
  {
    path: 'approved-application',
    name: prop + '.approved_application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/application/approvedList.vue')
  },
  {
    path: 'approval-application',
    name: prop + '.approval_application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/application/approvalList.vue')
  }
]

const routes = [
  {
    path: '/incentive-grant-service/farm-machinery/application-management',
    name: 'incentive_grant_service.farm_machinery.application_management',
    component: () => import('@/layouts/IncentiveGrantLayout.vue'),
    meta: { auth: true },
    children: childRoutes('incentive_grant_service.farm_machinery.application_management')
  }
]

export default routes
