export default {
  addUser ({ commit }, payload) {
    commit('addUser', payload)
  },
  addUserList ({ commit }, payload) {
    commit('addUserList', payload)
  },
  updateUser ({ commit }, payload) {
    commit('updateUser', payload)
  },
  updateStatus ({ commit }, payload) {
    commit('updateStatus', payload)
  },
  // Pump Operator
  addPumpOperator ({ commit }, payload) {
    commit('addPumpOperator', payload)
  },
  addPumpOperatorList ({ commit }, payload) {
    commit('addPumpOperatorList', payload)
  },
  updatePumpOperator ({ commit }, payload) {
    commit('updatePumpOperator', payload)
  },
  updatePumpOperatorStatus ({ commit }, payload) {
    commit('updatePumpOperatorStatus', payload)
  },
  // Pump Information
  addPumpInformation ({ commit }, payload) {
    commit('addPumpInformation', payload)
  },
  addPumpInformationList ({ commit }, payload) {
    commit('addPumpInformationList', payload)
  },
  updatePumpInformation ({ commit }, payload) {
    commit('updatePumpInformation', payload)
  },
  togglePumpInformationStatus ({ commit }, payload) {
    commit('togglePumpInformationStatus', payload)
  },
  // Pump Scheduler
  addPumpScheduler ({ commit }, payload) {
    commit('addPumpScheduler', payload)
  },
  addPumpSchedulerList ({ commit }, payload) {
    commit('addPumpSchedulerList', payload)
  },
  updatePumpScheduler ({ commit }, payload) {
    commit('updatePumpScheduler', payload)
  },
  togglePumpSchedulerStatus ({ commit }, payload) {
    commit('togglePumpSchedulerStatus', payload)
  },
  addPumpOptSuspensionList ({ commit }, payload) {
      commit('addPumpOptSuspensionList', payload)
  },
  addLandFarmerList ({ commit }, payload) {
    commit('addLandFarmerList', payload)
  }
}
