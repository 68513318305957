const childRoutes = (prop) => [
  {
    path: 'manage-crops-gallery',
    name: prop + '.manage_crops_gallery',
    component: () => import('../pages/manage-crops-gallery/List')
  },
  {
    path: 'manage-pest-gallery',
    name: prop + '.manage_pest_gallery',
    component: () => import('../pages/manage-pest-gallery/List')
  },
  {
    path: 'manage-gallery',
    name: prop + '.manage_gallery',
    component: () => import('../pages/manage-gallery/List')
  }
]
const routes = [
  {
    path: '/virtual-museum-service/content-management-system/',
    name: 'virtual_museum_service.content_management_system',
    component: () => import('@/layouts/VirtualMuseumLayout.vue'),
    children: childRoutes('virtual_museum_service.content_management_system'),
    meta: { auth: true }
  }
]

export default routes
