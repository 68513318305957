export const mutations = {
    // test
    addTestList (state, payload) {
        state.tests.push(payload)
    },
    updateTestStatus (state, payload) {
        state.tests = state.test.map(function (item) {
            if (item.id === payload.id) {
                payload.status = !payload.status
                return payload
            }

            return item
        })
    },
    // Irrigation Water Testing Request
    addIrrigationWaterTestingRequest (state, IrrigationWaterTestingRequest) {
        state.IrrigationWaterTestingRequests.push(IrrigationWaterTestingRequest)
    },
    addIrrigationWaterTestingRequestList (state, IrrigationWaterTestingRequests) {
        state.IrrigationWaterTestingRequests = IrrigationWaterTestingRequests
    },
    updateIrrigationWaterTestingRequest (state, IrrigationWaterTestingRequest) {
        state.IrrigationWaterTestingRequests = state.IrrigationWaterTestingRequests.map(function (item) {
        if (item.id === IrrigationWaterTestingRequest.id) {
            return IrrigationWaterTestingRequest
        }

        return item
        })
    },
    toggleIrrigationWaterTestingRequestStatus (state, payload) {
        state.IrrigationWaterTestingRequests = state.IrrigationWaterTestingRequests.map(function (item) {
        if (item.id === payload.id) {
            payload.status = !payload.status
            return payload
        }

        return item
        })
    },
    // Drinking Water Testing Request
    addDrinkingWaterTestingRequest (state, DrinkingWaterTestingRequest) {
        state.DrinkingWaterTestingRequests.push(DrinkingWaterTestingRequest)
    },
    addDrinkingWaterTestingRequestList (state, DrinkingWaterTestingRequests) {
        state.DrinkingWaterTestingRequests = DrinkingWaterTestingRequests
    },
    updateDrinkingWaterTestingRequest (state, DrinkingWaterTestingRequest) {
        state.DrinkingWaterTestingRequests = state.DrinkingWaterTestingRequests.map(function (item) {
        if (item.id === DrinkingWaterTestingRequest.id) {
            return DrinkingWaterTestingRequest
        }

        return item
        })
    },
    toggleDrinkingWaterTestingRequestStatus (state, payload) {
        state.DrinkingWaterTestingRequests = state.DrinkingWaterTestingRequests.map(function (item) {
        if (item.id === payload.id) {
            payload.status = !payload.status
            return payload
        }

        return item
        })
    },
    // Industrial Water Testing Request
    addIndustrialWaterTestingRequest (state, IndustrialWaterTestingRequest) {
        state.IndustrialWaterTestingRequests.push(IndustrialWaterTestingRequest)
    },
    addIndustrialWaterTestingRequestList (state, IndustrialWaterTestingRequests) {
        state.IndustrialWaterTestingRequests = IndustrialWaterTestingRequests
    },
    updateIndustrialWaterTestingRequest (state, IndustrialWaterTestingRequest) {
        state.IndustrialWaterTestingRequests = state.IndustrialWaterTestingRequests.map(function (item) {
        if (item.id === IndustrialWaterTestingRequest.id) {
            return IndustrialWaterTestingRequest
        }

        return item
        })
    },
    toggleIndustrialWaterTestingRequestStatus (state, payload) {
        state.IndustrialWaterTestingRequests = state.IndustrialWaterTestingRequests.map(function (item) {
        if (item.id === payload.id) {
            payload.status = !payload.status
            return payload
        }

        return item
        })
    },
    // Pending Water Testing Request
    addPendingWaterTestingRequest (state, PendingWaterTestingRequest) {
        state.PendingWaterTestingRequests.push(PendingWaterTestingRequest)
    },
    addPendingWaterTestingRequestList (state, PendingWaterTestingRequests) {
        state.PendingWaterTestingRequests = PendingWaterTestingRequests
    },
    updatePendingWaterTestingRequest (state, PendingWaterTestingRequest) {
        state.PendingWaterTestingRequests = state.PendingWaterTestingRequests.map(function (item) {
        if (item.id === PendingWaterTestingRequest.id) {
            return PendingWaterTestingRequest
        }

        return item
        })
    },
    togglePendingWaterTestingRequestStatus (state, payload) {
        state.PendingWaterTestingRequests = state.PendingWaterTestingRequests.map(function (item) {
        if (item.id === payload.id) {
            payload.status = !payload.status
            return payload
        }

        return item
        })
    },
    // Publish Water Testing Request
    addPublishWaterTestingRequest (state, PublishWaterTestingRequest) {
        state.PublishWaterTestingRequests.push(PublishWaterTestingRequest)
    },
    addPublishWaterTestingRequestList (state, PublishWaterTestingRequests) {
        state.PublishWaterTestingRequests = PublishWaterTestingRequests
    },
    updatePublishWaterTestingRequest (state, PublishWaterTestingRequest) {
        state.PublishWaterTestingRequests = state.PublishWaterTestingRequests.map(function (item) {
        if (item.id === PublishWaterTestingRequest.id) {
            return PublishWaterTestingRequest
        }

        return item
        })
    },
    togglePublishWaterTestingRequestStatus (state, payload) {
        state.PublishWaterTestingRequests = state.PublishWaterTestingRequests.map(function (item) {
        if (item.id === payload.id) {
            payload.status = !payload.status
            return payload
        }

        return item
        })
    },
    // Reported Water Testing Request
    addReportedWaterTestingRequest (state, ReportedWaterTestingRequest) {
        state.ReportedWaterTestingRequests.push(ReportedWaterTestingRequest)
    },
    addReportedWaterTestingRequestList (state, ReportedWaterTestingRequests) {
        state.ReportedWaterTestingRequests = ReportedWaterTestingRequests
    },
    updateReportedWaterTestingRequest (state, ReportedWaterTestingRequest) {
        state.ReportedWaterTestingRequests = state.ReportedWaterTestingRequests.map(function (item) {
        if (item.id === ReportedWaterTestingRequest.id) {
            return ReportedWaterTestingRequest
        }

        return item
        })
    },
    toggleReportedWaterTestingRequestStatus (state, payload) {
        state.ReportedWaterTestingRequests = state.ReportedWaterTestingRequests.map(function (item) {
        if (item.id === payload.id) {
            payload.status = !payload.status
            return payload
        }

        return item
        })
    }
}
