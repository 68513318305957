export default {
  addTest ({ commit }, payload) {
    commit('addTest', payload)
  },
  addTestList ({ commit }, payload) {
    commit('addTestList', payload)
  },
  updateTestStatus ({ commit }, payload) {
    commit('updateTestStatus', payload)
  },
  // farmer request
  addFarmerRequestList ({ commit }, payload) {
    commit('addFarmerRequestList', payload)
  },
  updateFarmerRequestStatus ({ commit }, payload) {
    commit('updateFarmerRequestStatus', payload)
  },
  addwareServiceRegistrationList ({ commit }, payload) {
    commit('addwareServiceRegistrationList', payload)
  },
  addRegRecomm ({ commit }, payload) {
    commit('addRegRecomm', payload)
  },
  toggleRegRecomStatus ({ commit }, payload) {
    commit('toggleRegRecomStatus', payload)
  },
  RegApproveStatus ({ commit }, payload) {
    commit('RegApproveStatus', payload)
  },
  RegRejectStatus ({ commit }, payload) {
    commit('RegRejectStatus', payload)
  },
  addRegRecomDetailsList ({ commit }, payload) {
    commit('addRegRecomDetailsList', payload)
  },
  // payment germplasm-collection
  addPaymentCollectionList ({ commit }, payload) {
    commit('addPaymentCollectionList', payload)
  },
  updatePaymentCollectionStatus ({ commit }, payload) {
    commit('updatePaymentCollectionStatus', payload)
  },
  // delivery schedule list
  addDeliveryScheduleList ({ commit }, payload) {
    commit('addDeliveryScheduleList', payload)
  }

}
