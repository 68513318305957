const organizationType = [
    {
        value: 1,
        text_en: 'Ministry',
        text_bn: 'মন্ত্রণালয়'
    },
    {
        value: 3,
        text_en: 'University',
        text_bn: 'বিশ্ববিদ্যালয়'
    },
    {
        value: 2,
        text_en: 'Others',
        text_bn: 'অন্যান্য'
    }
]

const portalTypeList = [
    {
        value: 1,
        text_en: 'Magazine',
        text_bn: 'ম্যাগাজিন'
    },
    {
        value: 2,
        text_en: 'Banner',
        text_bn: 'ব্যানার'
    },
    {
        value: 3,
        text_en: 'Poster',
        text_bn: 'পোস্টার'
    },
    {
        value: 4,
        text_en: 'Leaflet',
        text_bn: 'লিফলেট'
    },
    {
        value: 5,
        text_en: 'Research Reports',
        text_bn: 'গবেষণা প্রতিবেদন'
    },
    {
        value: 6,
        text_en: 'Others',
        text_bn: 'অন্যান্য'
    }
]
const proposalStatusList = [
    { value: 0, text_en: 'Pending', text_bn: 'বিচারাধীন' },
    { value: 2, text_en: 'Approved', text_bn: 'অনুমোদিত' },
    { value: 3, text_en: 'Reject', text_bn: 'প্রত্যাখ্যাত' },
    { value: 4, text_en: 'Resubmit', text_bn: 'পুনরায় জমা দিন' }

]
const researchTypeList = [
    { value: 1, text_en: 'Individual Research', text_bn: 'ব্যক্তিগত গবেষণা' },
    { value: 2, text_en: 'Coordinate Research', text_bn: 'সমন্বয় গবেষণা' },
    { value: 3, text_en: 'Divisional Research', text_bn: 'বিভাগীয় গবেষণা' }
]
const projectStatusList = [
    { value: 0, text_en: 'Not Started', text_bn: 'এখনো শুরু হয়নি' },
    { value: 1, text_en: 'Ongoing', text_bn: 'চলমান' },
    { value: 2, text_en: 'Completed', text_bn: 'সম্পন্ন' },
    { value: 3, text_en: 'Completed (Waiting for admin)', text_bn: 'সম্পন্ন (এডমিনের অপেক্ষায়' }
]

const publicationFeeTypeList = [
    { value: 1, text_en: '1-19', text_bn: '১-১৯' },
    { value: 2, text_en: 'Above 19', text_bn: '১৯ এর উপরে' }
]

function generateYearList () {
    const min = new Date().getFullYear()
    const oldMax = min - 5
    const newMax = min + 5
    const newYears = []
    const oldYears = []
    for (let i = oldMax; i < min; i++) {
        oldYears.push(i)
    }
    for (let j = min; j <= newMax; j++) {
        newYears.push(j)
    }
    const yearList = [...oldYears, ...newYears]
    return yearList
}

const yearList = generateYearList()

export default {
    organizationType,
    proposalStatusList,
    projectStatusList,
    researchTypeList,
    portalTypeList,
    publicationFeeTypeList,
    yearList
}
