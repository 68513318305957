const childRoutes = (prop) => [
    {
      path: 'demand-collection',
      name: prop + 'demand_collection',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/demand-collection/List.vue')
    },
    {
        path: 'demand-collection-received',
        name: prop + 'demand-collection-received',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/demand-collection-received/List.vue')
    },
    {
        path: 'demand-collection-request',
        name: prop + 'demand-collection-request',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/demand-collection-request/List.vue')
    },
    {
        path: 'demand-collection-approved',
        name: prop + 'demand-collection-approved',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/demand-collection-approved/List.vue')
    },
    {
        path: 'pending-distribution',
        name: prop + 'pending_distribution',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/pending-distribution/List.vue')
    },
    {
        path: 'single-distribution',
        name: prop + 'single_distribution',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/single-distribution/List.vue')
    },
    {
        path: 'distributed-list',
        name: prop + 'distributed_list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/distributed/List.vue')
    }
]

const routes = [
    {
        path: '/incentive-grant-service/dae/grant-allocation-distribution',
        name: 'incentive_grant_service.dae.grant_allocation_distribution',
        component: () => import('@/layouts/IncentiveGrantLayout.vue'),
        meta: { auth: true },
        children: childRoutes('incentive_grant_service.dae.grant_allocation_distribution')
    }
]

export default routes
