const childRoute = (prop) => [
  {
    path: 'crop-type',
    name: prop + '.crop_type',
    meta: { auth: true },
    component: () => import('../pages/crop-type/List.vue')
  },
  {
    path: 'crop-name',
    name: prop + '.crop_name',
    meta: { auth: true },
    component: () => import('../pages/crop-name/List.vue')
  },
  {
    path: 'organization',
    name: prop + '.organization',
    meta: { auth: true },
    component: () => import('../pages/organization/List.vue')
  },
  {
    path: 'conservation-type',
    name: prop + '.conservation_type',
    meta: { auth: true },
    component: () => import('../pages/conservation-type/List.vue')
  },
  {
    path: 'unit',
    name: prop + '.unit',
    meta: { auth: true },
    component: () => import('../pages/unit/List.vue')
  },
  {
    path: 'collection-source',
    name: prop + '.collection_source',
    meta: { auth: true },
    component: () => import('../pages/collection-source/List.vue')
  },
  {
    path: 'characterization-type',
    name: prop + '.characterization_type',
    meta: { auth: true },
    component: () => import('../pages/characterization-type/List.vue')
  },
  {
    path: 'germplasm-receiver-info',
    name: prop + '.germplasm_receiver_info',
    meta: { auth: true },
    component: () => import('../pages/germplasm-receiver-info/List.vue')
  },
  {
    path: 'report-header',
    name: prop + '.report_header',
    meta: { auth: true },
    component: () => import('../pages/report-header/List.vue')
  },
  {
    path: 'descriptor-heading',
    name: prop + '.descriptor_heading',
    meta: { auth: true },
    component: () => import('../pages/descriptor-heading/List.vue')
  },
  {
    path: 'descriptor-label',
    name: prop + '.descriptor_label',
    meta: { auth: true },
    component: () => import('../pages/descriptor-label/List.vue')
  },
  {
    path: 'code-guide',
    name: prop + '.code_guide',
    meta: { auth: true },
    component: () => import('../pages/code-guide/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/germplasm-configuration',
    name: 'seeds_fertilizer_service.germplasm-configuration',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.germplasm-configuration')
  }
]

export default routes
