export const mutations = {
  mutateCommonObj (state, payload) {
    state.commonObj = Object.assign({}, state.commonObj, payload)
  },
  changeCommonObjDropdown (state, payload) {
    state.commonObj.schemeTypeList = state.commonObj.schemeTypeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.schemeFromAffidavitList = state.commonObj.schemeFromAffidavitList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.categoryList = state.commonObj.categoryList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.subCategoryList = state.commonObj.subCategoryList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.unitList = state.commonObj.unitList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.itemList = state.commonObj.itemList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.pumpType = state.commonObj.pumpType.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.horsePower = state.commonObj.horsePower.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.projectList = state.commonObj.projectList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.waterTestingParameterList = state.commonObj.waterTestingParameterList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.mouzaList = state.commonObj.mouzaList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
  },
  // Equiptment Type
  addEquipmentTypes (state, equipmentTypes) {
    state.equipmentTypes.push(equipmentTypes)
  },
  updateEquipmentType (state, payload) {
    state.equipmentTypes = state.equipmentTypes.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }

      return item
    })
  },
  addEquipmentTypeList (state, equipmentType) {
    state.equipmentTypes = equipmentType
  },
  toggleEquipmentTypeStatus (state, payload) {
    state.equipmentTypes = state.equipmentTypes.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  // Scheme Type
  addSchemeType (state, schemeType) {
    state.schemeTypes.push(schemeType)
  },
  updateSchemeType (state, payload) {
    state.schemeTypes = state.schemeTypes.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }

      return item
    })
  },
  addSchemeTypeList (state, schemeType) {
    state.schemeTypes = schemeType
  },
  toggleSchemeTypeStatus (state, payload) {
    state.schemeTypes = state.schemeTypes.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  // Category
  addCategory (state, category) {
    state.categories.push(category)
  },
  updateCategory (state, payload) {
    state.categories = state.categories.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }

      return item
    })
  },
  addCategoryList (state, category) {
    state.categories = category
  },
  toggleCategoryStatus (state, payload) {
    state.categories = state.categories.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  // Item Sub-Category
  addSubCategory (state, subCategory) {
    state.subCategories.push(subCategory)
  },
  updateSubCategory (state, payload) {
    state.subCategories = state.subCategories.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }

      return item
    })
  },
  addSubCategoryList (state, subCategory) {
    state.subCategories = subCategory
  },
  toggleSubCategoryStatus (state, payload) {
    state.subCategories = state.subCategories.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  // Store Item
  addStoreItem (state, StoreItem) {
    state.storeItems.push(StoreItem)
  },
  updateStoreItem (state, payload) {
    state.storeItems = state.storeItems.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }

      return item
    })
  },
  addStoreItemList (state, StoreItem) {
    state.storeItems = StoreItem
  },
  toggleStoreItemStatus (state, payload) {
    state.storeItems = state.storeItems.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  // Pump Progress Type
  addPumpProgressType (state, pumpProgressType) {
    state.pumpProgressTypes.push(pumpProgressType)
  },
  addPumpProgressTypeList (state, pumpProgressTypes) {
    state.pumpProgressTypes = pumpProgressTypes
  },
  updatePumpProgressType (state, pumpProgressType) {
    state.pumpProgressTypes = state.pumpProgressTypes.map(function (item) {
      if (item.id === pumpProgressType.id) {
        return pumpProgressType
      }

      return item
    })
  },
  updatePumpProgressTypeStatus (state, payload) {
    state.pumpProgressTypes = state.pumpProgressTypes.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  },
  // Laboratory
  addLaboratory (state, laboratory) {
    state.laboratories.push(laboratory)
  },
  updateLaboratory (state, payload) {
    state.laboratories = state.laboratories.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }

      return item
    })
  },
  addLaboratoryList (state, laboratory) {
    state.laboratories = laboratory
  },
  toggleLaboratoryStatus (state, payload) {
    state.laboratories = state.laboratories.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  addPaymentTypeList (state, paymentTypes) {
    state.paymentTypes = paymentTypes
  },
  togglePaymentTypeStatus (state, payload) {
    state.paymentTypes = state.paymentTypes.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  updatePaymentType (state, payload) {
    state.paymentTypes = state.paymentTypes.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }
      return item
    })
  },
  // payment
  addPayment (state, payment) {
    state.payments.push(payment)
  },
  addPaymentList (state, payments) {
    state.payments = payments
  },
  togglePaymentStatus (state, payload) {
    state.payments = state.payments.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  // addSchemeFormAffidavitList
  addSchemeFormAffidavit (state, schemeFormAffidavit) {
    state.schemeFormAffidavits.push(schemeFormAffidavit)
  },
  addSchemeFormAffidavitList (state, schemeFormAffidavit) {
    state.schemeFormAffidavits = schemeFormAffidavit
  },
  updateSchemeFormAffidavit (state, payload) {
    state.schemeFormAffidavits = state.schemeFormAffidavits.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }

      return item
    })
  },
  toggleSchemeFormAffidavitStatus (state, payload) {
    state.schemeFormAffidavits = state.schemeFormAffidavits.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  // Measurement Unit
  addMeasurementUnit (state, measurementUnit) {
    state.measurementUnits.push(measurementUnit)
  },
  addMeasurementUnitList (state, measurementUnits) {
    state.measurementUnits = measurementUnits
  },
  updateMeasurementUnit (state, measurementUnit) {
    state.measurementUnits = state.measurementUnits.map(function (item) {
      if (item.id === measurementUnit.id) {
        return measurementUnit
      }

      return item
    })
  },
  updateMeasurementUnitStatus (state, payload) {
    state.measurementUnits = state.measurementUnits.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  },
  // Horse Power
  addHorsePower (state, horsePower) {
    state.horsePowers.push(horsePower)
  },
  addHorsePowerList (state, horsePowers) {
    state.horsePowers = horsePowers
  },
  updateHorsePower (state, horsePower) {
    state.horsePowers = state.horsePowers.map(function (item) {
      if (item.id === horsePower.id) {
        return horsePower
      }

      return item
    })
  },
  updateHorsePowerStatus (state, payload) {
    state.horsePowers = state.horsePowers.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  },
   // Circule Area
  addCirculerArea (state, circleArea) {
    state.circleAreas.push(circleArea)
  },
  addCirculerAreaList (state, circleArea) {
    state.circleAreas = circleArea
  },
  updateCircleArea (state, circleArea) {
    state.circleAreas = state.circleAreas.map(function (item) {
      if (item.id === circleArea.id) {
        return circleArea
      }

      return item
    })
  },
  CircleToggleStatus (state, payload) {
    state.circleAreas = state.circleAreas.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  }
}
