import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import custom from './custom'
/**
 * This store will be used for all modules of this component
 */

const state = {
  commonObj: {
    laodList: false,
    hasDropdownLoaded: false,
    perPage: 10,
    dateFormat: 'dd/mm/YYYY',
    timeFormat: 'h:m',
    loading: false,
    listReload: false,
    orgNotesList: [],
    feeNamesList: [],
    serviceNamesList: [],
    dynamicFormList: [],
    stepNamesList: [],
    inputOptionList: custom.inputOptionList,
    languageTypeList: custom.tmpLanguageTypeList,
    operatorList: custom.operatorList,
    serviceSteps: [],
    serviceStepForm: [],
    dynamicButtonList: [],
    seviceStepWiseButtonList: [],
    menuInfoList: [],
    alignmentList: custom.alignmentList,
    autoGenNumTypeList: [],
    reportMenuList: [],
    reportProcess: [],
    labList: [],
    activeIngredientList: [],
    agricultureProductList: [],
    agricultureProductTypeList: [],
    foreignEnlishmentList: [],
    countryList: [],
    preconditionList: [],
    cropList: [],
    marketList: [],
    fertilizerName: [],
    noticeList: []
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
