const seasonList = [
  {
    value: 1,
    text_en: 'Robi',
    text_bn: 'রবি'
  },
  {
    value: 2,
    text_en: 'Kharif-1',
    text_bn: 'খরিফ-১'
  },
  {
    value: 3,
    text_en: 'Kharif-2',
    text_bn: 'খরিফ-২'
  }
]
const seasonSetupList = [
    {
      value: 1,
      text_en: 'Robi',
      text_bn: 'রবি'
    },
    {
      value: 2,
      text_en: 'Kharif-1',
      text_bn: 'খরিফ-১'
    },
    {
      value: 3,
      text_en: 'Kharif-2',
      text_bn: 'খরিফ-২'
    }
]

const subsidyCircularTypeList = [
    {
      value: 1,
      text_en: 'Subsidy',
      text_bn: 'ভর্তুকি'
    },
    {
      value: 2,
      text_en: 'Incentive',
      text_bn: 'প্রণোদনা'
    },
    {
      value: 3,
      text_en: 'Rehabilitation',
      text_bn: 'পুনর্বাসন'
    },
    {
      value: 4,
      text_en: 'Financial Aid',
      text_bn: 'আর্থিক সাহায্য'
    }
]

const subsidyStatusList = [
    {
      value: 1,
      text_en: 'Pending',
      text_bn: 'বিচারাধীন'
    },
    {
      value: 2,
      text_en: 'Forward',
      text_bn: 'ফরওয়ার্ড'
    },
    {
      value: 3,
      text_en: 'Approve',
      text_bn: 'অনুমোদন'
    },
    {
      value: 4,
      text_en: 'Reject',
      text_bn: 'প্রত্যাখ্যান'
    },
    {
      value: 5,
      text_en: 'Distributed',
      text_bn: 'বিতরণ করা হয়েছে'
    }
]
const stockTypeList = [
  {
    value: 1,
    text_en: 'Instrument',
    text_bn: 'যন্ত্রপাতি'
  },
  {
    value: 2,
    text_en: 'Crop',
    text_bn: 'শস্য'
  },
  {
    value: 3,
    text_en: 'Material',
    text_bn: 'উপাদান'
  }
]

export default {
    seasonSetupList,
    subsidyCircularTypeList,
    subsidyStatusList,
    stockTypeList,
    seasonList
}
