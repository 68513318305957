const childRoutes = (prop) => [
    {
      path: 'application-management',
      name: prop + 'application_management',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/application-management/List.vue')
    },
    {
      path: 'verification-approval',
      name: prop + '.verification_approval',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/verification-approval/Form.vue')
    },
    {
      path: 'stock-management',
      name: prop + 'stock_management',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/stock-management/List.vue')
    },
    {
      path: 'distribution-form',
      name: prop + 'distribution_form',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/distribution/Form.vue')
    },
    {
      path: 'cash-disbursement-payroll',
      name: prop + 'cash_disbursement_payroll',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/payroll/List.vue')
    },
    {
      path: 'bill-prepare',
      name: prop + 'bill_prepare',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/bill-prepare/List.vue')
    },
    {
      path: 'bill-request',
      name: prop + 'bill_request',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/bill-request/List.vue')
    },
    {
      path: 'bill-approved',
      name: prop + 'bill_approved',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/bill-approved/List.vue')
    },
    {
      path: 'priority-request',
      name: prop + 'priority_request',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/priority-request/List.vue')
    },
    {
      path: 'subsidy-amount-report',
      name: prop + 'subsidy_amount_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/subsidy-amount-report/List.vue')
    },
    {
      path: 'subsidy-distribution-report',
      name: prop + 'subsidy_distribution_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/subsidy-distribution-report/List.vue')
    },
    {
      path: 'subsidy-farmer-report',
      name: prop + 'subsidy_farmer_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/subsidy-farmer-report/List.vue')
    },
    {
      path: 'pending-distribution',
      name: prop + 'pending_distribution',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/pending-distribution/List.vue')
    },
    {
      path: 'single-distribution',
      name: prop + 'single_distribution',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/single-distribution/List.vue')
    },
    {
      path: 'distribution',
      name: prop + 'distributed_list',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/distributed-list/List.vue')
    }
]

const routes = [
    {
      path: '/incentive-grant-service/dae/subsidy',
      name: 'incentive_grant_service.dae.subsidy',
      component: () => import('@/layouts/IncentiveGrantLayout.vue'),
      meta: { auth: true },
      children: childRoutes('incentive_grant_service.dae.subsidy')
    }
]

export default routes
