const childRoute = (prop) => [
  {
    path: 'dealer-wise-fertilizer-allocation',
    name: prop + '.dealer_wise_fertilizer_allocation',
    meta: { auth: true },
    component: () => import('../pages/dealer-wise-fertilizer-allocation/List.vue')
  },
  {
    path: 'ministry-fertilizer-allocation',
    name: prop + '.ministry-fertilizer-allocation',
    meta: { auth: true },
    component: () => import('../pages/ministry-fertilizer-allocation/List.vue')
  },
  {
    path: 'badc-allocation',
    name: prop + '.badc_allocation',
    meta: { auth: true },
    component: () => import('../pages/badc-allocation/List.vue')
  },
  {
    path: 'point-of-sale',
    name: prop + '.point_of_sale',
    meta: { auth: true },
    component: () => import('../pages/point-of-sale/List.vue')
  },
  {
    path: 'fertilizer-delivery',
    name: prop + '.fertilizer_delivery',
    meta: { auth: true },
    component: () => import('../pages/fertilizer-delivery/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/fertilizer-sales',
    name: 'seeds_fertilizer_service.fertilizer_sales',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.fertilizer-sales')
  }
]

export default routes
