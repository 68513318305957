const childRoute = (prop) => [
  {
    path: 'port-zone-setup',
    name: prop + '.port-zone-setup',
    meta: { auth: true },
    component: () => import('../pages/port-zone-setup/List.vue')
  },
  {
    path: 'agent',
    name: prop + '.agent',
    meta: { auth: true },
    component: () => import('../pages/agent/List.vue')
  },
  {
    path: 'sales-center',
    name: prop + '.sales-center',
    meta: { auth: true },
    component: () => import('../pages/sales-center/List.vue')
  },
  {
    path: 'transport-rent',
    name: prop + '.transport-rent',
    meta: { auth: true },
    component: () => import('../pages/transport-rent//List.vue')
  },
  {
    path: 'port-info',
    name: prop + '.port-info',
    meta: { auth: true },
    component: () => import('../pages/port-info/List.vue')
  },
  {
    path: 'fertilizer-import-country',
    name: prop + '.fertilizer_import_country',
    meta: { auth: true },
    component: () => import('../pages/fertilizer-import-country/List.vue')
  },
  {
    path: 'warehouse-user',
    name: prop + '.warehouse-user',
    meta: { auth: true },
    component: () => import('../pages/warehouse-user/List.vue')
  },
  {
    path: 'godown-information',
    name: prop + '.godown-information',
    meta: { auth: true },
    component: () => import('../pages/godown-information/List.vue')
  },
  {
    path: 'dealer-setup',
    name: prop + '.dealer-setup',
    meta: { auth: true },
    component: () => import('../pages/dealer-setup/List.vue')
  },
  {
    path: 'fertilizer-type',
    name: prop + '.fertilizer-type',
    meta: { auth: true },
    component: () => import('../pages/fertilizer-type/List.vue')
  },
  {
    path: 'fertilizer-price',
    name: prop + '.fertilizer-price',
    meta: { auth: true },
    component: () => import('../pages/fertilizer-price/List.vue')
  },
  {
    path: 'allocation-type',
    name: prop + '.allocation-type',
    meta: { auth: true },
    component: () => import('../pages/allocation-type/List.vue')
  },
  {
    path: 'fertilizer-name',
    name: prop + '.fertilizer-name',
    meta: { auth: true },
    component: () => import('../pages/fertilizer-name/List.vue')
  },
  {
    path: 'organization',
    name: prop + '.organization',
    meta: { auth: true },
    component: () => import('../pages/organization/List.vue')
  },
  {
    path: 'fob-procurement',
    name: prop + '.fob-procurement',
    meta: { auth: true },
    component: () => import('../pages/fob-procurement/List.vue')
  },
  {
    path: 'report-heading',
    name: prop + '.report_heading',
    meta: { auth: true },
    component: () => import('../pages/report-heading/List.vue')
  },
  {
    path: 'opening-balance',
    name: prop + '.opening_balance',
    meta: { auth: true },
    component: () => import('../pages/opening-blance/List.vue')
  },
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../pages/dashboard/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/fertilizer-config',
    name: 'seeds_fertilizer_service.fertilizer_config',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.fertilizer-config')
  }
]

export default routes
