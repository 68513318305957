const childRoutes = (prop) => [
    {
      path: 'demand-collection',
      name: prop + 'demand_collection',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/demand-collection/List.vue')
    },
    {
        path: 'demand-collection-received',
        name: prop + 'demand-collection-received',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/demand-collection-received/List.vue')
    },
    {
        path: 'demand-collection-request',
        name: prop + 'demand-collection-request',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/demand-collection-request/List.vue')
    },
    {
        path: 'demand-collection-approved',
        name: prop + 'demand-collection-approved',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/demand-collection-approved/List.vue')
    },
    {
        path: 'pending-distribution',
        name: prop + 'pending_distribution',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/pending-distribution/List.vue')
    },
    {
        path: 'distributed-list',
        name: prop + 'distributed_list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/distributed/List.vue')
    },
    {
        path: 'farmer-selection',
        name: prop + 'farmer_selection',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/farmer-selection/List.vue')
      },
      {
        path: 'farmer-selection-form',
        name: prop + 'farmer_selection_form',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/farmer-selection/Form.vue')
      }
]

const routes = [
    {
        path: '/incentive-grant-service/dae/financial-aid',
        name: 'incentive_grant_service.dae.financial_aid',
        component: () => import('@/layouts/IncentiveGrantLayout.vue'),
        meta: { auth: true },
        children: childRoutes('incentive_grant_service.dae.financial_aid')
    }
]

export default routes
