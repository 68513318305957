const routes = [{
    path: '/agri-marketing-service/market-linkage/linkage',
    name: 'agri-marketing.market_linkage_linkage',
    component: () => import('@/layouts/AgriMarketingLayout.vue'),
    meta: { auth: true },
    children: [
        {
            path: 'grower-buyer-pofile',
            name: 'grower_buyer_pofile',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/grower-buyer-pofile/List.vue')
        },
        {
            path: 'registration-list',
            name: 'registration_list',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/registration-list/List.vue')
        },
        {
            path: 'product-information',
            name: 'product_information',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/product-information/List.vue')
        },
        {
            path: 'product-information-form',
            name: 'product_information_form',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/product-information/Form.vue')
        },
        {
            path: 'market-linkage-dashboard',
            name: 'market_linkage_dashboard',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/market-linkage-dashboard/index.vue')
        }
    ]
  }
]
export default routes
