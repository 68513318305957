export default {
  mutateCommonObj ({ commit }, payload) {
    commit('mutateCommonObj', payload)
  },
  localizeCommonDropdown ({ commit }, payload) {
    commit('localizeDropdown', payload)
  },
  setCurrentFiscalYearId ({ commit }, payload) {
    commit('setCurrentFiscalYearId', payload)
  }
}
