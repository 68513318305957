const childRoute = (prop) => [
  {
    path: 'germplasm-collection',
    name: prop + '.germplasm_collection',
    meta: { auth: true },
    component: () => import('../pages/germplasm-collection/List.vue')
  },
  {
    path: 'passport-data',
    name: prop + '.passport_data',
    meta: { auth: true },
    component: () => import('../pages/passport-data/List.vue')
  },
  {
    path: 'characterization-form',
    name: prop + '.characterization_form',
    meta: { auth: true },
    component: () => import('../pages/characterization-form/List.vue')
  },
  {
    path: 'characterization',
    name: prop + '.characterization',
    meta: { auth: true },
    component: () => import('../pages/characterization/List.vue')
  },
  {
    path: 'conservation-details',
    name: prop + '.conservation_details',
    meta: { auth: true },
    component: () => import('../pages/conservation-details/List.vue')
  },
  {
    path: 'germplasm-request',
    name: prop + '.germplasm_request',
    meta: { auth: true },
    component: () => import('../pages/germplasm-request/List.vue')
  },
  {
    path: 'germplasm-check-status',
    name: prop + '.germplasm_check_status',
    meta: { auth: true },
    component: () => import('../pages/germplasm-check-status/List.vue')
  },
  {
    path: 'production-information',
    name: prop + '.production_information',
    meta: { auth: true },
    component: () => import('../pages/production-information/List.vue')
  },
  {
    path: 'germplasm-distribution',
    name: prop + '.germplasm_distribution',
    meta: { auth: true },
    component: () => import('../pages/germplasm-distribution/List.vue')
  },
  {
    path: 'passport',
    name: prop + '.passport',
    meta: { auth: true },
    component: () => import('../pages/germplasm-collection/Form.vue')
  },
  {
    path: 'characterization-add',
    name: prop + '.characterization-add',
    meta: { auth: true },
    component: () => import('../pages/characterization/Form.vue')
  },
  {
    path: 'characterization-view',
    name: prop + '.characterization-view',
    meta: { auth: true },
    component: () => import('../pages/characterization-form/Form.vue')
  },
  {
    path: 'characterization-info',
    name: prop + '.characterization-info',
    meta: { auth: true },
    component: () => import('../pages/characterization-info/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/germplasm-germplasm',
    name: 'seeds_fertilizer_service.germplasm-germplasm',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.germplasm-germplasm')
  }
]

export default routes
