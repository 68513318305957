const configurationRoutes = (prop) => [
  {
    path: 'seed-type-setup',
    name: prop + 'seed-type-setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/seed-type-entry/List.vue')
  },
  {
    path: 'seed-type-setup',
    name: prop + 'seed-type-setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/seed-type-entry/List.vue')
  },
  {
    path: 'seed-name-list',
    name: prop + 'seed-name-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/seed-name-entry/List.vue')
  },
  {
    path: 'seed-class-setup',
    name: prop + 'seed-class-setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/seed-class-entry/List.vue')
  },
  {
    path: 'quality-unit-setup',
    name: 'quality-unit-setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/quality-unit-entry/List.vue')
  },
  {
    path: 'seed-variety-setup',
    name: prop + 'seed-variety-setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/seed-variety-entry/List.vue')
  },
  {
    path: 'production-season-setup',
    name: prop + 'production-season-setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/production-season-entry/List.vue')
  },
  {
    path: 'seed-packet-setup',
    name: prop + 'seed-packet-setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/seed-packet-entry/List.vue')
  },
  {
    path: 'inspection-schedule-setup',
    name: prop + 'inspection-schedule-setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/inspection-schedule-entry/List.vue')
  },
  {
    path: 'seed-quality-list-setup',
    name: prop + 'seed-quality-list-setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/seed-quality-entry/List.vue')
  },
  {
    path: 'fee-policy-list',
    name: prop + 'fee-policy-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/fee-policy-entry/List.vue')
  },
  {
    path: 'service-info-list',
    name: prop + 'service-info-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/service-info-entry/List.vue')
  },
  {
    path: 'report-heading',
    name: prop + '.report_heading',
    meta: { auth: true },
    component: () => import('../pages/configuration/report-heading/List.vue')
  },
  {
    path: 'payment-entry',
    name: prop + '.payment_entry',
    meta: { auth: true },
    component: () => import('../pages/configuration/payment-entry/List.vue')
  },
  {
    path: 'seed-producer-setup',
    name: prop + '.seed_producer_setup',
    meta: { auth: true },
    component: () => import('../pages/configuration/seed-producer-name/List.vue')
  },
  {
    path: 'seed-processing-center',
    name: prop + '.seed_processing_center_setup',
    meta: { auth: true },
    component: () => import('../pages/configuration/seed-processing-center/List.vue')
  },
  {
    path: 'seed-scheme',
    name: prop + '.seed_scheme_setup',
    meta: { auth: true },
    component: () => import('../pages/configuration/seed-scheme/List.vue')
  }
]

const applicantRoutes = (prop) => [
  {
    path: 'application-verification',
    name: prop + 'application_verification',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/applicant-panel/application-verification/List.vue')
  },
  {
    path: 'inspection-schedule-confirm',
    name: prop + 'inspection-schedule-confirm',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/applicant-panel/inspection-schedule-confirm/List.vue')
  },
  {
    path: 'inspection-report-submit',
    name: prop + 'inspection-report-submit',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/applicant-panel/inspection-report-submit/List.vue')
  },
  {
    path: 'inspection-report-second',
    name: prop + 'inspection-report-second',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/applicant-panel/inspection-report-second/List.vue')
  },
  {
    path: 'inspection-report-third',
    name: prop + 'inspection-report-third',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/applicant-panel/inspection-report-third/List.vue')
  },
  {
    path: 'application-approval-rejection',
    name: prop + 'application-approval-rejection',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/applicant-panel/application-approval-rejection/List.vue')
  },
  {
    path: 'field-certificate-list',
    name: prop + 'field-certificate-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/applicant-panel/field-certificate-list/List.vue')
  },
  {
    path: 'seed-certification-dashboard',
    name: prop + 'seed_certification_dashboard',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/seed-certification-dashboard.vue')
  }
]

const dashboardRoutes = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../pages/dashboard.vue')
  }
]

const reportRoutes = (prop) => [
  {
    path: 'org-wise-certified-seed-amount-report',
    name: prop + '.org_wise_certified_seed_amount_report',
    meta: { auth: true },
    component: () => import('../pages/reports/org-wise-certified-seed-amount-report/List.vue')
  },
  {
    path: 'org-wise-certified-land-amount-report',
    name: prop + '.org_wise_certified_land_amount_report',
    meta: { auth: true },
    component: () => import('../pages/reports/org-wise-certified-land-amount-report/List.vue')
  },
  {
    path: 'report-of-land-and-seed-production',
    name: prop + '.report_of_land_and_seed_production',
    meta: { auth: true },
    component: () => import('../pages/reports/report-of-land-and-seed-production/List.vue')
  },
  {
    path: 'report-of-seed-wise-land-and-seed-production',
    name: prop + '.report_of_seed_wise_land_and_seed_production',
    meta: { auth: true },
    component: () => import('../pages/reports/report-of-seed-wise-land-and-seed-production/List.vue')
  }
]

const routes = [
  {
    path: '/agri-research-service/seed-certification',
    name: 'agri_research_service.seed_certification',
    component: () => import('@/layouts/AgriResearchLayout.vue'),
    meta: { auth: true },
    children: applicantRoutes('agri_research_service.seed_certification')
  },
  {
    path: '/agri-research-service/seed-certification/configuration',
    name: 'agri_research_service.seed_certification.configuration',
    component: () => import('@/layouts/AgriResearchLayout.vue'),
    meta: { auth: true },
    children: configurationRoutes('agri_research_service.seed_certification.configuration')
  },
  {
    path: '/agri-research-service',
    name: 'agri_research_service.dashboard',
    component: () => import('@/layouts/AgriResearchLayout.vue'),
    meta: { auth: true },
    children: dashboardRoutes('agri_research_service.dashboard')
  },
  {
    path: '/agri-research-service/seed-certification/reports',
    name: 'agri_research_service.seed_certification.reports',
    component: () => import('@/layouts/AgriResearchLayout.vue'),
    meta: { auth: true },
    children: reportRoutes('agri_research_service.seed_certification.reports')
  }
]

export default routes
