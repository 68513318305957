const childRoute = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/Dashboard.vue')
  },
  {
    path: 'complain-list',
    name: prop + '.complain_list',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/complain-list/List.vue')
  },
  {
    path: 'required-maintenance',
    name: prop + '.required_maintenance',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/required-maintenance/List.vue')
  },
  {
    path: 'maintenance-task',
    name: prop + '.maintenance_task',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/maintenance-task/List.vue')
  },
  {
    path: 'complain-receive-requisition',
    name: prop + '.complain_receive_requisition',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/receive-requisition/List.vue')
  },
  {
    path: 'supply-equipment',
    name: prop + '.supply_equipment',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/supply-equipment/List.vue')
  },
  {
    path: 'directory',
    name: prop + '.directory',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/directory/List.vue')
  },
  {
    path: 'report/complain',
    name: prop + '.report.complain',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/report/ComplainReportList.vue')
  },
  {
    path: 'report/maintenance',
    name: prop + '.report.maintenance',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/report/MaintenanceReport.vue')
  },
  {
    path: 'report/rating-feedback',
    name: prop + '.report.rating_feedback',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/report/RatingFeedbackReport.vue')
  }
]

const routes = [
  {
    path: '/irrigation/pump-maintenance',
    name: 'irrigation.pump_maintenance',
    component: () => import('@/layouts/IrrigationSchemeLayout.vue'),
    meta: { auth: true },
    children: childRoute('irrigation.pump_maintenance')
  }
]

export default routes
