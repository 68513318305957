const childRoutes = (prop) => [
    {
        path: 'manage-insect',
        name: prop + '.manage_insect',
        component: () => import('../pages/insect-management/List')
    },
    {
        path: 'add-insect',
        name: prop + '.add_insect',
        component: () => import('../pages/insect-management/Form')
    },
    {
        path: 'details',
        name: prop + '.details',
        component: () => import('../pages/insect-management/Details')
    },
    {
        path: 'manage-disease',
        name: prop + '.manage_disease',
        component: () => import('../pages/disease-manage/List')
    },
    {
        path: 'add-disease',
        name: prop + '.add_disease',
        component: () => import('../pages/disease-manage/Form')
    },
    {
        path: 'disease-details',
        name: prop + '.disease_details',
        component: () => import('../pages/disease-manage/Details')
    }
]
const routes = [
    {
      path: '/virtual-museum-service/pest-management/',
      name: 'virtual_museum_service.pest_management',
      component: () => import('@/layouts/VirtualMuseumLayout.vue'),
      children: childRoutes('virtual_museum_service.pest_management'),
      meta: { auth: true }
    }
]

export default routes
