import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import Customs from './custom'

/**
 * This store will be used for all modules of this component
 */
const state = {
  currentFiscalYearId: 0,
  commonObj: {
    hasDropdownLoaded: false,
    programTypeList: [],
    carryoverList: [],
    portZoneSetupList: [],
    CropTypeList: [],
    CropNameList: [],
    cnfAgentList: [],
    transportAgentList: [],
    fertilizerImportCountryList: [],
    salesCenterList: [],
    VarietyList: [],
    seedClassList: [],
    productionSourceList: [],
    measurementUnitList: [],
    productionSeasonList: [],
    seasonList: Customs.seasonList,
    fertilizerTypeList: [],
    fertilizerNameList: [],
    portInfoSetupList: [],
    godownInfoList: [],
    allocationTypeList: [],
    organizationList: [],
    priceTypeList: [],
    packetVarietyList: [],
    zoneOfficeList: [],
    spcOfficeList: [],
    prerequisiteChecklist: [],
    gpOrganizationsList: [],
    dealerBasicList: [],
    gpCropNameList: [],
    gpCropTypeList: [],
    gpConservationTypeList: [],
    gpUnitList: [],
    collectionSourceList: [],
    characterizationTypeList: [],
    germplasmRequestList: [],
    descriptorLabelList: [],
    descriptorHeadingList: [],
    codeGuideDetail: [],
    dealerBasicInfoList: [],
    monthListfiscalYear: Customs.months,
    seedProductionSourceTypeList: [],
    unitList: Customs.unitList,
    dashboardMonthList: Customs.dashboardMonthList,
    seedCategoryList: [],
    seedSubTypeList: []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
