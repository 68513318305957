export const mutations = {
  addTest (state, payload) {
    state.test.push(payload)
  },
  // Complain type
  addComplainType (state, complainType) {
    state.complainTypes.push(complainType)
  },
  addComplainTypeList (state, complainTypes) {
    state.complainTypes = complainTypes
  },
  updateComplainType (state, complainType) {
    state.complainTypes = state.complainTypes.map(function (item) {
      if (item.id === complainType.id) {
        return complainType
      }

      return item
    })
  },
  updateComplainTypeStatus (state, payload) {
    state.complainTypes = state.complainTypes.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  },
  // Complain type end
  // Complain Info
  addComplainInfoList (state, complainInfo) {
    state.complainInfos = complainInfo
  },
  addComplainInfo (state, complainInfo) {
    state.complainInfos.push(complainInfo)
  },
  toggleComplainInfo (state, complainInfo) {
    state.complainInfos = state.complainInfos.map(function (item) {
      if (item.id === complainInfo.id) {
        return complainInfo
      }
      return item
    })
  },
  // Complain Designation start
  addComplainDesignation (state, complainDesignation) {
    state.complainDesignations.push(complainDesignation)
  },
  addComplainDesignationList (state, complainDesignations) {
    state.complainDesignations = complainDesignations
  },
  updateComplainDesignation (state, complainDesignation) {
    state.complainDesignations = state.complainDesignations.map(function (item) {
      if (item.id === complainDesignation.id) {
        return complainDesignation
      }
      return item
    })
  },
  updateComplainDesignationStatus (state, payload) {
    state.complainDesignations = state.complainDesignations.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  },
  // Complain Designation end
  // Complain Chain start
  addComplainChain (state, complainChain) {
    state.complainChains.push(complainChain)
  },
  addComplainChainList (state, complainChains) {
    state.complainChains = complainChains
  },
  updateComplainChain (state, complainChain) {
    state.complainChains = state.complainChains.map(function (item) {
      if (item.id === complainChain.id) {
        return complainChain
      }

      return item
    })
  },
  updateComplainChainStatus (state, payload) {
    state.complainChains = state.complainChains.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  },
  // Complain RequestList
  addComplainRequestList (state, complainRequestList) {
    state.complainRequestList = complainRequestList
  },
  // Complain Send start
  addComplainSend (state, complainSend) {
    state.complainSends.push(complainSend)
  },
  addComplainSendList (state, complainSends) {
    state.complainSends = complainSends
  },
  updateComplainSend (state, complainSend) {
    state.complainSends = state.complainSends.map(function (item) {
      if (item.id === complainSend.id) {
        return complainSend
      }
      return item
    })
  },
  updateComplainSendStatus (state, payload) {
    state.complainSends = state.complainSends.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  }
}
