const childRoute = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/dashboard/index.vue')
  },
  {
    path: 'irrigation-water',
    name: prop + '.irrigation-water',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/irrigation-water-request/List.vue')
  },
  {
    path: 'drinking-water',
    name: prop + '.drinking-water',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/drinking-water-request/List.vue')
  },
  {
    path: 'industrial-water',
    name: prop + '.industrial-water',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/industrial-water-request/List.vue')
  },
  {
    path: 'testing-report-collection',
    name: prop + '.testing-report-collection',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/testing-report-collection/List.vue')
  },
  {
    path: 'publish-water-testing-report',
    name: prop + '.publish-water-testing-report',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/publish-water-testing-report/List.vue')
  },
  {
    path: 'testing-payment-report',
    name: prop + '.testing-payment-report',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/testing-payment-report/List.vue')
  },
  {
    path: 'water-testing-reported',
    name: prop + '.water-testing-reported',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/water-testing-reported/List.vue')
  },
  {
    path: 'water-testing-entry',
    name: prop + '.water_testing_entry',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/water-testing-entry/List.vue')
  }
]

const routes = [
  {
    path: '/irrigation/water-testing',
    name: 'irrigation.water_testing',
    component: () => import('../../../../layouts/IrrigationSchemeLayout.vue'),
    meta: { auth: true },
    children: childRoute('water_testing')
  }
]

export default routes
