import Vue from 'vue'
import moment from 'moment'
import i18n from '../i18n'
import Store from '../store/index'

function formatNumber (value) {
  return Number(value).toLocaleString()
}
const formatForDate = Store.getters.commonObj.dateFormat

function dateFormat (value, format = formatForDate) {
    require('moment/min/locales.min')
    moment.locale(i18n.locale)
    const parsedDate = moment(value)
    if (!parsedDate.isValid()) {
        return ''
    }
    return parsedDate.format(format)
}

// function dateFormat (value, format = formatForDate) {
//     require('moment/min/locales.min')
//     moment.locale(i18n.locale)
//     return moment(value).format(format)
// }

function dateFromNow (value, unitOfTime = Store.getters.commonObj.unitOfTime) {
    require('moment/min/locales.min')
    moment.locale(i18n.locale)
    return moment(value).startOf(unitOfTime).fromNow()
}

function dateFormatTime (value) {
    require('moment/min/locales.min')
    moment.locale(i18n.locale)
    const commonObj = Store.getters.commonObj
    return moment(value).format(commonObj.dateFormat + ' h:mm:ss a')
}

function time12Formate (time) {
    return moment(time, ['HH.mm']).format('hh:mm a')
    // const timeIndex1st = time.split(':')[0]
    // const timeIndex3rd = time.split(':')[1]
    // if (timeIndex1st >= 12) {
    //     return (timeIndex1st - 12) + ':' + timeIndex3rd + ' PM'
    // } else {
    //     return timeIndex1st + ':' + timeIndex3rd + ' AM'
    // }
}

function time12FormateTraining (time) {
    return moment(time, ['HH.mm']).format('hh:mm')
    // const timeIndex1st = time.split(':')[0]
    // const timeIndex3rd = time.split(':')[1]
    // if (timeIndex1st >= 12) {
    //     return (timeIndex1st - 12) + ':' + timeIndex3rd + ' PM'
    // } else {
    //     return timeIndex1st + ':' + timeIndex3rd + ' AM'
    // }
}

function numberFormatBn (value, $this, config = { }) {
    try {
        if (typeof value !== 'undefined' && value) {
            const firstChar = value.substring(0, 1)
            const hasZero = parseInt(firstChar) === 0
            return hasZero ? (i18n.locale === 'bn' ? '০' + $this.$n(value, config) : '0' + $this.$n(value, config))
                : $this.$n(value, config)
        }
    } catch (e) {
        return value
    }
}

function mobileNumber (value) {
    try {
        if (typeof value !== 'undefined' && value) {
           if (i18n.locale === 'bn') {
               return '০' + i18n.n(value, { useGrouping: false })
           } else {
            return '0' + i18n.n(value, { useGrouping: false })
        }
        }
    } catch (e) {
        return value
    }
}

function capitalize (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

function kebabToWords (string) {
    const words = string.split('-')
    const capitalized = words.map(word => capitalize(word))
    return capitalized.join(' ')
}

function snakeToWords (string) {
    const words = string.split('_')
    const capitalized = words.map(word => capitalize(word))
    return capitalized.join(' ')
}
function wordsToSnake (str) {
    if (typeof str !== 'undefined' && typeof str === 'string') {
        const words = str.split(' ')
        return words.join('_').toLowerCase()
    }
}

function getRegexString (str) {
    if (typeof str !== 'undefined' && typeof str === 'string') {
        const newStr = str.replace(/[^a-zA-Z_ ]/g, '')
        return newStr
    }
}

function getExtension (file) {
    const fileName = file.name
    return fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2)
}
function randomNumber () {
    return Math.floor(Math.random() * 10000000)
}

function currency ($event, model) {
    const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
    // only allow number and one dot
    if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || model.indexOf('.') !== -1)) { // 46 is dot
        $event.preventDefault()
    }
    // restrict to 2 decimal places
    if (model != null && model.indexOf('.') > -1 && (model.split('.')[1].length > 1)) {
        $event.preventDefault()
    }
}

function shortString (str, n) {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str
}
function addLeadingZeros (num, totalLength) {
    return String(num).padStart(totalLength, i18n.locale === 'bn' ? '০' : '0')
}
function integerFormate (number) {
    const splitted = number.split('')
    let i = 0
    while (splitted.shift() === '0') {
        i += 1
    }
    const transNumber = i18n.n(number, { useGrouping: false })
    const fianlNum = addLeadingZeros(transNumber, i)
    return fianlNum
}

function numberConvert (value) {
    if (i18n.locale === 'bn') {
        return value.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
    } else {
        return value.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
    }
}

function convertNumberToEnglishWords (number) {
    if ((number < 0) || (number > 999999999)) {
        alert('Number is out of range')
        return
      }
      const numberArray = number.toString().split('.')
      const numberWithoutFloatingPart = numberArray[0] ? parseInt(numberArray[0]) : 0
      const numberWithFloatingPart = numberArray[1]
      let stringBeforeFloatingPart = ''
      if (numberWithoutFloatingPart) {
        stringBeforeFloatingPart = convertNumber(numberWithoutFloatingPart)
      }
      let numberWords = stringBeforeFloatingPart
      if (numberWithFloatingPart) {
        if (stringBeforeFloatingPart) {
          numberWords += stringBeforeFloatingPart + ' Point ' + this.convertNumber(parseInt(numberWithFloatingPart))
        } else {
          numberWords += 'Point ' + convertNumber(parseInt(numberWithFloatingPart))
        }
      }
      return numberWords
}

function empty (str) {
    return (!str || str.length === 0)
}

function convertNumber (number) {
    const Kt = Math.floor(number / 10000000) /* Koti */
      number -= Kt * 10000000
      const Gn = Math.floor(number / 100000) /* lakh  */
      number -= Gn * 100000
      const kn = Math.floor(number / 1000) /* Thousands (kilo) */
      number -= kn * 1000
      const Hn = Math.floor(number / 100) /* Hundreds (hecto) */
      number -= Hn * 100
      const Dn = Math.floor(number / 10) /* Tens (deca) */
      const n = number % 10 /* Ones */

      let res = ''

      if (Kt) {
        res += convertNumber(Kt) + ' Koti '
      }
      if (Gn) {
        res += convertNumber(Gn) + ' Lakh'
      }

      if (kn) {
        res += (empty(res) ? '' : ' ') +
          convertNumber(kn) + ' Thousand'
      }

      if (Hn) {
        res += (empty(res) ? '' : ' ') +
          convertNumber(Hn) + ' Hundred'
      }

      const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six',
        'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen',
        'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eightteen',
        'Nineteen']
      const tens = ['', '', 'Twenty', 'Thirty', 'Fourty', 'Fifty', 'Sixty',
        'Seventy', 'Eigthy', 'Ninety']

      if (Dn || n) {
        if (!empty(res)) {
          res += ' and '
        }

        if (Dn < 2) {
          res += ones[Dn * 10 + n]
        } else {
          res += tens[Dn]

          if (n) {
            res += '-' + ones[n]
          }
        }
      }

      if (empty(res)) {
        res = 'zero'
      }

      return res
}

function convertNumberWords (amount) {
    if (i18n.locale === 'bn') {
        return convertNumberToBanglaWords(amount)
    }
    return convertNumberToEnglishWords(amount)
}
function convertNumberToBanglaWords (amount) {
    const Words = ['', 'এক', 'দুই', 'তিন', 'চার', 'পাঁচ', 'ছয়', 'সাত', 'আট', 'নয়', 'দশ',
      'এগার', 'বার', 'তের', 'চৌদ্দ', 'পনের', 'ষোল', 'সতের', 'আঠার', 'ঊনিশ', 'বিশ',
      'একুশ', 'বাইশ', 'তেইশ', 'চব্বিশ', 'পঁচিশ', 'ছাব্বিশ', 'সাতাশ', 'আঠাশ', 'ঊনত্রিশ', 'ত্রিশ',
      'একত্রিশ', 'বত্রিশ', 'তেত্রিশ', 'চৌত্রিশ', 'পঁয়ত্রিশ', 'ছত্রিশ', 'সাঁইত্রিশ', 'আটত্রিশ', 'ঊনচল্লিশ', 'চল্লিশ',
      'একচল্লিশ', 'বিয়াল্লিশ', 'তেতাল্লিশ', 'চুয়াল্লিশ', 'পঁয়তাল্লিশ', 'ছেচল্লিশ', 'সাতচল্লিশ', 'আটচল্লিশ', 'ঊনপঞ্চাশ', 'পঞ্চাশ',
      'একান্ন', 'বায়ান্ন', 'তিপ্পান্ন', 'চুয়ান্ন', 'পঞ্চান্ন', 'ছাপ্পান্ন', 'সাতান্ন', 'আটান্ন', 'ঊনষাট', 'ষাট',
      'একষট্টি', 'বাষট্টি', 'তেষট্টি', 'চৌষট্টি', 'পঁয়ষট্টি', 'ছেষট্টি', 'সাতষট্টি', 'আটষট্টি', 'ঊনসত্তর', 'সত্তর',
      'একাতর', 'বাহাত্তর', 'তিয়াত্তর', 'চুয়াত্তর', 'পঁচাত্তর', 'ছিয়াত্তর', 'সাতাত্তর', 'আটাত্তর', 'ঊনআশি', 'আশি',
      'একাশি', 'বিরাশি', 'তিরাশি', 'চুরাশি', 'পঁচাশি', 'ছিয়াশি', 'সাতাশি', 'আটাশি', 'ঊননব্বই', 'নব্বই',
      'একানব্বই', 'বিরানব্বই', 'তিরানব্বই', 'চুরানব্বই', 'পঁচানব্বই', 'ছিয়ানব্বই', 'সাতানব্বই', 'আটানব্বই', 'নিরানব্বই']

    amount = amount.toString()
    const atemp = amount.split('.')
    let beforeWord = ''
    let afterWord = ''
    const beforeNumber = atemp[0]
    if (beforeNumber !== '0') {
      beforeWord = toWord(beforeNumber, Words)
    }
    if (atemp.length === 2) {
      const afterNumber = atemp[1]
      afterWord = toWord(afterNumber, Words)
      if (beforeWord !== '') {
        beforeWord += ' দশমিক ' + afterWord
      } else {
        beforeWord += 'দশমিক ' + afterWord
      }
    }
    return beforeWord
}

function toWord (number, words) {
var nLength = number.length
var wordsString = ''

if (nLength <= 9) {
    const nArray = [0, 0, 0, 0, 0, 0, 0, 0, 0]
    const receivedNArray = []
    for (let i = 0; i < nLength; i++) {
    receivedNArray[i] = parseInt(number.substr(i, 1))
    }
    for (let i = 9 - nLength, j = 0; i < 9; i++, j++) {
    nArray[i] = receivedNArray[j]
    }
    for (let i = 0, j = 1; i < 9; i++, j++) {
    if (i === 0 || i === 2 || i === 4 || i === 7) {
        if (nArray[i] === 1) {
        nArray[j] = 10 + parseInt(nArray[j])
        nArray[i] = 0
        } else if (nArray[i] === 2) {
        nArray[j] = 20 + parseInt(nArray[j])
        nArray[i] = 0
        } else if (nArray[i] === 3) {
        nArray[j] = 30 + parseInt(nArray[j])
        nArray[i] = 0
        } else if (nArray[i] === 4) {
        nArray[j] = 40 + parseInt(nArray[j])
        nArray[i] = 0
        } else if (nArray[i] === 5) {
        nArray[j] = 50 + parseInt(nArray[j])
        nArray[i] = 0
        } else if (nArray[i] === 6) {
        nArray[j] = 60 + parseInt(nArray[j])
        nArray[i] = 0
        } else if (nArray[i] === 7) {
        nArray[j] = 70 + parseInt(nArray[j])
        nArray[i] = 0
        } else if (nArray[i] === 8) {
        nArray[j] = 80 + parseInt(nArray[j])
        nArray[i] = 0
        } else if (nArray[i] === 9) {
        nArray[j] = 90 + parseInt(nArray[j])
        nArray[i] = 0
        }
    }
    }

    let value = ''
    for (let i = 0; i < 9; i++) {
    value = parseInt(nArray[i])
    if (value !== 0) {
        wordsString += words[value] + ''
    }
    if ((i === 1 && value !== 0) || (i === 0 && value !== 0 && parseInt(nArray[i + 1]) === 0)) {
        wordsString += ' কোটি '
    }
    if ((i === 3 && value !== 0) || (i === 2 && value !== 0 && parseInt(nArray[i + 1]) === 0)) {
        wordsString += ' লাখ '
    }
    if ((i === 5 && value !== 0) || (i === 4 && value !== 0 && parseInt(nArray[i + 1]) === 0)) {
        wordsString += ' হাজার '
    } else if (i === 6 && value !== 0) {
        wordsString += 'শ '
    }
    }

    wordsString = wordsString.split('  ').join(' ')
}
return wordsString
}

// Vue.filter ('myDate', function (text) {
//   return moment(text).format('MMMM Do YYYY')
// })

Vue.filter('regexString', function (value) {
    return getRegexString(value)
})

Vue.filter('reverse', function (value) {
    // slice to make a copy of array, then reverse the copy
    return value.slice().reverse()
})

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).fromNow()
    }
})

Vue.filter('time12Formate', function (time) {
    return moment(time, ['HH.mm']).format('hh:mm a')
    // const timeIndex1st = time.split(':')[0]
    // const timeIndex3rd = time.split(':')[1]
    // if (timeIndex1st >= 12) {
    //     return (timeIndex1st - 12) + ':' + timeIndex3rd + ' PM'
    // } else {
    //     return timeIndex1st + ':' + timeIndex3rd + ' AM'
    // }
})

Vue.filter('time12FormateTraining', function (time) {
    return moment(time, ['HH.mm']).format('hh:mm')
    // const timeIndex1st = time.split(':')[0]
    // const timeIndex3rd = time.split(':')[1]
    // if (timeIndex1st >= 12) {
    //     return (timeIndex1st - 12) + ':' + timeIndex3rd + ' PM'
    // } else {
    //     return timeIndex1st + ':' + timeIndex3rd + ' AM'
    // }
})

Vue.filter('dateFromNow', function (value) {
    if (value) {
        return dateFromNow(String(value))
    }
})

Vue.filter('convertNumberWords', function (value) {
    if (value) {
        return convertNumberWords(value)
    }
})

Vue.filter('formatDateDMY', function (value) {
    if (value) {
        return moment(value).format('DD-MM-YYYY')
    }
})

Vue.filter('timeFormat', function (value) {
    if (value) {
        return moment.parseZone(value).utcOffset()
    }
})

Vue.filter('dateFormat', function (value) {
  return dateFormat(value)
})

Vue.filter('dateFormatTime', function (value) {
    require('moment/min/locales.min')
    moment.locale(i18n.locale)
    const commonObj = Store.getters.commonObj
    return moment(value).format(commonObj.dateFormat + ' h:mm:ss a')
})

Vue.filter('kebabToWords', (value) => {
    if (typeof value !== 'undefined') {
        return kebabToWords(value)
    }
})

Vue.filter('snakeToWords', (value) => {
    if (typeof value !== 'undefined') {
        return snakeToWords(value)
    }
})

Vue.filter('wordsToSnake', (value) => {
    if (typeof value !== 'undefined') {
        return wordsToSnake(value)
    }
})

Vue.filter('numberFormatBn', (value, config = { }) => {
    return numberFormatBn(value, config)
})

Vue.filter('shortString', (value, length) => {
    return shortString(value, length)
})

Vue.filter('mobileNumber', (value) => {
    return mobileNumber(value)
})

Vue.filter('integerFormate', (value) => {
    return integerFormate(value)
})

Vue.filter('numberConvert', (value) => {
    return numberConvert(value)
})

export { capitalize, formatNumber, dateFormat, dateFormatTime, mobileNumber, numberFormatBn, kebabToWords, snakeToWords, wordsToSnake, getExtension, randomNumber, currency, dateFromNow, time12Formate, time12FormateTraining, shortString, getRegexString, integerFormate, numberConvert, convertNumberWords }
