const routes = [{
    path: '/agri-marketing-service/market-linkage/report',
    name: 'agri-marketing.market_linkage.report',
    component: () => import('@/layouts/AgriMarketingLayout.vue'),
    meta: { auth: true },
    children: [
        {
            path: 'farmers-info-report',
            name: 'farmers_info_report',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/farmers-info-report/List.vue')
        },
        {
            path: 'market-information-report',
            name: 'market_information_report',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/market-information-report/List.vue')
        },
        {
            path: 'product-info-report',
            name: 'product_info_report',
            component: () =>
                import('../pages/product-information-report/List.vue')
        },
        {
            path: 'buyers-information-report',
            name: 'buyers_information_report',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/buyer’s-information-report/List.vue')
        }
    ]
  }
]
export default routes
