const childRoute = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/Dashboard.vue')
  },
  {
    path: 'new-application',
    name: prop + '.new_application',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/new-application/List.vue')
  },
  {
    path: 'reissue-application',
    name: prop + '.reissue_application',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/reissue-application/List.vue')
  },
  {
    path: 'application-report',
    name: prop + '.application-report',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/application-report/List.vue')
  },
  {
    path: 'generate-card',
    name: prop + '.generate_card',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/generate-card/List.vue')
  },
  {
    path: 'card-list',
    name: prop + '.card_list',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/card-list/List.vue')
  },
  {
    path: 'payment-germplasm-collection-report',
    name: prop + '.payment-germplasm-collection-report',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/payment-collection-report/List.vue')
  }
]

const routes = [
  {
    path: '/irrigation/card-payment',
    name: 'irrigation.card_payment',
    component: () => import('../../../../layouts/IrrigationSchemeLayout.vue'),
    meta: { auth: true },
    children: childRoute('irrigation.card_payment')
  }
]

export default routes
