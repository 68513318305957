const childRoute = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.assign-task.dashboard',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/Dashboard.vue')
  },
  {
    path: 'assign-task',
    name: prop + '.assign-task.list',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/assign-task/List')
  },
  {
    path: 'task-calendar',
    name: prop + '.task-calendar.list',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/task-calendar/List')
  },
  {
    path: 'task-tracker',
    name: prop + '.task-tracker.list',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/task-tracker/List')
  },
  {
    path: 'task-report',
    name: prop + '.task-report.list',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/task-report/List')
  },
  {
    path: 'notifications',
    name: prop + '.notifications',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/notifications/List')
  },
  {
    path: 'daily-task-report',
    name: prop + './daily.task.report',
    meta: { auth: false, name: 'Editable' },
    component: () => import('../pages/daily-task-report/List')
  }
]

const routes = [
  {
    path: '/irrigation/task',
    name: 'irrigation.task',
    component: () => import('../../../../layouts/IrrigationSchemeLayout.vue'),
    meta: { auth: false },
    children: childRoute('irrigation_task')
  }
]

export default routes
