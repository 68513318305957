import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import Custom from './custom'

const state = {
  commonObj: {
    hasDropdownLoaded: false,
    perPage: 10,
    dateFormat: 'dd/mm/YYYY',
    timeFormat: 'h:m',
    loading: false,
    listReload: false,
    organizationType: Custom.organizationType,
    proposalStatusList: Custom.proposalStatusList,
    projectStatusList: Custom.projectStatusList,
    seedTypeNameList: [],
    seedNameList: [],
    seedVarietyList: [],
    seedClassNameList: [],
    publicationTypeList: [],
    publicationTitleList: [],
    publicationFeeList: [],
    qualityUnitList: [],
    publicationContentAreaList: [],
    productionSeasonList: [],
    seedPacketList: [],
    serviceList: [],
    projectList: [],
    subProjectList: [],
    sectorList: [],
    subSectorList: [],
    thematicAreaList: [],
    researchDivisionList: [],
    agencyList: [],
    budgetTypeList: [],
    budgetHeadList: [],
    labInfoList: [],
    componentOrgList: [],
    feePolicy: [],
    researchTypeList: Custom.researchTypeList,
    circularList: [],
    portalTypeList: Custom.portalTypeList,
    divisionalResearchList: [],
    roleInformationList: [],
    monitoringTeamList: [],
    publicationFeeTypeList: Custom.publicationFeeTypeList,
    schemeList: [],
    seedProcessingCentersList: [],
    divisionalCircularList: [],
    yearList: Custom.yearList
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
