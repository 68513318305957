export const mutations = {
    mutateExternalCommonProperties (state, payload) {
        state.static = Object.assign({}, state.static, payload)
    },
    setOrgAndOrgComponentList (state, payload) {
        state.commonObj.orgList = payload.orgList
        state.commonObj.orgComponentList = payload.orgComponentList
    },
    mutateExternalDropdown (state, payload) {
        state.commonObj = Object.assign(state.commonObj, payload)
    },
    setActivePanel (state, payload) {
        state.activePanel = payload
    },
    setPanelObj (state, payload) {
        state.panelObj = Object.assign({}, state.panelObj, payload)
    },
    setTrainingPanel (state, payload) {
        state.panelObj = Object.assign({}, state.panelObj, payload)
    },
    localizeExternalDropdown (state, payload) {
        state.commonObj.countryList = state.commonObj.countryList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.divisionList = state.commonObj.divisionList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.districtList = state.commonObj.districtList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.upazilaList = state.commonObj.upazilaList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.unionList = state.commonObj.unionList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.designationList = state.commonObj.designationList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.masterDesignationList = state.commonObj.masterDesignationList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.gradeList = state.commonObj.gradeList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.pauroshobaList = state.commonObj.pauroshobaList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.wardList = state.commonObj.wardList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.cityCorporationList = state.commonObj.cityCorporationList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.fiscalYearList = state.commonObj.fiscalYearList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.fertilizerNameList = state.commonObj.fertilizerNameList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.fertilizerTypeList = state.commonObj.fertilizerTypeList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.monthListfiscalYear = state.commonObj.monthListfiscalYear.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.organizationProfileList = state.commonObj.organizationProfileList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.orgList = state.commonObj.orgList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.officeList = state.commonObj.officeList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObj.officeTypeList = state.commonObj.officeTypeList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
    },
    getSchemeApplicationList (state, payload) {
        state.schemeApplicationLists = payload
    },
    getPumpOptApplicationList (state, payload) {
        state.pumpOptApplicationLists = payload
    },
    togglePumpOptApplicationStatus (state, pumpOptApplication) {
        state.pumpOptApplicationLists = state.pumpOptApplicationLists.map(function (item) {
            if (item.id === pumpOptApplication.id) {
                return pumpOptApplication
            }
            return item
        })
    },
    getPumpList (state, payload) {
        state.pumpList = payload
    },
    // Water Testing Request
    addwaterTestingRequest (state, waterTestingRequest) {
        state.waterTestingRequests.push(waterTestingRequest)
    },
    addwaterTestingRequestList (state, waterTestingRequests) {
        state.waterTestingRequests = waterTestingRequests
    },
    updatewaterTestingRequest (state, waterTestingRequest) {
        state.waterTestingRequests = state.waterTestingRequests.map(function (item) {
            if (item.id === waterTestingRequest.id) {
                return waterTestingRequest
            }

            return item
        })
    },
    togglewaterTestingRequestStatus (state, payload) {
        state.waterTestingRequests = state.waterTestingRequests.map(function (item) {
            if (item.id === payload.id) {
                payload.status = !payload.status
                return payload
            }

            return item
        })
    },
    // Smart-card-application
    getSMCApplicationList (state, payload) {
        state.smartCardApplicationLists = payload
    },
    setReissueStatus (state, payload) {
        state.reissueData = payload
    },
    // Farmer-basic-info
    getFarmerBasicInfoDetails (state, payload) {
        state.farmerBasicInfo = payload
    },
    // warehouse-farmer-profile
    getFarmerProfileList (state, payload) {
        state.warehouseFarmerProfiles = payload
    },
    addFarmerRatingList (state, ratinglist) {
        state.ratinglists = ratinglist
    },
    // ware-farmer-info
    getfarmerWareInfoDetails (state, payload) {
        state.farmerWareInfo = payload
    },
    setIrrigationCommonDropdown (state, response, locale) {
        state.waterTestingParameterList = response.data.waterTestingParameterList.map(item => {
            const tmp = locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.circleAreaList = response.data.circleAreaList.map(item => {
            const tmp = locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.pumpTypeList = response.data.pumpTypeList.map(item => {
            const tmp = locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.affidavitList = response.data.affidavitList.map(item => {
            const tmp = locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.schemeTypeList = response.data.schemeTypeList.map(item => {
            const tmp = locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.subSchemeTypeList = response.data.subSchemeTypeList.map(item => {
            const tmp = locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
    },
    // Set Affidavit List
    setAffidavitList (state, payload) {
        state.affidavitList = payload
    },
    // Set Warehouse Info List
    setWarehouseInfoList (state, payload) {
        state.warehouseInfoList = payload
    },
    // Set Region DistrictList
    setRegionDistrictList (state, payload) {
        state.regionDistrictList = payload
    },
    setRegionList (state, payload) {
        state.regionList = payload
    },
    setMarketList (state, payload) {
        state.marketList = payload
    },
    // Set Scheme Type List
    setSchemeTypeList (state, payload) {
        state.schemeTypeList = payload
    },
    setSubSchemeTypeList (state, payload) {
        state.subSchemeTypeList = payload
    },
    setPumpCapacityList (state, payload) {
        state.pumpCapacityList = payload
    },
    setWaterTestingParameterList (state, payload) {
        state.waterTestingParameterList = payload
    },
    setPumpTypeList (state, payload) {
        state.pumpTypeList = payload
    },
    setCircleAreaList (state, payload) {
        state.circleAreaList = payload
    },
    mutateWarehouseProperties (state, payload) {
        state.warehouse = Object.assign({}, state.warehouse, payload)
    },
    changeWarehouseDropdown (state, payload) {
        state.warehouse.commodityGroupList = state.warehouse.commodityGroupList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.warehouse.commodityNameList = state.warehouse.commodityNameList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.warehouse.regionList = state.warehouse.regionList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.warehouse.warehouseDesignationList = state.warehouse.warehouseDesignationList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.warehouse.warehouseTypeList = state.warehouse.warehouseTypeList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.warehouse.warehouseInfoList = state.warehouse.warehouseInfoList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.warehouse.warehouseLevelList = state.warehouse.warehouseLevelList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.warehouse.fiscalYearList = state.warehouse.fiscalYearList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.warehouse.marketInfoList = state.warehouse.marketInfoList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
    },
    addSchemeFarmerList (state, payload) {
        state.schemeFarmerList = payload
    },
    addSchemeAppFarmer (state, payload) {
        state.schemeAppAddedFarmers = payload
    },
    addPumpOptDetails (state, payload) {
        state.pumpOptDetails = payload
    },
    ginnerGrowerProfileDetails (state, payload) {
        state.ginnerGrowerDetails = payload
    },
    mutateIncentiveGrantProperties (state, payload) {
        state.incentiveGrant = Object.assign({}, state.incentiveGrant, payload)
    },
    mutateExternalUserAgriMarketing (state, payload) {
        state.agriMarketing = Object.assign({}, state.agriMarketing, payload)
    },
    changeIncentiveGrantDropdown (state, payload) {
        state.incentiveGrant.circularInfoList = state.incentiveGrant.circularInfoList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
    },
    // set grant applicant details
    setGrantApplicantDetails (state, payload) {
        state.incentiveGrant.applicantDetails = payload
        state.incentiveGrant.appGeneralInfoID = payload.id
    },
    mutateCommonProfile (state, payload) {
        state.commonProfile = Object.assign({}, state.commonProfile, payload)
    },
    localizeExternalAgriMarketingDropdown (state, payload) {
        state.agriMarketing.regionList = state.agriMarketing.regionList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriMarketing.zoneList = state.agriMarketing.zoneList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriMarketing.unitList = state.agriMarketing.unitList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriMarketing.hatList = state.agriMarketing.hatList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriMarketing.seasonList = state.agriMarketing.seasonList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriMarketing.cottonVaritiesList = state.agriMarketing.cottonVaritiesList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriMarketing.cottonNameList = state.agriMarketing.cottonNameList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
    }
}
