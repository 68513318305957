const taskReportStatusList = [
    {
        text: 'Complete',
        text_bn: 'সম্পন্ন',
        text_en: 'Complete',
        value: 1
    },
    {
        text: 'Partial',
        text_bn: 'আধা সম্পন্ন',
        text_en: 'Partial',
        value: 2
    }
]

const taskTrackerStatus = [
    {
        text: 'Pending',
        text_bn: 'বিচারাধীন',
        text_en: 'Pending',
        value: 1
    },
    {
        text: 'Complete',
        text_bn: 'সমাপ্ত',
        text_en: 'Complete',
        value: 2
    },
    {
        text: 'Verified',
        text_bn: 'যাচাইকৃত',
        text_en: 'Verified',
        value: 3
    }
]

export default {
    taskReportStatusList, taskTrackerStatus
}
