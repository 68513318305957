const childRoutes = (prop) => [
  {
    path: 'farm-machinery-report',
    name: prop + '.farm_machinery_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/farm-machinery-report/List.vue')
  },
  {
    path: 'allocation-report',
    name: prop + '.allocation_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/allocation-report/List.vue')
  },
  {
    path: 'supplier-wise-assign-report',
    name: prop + '.supplier_wise_assign_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/supplier-wise-assign-report/List.vue')
  },
  {
    path: 'distribution-report',
    name: prop + '.distribution_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/distribution-report/List.vue')
  }
]

const routes = [
  {
    path: '/incentive-grant-service/farm-machinery/reports',
    name: 'incentive_grant_service.farm_machinery.reports',
    component: () => import('@/layouts/IncentiveGrantLayout.vue'),
    meta: { auth: true },
    children: childRoutes('incentive_grant_service.farm_machinery.reports')
  }
]

export default routes
