export const mutations = {
  // test
  addTestList (state, payload) {
    state.tests.push(payload)
  },
  updateTestStatus (state, payload) {
    state.tests = state.test.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  },
  addWarehouseTypeList (state, warehouseTypes) {
    state.warehouseTypes = warehouseTypes
  },
  // sub group
  addSubGroupList (state, subgroup) {
    state.subgroups = subgroup
  },
  updateWarehouseSubGroupStatus (state, subgroup) {
    state.subgroups = state.subgroups.map(function (item) {
      if (item.id === subgroup.id) {
        subgroup.status = !subgroup.status
          return subgroup
      }
      return item
    })
  },
  updateWarehouseTypeStatus (state, payload) {
    state.warehouseTypes = state.warehouseTypes.map(function (item) {
      if (item.id === payload.id) {
          payload.status = !payload.status
          return payload
      }
      return item
    })
  },
  // commodity group
  addWarehouseGroupList (state, warehouseGroup) {
    state.warehouseGroups = warehouseGroup
  },
  updateWarehouseGroupStatus (state, warehouseGroup) {
    state.warehouseGroups = state.warehouseGroups.map(function (item) {
      if (item.id === warehouseGroup.id) {
          warehouseGroup.status = !warehouseGroup.status
          return warehouseGroup
      }
      return item
    })
  },
  // commodity name
  addCommodityNameList (state, commodityName) {
    state.commodityNames = commodityName
  },
  updateCommodityNameStatus (state, commodityName) {
    state.commodityNames = state.commodityNames.map(function (item) {
      if (item.id === commodityName.id) {
          commodityName.status = !commodityName.status
          return commodityName
      }
      return item
    })
  },
  // commodity designation
  addWarehouseDesignationList (state, warehouseDesignation) {
    state.warehouseDesignations = warehouseDesignation
  },
  // commodity Market Price
  addCommodityMarketPriceList (state, warehouseMarketPrice) {
    state.warehouseMarketPrices = warehouseMarketPrice
  },
  updateCommodityMarketPriceStatus (state, warehouseMarketPrice) {
    state.warehouseMarketPrices = state.warehouseMarketPrices.map(function (item) {
      if (item.id === warehouseMarketPrice.id) {
        warehouseMarketPrice.status = !warehouseMarketPrice.status
          return warehouseMarketPrice
      }
      return item
    })
  },
  // warehouse fiscal year
  addWarehouseFiscalYearList (state, warehouseFiscal) {
    state.warehouseFiscals = warehouseFiscal
  },
  updateWarehouseFiscalStatus (state, warehouseFiscal) {
    state.warehouseFiscals = state.warehouseFiscals.map(function (item) {
      if (item.id === warehouseFiscal.id) {
        warehouseFiscal.status = !warehouseFiscal.status
          return warehouseFiscal
      }
      return item
    })
  },
  // warehouse information
  addLoanRateList (state, loanRate) {
      state.loanRates = loanRate
    },
    updateLoanRateStatus (state, loanRate) {
      state.loanRates = state.loanRates.map(function (item) {
        if (item.id === loanRate.id) {
            loanRate.status = !loanRate.status
            return loanRate
        }
        return item
      })
    },
        // warehouse region
  addRegionList (state, region) {
      state.regions = region
    },
  updateRegionStatus (state, region) {
    state.regions = state.regions.map(function (item) {
      if (item.id === region.id) {
          region.status = !region.status
          return region
      }
      return item
    })
  },
  // bank loan calculator
  addLoanCalculatorList (state, loanCalculator) {
      state.loanCalculators = loanCalculator
    },
  updateLoanCalculatorStatus (state, loanCalculator) {
    state.loanCalculators = state.loanCalculators.map(function (item) {
      if (item.id === loanCalculator.id) {
          loanCalculator.status = !loanCalculator.status
          return loanCalculator
      }
      return item
    })
    },
  // warehouse level
  addWarehouseLevelList (state, warehouseLevel) {
    state.warehouseLevels = warehouseLevel
  },
  updateWarehouseLevelStatus (state, warehouseLevel) {
    state.warehouseLevels = state.warehouseLevels.map(function (item) {
        if (item.id === warehouseLevel.id) {
            warehouseLevel.status = !warehouseLevel.status
            return warehouseLevel
        }
        return item
    })
  },
  addWarehouseServiceList (state, warehouseService) {
    state.warehouseServices = warehouseService
  },
  updateWarehousServiceStatus (state, warehouseService) {
    state.warehouseServices = state.warehouseServices.map(function (item) {
      if (item.id === warehouseService.id) {
        warehouseService.status = !warehouseService.status
        return warehouseService
      }
      return item
    })
  },
  addWarehouseBankLoanFacilityList (state, warehouseBankLoanFacility) {
    state.warehouseBankLoanFacilitys = warehouseBankLoanFacility
  },
  updateWarehousBankLoanFacilityStatus (state, warehouseBankLoanFacility) {
    state.warehouseBankLoanFacilitys = state.warehouseBankLoanFacilitys.map(function (item) {
      if (item.id === warehouseBankLoanFacility.id) {
        warehouseBankLoanFacility.status = !warehouseBankLoanFacility.status
        return warehouseBankLoanFacility
      }
      return item
    })
  },
  addWarehouseRentList (state, warehouseRent) {
    state.warehouseRents = warehouseRent
  },
  updateWarehousRentStatus (state, warehouseRent) {
    state.warehouseRents = state.warehouseRents.map(function (item) {
      if (item.id === warehouseRent.id) {
        warehouseRent.status = !warehouseRent.status
        return warehouseRent
      }
      return item
    })
  },
  addWarehouseOpeningBalanceList (state, openingBalance) {
    state.openingBalances = openingBalance
  },
  updateWarehousOpeningBalanceStatus (state, openingBalance) {
    state.openingBalances = state.openingBalances.map(function (item) {
      if (item.id === openingBalance.id) {
        openingBalance.status = !openingBalance.status
        return openingBalance
      }
      return item
    })
  },
  // report header
  addWarehouseReportHadingList (state, reportHeader) {
    state.reportHeaders = reportHeader
  },
  updateWarehousReportHeaderStatus (state, reportHeader) {
    state.reportHeaders = state.reportHeaders.map(function (item) {
      if (item.id === reportHeader.id) {
        reportHeader.status = !reportHeader.status
        return reportHeader
      }
      return item
    })
  },
  addWarehouseCategoryList (state, warehouseCategories) {
    state.warehouseCategories = warehouseCategories
  },
  updateWarehouseCategoryStatus (state, payload) {
    state.warehouseCategories = state.warehouseCategories.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }
      return item
    })
  },
  addMarketInfoList (state, marketInfos) {
    state.marketInfos = marketInfos
  },
  updateMarketInfoStatus (state, marketInfo) {
    state.marketInfos = state.marketInfos.map(function (item) {
      if (item.id === marketInfo.id) {
        marketInfo.status = !marketInfo.status
        return marketInfo
      }
      return item
    })
  }
}
