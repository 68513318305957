const childRoutes = (prop) => [
    {
        path: 'subzone-wise-summary-report',
        name: prop + 'subzone_wise_summary_report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/subzone-wise-summary-report/List.vue')
    },
    {
        path: 'center-wise-summary-report',
        name: prop + 'center_wise_summary_report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/center-wise-summary-report/List.vue')
    },
    {
        path: 'subzone-wise-consolidated-report',
        name: prop + 'subzone_wise_consolidated_report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/subzone-wise-consolidated-report/List.vue')
    },
    {
        path: 'farmer-subsidy-demand-report',
        name: prop + 'farmer_subsidy_demand_report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/farmer-subsidy-demand-report/List.vue')
    },
    {
        path: 'unit-wise-summary-report',
        name: prop + 'unit_wise_summary_report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/unit-wise-summary-report/List.vue')
    },
    {
        path: 'summary-mill-wise-report-new',
        name: prop + 'summary-mill-wise-report-new',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/summary-mill-wise-report-new/List.vue')
    },
    {
        path: 'mill-wise-disbursement-report',
        name: prop + 'mill-wise-disbursement-report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/mill-wise-disbursement-report/List.vue')
    },
    {
        path: 'subzone-disbursement-report',
        name: prop + 'subzone-disbursement-report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/subzone-disbursement-report/List.vue')
    },
    {
        path: 'center-wise-disbursement-report',
        name: prop + 'center-wise-disbursement-report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/center-wise-disbursement-report/List.vue')
    },
    {
        path: 'unit-wise-disbursement-report',
        name: prop + 'unit-wise-disbursement-report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/unit-wise-disbursement-report/List.vue')
    }
]

const routes = [
    {
        path: '/incentive-grant-service/bsri/reports',
        name: 'incentive_grant_service.bsri.reports',
        component: () => import('@/layouts/IncentiveGrantLayout.vue'),
        meta: { auth: true },
        children: childRoutes('incentive_grant_service.bsri.reports')
    }
]

export default routes
