export default {
  mutateCommonObj (state, payload) {
    state.commonObj = Object.assign({}, state.commonObj, payload)
  },
  localizeDropdown (state, payload) {
    state.commonObj.programTypeList = state.commonObj.programTypeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
    })
    state.commonObj.carryoverList = state.commonObj.carryoverList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
    })
    state.commonObj.portZoneSetupList = state.commonObj.portZoneSetupList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
    })
    state.commonObj.CropTypeList = state.commonObj.CropTypeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
    })
    state.commonObj.seedCategoryList = state.commonObj.seedCategoryList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
    })
    state.commonObj.seedSubTypeList = state.commonObj.seedSubTypeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
    })
    state.commonObj.CropNameList = state.commonObj.CropNameList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
    })
    state.commonObj.VarietyList = state.commonObj.VarietyList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
    })
    state.commonObj.seedClassList = state.commonObj.seedClassList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.productionSourceList = state.commonObj.productionSourceList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.measurementUnitList = state.commonObj.measurementUnitList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.productionSeasonList = state.commonObj.productionSeasonList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.seasonList = state.commonObj.seasonList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.cnfAgentList = state.commonObj.cnfAgentList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.transportAgentList = state.commonObj.transportAgentList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
    state.commonObj.fertilizerImportCountryList = state.commonObj.fertilizerImportCountryList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.salesCenterList = state.commonObj.salesCenterList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.fertilizerTypeList = state.commonObj.fertilizerTypeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.fertilizerNameList = state.commonObj.fertilizerNameList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.portInfoSetupList = state.commonObj.portInfoSetupList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.godownInfoList = state.commonObj.godownInfoList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.allocationTypeList = state.commonObj.allocationTypeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.organizationList = state.commonObj.organizationList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.priceTypeList = state.commonObj.priceTypeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
    })
    state.commonObj.packetVarietyList = state.commonObj.packetVarietyList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
    })
    state.commonObj.zoneOfficeList = state.commonObj.zoneOfficeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.spcOfficeList = state.commonObj.spcOfficeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.prerequisiteChecklist = state.commonObj.prerequisiteChecklist.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
    })
    state.commonObj.dealerBasicList = state.commonObj.dealerBasicList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
    })
    state.commonObj.gpOrganizationsList = state.commonObj.gpOrganizationsList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.gpCropTypeList = state.commonObj.gpCropTypeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
    })
    state.commonObj.gpCropNameList = state.commonObj.gpCropNameList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })

    state.commonObj.gpConservationTypeList = state.commonObj.gpConservationTypeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.gpUnitList = state.commonObj.gpUnitList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.collectionSourceList = state.commonObj.collectionSourceList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.characterizationTypeList = state.commonObj.characterizationTypeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.descriptorLabelList = state.commonObj.descriptorLabelList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.descriptorHeadingList = state.commonObj.descriptorHeadingList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.codeGuideDetail = state.commonObj.codeGuideDetail.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.monthListfiscalYear = state.commonObj.monthListfiscalYear.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.seedProductionSourceTypeList = state.commonObj.seedProductionSourceTypeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.unitList = state.commonObj.unitList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.dashboardMonthList = state.commonObj.dashboardMonthList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
  },
  setCurrentFiscalYearId (state, payload) {
    state.currentFiscalYearId = payload
  }
}
