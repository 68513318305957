const childRoutes = (prop) => [
  {
      path: 'dashboard',
      name: prop + '.dashboard',
      component: () => import('../pages/dashboard/index')
  },
  {
    path: 'fmms-dashboard',
    name: prop + '.dashboard',
    component: () => import('../pages/dashboard/fmms')
  },
  {
    path: 'received-notifications',
    name: 'received-notifications',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/received-notifications/List.vue')
  }
]
const routes = [
  {
    path: '/incentive-grant-service',
    name: 'incentive_grant_service',
    component: () => import('@/layouts/IncentiveGrantLayout.vue'),
    children: childRoutes('incentive-grant-service'),
    meta: { auth: true }
  }
]

export default routes
