const childRoutes = (prop) => [
    {
        path: 'demand-collection',
        name: prop + 'demand_collection',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/demand-collection/List.vue')
    },
    {
        path: 'demand-collection-request',
        name: prop + 'demand_collection_request',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/demand-collection-request/List.vue')
    },
    {
        path: 'checklist-info-verification',
        name: prop + 'checklist_info_verification',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/checklist-info-verification/List.vue')
    },
    {
        path: 'field-level-inspection',
        name: prop + 'field_level_inspection',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/field-inspection/List.vue')
    },
    {
        path: 'subsidized-farmer-approval-list',
        name: prop + 'subsidized_farmer-approval-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/subsidized-farmer-approval/List.vue')
    },
    {
        path: 'mill-wise-summary-sheet',
        name: prop + 'mill_wise_summary-sheet',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/verification-mill-wise-subsidiary-summary-sheet/List.vue')
    },
    {
        path: 'subsidy-requisite-list-verification',
        name: prop + 'subsidy_requisite_list_verification',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/subsidy-requisite-list-verification/List.vue')
    },
    {
        path: 'subsidy-proposal-approval',
        name: prop + 'subsidy_proposal_approval',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/subsidy-proposal-approval/List.vue')
    },
    {
        path: 'subsidy-proposal-approval-list',
        name: prop + 'subsidy_proposal_approval-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/subsidy-proposal-approval-list/List.vue')
    },
    {
        path: 'consent-fund-clearance-list',
        name: prop + 'consent_fund_clearance_list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/consent-fund-clearance/List.vue')
    },
    {
        path: 'distribution-preparation',
        name: prop + 'distribution_preparation',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/distribution-preparation/List.vue')
    },
    {
        path: 'distribution-list-approval',
        name: prop + 'distribution_list_approval',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/distribution-list-approval/List.vue')
    },
    {
        path: 'distribution-registrar',
        name: prop + 'distribution_registrar',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/distribution-registrar/List.vue')
    },
    {
        path: 'subsidy-requisition-and-approval',
        name: prop + 'subsidy-requisition-and-approval',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/subsidy-requisition-and-approval/List.vue')
    },
    {
        path: 'fund-disbursement',
        name: prop + 'fund_disbursement',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/fund-disbursement/List.vue')
    },
    {
        path: 'fund-received',
        name: prop + 'fund_received',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/fund-received/List.vue')
    },
    {
        path: 'subsidize-far-approval',
        name: prop + 'subsidize_far_approval',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/subsidize-far-approval/List.vue')
    },
    {
        path: 'subsidize-far-approval-list',
        name: prop + 'subsidize_far_approval_list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/subsidize-far-approval-list/List.vue')
    },
    {
        path: 'subsidized-allocation',
        name: prop + 'subsidized_allocation',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/subsidized-allocation/List.vue')
    },
    {
        path: 'subsidized-allocation-request',
        name: prop + 'subsidized_allocation_request',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/subsidized-allocation-request/List.vue')
    },
    {
        path: 'subsidized-allocation-approved',
        name: prop + 'subsidized_allocation_approved',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/subsidized-allocation-approved/List.vue')
    }
]

const routes = [
    {
        path: '/incentive-grant-service/bsri/demand-distribution',
        name: 'incentive_grant_service.bsri.demand_distribution',
        component: () => import('@/layouts/IncentiveGrantLayout.vue'),
        meta: { auth: true },
        children: childRoutes('incentive_grant_service.bsri.demand_distribution')
    }
]

export default routes
