import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'

const state = {
    createCommittees: [],
    manageAgendas: [],
    meetingMinutes: [],
    manageExpenses: []
}
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
