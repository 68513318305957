import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'

const state = {
  commonObj: {
    hasDropdownLoaded: false,
    schemeTypeList: [],
    schemeFromAffidavitList: [],
    categoryList: [],
    subCategoryList: [],
    unitList: [],
    itemList: [],
    pumpType: [],
    horsePower: [],
    pumpStock: [],
    circleArea: [],
    projectList: [],
    laboratoryList: [],
    pumpInfoList: [],
    equipmentTypeList: [],
    waterTestingParameterList: [],
    mouzaList: []
  },
  users: [],
  paymentTypes: [],
  payments: [],
  pumpProgressTypes: [],
  laboratories: [],
  schemeFormAffidavits: [],
  horsePowers: [],
  circleAreas: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
