const childRoutes = (prop) => [
  {
    path: 'allocation-list',
    name: prop + '.allocation_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/allocation/List.vue')
  },
  {
    path: 'allocation-entry',
    name: prop + '.allocation_entry',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/allocation/Form.vue')
  },
  {
    path: 'allocation-approved-list',
    name: prop + '.allocation_approve_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/allocation/ApprovedList.vue')
  },
  {
    path: 'distribution-list',
    name: prop + '.distribution_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/distribution/List.vue')
  },
  {
    path: 'application-verification-list',
    name: prop + '.application_verification_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/applicationVerification/List.vue')
  },
  {
    path: 'distribution-details/:id',
    name: prop + '.distribution_details',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/distribution/Details.vue')
  }
]

const routes = [
  {
    path: '/incentive-grant-service/farm-machinery/allocation',
    name: 'incentive_grant_service.farm_machinery.allocation',
    component: () => import('@/layouts/IncentiveGrantLayout.vue'),
    meta: { auth: true },
    children: childRoutes('incentive_grant_service.farm_machinery.allocation')
  }
]

export default routes
