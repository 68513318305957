export const mutations = {
  addCategoryDocumentList (state, category) {
    state.categories = category
  },
  toggleCategoryDocumentStatus (state, payload) {
    state.categories = state.categories.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  ArchivetoggleDocumentStatus (state, payload) {
    state.categories = state.categories.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  updateCategoryDocumentType (state, payload) {
    state.categories = state.categories.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }
      return item
    })
  },
  addDocumentTypeList (state, document) {
    state.documents = document
  },
  toggleDocumentStatus (state, payload) {
    state.documents = state.documents.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  updateDocumentType (state, payload) {
    state.documents = state.documents.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }
      return item
    })
  },
  addArchivedTypeList (state, archived) {
     state.archives = archived
  },
  addCategoryDocumentShow (state, show) {
    state.shows = show
 }
}
