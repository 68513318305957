const childRoute = (prop) => [
  {
    path: 'application-list',
    name: prop + '.application-list',
    meta: { auth: true },
    component: () => import('../pages/application-allocation/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/application-for-allocation',
    name: 'seeds_fertilizer_service.application-for-allocation',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.application-for-allocation')
  }
]

export default routes
