const childRoutes = (prop) => [
    {
        path: 'payment-report',
        name: prop + 'payment_report',
        meta: { auth: true },
        component: () => import('../pages/payment-report/List.vue')
    },
    {
        path: 'allowance-report',
        name: prop + 'allowance_report',
        meta: { auth: true },
        component: () => import('../pages/allowance-report/List.vue')
    },
    {
        path: 'other-allowance-report',
        name: prop + 'other_allowance_report',
        meta: { auth: true },
        component: () => import('../pages/other-allowance-report/List.vue')
    },
    {
        path: 'other-honorarium-report',
        name: prop + 'other_honorarium_report',
        meta: { auth: true },
        component: () => import('../pages/other-honorarium-report/List.vue')
    },
    {
        path: 'other-material-report',
        name: prop + 'other_material_report',
        meta: { auth: true },
        component: () => import('../pages/other-material-report/List.vue')
    },
    {
        path: 'officer-material-report',
        name: prop + 'officer_material_report',
        meta: { auth: true },
        component: () => import('../pages/officer-material-report/List.vue')
    },
    {
        path: 'training-course-summary-report',
        name: prop + 'training_course_summary_report',
        meta: { auth: true },
        component: () => import('../pages/training-course-summary-report/List.vue')
    },
    {
        path: 'training-course-wise-attendance-report',
        name: prop + 'training_course_wise_attendance_report',
        meta: { auth: true },
        component: () => import('../pages/training-course-wise-attendance-report/List.vue')
    },
    {
        path: 'performance-report',
        name: prop + 'performance_report',
        meta: { auth: true },
        component: () => import('../pages/performance-report/List.vue')
    },
    {
        path: 'training-summary-report',
        name: prop + 'training_summary_report',
        meta: { auth: true },
        component: () => import('../pages/training-summary-report/List.vue')
    },
    {
        path: 'dormitory-others-charge-report',
        name: prop + 'dormitory_others_charge_report',
        meta: { auth: true },
        component: () => import('../pages/dormatory-others-charge-report/List.vue')
    },
    {
        path: 'trainee-performance-report',
        name: prop + 'trainee_performance_report',
        meta: { auth: true },
        component: () => import('../pages/trainee-performance-report/List.vue')
    },
    {
        path: 'trainer-performance-report',
        name: prop + 'trainer_performance_report',
        meta: { auth: true },
        component: () => import('../pages/trainer-performance-report/List.vue')
    },
    {
        path: 'training-course-evaluation-report',
        name: prop + 'training_course_evaluation_report',
        meta: { auth: true },
        component: () => import('../pages/training-course-evaluation-report/List.vue')
    },
    {
        path: 'pre-post-compare-evaluation-report',
        name: prop + 'pre_post_compare_evaluation_report',
        meta: { auth: true },
        component: () => import('../pages/pre-post-compare-evaluation-report/List.vue')
    },
    {
        path: 'veue-reservation-report',
        name: prop + 'veue_reservation_report',
        meta: { auth: true },
        component: () => import('../pages/veue-reservation-report/List.vue')
    },
    {
        path: 'course-wise-participant-report',
        name: prop + 'course_wise_participant_report',
        meta: { auth: true },
        component: () => import('../pages/course-wise-participant-report/List.vue')
    }
]

const routes = [
    {
      path: '/training-e-learning-service/report',
      name: 'training_e_learning_service.report',
      component: () => import('@/layouts/TrainingElearningLayout.vue'),
      meta: { auth: true },
      children: childRoutes('training_e_service.barc.report')
    }
]

export default routes
