export default {
  addTestList ({ commit }, payload) {
    commit('addTestList', payload)
  },
  updateTestStatus ({ commit }, payload) {
    commit('updateTestStatus', payload)
  },
  // sub group action
  addSubGroupList ({ commit }, payload) {
    commit('addSubGroupList', payload)
  },
  updateWarehouseSubGroupStatus ({ commit }, payload) {
    commit('updateWarehouseSubGroupStatus', payload)
  },
  // type
  addWarehouseTypeList ({ commit }, payload) {
    commit('addWarehouseTypeList', payload)
  },
  updateWarehouseTypeStatus ({ commit }, payload) {
    commit('updateWarehouseTypeStatus', payload)
  },
   // warehouse group
  addWarehouseGroupList ({ commit }, payload) {
    commit('addWarehouseGroupList', payload)
  },
  updateWarehouseGroupStatus ({ commit }, payload) {
    commit('updateWarehouseGroupStatus', payload)
  },
  // commodity name
  addCommodityNameList ({ commit }, payload) {
    commit('addCommodityNameList', payload)
  },
  updateCommodityNameStatus ({ commit }, payload) {
    commit('updateCommodityNameStatus', payload)
  },
  addWarehouseDesignationList ({ commit }, payload) {
    commit('addWarehouseDesignationList', payload)
  },
  addCommodityMarketPriceList ({ commit }, payload) {
    commit('addCommodityMarketPriceList', payload)
  },
  updateCommodityMarketPriceStatus ({ commit }, payload) {
    commit('updateCommodityMarketPriceStatus', payload)
  },
  // warehouse information
  addWarehouseFiscalYearList ({ commit }, payload) {
    commit('addWarehouseFiscalYearList', payload)
  },
  updateWarehouseFiscalStatus ({ commit }, payload) {
    commit('updateWarehouseFiscalStatus', payload)
  },
  // loan rate
  addLoanRateList ({ commit }, payload) {
    commit('addLoanRateList', payload)
  },
  updateLoanRateStatus ({ commit }, payload) {
    commit('updateLoanRateStatus', payload)
  },
  // warehouse region
  addRegionList ({ commit }, payload) {
    commit('addRegionList', payload)
  },
  updateRegionStatus ({ commit }, payload) {
    commit('updateRegionStatus', payload)
  },
  addLoanCalculatorList ({ commit }, payload) {
    commit('addLoanCalculatorList', payload)
  },
  updateLoanCalculatorStatus ({ commit }, payload) {
    commit('updateLoanCalculatorStatus', payload)
  },
  // warehouse level
  addWarehouseLevelList ({ commit }, payload) {
    commit('addWarehouseLevelList', payload)
  },
  updateWarehouseLevelStatus ({ commit }, payload) {
    commit('updateWarehouseLevelStatus', payload)
 },
  addWarehouseServiceList ({ commit }, payload) {
    commit('addWarehouseServiceList', payload)
  },
  updateWarehousServiceStatus ({ commit }, payload) {
    commit('updateWarehousServiceStatus', payload)
  },
  addWarehouseBankLoanFacilityList ({ commit }, payload) {
    commit('addWarehouseBankLoanFacilityList', payload)
  },
  updateWarehousBankLoanFacilityStatus ({ commit }, payload) {
    commit('updateWarehousBankLoanFacilityStatus', payload)
  },
  // warehouse rent
  addWarehouseRentList ({ commit }, payload) {
    commit('addWarehouseRentList', payload)
  },
  updateWarehousRentStatus ({ commit }, payload) {
    commit('updateWarehousRentStatus', payload)
  },
   // Opening Balance
   addWarehouseOpeningBalanceList ({ commit }, payload) {
    commit('addWarehouseOpeningBalanceList', payload)
  },
  updateWarehousOpeningBalanceStatus ({ commit }, payload) {
    commit('updateWarehousOpeningBalanceStatus', payload)
  },
    // Opening Balance
  addWarehouseReportHadingList ({ commit }, payload) {
  commit('addWarehouseReportHadingList', payload)
  },
  updateWarehousReportHeaderStatus ({ commit }, payload) {
    commit('updateWarehousReportHeaderStatus', payload)
  },
    // warehouse category
    addWarehouseCategoryList ({ commit }, payload) {
        commit('addWarehouseCategoryList', payload)
    },
    updateWarehouseCategoryStatus ({ commit }, payload) {
        commit('updateWarehouseCategoryStatus', payload)
    },
    // market info
    addMarketInfoList ({ commit }, payload) {
        commit('addMarketInfoList', payload)
    },
    updateMarketInfoStatus ({ commit }, payload) {
        commit('updateMarketInfoStatus', payload)
    }
}
