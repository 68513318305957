export default {
    mutateExternalCommonProperties ({ commit }, payload) {
        commit('mutateExternalCommonProperties', payload)
    },
    getSchemeApplicationList ({ commit }, payload) {
        commit('getSchemeApplicationList', payload)
    },
    getPumpOptApplicationList ({ commit }, payload) {
        commit('getPumpOptApplicationList', payload)
    },
    togglePumpOptApplicationStatus ({ commit }, payload) {
        commit('togglePumpOptApplicationStatus', payload)
    },
    // Pump Information
    addwaterTestingRequest ({ commit }, payload) {
        commit('addwaterTestingRequest', payload)
    },
    addwaterTestingRequestList ({ commit }, payload) {
        commit('addwaterTestingRequestList', payload)
    },
    updatewaterTestingRequest ({ commit }, payload) {
        commit('updatewaterTestingRequest', payload)
    },
    togglewaterTestingRequestStatus ({ commit }, payload) {
        commit('togglewaterTestingRequestStatus', payload)
    },
    getPumpList ({ commit }, payload) {
        commit('getPumpList', payload)
    },
    // Smart-card-application
    getSMCApplicationList ({ commit }, payload) {
        commit('getSMCApplicationList', payload)
    },
    setReissueStatus ({ commit }, payload) {
        commit('setReissueStatus', payload)
    },
    // Farmer-basic-info
    getFarmerBasicInfoDetails ({ commit }, payload) {
        commit('getFarmerBasicInfoDetails', payload)
    },
    getFarmerProfileList ({ commit }, payload) {
        commit('getFarmerProfileList', payload)
    },
    addFarmerRatingList ({ commit }, payload) {
        commit('addFarmerRatingList', payload)
    },
    //  warehouse farmer
    getfarmerWareInfoDetails ({ commit }, payload) {
        commit('getfarmerWareInfoDetails', payload)
    },
    changeWarehouseDropdown ({ commit }, payload) {
        commit('changeWarehouseDropdown', payload)
    },
    addSchemeFarmerList ({ commit }, payload) {
        commit('addSchemeFarmerList', payload)
    },
    addPumpOptDetails ({ commit }, payload) {
        commit('addPumpOptDetails', payload)
    },
    ginnerGrowerProfileDetails ({ commit }, payload) {
        commit('ginnerGrowerProfileDetails', payload)
    },
    mutateIncentiveGrantProperties ({ commit }, payload) {
        commit('mutateIncentiveGrantProperties', payload)
    },
    setGrantApplicantDetails ({ commit }, payload) {
        commit('setGrantApplicantDetails', payload)
    },
    changeIncentiveGrantDropdown ({ commit }, payload) {
        commit('changeIncentiveGrantDropdown', payload)
    },
    mutateCommonProfile ({ commit }, payload) {
        commit('mutateCommonProfile', payload)
    },
    localizeExternalAgriMarketingDropdown ({ commit }, payload) {
        commit('localizeExternalAgriMarketingDropdown', payload)
    }
}
