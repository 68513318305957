const childRoute = (prop) => [
  {
    path: 'service-eligibility-type',
    name: prop + '.service_eligibility_type',
    meta: { auth: true },
    component: () => import('../pages/service-eligibility-type/List.vue')
  },
  {
    path: 'content-entry',
    name: prop + '.content-entry',
    meta: { auth: true },
    component: () => import('../pages/content-entry/List.vue')
  },
  {
    path: 'manage-faq',
    name: prop + '.manage-faq',
    meta: { auth: true },
    component: () => import('../pages/manage-faq/List.vue')
  }
]
const routes = [
  {
    path: '/common-service/info-service-management',
    name: 'common_service.info_service_mgmt',
    component: () => import('../../../../layouts/VerticleLayout.vue'),
    meta: { auth: true },
    children: childRoute('common_service.info_service_mgmt')
  }
]

export default routes
