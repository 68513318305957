const childRoute = (prop) => [
  {
    path: 'payment-log-list',
    name: prop + '.payment_log_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/payment-log/List.vue')
  },
  {
    path: 'service-entry-list',
    name: prop + '.service-entry-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/service-entry/List.vue')
  }
]
const routes = [
  {
    path: '/payment-management',
    name: 'payment-management',
    component: () => import('../../../../layouts/VerticleLayout.vue'),
    meta: { auth: true },
    children: childRoute('payment-management')
  }
]

export default routes
