export default {
  // Notification Type
  addNotificationType ({ commit }, payload) {
    commit('addNotificationType', payload)
  },
  updateNotificationType ({ commit }, payload) {
    commit('updateNotificationType', payload)
  },
  addNotificationTypeList ({ commit }, payload) {
    commit('addNotificationTypeList', payload)
  },
  toggleNotificationTypeStatus ({ commit }, payload) {
    commit('toggleNotificationTypeStatus', payload)
  },
  // Notification Setting
  addNotificationSetting ({ commit }, payload) {
    commit('addNotificationSetting', payload)
  },
  updateNotificationSetting ({ commit }, payload) {
    commit('updateNotificationSetting', payload)
  },
  addNotificationSettingList ({ commit }, payload) {
    commit('addNotificationSettingList', payload)
  },
  toggleNotificationSettingStatus ({ commit }, payload) {
    commit('toggleNotificationSettingStatus', payload)
  },
  // Notification Template
  addNotificationTemplate ({ commit }, payload) {
    commit('addNotificationTemplate', payload)
  },
  updateNotificationTemplate ({ commit }, payload) {
    commit('updateNotificationTemplate', payload)
  },
  addNotificationTemplateList ({ commit }, payload) {
    commit('addNotificationTemplateList', payload)
  },
  toggleNotificationTemplateStatus ({ commit }, payload) {
    commit('toggleNotificationTemplateStatus', payload)
  },
  // Notification Template
  addCirculateNotice ({ commit }, payload) {
    commit('addCirculateNotice', payload)
  },
  updateCirculateNotice ({ commit }, payload) {
    commit('updateCirculateNotice', payload)
  },
  addCirculateNoticeList ({ commit }, payload) {
    commit('addCirculateNoticeList', payload)
  },
  toggleCirculateNoticeStatus ({ commit }, payload) {
    commit('toggleCirculateNoticeStatus', payload)
  }
}
