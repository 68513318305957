const supplierPanel = (prop) => [
  {
    path: 'profile',
    name: prop + '.profile',
    meta: { auth: true },
    component: () => import('../pages/Profile.vue')
  },
  {
    path: 'supplier-machinery-info',
    name: prop + '.supplier_machinery_info',
    meta: { auth: true },
    component: () => import('../pages/supplier-machinery-info/List.vue')
  }
]

const routes = [
  {
    path: '/supplier',
    name: 'external_user.supplier',
    component: () => import('@/layouts/ExternalUserLayout.vue'),
    meta: { auth: true },
    children: supplierPanel('external_user.supplier')
  }
]

export default routes
