export default {
    mutateExternalTraneeTranierDropdown ({ commit }, payload) {
        commit('mutateExternalTraneeTranierDropdown', payload)
    },
    localizeExternalTraneeTranierDropdown ({ commit }, payload) {
        commit('localizeExternalTraneeTranierDropdown', payload)
    },
    getTrainerOrTraineeDetails ({ commit }, payload) {
        commit('getTrainerOrTraineeDetails', payload)
    }
}
