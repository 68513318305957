export default {
  statusUpdate ({ commit }, payload) {
    commit('statusUpdate', payload)
  },
  stockInList ({ commit }, payload) {
    commit('stockInList', payload)
  },
  stockInToggleStatus ({ commit }, payload) {
    commit('stockInToggleStatus', payload)
  },
  updateRequisition ({ commit }, payload) {
    commit('updateRequisition', payload)
  },
  stockOutList ({ commit }, payload) {
    commit('stockOutList', payload)
  },
  stockOutApprove ({ commit }, payload) {
    commit('stockOutApprove', payload)
  },
  addFarmerDetails ({ commit }, payload) {
    commit('addFarmerDetails', payload)
  }
}
