export const mutations = {
  addUser (state, user) {
    state.users.push(user)
  },
  addUserList (state, users) {
    state.users = users
  },
  updateUser (state, user) {
    state.users = state.users.map(function (item) {
      if (item.id === user.id) {
        return user
      }

      return item
    })
  },
  updateStatus (state, payload) {
    state.users = state.users.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  },
  // Pump Operator
  addPumpOperator (state, pumpOperator) {
    state.pumpOperators.push(pumpOperator)
  },
  addPumpOperatorList (state, pumpOperators) {
    state.pumpOperators = pumpOperators
  },
  updatePumpOperator (state, pumpOperator) {
    state.pumpOperators = state.pumpOperators.map(function (item) {
      if (item.id === pumpOperator.id) {
        return pumpOperator
      }

      return item
    })
  },
  updatePumpOperatorStatus (state, payload) {
    state.pumpOperators = state.pumpOperators.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  },
  // Pump Information
  addPumpInformation (state, pumpInformation) {
    state.pumpInformations.push(pumpInformation)
  },
  addPumpInformationList (state, pumpInformations) {
    state.pumpInformations = pumpInformations
  },
  updatePumpInformation (state, pumpInformation) {
    state.pumpInformations = state.pumpInformations.map(function (item) {
      if (item.id === pumpInformation.id) {
        return pumpInformation
      }

      return item
    })
  },
  togglePumpInformationStatus (state, payload) {
    state.pumpInformations = state.pumpInformations.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  },
  // Pump Scheduler
  addPumpScheduler (state, pumpScheduler) {
    state.pumpSchedulers.push(pumpScheduler)
  },
  addPumpSchedulerList (state, pumpSchedulers) {
    state.pumpSchedulers = pumpSchedulers
  },
  updatePumpScheduler (state, pumpScheduler) {
    state.pumpSchedulers = state.pumpSchedulers.map(function (item) {
      if (item.id === pumpScheduler.id) {
        return pumpScheduler
      }

      return item
    })
  },
  togglePumpSchedulerStatus (state, payload) {
    state.pumpSchedulers = state.pumpSchedulers.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  },
  addPumpOptSuspensionList (state, payload) {
    state.pumpOperatorSuspensions = payload
  },
  addLandFarmerList (state, payload) {
    state.landFarmerList = payload
  }
}
