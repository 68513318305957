export default {
    // Test
    addTestList ({ commit }, payload) {
        commit('addTestList', payload)
    },
    updateTestStatus ({ commit }, payload) {
        commit('updateTestStatus', payload)
    },
    // Task Start
    addIrriTask ({ commit }, payload) {
        commit('addIrriTask', payload)
    },
    irriTaskList ({ commit }, payload) {
        commit('irriTaskList', payload)
    },
    updateIrriTask ({ commit }, payload) {
        commit('updateIrriTask', payload)
    },
    updateIrriTaskStatus ({ commit }, payload) {
        commit('updateIrriTaskStatus', payload)
    },
    // Task End
    // Task Calendar List Starts
    taskCalendarList ({ commit }, payload) {
        commit('taskCalendarList', payload)
    },
    // Task Calendar List Ends
    irriTaskTrackerList ({ commit }, payload) {
        commit('irriTaskTrackerList', payload)
    },
    verifyTask ({ commit }, payload) {
        commit('verifyTask', payload)
    },
    // Task Reports List
    irriTaskReportsList ({ commit }, payload) {
        commit('irriTaskReportsList', payload)
    },
    addTaskInfo ({ commit }, payload) {
        commit('addTaskInfo', payload)
    }
}
