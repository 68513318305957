export const mutations = {
    // New Application  Request
    addNewApplicationRequest (state, NewApplicationRequest) {
        state.NewApplicationRequests.push(NewApplicationRequest)
    },
    addNewApplicationRequestList (state, NewApplicationRequests) {
        state.NewApplicationRequests = NewApplicationRequests
    },
    updateNewApplicationRequest (state, NewApplicationRequest) {
        state.NewApplicationRequests = state.NewApplicationRequests.map(function (item) {
        if (item.id === NewApplicationRequest.id) {
            return NewApplicationRequest
        }

        return item
        })
    },
    toggleNewApplicationRequestStatus (state, payload) {
        state.NewApplicationRequests = state.NewApplicationRequests.map(function (item) {
        if (item.id === payload.id) {
            payload.status = !payload.status
            return payload
        }

        return item
        })
    },
    // Reissue Application Request
    addReissueApplicationRequest (state, ReissueApplicationRequest) {
        state.ReissueApplicationRequests.push(ReissueApplicationRequest)
    },
    addReissueApplicationRequestList (state, ReissueApplicationRequests) {
        state.ReissueApplicationRequests = ReissueApplicationRequests
    },
    updateReissueApplicationRequest (state, ReissueApplicationRequest) {
        state.ReissueApplicationRequests = state.ReissueApplicationRequests.map(function (item) {
        if (item.id === ReissueApplicationRequest.id) {
            return ReissueApplicationRequest
        }

        return item
        })
    },
    toggleReissueApplicationRequestStatus (state, payload) {
        state.ReissueApplicationRequests = state.ReissueApplicationRequests.map(function (item) {
        if (item.id === payload.id) {
            payload.status = !payload.status
            return payload
        }

        return item
        })
    },
    // Smart Card Approval List
    addSmartCardApprove (state, SmartCardApprove) {
        state.SmartCardApproves.push(SmartCardApprove)
    },
    addSmartCardApproveList (state, SmartCardApproves) {
        state.SmartCardApproves = SmartCardApproves
    },
    updateSmartCardApprove (state, SmartCardApprove) {
        state.SmartCardApproves = state.SmartCardApproves.map(function (item) {
        if (item.id === SmartCardApprove.id) {
            return SmartCardApprove
        }

        return item
        })
    },
    toggleSmartCardApproveStatus (state, payload) {
        state.SmartCardApproves = state.SmartCardApproves.map(function (item) {
        if (item.id === payload.id) {
            payload.status = !payload.status
            return payload
        }

        return item
        })
    },
    // Smart Card List
    addSmartCard (state, SmartCard) {
        state.SmartCards.push(SmartCard)
    },
    addSmartCardList (state, SmartCards) {
        state.SmartCards = SmartCards
    },
    updateSmartCard (state, SmartCard) {
        state.SmartCards = state.SmartCards.map(function (item) {
        if (item.id === SmartCard.id) {
            return SmartCard
        }

        return item
        })
    },
    toggleSmartCardStatus (state, payload) {
        state.SmartCards = state.SmartCards.map(function (item) {
        if (item.id === payload.id) {
            payload.status = !payload.status
            return payload
        }

        return item
        })
    }, // Smart-card-application
    getSMCApplicationList (state, payload) {
        state.smartCardApplicationLists = payload
    }
}
