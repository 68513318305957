const childRoutes = (prop) => [
    // {
    //   path: 'grant-application-status-report',
    //   name: prop + 'grant_application_status_report',
    //   meta: { auth: true, name: 'Editable' },
    //   component: () => import('../pages/grant-application-status-report/List.vue')
    // },
    {
      path: 'fund-distribution-report',
      name: prop + 'fund_distribution_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/fund-distribution-report/List.vue')
    },
    {
      path: 'due-applicant-funding-report',
      name: prop + 'due_applicant_funding_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/due-applicant-funding-report/List.vue')
    },
    {
      path: 'approval-application-report',
      name: prop + 'approval_application_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/approval-application-report/List.vue')
    }
]

const routes = [
    {
        path: '/incentive-grant-service/barc/report',
        name: 'incentive_grant_service.barc.report',
        component: () => import('@/layouts/IncentiveGrantLayout.vue'),
        meta: { auth: true },
        children: childRoutes('incentive_grant_service.barc.report')
    }
]

export default routes
