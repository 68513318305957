import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'

const state = {
  users: [],
  pumpOperators: [],
  pumpScheduers: [],
  pumpInformations: [],
  pumpOperatorSuspensions: [],
  schemeFarmerList: [],
  landFarmerList: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
