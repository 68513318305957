export const mutations = {
  // test
  addTest (state, test) {
    state.tests.push(test)
  },
  addTestList (state, payload) {
    state.tests = payload
  },
  updateTestStatus (state, payload) {
    state.tests = state.test.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }
      return item
    })
  },
  // farmer request
  addFarmerRequestList (state, farmerRequest) {
    state.farmerRequests = farmerRequest
  },
  updateFarmerRequestStatus (state, farmerRequest) {
    state.farmerRequests = state.farmerRequests.map(function (item) {
      if (item.id === farmerRequest.id) {
        farmerRequest.status = !farmerRequest.status
        return farmerRequest
      }
      return item
    })
  },
  addwareServiceRegistrationList (state, registrations) {
    state.registrations = registrations
  },
  addRegRecomm (state, recommendations) {
    state.recommendations = recommendations
  },
  toggleRegRecomStatus (state, payload) {
    state.recommendations = state.recommendations.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  RegApproveStatus (state, payload) {
    state.recommendations = state.recommendations.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  RegRejectStatus (state, payload) {
    state.recommendations = state.recommendations.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  addRegRecomDetailsList (state, regdetail) {
    state.regdetails = regdetail
  },
  // payment germplasm-collection
  addPaymentCollectionList (state, paymentCollection) {
    state.paymentCollections = paymentCollection
  },
  updatePaymentCollectionStatus (state, paymentCollection) {
    state.paymentCollections = state.paymentCollections.map(function (item) {
      if (item.id === paymentCollection.id) {
        paymentCollection.status = !paymentCollection.status
        return paymentCollection
      }
      return item
    })
  },
  // delivery schedule list
  addDeliveryScheduleList (state, deliveryScheduleList) {
    state.deliveryScheduleLists = deliveryScheduleList
  }
}
