import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'
import Custom from './custom'

const state = {
    taskAssignList: [],
    taskCalendarList: [],
    taskTrackerList: [],
    taskReportsList: [],
    taskReportStatusList: Custom.taskReportStatusList,
    taskTrackerStatusList: Custom.taskTrackerStatus,
    taskAssignToUserList: [],
    taskInfo: {}
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
