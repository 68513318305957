// import store from '@/store'
const approveStatus = 1 // store.state.ExternalUserAuth.farmerUserDetails.user_detail_farmer.status

const guard = function (to, from, next) {
    // check for valid approved user(only Farmer)
    if (approveStatus === 1) {
        next()
    } else {
        next(next({ name: 'external-user' + '.dashboard' }))
    }
}

const commonRoutes = (prop) => [
  {
    path: '/dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../pages/Dashboard.vue')
  },
  {
    path: '/common/dashboard',
    name: prop + '.common_dashboard',
    meta: { auth: true },
    component: () => import('../pages/CommonDashboard.vue')
  },
  {
    path: 'site-pereference',
    name: prop + '.site_preference',
    meta: { auth: true },
    component: () => import('@/views/Pages/SitePreference')
  },
  {
    path: 'password-change',
    name: prop + '.password_change',
    meta: { auth: true },
    component: () => import('@/modules/auth-service/auth/pages/security/ChangePassword.vue')
  }
]

const childRoute = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../pages/Dashboard.vue')
  },
  {
    path: 'my-profile',
    name: prop + '.my_profile',
    meta: { auth: true },
    component: () => import('../pages/farmer-basic-info/Show.vue')
  },
  {
    path: 'my-profile-form',
    name: prop + '.my_profile_form',
    meta: { auth: true },
    component: () => import('../pages/farmer-basic-info/Form.vue')
  },
  {
    path: 'change-mobile',
    name: prop + '.change_mobile',
    meta: { auth: true },
    component: () => import('../pages/farmer-basic-info/Mobile.vue')
  },
  {
    path: 'scheme-application',
    name: prop + '.scheme_application',
    meta: { auth: true },
    component: () => import('../pages/scheme-application/List.vue')
  },
  {
    path: 'scheme-application/:status',
    name: prop + '.scheme_application.status',
    meta: { auth: true },
    component: () => import('../pages/scheme-application/List.vue')
  },
  {
    path: 'pump-operator-application',
    name: prop + '.pump_operator_application',
    meta: { auth: false },
    component: () => import('../pages/pump-opt-application/List.vue')
  },
  {
    path: 'pump-operator-application/:status',
    name: prop + '.pump_operator_application.status',
    meta: { auth: false },
    component: () => import('../pages/pump-opt-application/List.vue')
  },
  {
    path: 'smart-card-application',
    name: prop + '.smart_card_application',
    meta: { auth: true },
    component: () => import('../pages/smart-card-application/List.vue')
  },
  {
    path: 'smart-card-application/:status',
    name: prop + '.smart_card_application.status',
    meta: { auth: true },
    component: () => import('../pages/smart-card-application/List.vue')
  },
  {
    path: 'smart-card-application/view/:id',
    name: prop + '.smart_card_application_view',
    meta: { auth: true },
    component: () => import('../pages/smart-card-application/Show.vue')
  },
  {
    path: 'smart-card-application/edit/:id',
    name: prop + '.smart_card_application_edit',
    meta: { auth: false },
    component: () => import('../pages/smart-card-application/Form.vue')
  },
  {
    path: 'smart-card-application-form',
    name: prop + '.smart_card_application_form',
    meta: { auth: true },
    component: () => import('../pages/smart-card-application/Form.vue')
  },
  {
    path: 'water-testing-request',
    name: prop + '.water_testing_request',
    meta: { auth: true },
    component: () => import('../pages/Dashboard.vue')
  },
  {
    path: 'farmer-complain',
    name: prop + '.farmer_complain',
    meta: { auth: true },
    component: () => import('../pages/complain/List.vue')
  },
  {
    path: 'farmer-complain-form',
    name: prop + '.farmer_complain_form',
    meta: { auth: true },
    props: true,
    component: () => import('../pages/complain/Form.vue')
  },
  {
    path: 'my-assign-task',
    name: prop + '.my_assign_task',
    meta: { auth: true },
    component: () => import('../pages/task/task-calendar/List.vue')
  },
  {
    path: 'daily-task',
    name: prop + '.my_daily_task',
    meta: { auth: true },
    component: () => import('../pages/task/daily-task/List.vue')
  },
  {
    path: 'payment',
    name: prop + '.payment',
    meta: { auth: true },
    component: () => import('../pages/payment/List.vue')
  },
  {
    path: 'apply-resunk',
    name: prop + '.apply_resunk',
    meta: { auth: true },
    component: () => import('../pages/resunk/ResunkApplication.vue')
  },
  {
    path: 'scheme-application-submit',
    name: prop + '.scheme-application-submit',
    meta: { auth: true },
    component: () => import('../pages/scheme-application/SchemeApplication.vue')
  },
  {
    path: 'scheme-application-submit/:id',
    name: prop + '.scheme-application-submit.id',
    meta: { auth: true },
    component: () => import('../pages/scheme-application/SchemeApplication.vue')
  },
  {
    path: 'pump-opt-application',
    name: prop + '.pump-opt-application',
    meta: { auth: false },
    component: () => import('../pages/pump-opt-application/PumpOptApplication.vue')
  },
  {
    path: 'pump-opt-application/:id',
    name: prop + '.pump-opt-application.id',
    meta: { auth: false },
    component: () => import('../pages/pump-opt-application/PumpOptApplication.vue')
  },
  {
    path: 'pump-opt-renew/:id',
    name: prop + '.pump-opt-renew',
    meta: { auth: false },
    component: () => import('../pages/pump-opt-application/PumpOptApplicationRenew.vue')
  },
  {
    path: 'water-testing-request-list',
    name: prop + '.water_testing_request_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/water-testing-request/List.vue')
  },
  {
    path: 'water-testing-request-form',
    name: prop + '.water_testing_request_form',
    meta: { auth: true, name: 'Editable' },
    props: true,
    component: () => import('../pages/water-testing-request/Form.vue')
  },
  {
    path: 'water-testing-request-list/:status',
    name: prop + '.water_testing_request_list.status',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/water-testing-request/List.vue')
  },
  {
    path: 'rating',
    name: prop + '.rating',
    meta: { auth: true },
    component: () => import('../pages/rating/List.vue')
  }
]

const warehouseFarmerdRoute = (prop) => [
    {
      path: 'dashboard',
      name: prop + '.dashboard',
      meta: { auth: true },
      component: () => import('../pages/DashboardWarehouse.vue')
    },
    {
        path: 'profile',
        name: prop + '.profile',
        meta: { auth: true },
        component: () =>
            import('../pages/warehouse-farmer/ware-farm-profile/Show.vue')
    },
    {
        path: 'profile-form',
        name: prop + '.profile-form',
        meta: { auth: true },
        component: () =>
            import('../pages/warehouse-farmer/ware-farm-profile/Form.vue')
    },
    {
        path: 'ware-farmer-panel-add-request',
        name: prop + '.add_request',
        beforeEnter: (to, from, next) => {
            guard(to, from, next)
        },
        meta: { auth: true },
        component: () =>
            import('../pages/warehouse-farmer/ware-farmer-request/List.vue')
    },
    {
      path: 'ware-farmer-panel-add-request-form',
      name: prop + '.add_request_form',
      beforeEnter: (to, from, next) => {
          guard(to, from, next)
      },
      meta: { auth: true },
      component: () =>
          import('../pages/warehouse-farmer/ware-farmer-request/Form.vue')
  },
    {
        path: 'ware-farmer-make-payment',
        name: prop + '.make_payment',
        beforeEnter: (to, from, next) => {
            guard(to, from, next)
        },
        meta: { auth: true },
        component: () =>
            import('../pages/warehouse-farmer/make-payment/List.vue')
    },
    {
        path: 'ware-farmer-panel-rating-entry',
        name: prop + '.service_rating',
        beforeEnter: (to, from, next) => {
            guard(to, from, next)
        },
        meta: { auth: true },
        component: () =>
            import('../pages/warehouse-farmer/ware-farm-rating-entry/List.vue')
    },
    {
        path: 'complain',
        name: prop + '.complain',
        beforeEnter: (to, from, next) => {
            guard(to, from, next)
        },
        meta: { auth: true },
        component: () =>
            import('../pages/warehouse-farmer/complain/List.vue')
    },
    {
        path: 'qc-certificate',
        name: prop + '.qc-certificate',
        beforeEnter: (to, from, next) => {
            guard(to, from, next)
        },
        meta: { auth: true },
        component: () =>
            import('../pages/warehouse-farmer/qc-certificate/List.vue')
    },
    {
        path: 'warehouse-service',
        name: prop + '.warehouse-service',
        beforeEnter: (to, from, next) => {
            guard(to, from, next)
        },
        meta: { auth: true },
        component: () =>
            import('../pages/warehouse-farmer/warehouse-service/List.vue')
    },
    {
        path: 'far-delivery-schedule',
        name: prop + '.far-delivery-schedule',
        beforeEnter: (to, from, next) => {
            guard(to, from, next)
        },
        meta: { auth: true },
        component: () =>
            import('../pages/warehouse-farmer/far-delivery-schedule/List.vue')
    },
    {
        path: 'market-price',
        name: prop + '.market-price',
        beforeEnter: (to, from, next) => {
            guard(to, from, next)
        },
        meta: { auth: true },
        component: () =>
            import('../pages/warehouse-farmer/market-price/List.vue')
    },
    {
        path: 'product-stock-report',
        name: prop + '.product-stock-report',
        beforeEnter: (to, from, next) => {
            guard(to, from, next)
        },
        meta: { auth: true },
        component: () =>
            import('../pages/warehouse-farmer/product-stock-report/index.vue')
    }
]

const ginnerRoute = (prop) => [{
        path: 'dashboard',
        name: prop + '.dashboard',
        meta: { auth: true },
        component: () =>
            import('../pages/Dashboard.vue')
    },
    {
        path: 'profile',
        name: prop + '.profile',
        meta: { auth: true },
        component: () =>
            import('../pages/ginner/profile/Show.vue')
    },
    {
        path: 'profile-form',
        name: prop + '.profile-form',
        meta: { auth: true },
        component: () =>
            import('../pages/ginner/profile/Form.vue')
    },
    {
        path: 'manage-schedule',
        name: prop + '.manage-schedule',
        meta: { auth: true },
        component: () => import('../pages/ginner/manage-schedule/List.vue')
    },
    {
        path: 'hatt-management',
        name: prop + '.hatt-management',
        meta: { auth: true },
        component: () => import('../pages/ginner/hatt-management/List.vue')
    }
]

const growerRoute = (prop) => [{
        path: 'dashboard',
        name: prop + '.dashboard',
        meta: { auth: true },
        component: () =>
            import('../pages/Dashboard.vue')
    },
    {
        path: 'profile',
        name: prop + '.profile',
        meta: { auth: true },
        component: () =>
            import('../pages/grower/profile/Show.vue')
    },
    {
        path: 'profile-form',
        name: prop + '.profile-form',
        meta: { auth: true },
        component: () => import('../pages/grower/profile/Form.vue')
    },
    {
        path: 'production-achievement',
        name: prop + '.production-achievement',
        meta: { auth: true },
        component: () => import('../pages/grower/production-achievement/List.vue')
    },
    {
        path: 'hatt-management',
        name: prop + '.hatt-management',
        meta: { auth: true },
        component: () => import('../pages/grower/hatt-management/List.vue')
    }
]

const divisionalHead = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../pages/divisional-head/dashboard/Show.vue')
  },
  {
    path: 'campaign-request',
    name: prop + '.campaign_request',
    meta: { auth: true },
    component: () => import('../pages/divisional-head/campaign-request/List.vue')
  },
  {
    path: 'campaign-material',
    name: prop + '.campaign_material',
    meta: { auth: true },
    component: () => import('../pages/divisional-head/campaign-material/List.vue')
  },
  {
    path: 'campaign-information',
    name: prop + '.campaign_information',
    meta: { auth: true },
    component: () => import('../pages/divisional-head/campaign-information/List.vue')
  },
  {
    path: 'campaign-attendance',
    name: prop + '.campaign_attendance',
    meta: { auth: true },
    component: () => import('../pages/divisional-head/campaign-attendance/List.vue')
  },
  {
    path: 'campaign-feedback',
    name: prop + '.campaign_feedback',
    meta: { auth: true },
    component: () => import('../pages/divisional-head/campaign-feedback/List.vue')
  },
  {
    path: 'campaign-dashboard',
    name: prop + '.campaign_dashboard',
    meta: { auth: true },
    component: () => import('../pages/divisional-head/campaign-dashboard/index.vue')
  }
]

const dealerPanel = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../pages/dealer-panel/Dashboard.vue')
  },
  {
    path: 'profile',
    name: prop + '.profile',
    meta: { auth: true },
    component: () => import('../pages/dealer-panel/profile/Show.vue')
  },
  {
    path: 'fertilizer-allotment',
    name: prop + '.fertilizer_allotment',
    meta: { auth: true },
    component: () => import('../pages/dealer-panel/fertilizer-allocation/List')
  },
  {
    path: 'profile-form',
    name: prop + '.profile-form',
    meta: { auth: true },
    component: () => import('../pages/dealer-panel/profile/Form.vue')
  },
  {
    path: 'requisition',
    name: prop + '.requisition',
    meta: { auth: true },
    component: () => import('../pages/dealer-panel/requisition/List.vue')
  },
  {
    path: 'requisition-form',
    name: prop + '.requisition_form',
    meta: { auth: true },
    props: true,
    component: () => import('../pages/dealer-panel/requisition/Form.vue')
  },
  {
    path: 'sales-entry',
    name: prop + '.sales_entry',
    meta: { auth: true },
    component: () => import('../pages/dealer-panel/sales-entry/List.vue')
  },
  {
    path: 'fertilizer-requisition-report',
    name: prop + '.fertilizer_requisition_report',
    meta: { auth: true },
    component: () => import('../pages/dealer-panel/reports/fertilizer-requisition-report/List.vue')
  },
  {
    path: 'fertilizer-allotment-report',
    name: prop + '.fertilizer_allotment_report',
    meta: { auth: true },
    component: () => import('../pages/dealer-panel/reports/fertilizer-allotment-report/List.vue')
  },
  {
    path: 'fertilizer-sales-report',
    name: prop + '.fertilizer_sales_report',
    meta: { auth: true },
    component: () => import('../pages/dealer-panel/reports/fertilizer-sales-report/List.vue')
  }
]

const grantPanel = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../pages/grant-panel/Dashboard.vue')
    // redirect: { name: 'external_user.grant_panel.application_form' }
  },
  {
    path: 'profile-update',
    name: prop + '.profile_update',
    meta: { auth: true },
    component: () => import('../pages/grant-panel/profile/Form.vue')
  },
  {
    path: 'profile-view',
    name: prop + '.profile_view',
    meta: { auth: true },
    component: () => import('../pages/grant-panel/profile/View.vue')
  },
  {
    path: 'circular',
    name: prop + '.circular',
    meta: { auth: true },
    component: () => import('../pages/grant-panel/circular/List.vue')
  },
  {
    path: 'application-form',
    name: prop + '.application_form',
    meta: { auth: true },
    component: () => import('../pages/grant-panel/application-form/List.vue')
  },
  {
    path: 'application-form-main',
    name: prop + '.application_form_main',
    meta: { auth: true },
    component: () => import('../pages/grant-panel/application-form/AdmissionFormMain.vue')
  },
  {
    path: 'application-form-main/:id',
    name: prop + '.application_form_main',
    meta: { auth: true },
    component: () => import('../pages/grant-panel/application-form/AdmissionFormMain.vue')
  },
  {
    path: 'fund-request',
    name: prop + '.fund_request',
    meta: { auth: true },
    component: () => import('../pages/grant-panel/fund-request/List.vue')
  }
]

const virtualMuseum = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../pages/virtual-museum/Dashboard.vue')
    // redirect: { name: 'external_user.grant_panel.application_form' }
  },
  {
    path: 'contact-us',
    name: prop + '.contact_us',
    meta: { auth: true },
    component: () => import('../pages/virtual-museum/contact-us/List.vue')
  },
  {
    path: 'virtual-krishibid',
    name: prop + '.virtual_krishibid',
    meta: { auth: true },
    component: () => import('../pages/virtual-museum/virtual-krishibid/List.vue')
  },
  {
    path: 'visit-request',
    name: prop + '.visit_request',
    meta: { auth: true },
    component: () => import('../pages/virtual-museum/visit-request/List.vue')
  },
  {
    path: 'visit_request_physical',
    name: prop + '.visit_request_physical',
    meta: { auth: true },
    component: () => import('../pages/virtual-museum/visit-request/Form-hard.vue')
  }
]

const routes = [
    {
        path: '/',
        name: 'external-user',
        component: () =>
            import('@/layouts/ExternalUserLayout.vue'),
        meta: { auth: true },
        children: commonRoutes('external-user')
    },
    {
        path: '/irrigation',
        name: 'external-user',
        component: () =>
            import('@/layouts/ExternalUserLayout.vue'),
        meta: { auth: true },
        children: childRoute('external-user')
    },
    {
        path: '/warehouse-farmer',
        name: 'external_user.warehouse_farmer',
        component: () => import('@/layouts/ExternalUserLayout.vue'),
        meta: { auth: true },
        children: warehouseFarmerdRoute('external_user.warehouse_farmer')
    },
    {
        path: '/ginner',
        name: 'external_user.ginner',
        component: () => import('@/layouts/ExternalUserLayout.vue'),
        meta: { auth: true },
        children: ginnerRoute('external_user.ginner')
    },
    {
        path: '/grower',
        name: 'external_user.grower',
        component: () => import('@/layouts/ExternalUserLayout.vue'),
        meta: { auth: true },
        children: growerRoute('external_user.grower')
    },
    {
        path: '/divisional-head',
        name: 'external_user.divisional_head',
        component: () => import('@/layouts/ExternalUserLayout.vue'),
        meta: { auth: true },
        children: divisionalHead('external_user.divisional_head')
    },
    {
        path: '/dealer-panel',
        name: 'external_user.dealer_panel',
        component: () => import('@/layouts/ExternalUserLayout.vue'),
        meta: { auth: true },
        children: dealerPanel('external_user.dealer_panel')
    },
    {
      path: '/grant-panel',
      name: 'external_user.grant_panel',
      component: () => import('@/layouts/ExternalUserLayout.vue'),
      meta: { auth: true },
      children: grantPanel('external_user.grant_panel')
    },
    {
      path: '/virtual-museum',
      name: 'external_user.virtual_museum',
      component: () => import('@/layouts/ExternalUserLayout.vue'),
      meta: { auth: true },
      children: virtualMuseum('external_user.virtual_museum')
    }
]

export default routes
