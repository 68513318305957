export default {
  addTestList ({ commit }, payload) {
    commit('addTestList', payload)
  },
  updateTestStatus ({ commit }, payload) {
    commit('updateTestStatus', payload)
  },
  addWarehouseServiceList ({ commit }, payload) {
    commit('addWarehouseServiceList', payload)
  },
  updateWarehousServiceStatus ({ commit }, payload) {
    commit('updateWarehousServiceStatus', payload)
  },
  addWarehouseBankLoanFacilityList ({ commit }, payload) {
    commit('addWarehouseBankLoanFacilityList', payload)
  },
  updateWarehousBankLoanFacilityStatus ({ commit }, payload) {
    commit('updateWarehousBankLoanFacilityStatus', payload)
  }
}
