export default {
  schemeApplicationListFind: (state) => (id) => state.schemeApplicationLists.find(schemeApplication => schemeApplication.id === id),
  waterTestingRequestFind: (state) => (id) => state.waterTestingRequests.find(waterTestingRequest => waterTestingRequest.id === id),
  wareFarmerFind: (state) => state.getDetails,
  commonObj: state => state.commonObj,
  orgList: state => state.commonObj.orgList,
  orgComponentList: state => state.commonObj.orgComponentList,
  getActivePanel: state => state.activePanel,
  panelObj: state => state.panelObj
}
