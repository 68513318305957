// const childRoute = (prop) => [
//   {
//     path: 'dashboard',
//     name: prop + '.dashboard',
//     meta: { auth: true },
//     component: () => import('../pages/Dashboard.vue')
//   }
// ]

const traineePanel = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () =>
        import('../pages/Dashboard.vue')
  },
  {
    path: 'profile',
    name: prop + '.profile',
    meta: { auth: true },
    component: () =>
        import('../pages/profile/Form.vue')
  },
  {
    path: 'circular-list',
    name: prop + '.circular_list',
    meta: { auth: true },
    component: () => import('../pages/circular-list/List.vue')
  },
  {
    path: 'certificate-list',
    name: prop + '.certificate_list',
    meta: { auth: true },
    component: () => import('../pages/certificate-list/List.vue')
  },
  {
    path: 'badc-certificate',
    name: prop + '.badc_certificate',
    meta: { auth: true },
    component: () => import('../pages/certificate-list/BADCCertificateView.vue')
  },
  {
    path: 'release-order-list',
    name: prop + '.release_order_list',
    meta: { auth: true },
    component: () => import('../pages/release-order/List.vue')
  },
  {
    path: 'release-order-details',
    name: prop + 'release_order_details',
    meta: { auth: true },
    component: () => import('../pages/release-order/ReleaseDetails.vue')
  },
  {
    path: 'certificate-generate-details-two',
    name: prop + 'certificate_generate_details_two',
    meta: { auth: true },
    component: () => import('../pages/certificate-list/DetailsTwo.vue')
  },
  {
    path: 'complains-list',
    name: prop + '.complains_list',
    meta: { auth: true },
    component: () => import('../pages/complains/List.vue')
  },
  {
    path: 'faq-management',
    name: prop + '.faq_management',
    meta: { auth: true },
    component: () => import('../pages/faq-management/List.vue')
  },
  {
    path: 'request-document',
    name: prop + '.request_document',
    meta: { auth: true },
    component: () => import('../pages/request-document/List.vue')
  },
  {
    path: 'bill-receive',
    name: prop + '.bill_receive',
    meta: { auth: true },
    component: () => import('../pages/payment/List.vue')
  },
  {
    path: 'booking-status',
    name: prop + '.booking_status',
    meta: { auth: true },
    component: () => import('../pages/booking-status/List.vue')
  },
  {
    path: 'training-calendar',
    name: prop + '.training_calendar',
    meta: { auth: true },
    component: () => import('../pages/training-calendar/List.vue')
  },
  {
    path: 'routine-list',
    name: prop + '.training_schedule_list',
    meta: { auth: true },
    component: () => import('../pages/training-schedule/List.vue')
  },
  {
    path: 'routine',
    name: prop + '.training_schedule',
    meta: { auth: true },
    component: () => import('../pages/training-schedule/Details.vue')
  },
  {
    path: 'evaluation',
    name: prop + '.evaluation',
    meta: { auth: true },
    component: () => import('../pages/evaluation/List.vue')
  },
  {
    path: 'course-evaluation',
    name: prop + '.course_evaluation',
    meta: { auth: true },
    component: () => import('../pages/course-evaluation/List.vue')
  },
  {
    path: 'course-evaluation-form',
    name: prop + '.course_evaluation_form',
    meta: { auth: true },
    component: () => import('../pages/course-evaluation/Form.vue')
  },
  {
    path: 'trainer-evaluation',
    name: prop + '.trainer_evaluation',
    meta: { auth: true },
    component: () => import('../pages/trainer-evaluation/List.vue')
  },
  {
    path: 'trainee-evaluation',
    name: prop + '.trainee_evaluation',
    meta: { auth: true },
    component: () => import('../pages/trainee-evaluation/List.vue')
  },
  {
    path: 'trainer-evaluation-form',
    name: prop + 'trainer_evaluation_form',
    meta: { auth: true },
    component: () => import('../pages/trainer-evaluation/Form.vue')
  },
  {
    path: 'trainer-evaluation-details-list',
    name: prop + 'trainer_evaluation_details_list',
    meta: { auth: true },
    component: () => import('../pages/trainer-evaluation/TrainerList.vue')
  },
  {
    path: 'hand-note',
    name: prop + '.hand_note',
    meta: { auth: true },
    component: () => import('../pages/hand-note/List.vue')
  },
  {
    path: 'hand-note-entry-list',
    name: prop + 'hand_note_entry_list',
    meta: { auth: true },
    component: () => import('../pages/hand-note/TrainerList.vue')
  },
  {
    path: 'trainee-hand-note',
    name: prop + '.trainee_hand_note',
    meta: { auth: true },
    component: () => import('../pages/trainee-hand-note/List.vue')
  },
  {
    path: 'hand-note-list',
    name: prop + 'hand_note_list',
    meta: { auth: true },
    component: () => import('../pages/trainee-hand-note/TrainerList.vue')
  },
  {
    path: 'training-course-evaluation',
    name: prop + '.training_course_evaluation',
    meta: { auth: true },
    component: () => import('../pages/training-course-evaluation/List.vue')
  },
  {
    path: 'training-course-evaluation-form',
    name: prop + 'training_course_evaluation_form',
    meta: { auth: true },
    component: () => import('../pages/training-course-evaluation/CourseEvaluationForm.vue')
  },
  {
    path: 'training-course-evaluation-details',
    name: prop + 'training_course_evaluation_details',
    meta: { auth: true },
    component: () => import('../pages/training-course-evaluation/Details.vue')
  },
  {
    path: 'id-card',
    name: prop + '.id_card',
    meta: { auth: true },
    component: () => import('../pages/id-card/List.vue')
  },
  {
    path: 'received-notifications',
    name: prop + '.received_notifications',
    meta: { auth: true },
    component: () => import('../pages/received-notifications/List.vue')
  },
  {
    path: 'attendance',
    name: prop + '.attendance',
    meta: { auth: true },
    component: () => import('../pages/attendance/List.vue')
  },
  {
    path: 'attendance-form',
    name: prop + '.attendance_form',
    meta: { auth: true },
    component: () => import('../pages/attendance/Form.vue')
  },
  {
    path: 'attendance-details',
    name: prop + '.attendance_details',
    meta: { auth: true },
    component: () => import('../pages/attendance/AttendenceDetails.vue')
  },
  {
    path: 'assigned-driver',
    name: prop + '.assigned_driver',
    meta: { auth: true },
    component: () => import('../pages/assigned-driver/List.vue')
  },
  {
    path: 'invitation',
    name: prop + '.invitation',
    meta: { auth: true },
    component: () => import('../pages/invitation/List.vue')
  },
  {
    path: 'invitation-details',
    name: prop + '.invitation_details',
    meta: { auth: true },
    component: () => import('../pages/invitation/Details.vue')
  },
  {
    path: 'bill-payment',
    name: prop + '.bill_payment',
    meta: { auth: true },
    component: () => import('../pages/bill-payment/List.vue')
  }
]
const routes = [
  // {
  //     path: '/',
  //     name: 'external-user',
  //     component: () =>
  //         import('@/layouts/ExternalUserLayout.vue'),
  //     meta: { auth: true },
  //     children: childRoute('external-user')
  // },
  {
    path: '/trainee-trainer-panel',
    name: 'external_user.trainee_panel',
    component: () => import('@/layouts/ExternalUserLayout.vue'),
    meta: { auth: true },
    children: traineePanel('external_user.trainee_trainer_panel')
  }
]

export default routes
