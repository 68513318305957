export default {
  userFind: (state) => (id) => state.users.find(user => user.id === id),
  equipmentTypeFind: (state) => (id) => state.equipmentTypes.find(equipmentType => equipmentType.id === id),
  paymentTypeFind: (state) => (id) => state.paymentTypes.find(payment => payment.id === id),
  schemeTypeFind: (state) => (id) => state.schemeTypes.find(schemeType => schemeType.id === id),
  pumpTypeFind: (state) => (id) => state.pumpTypes.find(pumpType => pumpType.id === id),
  pumpProgressTypeFind: (state) => (id) => state.pumpProgressTypes.find(pumpProgressType => pumpProgressType.id === id),
  laboratoryFind: (state) => (id) => state.laboratories.find(laboratory => laboratory.id === id),
  categoriesFind: (state) => (id) => state.categoriess.find(category => category.id === id),
  measurementUnitFind: (state) => (id) => state.measurementUnits.find(measurementUnit => measurementUnit.id === id),
  subCategoriesFind: (state) => (id) => state.subCategories.find(subCategory => subCategory.id === id),
  storeItemsFind: (state) => (id) => state.storeItems.find(storeItem => storeItem.id === id),
  horsePowerFind: (state) => (id) => state.horsePowers.find(horsePower => horsePower.id === id),
  cirduleAreaFind: (state) => (id) => state.circleAreas.find(circleArea => circleArea.id === id),
  schemeTypeList: state => state.commonObj.schemeTypeList,
  pumpType: state => state.commonObj.pumpTypes
}
