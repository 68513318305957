export default {
  mutateVirtualMuseumCommonProperties ({ commit }, payload) {
    commit('mutateVirtualMuseumCommonProperties', payload)
  },
  localizeVirtualMuseumDropdown ({ commit }, payload) {
    commit('localizeVirtualMuseumDropdown', payload)
  },
  stateReload ({ commit }, payload) {
    commit('stateReload', payload)
  }
}
