const accessControlChildRoute = (prop) => [
  {
    path: 'user-role',
    name: prop + '.user_role',
    meta: { auth: true },
    component: () => import('../pages/user-role/List.vue')
  },
  {
    path: 'user-role-list',
    name: prop + '.user_role_list',
    meta: { auth: true },
    component: () => import('../pages/user-role/UserRoleList.vue')
  },
  {
    path: 'menu-privilege',
    name: prop + '.menu_privilege',
    meta: { auth: true },
    component: () => import('../pages/menu-privilege/List.vue')
  },
  {
    path: 'menu-privilege-list',
    name: prop + '.menu_privilege_list',
    meta: { auth: true },
    component: () => import('../pages/menu-privilege/MenuPrivilege.vue')
  },
  {
    path: 'log-report',
    name: prop + '.log_report',
    meta: { auth: true },
    component: () => import('../pages/log-report/List.vue')
  },
  {
    path: 'dashboard-user',
    name: prop + '.dashboard_user',
    meta: { auth: true },
    component: () => import('../pages/dashboard-user/List.vue')
  },
  {
    path: 'role-permission',
    name: prop + '.role_permission',
    meta: { auth: true },
    component: () => import('../pages/role/List.vue')
  }
]

const userChildRoute = (prop) => [
    {
      path: 'user-list',
      name: prop + '.user_list',
      meta: { auth: true },
      component: () => import('../pages/user/List.vue')
    },
    {
      path: 'role-list',
      name: prop + '.role_list',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/role/List.vue')
    }
]

const routes = [
    {
      path: '/common-service/user-management',
      name: 'common_service.user_management',
      component: () => import('@/layouts/VerticleLayout.vue'),
      meta: { auth: true },
      children: userChildRoute('common_service.user_management')
    },
    {
      path: '/common-service/access-control',
      name: 'common_service.access_control',
      component: () => import('@/layouts/VerticleLayout.vue'),
      meta: { auth: true },
      children: accessControlChildRoute('common_service.access_control')
    }
]

export default routes
