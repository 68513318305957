export default {
  mutateTrainingElearningCommonProperties ({ commit }, payload) {
    commit('mutateTrainingElearningCommonProperties', payload)
  },
  localizeCommonDropdownTrainingElearningService ({ commit }, payload) {
    commit('localizeCommonDropdownTrainingElearningService', payload)
  },
  setCurrentFiscalYearId ({ commit }, payload) {
    commit('setCurrentFiscalYearId', payload)
  }
}
