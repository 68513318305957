const childRoute = (prop) => [
    // farmer request
  {
    path: 'farmer-request',
    name: prop + '.farmer-request.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/farmer-request/List.vue')
  },
  {
    path: 'e-registration',
    name: prop + '.e-registration.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/e-registration/List.vue')
  },
  // payment-collection
  {
    path: 'payment-collection',
    name: prop + '.payment-collection.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/payment-collection/List.vue')
  },
  {
    path: 'reg-recommendation',
    name: prop + '.reg-recommendation.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/reg-recommendation/List.vue')
  },
  {
    path: 'reg-approve',
    name: prop + '.reg-approve.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/reg-approve/List.vue')
  },
  // delivery schedule list
  {
    path: 'delivery-schedule',
    name: prop + '.delivery-schedule.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/delivery-schedule/List.vue')
  },
  {
    path: 'space-availability-dashboard',
    name: prop + '.space-availability-dashboard.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/space-availability-dashboard/List.vue')
  }
]

const routes = [
  {
    path: '/warehouse/service',
    name: 'warehouse.service',
    component: () => import('../../../../layouts/WarehouseLayout.vue'),
    meta: { auth: true },
    children: childRoute('warehouse.ware-service')
  }
]

export default routes
