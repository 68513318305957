const childRoutes = (prop) => [
    {
        path: 'price-collection-verify',
        name: prop + 'price_collection_verify',
        meta: {
            auth: false,
            name: 'Editable'
        },
        component: () => import('../pages/price-collection-verify/List.vue')
    },
    {
        path: 'price-collection-details',
        name: prop + 'price_collection_details',
        meta: {
            auth: false,
            name: 'Editable'
        },
        component: () => import('../pages/price-collection-verify/Details.vue')
    },
    {
        path: 'price-view',
        name: prop + 'price_view',
        meta: {
            auth: false,
            name: 'Editable'
        },
        component: () => import('../pages/price-view/List.vue')
    },
    {
        path: 'market-commodity-price-list',
        name: prop + 'market_commodity_price_list',
        meta: {
            auth: false,
            name: 'Editable'
        },
        component: () => import('../pages/market-price-list/List.vue')
    },
    {
        path: 'market-commodity-grower-price-list',
        name: prop + 'market_commodity_price_list',
        meta: {
            auth: false,
            name: 'Editable'
        },
        component: () => import('../pages/market-grower-price-list/List.vue')
    },
    {
        path: 'market-commodity-weekly-price-list',
        name: prop + 'market_commodity_price_list',
        meta: {
            auth: false,
            name: 'Editable'
        },
        component: () => import('../pages/market-weekly-price-list/List.vue')
    }
]

const routes = [
    {
        path: '/agri-marketing/crop-price-info/price-information',
        name: 'agri-marketing.crop_price_info.price_information',
        component: () => import('@/layouts/AgriMarketingLayout.vue'),
        meta: { auth: false },
        children: childRoutes('agri-marketing.crop_price_info.price_information')
    }
]

export default routes
