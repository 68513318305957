const routes = [
  {
    path: '/committee',
    name: 'committee',
    component: () => import('../../../../layouts/VerticleLayout.vue'),
    meta: { auth: true },
    children: [
      {
        path: 'create-committee-list',
        name: 'create-committee-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/committee-create/List.vue')
      },
      {
        path: 'manage-agenda-list',
        name: 'manage-agenda-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/manage-agenda/List.vue')
      },
      {
        path: 'generate-circulate-list',
        name: 'generate-circulate-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/generate-circulate/List.vue')
      },
      {
        path: 'manage-expenses-list',
        name: 'manage-expenses-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/manage-expenses/List.vue')
      }
    ]
  }
]

export default routes
