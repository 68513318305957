export const mutations = {
    // create Committee
  addCreateCommittee (state, createCommittee) {
    state.createCommittees.push(createCommittee)
  },
  updateCreateCommittee (state, payload) {
    state.createCommittees = state.createCommittees.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }

      return item
    })
  },
  addCreateCommitteeList (state, createCommittee) {
    state.createCommittees = createCommittee
  },
  toggleCreateCommitteeStatus (state, payload) {
    state.createCommittees = state.createCommittees.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  // Manage Agenda
  addManageAgenda (state, manageAgenda) {
    state.manageAgendas.push(manageAgenda)
  },
  updateManageAgenda (state, manageAgenda) {
    state.manageAgendas = state.manageAgendas.map(function (item) {
      if (item.id === manageAgenda.id) {
        return manageAgenda
      }

      return item
    })
  },
  addManageAgendaList (state, manageAgenda) {
    state.manageAgendas = manageAgenda
  },
  deleteManageAgenda (state, manageAgenda) {
    var index = state.manageAgendas.findIndex(item => item.item_id === manageAgenda)
    state.manageAgendas.splice(index, 1)
  },
  // Meeting Munite
  addMeetingMinute (state, meetingMinute) {
    state.meetingMinutes.push(meetingMinute)
  },
  updateMeetingMinute (state, meetingMinute) {
    state.meetingMinutes = state.meetingMinutes.map(function (item) {
      if (item.id === meetingMinute.id) {
        return meetingMinute
      }

      return item
    })
  },
  addMeetingMinuteList (state, meetingMinute) {
    state.meetingMinutes = meetingMinute
  },
  deleteMeetingMinute (state, meetingMinute) {
    state.meetingMinutes = state.meetingMinutes.map(function (item) {
      if (item.id === meetingMinute.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  // Manage Expenses
  addManageExpenses (state, manageExpense) {
    state.manageExpenses.push(manageExpense)
  },
  updateManageExpenses (state, manageExpense) {
    state.manageExpenses = state.manageExpense.map(function (item) {
      if (item.id === manageExpense.id) {
        return manageExpense
      }

      return item
    })
  },
  addManageExpensesList (state, manageExpense) {
    state.manageExpenses = manageExpense
  },
  deleteManageExpenses (state, manageExpenses) {
    state.manageExpenses = state.manageExpenses.map(function (item) {
      if (item.id === manageExpenses.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  }
}
