const childRoute = (prop) => [
  {
    path: 'program-type',
    name: prop + '.program-type',
    meta: { auth: true },
    component: () => import('../pages/program-type/List.vue')
  },
  {
    path: 'crop-type',
    name: prop + '.crop_type',
    meta: { auth: true },
    component: () => import('../pages/crop-type/List.vue')
  },
  {
    path: 'master-category',
    name: prop + '.master_category',
    meta: { auth: true },
    component: () => import('../pages/master-category/List.vue')
  },
  {
    path: 'master-sub-types',
    name: prop + '.master_sub_types',
    meta: { auth: true },
    component: () => import('../pages/master-sub-type/List.vue')
  },
  {
    path: 'crop-name',
    name: prop + '.crop_name',
    meta: { auth: true },
    component: () => import('../pages/crop-name/List.vue')
  },
  {
    path: 'carryover-fresh',
    name: prop + '.carryover-fresh',
    meta: { auth: true },
    component: () => import('../pages/carryover-fresh/List.vue')
  },
  {
    path: 'price-type',
    name: prop + '.price-type',
    meta: { auth: true },
    component: () => import('../pages/price-type/List.vue')
  },
  {
    path: 'variety',
    name: prop + '.variety',
    meta: { auth: true },
    component: () => import('../pages/variety/List.vue')
  },
  {
    path: 'production-source',
    name: prop + '.production_source',
    meta: { auth: true },
    component: () => import('../pages/production-source/List.vue')
  },
  {
    path: 'seed-class',
    name: prop + '.seed_class',
    meta: { auth: true },
    component: () => import('../pages/seed-class/List.vue')
  },
  {
    path: 'measurement-unit',
    name: prop + '.measurement_unit',
    meta: { auth: true },
    component: () => import('../pages/measurement-unit/List.vue')
  },
  {
    path: 'production-season',
    name: prop + '.production_season',
    meta: { auth: true },
    component: () => import('../pages/production-season/List.vue')
  },
  {
    path: 'packet_variety',
    name: prop + '.packet_variety',
    meta: { auth: true },
    component: () => import('../pages/packet-variety/List.vue')
  },
  {
    path: 'price-info',
    name: prop + '.price-info',
    meta: { auth: true },
    component: () => import('../pages/price-info/List.vue')
  },
  {
    path: 'zone-office',
    name: prop + '.zone_office',
    meta: { auth: true },
    component: () => import('../pages/zone-office/List.vue')
  },
  {
    path: 'spc-office',
    name: prop + '.spc_office',
    meta: { auth: true },
    component: () => import('../pages/spc-office/List.vue')
  },
  {
    path: 'company_farmer_info',
    name: prop + '.company_farmer_info',
    meta: { auth: true },
    component: () => import('../pages/company-farmer-info/List.vue')
  },
  {
    path: 'prerequisite-checklist',
    name: prop + '.prerequisite-checklist',
    meta: { auth: true },
    component: () => import('../pages/prerequisite-checklist/List.vue')
  },
  {
    path: 'report-heading',
    name: prop + '.report_heading',
    meta: { auth: true },
    component: () => import('../pages/report-heading/List.vue')
  },
  {
    path: 'dealer-setup',
    name: prop + '.dealer_setup',
    meta: { auth: true },
    component: () => import('../pages/dealer-setup/List.vue')
  },
  {
    path: 'production-source-type',
    name: prop + '.production_source_type',
    meta: { auth: true },
    component: () => import('../pages/production-source-type/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/seeds-configuration',
    name: 'seeds_fertilizer_service.seeds-configuration',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.seeds-configuration')
  }
]

export default routes
