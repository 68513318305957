// monthList
const months = [
  {
    value: 7,
    text_en: 'July',
    text_bn: 'জুলাই'
  },
  {
    value: 8,
    text_en: 'August',
    text_bn: 'আগস্ট'
  },
  {
    value: 9,
    text_en: 'September',
    text_bn: 'সেপ্টেম্বর'
  },
  {
    value: 10,
    text_en: 'October',
    text_bn: 'অক্টোবর'
  },
  {
    value: 11,
    text_en: 'November',
    text_bn: 'নভেম্বর'
  },
  {
    value: 12,
    text_en: 'December',
    text_bn: 'ডিসেম্বর'
  },
  {
    value: 1,
    text_en: 'January',
    text_bn: 'জানুয়ারী'
  },
  {
    value: 2,
    text_en: 'February',
    text_bn: 'ফেব্রুয়ারী'
  },
  {
    value: 3,
    text_en: 'March',
    text_bn: 'মার্চ'
  },
  {
    value: 4,
    text_en: 'April',
    text_bn: 'এপ্রিল'
  },
  {
    value: 5,
    text_en: 'May',
    text_bn: 'মে'
  },
  {
    value: 6,
    text_en: 'June',
    text_bn: 'জুন'
  }
]

const seasonList = [
  {
      value: 100,
      text: 'Aus',
      text_en: 'Aus',
      text_bn: 'আউশ'
  },
  {
      value: 101,
      text: 'Aman',
      text_en: 'Aman',
      text_bn: 'আমন'
  },
  {
      value: 102,
      text: 'Boro',
      text_en: 'Boro',
      text_bn: 'বোরো'
  }
]

const unitList = [
  {
    value: 1,
    text_en: 'KG',
    text_bn: 'কেজি'
  },
  {
    value: 2,
    text_en: 'Metric Ton',
    text_bn: 'মেট্রিক টন'
  }
]

const dashboardMonthList = [
  {
    value: 7,
    text_en: 'Jul',
    text_bn: 'জুলাই'
  },
  {
    value: 8,
    text_en: 'Aug',
    text_bn: 'আগস্ট'
  },
  {
    value: 9,
    text_en: 'Sept',
    text_bn: 'সেপ্টে'
  },
  {
    value: 10,
    text_en: 'Oct',
    text_bn: 'অক্টো'
  },
  {
    value: 11,
    text_en: 'Nov',
    text_bn: 'নভে'
  },
  {
    value: 12,
    text_en: 'Dec',
    text_bn: 'ডিসে'
  },
  {
    value: 1,
    text_en: 'Jan',
    text_bn: 'জানু'
  },
  {
    value: 2,
    text_en: 'Feb',
    text_bn: 'ফেব্রু'
  },
  {
    value: 3,
    text_en: 'March',
    text_bn: 'মার্চ'
  },
  {
    value: 4,
    text_en: 'Apr',
    text_bn: 'এপ্রিল'
  },
  {
    value: 5,
    text_en: 'May',
    text_bn: 'মে'
  },
  {
    value: 6,
    text_en: 'June',
    text_bn: 'জুন'
  }
]

export default {
    months, unitList, dashboardMonthList, seasonList
}
