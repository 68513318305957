const childRoute = (prop) => [
  {
    path: 'carrirng-agent-wise-program-report',
    name: prop + '.carrirng-agent-wise-program-report',
    meta: { auth: true },
    component: () => import('../pages/carrirng-agent-wise-program-report/List.vue')
  },
  {
    path: 'received-report',
    name: prop + '.received-report',
    meta: { auth: true },
    component: () => import('../pages/received-report/List.vue')
  },
  {
    path: 'inter-godown-received-report',
    name: prop + '.inter_godown_received_report',
    meta: { auth: true },
    component: () => import('../pages/inter-godown-received-report/List.vue')
  },
  {
    path: 'program-management-report',
    name: prop + '.program_management_report',
    meta: { auth: true },
    component: () => import('../pages/program-management-report/List.vue')
  },
  {
    path: 'movement-allocation-report',
    name: prop + '.movement_allocation+report',
    meta: { auth: true },
    component: () => import('../pages/movement-allocation-report/List.vue')
  },
  {
    path: 'dealer-profile',
    name: prop + '.dealer_profile',
    meta: { auth: true },
    component: () => import('../pages/dealer-profile/List.vue')
  },
  {
    path: 'current-stock-report',
    name: prop + '.current_stock_report',
    meta: { auth: true },
    component: () => import('../pages/current-stock-report/List.vue')
  },
  {
    path: 'ministry-allocation-report',
    name: prop + '.ministry_allocation_report',
    meta: { auth: true },
    component: () => import('../pages/ministry-allocation-report/List.vue')
  },
  {
    path: 'opening-balance',
    name: prop + '.opening_balance_report',
    meta: { auth: true },
    component: () => import('../pages/opening-balance-report/List.vue')
  },
  {
    path: 'dealer-wise-allocation',
    name: prop + '.dealer-wise-allocation',
    meta: { auth: true },
    component: () => import('../pages/dealer-allocation-report/List.vue')
  },
  {
    path: 'allocation-report',
    name: prop + '.ministry_allocation_report',
    meta: { auth: true },
    component: () => import('../pages/ministry-allocation-report/List.vue')
  },
  {
    path: 'transport-program-management-report',
    name: prop + '.transport_program_management_report',
    meta: { auth: true },
    component: () => import('../pages/transport-program-management-report/List.vue')
  },
  {
    path: 'demand-against-allocation-report',
    name: prop + '.demand_against_allocation_report',
    meta: { auth: true },
    component: () => import('../pages/demand-against-allocation-report/List.vue')
  },
  {
    path: 'warehouse-report',
    name: prop + '.warehouse_report',
    meta: { auth: true },
    component: () => import('../pages/warehouse-report/List.vue')
  },
  {
    path: 'fertilizer-delivery-receive-report',
    name: prop + '.fertilizer_delivery_receive_report',
    meta: { auth: true },
    component: () => import('../pages/fertilizer-delivery-receive-report/List.vue')
  },
  {
    path: 'fertilizer-movement-report',
    name: prop + '.fertilizer_movement_report',
    meta: { auth: true },
    component: () => import('../pages/fertilizer-movement-report/List.vue')
  },
  {
    path: 'ministry-allocation-report',
    name: prop + '.ministry_allocation_report',
    meta: { auth: true },
    component: () => import('../pages/ministry-allocation-report/List.vue')
  },
  {
    path: 'stock-details-report',
    name: prop + '.stock_details_report',
    meta: { auth: true },
    component: () => import('../pages/stock-details-report/List.vue')
  },
  {
    path: 'daily-sales-report',
    name: prop + '.daily-sales_report',
    meta: { auth: true },
    component: () => import('../pages/daily-sales-report/List.vue')
  },
  {
    path: 'imported-fertilizer-stock',
    name: prop + '.imported_fertilizer_stock',
    meta: { auth: true },
    component: () => import('../pages/imported-fertilizer-stock/List.vue')
  },
  {
    path: 'fertilizer-requisition-report',
    name: prop + '.fertilizer-requisition-report',
    meta: { auth: true },
    component: () => import('../pages/fertilizer-requisition-report/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/fertilizer-reports',
    name: 'seeds_fertilizer_service.fertilizer-reports',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.fertilizer-reports')
  }
]

export default routes
