const childRoutes = (prop) => [
    {
      path: 'circular-publication',
      name: prop + '.circular_publication',
      meta: { auth: true },
      component: () => import('../pages/circular-publication/List.vue')
    },
    {
      path: 'booking-calender',
      name: prop + 'booking_calender',
      meta: { auth: true },
      component: () => import('../pages/booking-calender/List.vue')
    },
    {
      path: 'booking',
      name: prop + 'booking',
      meta: { auth: true },
      component: () => import('../pages/booking/List.vue')
    },
    {
      path: 'booking-apply',
      name: prop + 'booking_apply',
      meta: { auth: true },
      component: () => import('../pages/booking-apply/List.vue')
    },
    {
      path: 'registration',
      name: prop + 'registration',
      meta: { auth: true },
      component: () => import('../pages/registration/List.vue')
    },
    {
      path: 'registration-form',
      name: prop + 'registration_form',
      meta: { auth: true },
      component: () => import('../pages/registration/Form.vue')
    },
    {
      path: 'invitation/circular-list',
      name: prop + 'invitation_circularList',
      meta: { auth: true },
      component: () => import('../pages/invitation/CircularList.vue')
    },
    {
      path: 'invitation',
      name: prop + 'invitation',
      meta: { auth: true },
      component: () => import('../pages/invitation/List.vue')
    },
    {
      path: 'invitation/form',
      name: prop + 'invitation_form',
      meta: { auth: true },
      component: () => import('../pages/invitation/Form.vue')
    },
    {
      path: 'invitation/details',
      name: prop + 'invitation_details',
      meta: { auth: true },
      component: () => import('../pages/invitation/Details.vue')
    },
    {
      path: 'trainer-registration',
      name: prop + 'trainer_registration',
      meta: { auth: true },
      component: () => import('../pages/trainer-registration/List.vue')
    },
    {
      path: 'reject-trainer-registration',
      name: prop + 'reject_trainer_registration',
      meta: { auth: true },
      component: () => import('../pages/reject-trainer-registration/List.vue')
    },
    {
      path: 'trainer-registration-form',
      name: prop + 'trainer_registration_form',
      meta: { auth: true },
      component: () => import('../pages/trainer-registration/Form.vue')
    },
    {
      path: 'training-application',
      name: prop + 'training_application',
      meta: { auth: true },
      component: () => import('../pages/training-application/List.vue')
    },
    {
      path: 'request-document',
      name: prop + 'request_document',
      meta: { auth: true },
      component: () => import('../pages/request-document/List.vue')
    },
    {
      path: 'complain-list',
      name: prop + 'complain_list',
      meta: { auth: true },
      component: () => import('../pages/complains/List.vue')
    },
    {
      path: 'lesson-assign-circular-wise',
      name: prop + 'lesson_assign_circular_wise',
      meta: { auth: true },
      component: () => import('../pages/lesson-assign-circular-wise/List.vue')
    },
    {
      path: 'lesson-assign-circular-wise-form',
      name: prop + 'lesson_assign_circular_wise_form',
      meta: { auth: true },
      component: () => import('../pages/lesson-assign-circular-wise/Form.vue')
    },
    {
      path: 'duplicate-certificate',
      name: prop + 'duplicate_certificate',
      meta: { auth: true },
      component: () => import('../pages/duplicate-certificate/List.vue')
    },
    {
      path: 'sponsored-training-request',
      name: prop + 'sponsored_training_request',
      meta: { auth: true },
      component: () => import('../pages/sponsored-training-request/List.vue')
    },
    {
      path: 'rejected-registration-list',
      name: prop + 'rejected_registration_list',
      meta: { auth: true },
      component: () => import('../pages/rejected-registration-list/List.vue')
    }
]

const routes = [
    {
      path: '/training-e-learning-service/iabm',
      name: 'training_e_learning_service.iabm',
      component: () => import('@/layouts/TrainingElearningLayout.vue'),
      meta: { auth: true },
      children: childRoutes('training_e_service.barc.configuration')
    }
]

export default routes
