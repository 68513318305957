const routes = [
  {
    path: '/irrigation-scheme-service/pump-installation',
    name: 'pump-installation',
    component: () => import('../../../../layouts/IrrigationSchemeLayout.vue'),
    meta: { auth: true },
    children: [
      {
        path: 'application-list',
        name: 'application-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/application-list/List.vue')
      },
      {
        path: 'receive-application-list',
        name: 'receive_application-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/receive-application-list/List.vue')
      },
      {
        path: 'scheme-report',
        name: 'scheme_report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/scheme-report/List.vue')
      },
      {
        path: 'license-application-list',
        name: 'license-application-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/license-application-list/List.vue')
      },
      {
        path: 'current-status',
        name: 'current-status',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/current-status/List.vue')
      },
      {
        path: 'contract-agreement',
        name: 'contract-agreement',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/contract-agreement/List.vue')
      },
      {
        path: 'participation-fee',
        name: 'participation-fee',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/participation-fee/List.vue')
      },
      {
        path: 'requisition-list',
        name: 'requisition-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/requisition/List.vue')
      },
      {
        path: 'receive-requisition-list',
        name: 'receive-requisition-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/receive-requisition/List.vue')
      },
      {
        path: 'supply-equipment',
        name: 'supply-equipment',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/supply-equipment/List.vue')
      },
      {
        path: 'pump-install',
        name: 'pump-install',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/pump-install/List.vue')
      },
      {
        path: 'stock-in',
        name: 'stock-in',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/stock-in/List.vue')
      },
      {
        path: 'pump-operator-report',
        name: 'pump-operator-report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/pump-operator-report/List.vue')
      },
      {
        path: 'stock-out',
        name: 'stock-out',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/stock-out/List.vue')
      },
      {
        path: 'stock-out-approve',
        name: 'stock-out-approve',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/stock-out-approve/List.vue')
      },
      {
        path: 'pump-operator-application-list',
        name: 'pump-operator-application-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/pump-opt-application-list/List.vue')
      },
      {
        path: 'pump-opt-renew-application-list',
        name: 'pump-opt-renew-application-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/pump-opt-renew-application-list/List.vue')
      },
      {
        path: 'receive-pump-opt-app',
        name: 'receive-pump-opt-app',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/receive-pump-opt-app/List.vue')
      },
      {
        path: 'pump-opt-app-survey',
        name: 'pump-opt-app-survey',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/pump-opt-app-survey/List.vue')
      },
      {
        path: 'dashboard',
        name: 'pump_installation.dashboard',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/dashboard/index.vue')
      },
      {
        path: 'store-report',
        name: 'pump_installation.store-report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/store-report/List.vue')
      },
      {
        path: 'irrigation-farmer',
        name: 'pump_installation.irrigation_farmer',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/irrigation-farmer/List.vue')
      }
    ]
  }
]

export default routes
