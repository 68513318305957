export const mutations = {
    mutateExternalCommonProperties (state, payload) {
        state.static = Object.assign({}, state.static, payload)
    },
    mutateAgriResearchExternalDropdown (state, payload) {
        state.agriResearchCommonObj = Object.assign(state.agriResearchCommonObj, payload)
    },
    localizeAgriResearchExternalDropdown (state, payload) {
        state.agriResearchCommonObj.seedNameList = state.agriResearchCommonObj.seedNameList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.seedTypeNameList = state.agriResearchCommonObj.seedTypeNameList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.seedVarietyList = state.agriResearchCommonObj.seedVarietyList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.seedClassList = state.agriResearchCommonObj.seedClassList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.qualityUnitList = state.agriResearchCommonObj.qualityUnitList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.seedPacketList = state.agriResearchCommonObj.seedPacketList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.serviceList = state.agriResearchCommonObj.serviceList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.projectList = state.agriResearchCommonObj.projectList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.subProjectList = state.agriResearchCommonObj.subProjectList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.sectorList = state.agriResearchCommonObj.sectorList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.researchDivisionList = state.agriResearchCommonObj.researchDivisionList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.productionSeasonList = state.agriResearchCommonObj.productionSeasonList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.publicationTypeList = state.agriResearchCommonObj.publicationTypeList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.publicationTitleList = state.agriResearchCommonObj.publicationTitleList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.publicationFeeList = state.agriResearchCommonObj.publicationFeeList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.publicationContentAreaList = state.agriResearchCommonObj.publicationContentAreaList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.thematicAreaList = state.agriResearchCommonObj.thematicAreaList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.circularList = state.agriResearchCommonObj.circularList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.activityStatusList = state.agriResearchCommonObj.activityStatusList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.orgTypeList = state.agriResearchCommonObj.orgTypeList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.subSectorList = state.agriResearchCommonObj.subSectorList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.budgetHeadList = state.agriResearchCommonObj.budgetHeadList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.budgetTypeList = state.agriResearchCommonObj.budgetTypeList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.componentOrgList = state.agriResearchCommonObj.componentOrgList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.feePolicy = state.agriResearchCommonObj.feePolicy.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.organizationType = state.agriResearchCommonObj.organizationType.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.divisionalResearchList = state.agriResearchCommonObj.divisionalResearchList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.monitoringTeamList = state.agriResearchCommonObj.monitoringTeamList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.agriResearchCommonObj.roleInformationList = state.agriResearchCommonObj.roleInformationList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
    },
    mutateDropdownCommonConfig (state, payload) {
        state.commonObjCommonConfig = Object.assign({}, state.commonObjCommonConfig, payload)
    },
    localizeDropdownCommonConfig (state, payload) {
        state.commonObjCommonConfig.bankList = state.commonObjCommonConfig.bankList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
        state.commonObjCommonConfig.branchList = state.commonObjCommonConfig.branchList.map(item => {
            const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
    }
}
