export default {
    // Test
    addTestList ({ commit }, payload) {
        commit('addTestList', payload)
    },
    // Irrigation Water Testing Request
    addIrrigationWaterTestingRequest ({ commit }, payload) {
        commit('addIrrigationWaterTestingRequest', payload)
    },
    addIrrigationWaterTestingRequestList ({ commit }, payload) {
        commit('addIrrigationWaterTestingRequestList', payload)
    },
    updateIrrigationWaterTestingRequest ({ commit }, payload) {
        commit('updateIrrigationWaterTestingRequest', payload)
    },
    toggleIrrigationWaterTestingRequestStatus ({ commit }, payload) {
        commit('toggleIrrigationWaterTestingRequestStatus', payload)
    },
    // Drinking Water Testing Request
    addDrinkingWaterTestingRequest ({ commit }, payload) {
        commit('addDrinkingWaterTestingRequest', payload)
    },
    addDrinkingWaterTestingRequestList ({ commit }, payload) {
        commit('addDrinkingWaterTestingRequestList', payload)
    },
    updateDrinkingWaterTestingRequest ({ commit }, payload) {
        commit('updateDrinkingWaterTestingRequest', payload)
    },
    toggleDrinkingWaterTestingRequestStatus ({ commit }, payload) {
        commit('toggleDrinkingWaterTestingRequestStatus', payload)
    },
    // Industrial Water Testing Request
    addIndustrialWaterTestingRequest ({ commit }, payload) {
        commit('addIndustrialWaterTestingRequest', payload)
    },
    addIndustrialWaterTestingRequestList ({ commit }, payload) {
        commit('addIndustrialWaterTestingRequestList', payload)
    },
    updateIndustrialWaterTestingRequest ({ commit }, payload) {
        commit('updateIndustrialWaterTestingRequest', payload)
    },
    toggleIndustrialWaterTestingRequestStatus ({ commit }, payload) {
        commit('toggleIndustrialWaterTestingRequestStatus', payload)
    },
    // Pending Water Testing Request
    addPendingWaterTestingRequest ({ commit }, payload) {
        commit('addPendingWaterTestingRequest', payload)
    },
    addPendingWaterTestingRequestList ({ commit }, payload) {
        commit('addPendingWaterTestingRequestList', payload)
    },
    updatePendingWaterTestingRequest ({ commit }, payload) {
        commit('updatePendingWaterTestingRequest', payload)
    },
    togglePendingWaterTestingRequestStatus ({ commit }, payload) {
        commit('togglePendingWaterTestingRequestStatus', payload)
    },
    // Reported Water Testing Request
    addReportedWaterTestingRequest ({ commit }, payload) {
        commit('addReportedWaterTestingRequest', payload)
    },
    addReportedWaterTestingRequestList ({ commit }, payload) {
        commit('addReportedWaterTestingRequestList', payload)
    },
    updateReportedWaterTestingRequest ({ commit }, payload) {
        commit('updateReportedWaterTestingRequest', payload)
    },
    toggleReportedWaterTestingRequestStatus ({ commit }, payload) {
        commit('toggleReportedWaterTestingRequestStatus', payload)
    },
    // Publish Water Testing Request
    addPublishWaterTestingRequest ({ commit }, payload) {
        commit('addPublishWaterTestingRequest', payload)
    },
    addPublishWaterTestingRequestList ({ commit }, payload) {
        commit('addPublishWaterTestingRequestList', payload)
    },
    updatePublishWaterTestingRequest ({ commit }, payload) {
        commit('updatePublishWaterTestingRequest', payload)
    },
    togglePublishWaterTestingRequestStatus ({ commit }, payload) {
        commit('togglePublishWaterTestingRequestStatus', payload)
    }
}
