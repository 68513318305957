const childRoutes = (prop) => [
    {
        path: 'fact-sheet-preparation',
        name: prop + 'fact_sheet_preparation',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/crop-technology-data-management/fact-sheet-preparation/Form.vue')
    },
    {
        path: 'dashboard',
        name: prop + 'dashboard',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/crop-technology-data-management/dashboard/Form.vue')
    },
    {
        path: 'crop-data-edit',
        name: prop + 'crop_data_edit',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/crop-technology-data-management/crop-data-approval/Form.vue')
    },
    {
        path: 'technology-data-edit',
        name: prop + 'technology_data_edit',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/crop-technology-data-management/technology-data-approval/Form.vue')
    },
    {
        path: 'crop-data-list',
        name: prop + 'crop_data_list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/crop-technology-data-management/crop-data-list/List.vue')
    },
    {
        path: 'technology-data-list',
        name: prop + 'technology_data_list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/crop-technology-data-management/technology-data-list/List.vue')
    },
    {
        path: 'crop-data-approval',
        name: prop + 'crop_data_approval',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/crop-technology-data-management/crop-data-approval/List.vue')
    },
    {
        path: 'technology-data-approval',
        name: prop + 'technology_data_approval',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/crop-technology-data-management/technology-data-approval/List.vue')
    },
    {
        path: 'crop-data-report',
        name: prop + 'crop_data_report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/report/crop-data-report/List.vue')
    },
    {
        path: 'technology-report',
        name: prop + 'technology_report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/report/technology-report/List.vue')
    },
    {
        path: 'crop-data-list-details',
        name: prop + 'crop_data_list_details',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/crop-technology-data-management/crop-data-list/Details.vue')
    },
    {
        path: 'crop-data-approval-details',
        name: prop + 'crop_data_approval_details',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/crop-technology-data-management/crop-data-approval/Details.vue')
    },
    {
        path: 'crop-data-report-details',
        name: prop + 'crop_data_report_details',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/report/crop-data-report/Details.vue')
    },
    {
        path: 'technology-data-approval-details',
        name: prop + 'technology_data_approval_details',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/crop-technology-data-management/technology-data-approval/Details.vue')
    },
    {
        path: 'technology-report-details',
        name: prop + 'technology_report_details',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/report/technology-report/Details.vue')
    },
    {
        path: 'technology-data-list-details',
        name: prop + 'technology_data_list_details',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/crop-technology-data-management/technology-data-list/Details.vue')
    },
    {
        path: 'technology-data-report',
        name: prop + 'technology_data_report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/report/technology-data-report/List.vue')
    }
]
const routes = [
  {
    path: '/agri-research-service/national-crop-data-bank',
    name: 'agri_research_service.national-crop-data-bank',
    component: () => import('@/layouts/AgriResearchLayout.vue'),
    meta: { auth: true },
    children: childRoutes('agri_research_service.national-crop-data-bank')
  }
]

export default routes
