export const mutations = {
    mutateExternalIncentiveFarmerDropdown (state, payload) {
      state.incentiveFarmerObj = Object.assign(state.incentiveFarmerObj, payload)
    },
    localizeExternalIncentiveFarmerDropdown (state, payload) {
      state.incentiveFarmerObj.educationLevelList = state.incentiveFarmerObj.educationLevelList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.incentiveFarmerObj.circularList = state.incentiveFarmerObj.circularList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.incentiveFarmerObj.instrumentList = state.incentiveFarmerObj.instrumentList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.incentiveFarmerObj.supplierList = state.incentiveFarmerObj.supplierList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.incentiveFarmerObj.cropList = state.incentiveFarmerObj.cropList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.incentiveFarmerObj.enlistedUniversityList = state.incentiveFarmerObj.enlistedUniversityList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.incentiveFarmerObj.projectList = state.incentiveFarmerObj.projectList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.incentiveFarmerObj.agMaterialTypeList = state.incentiveFarmerObj.agMaterialTypeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.incentiveFarmerObj.agMaterialList = state.incentiveFarmerObj.agMaterialList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.incentiveFarmerObj.macMachineTypeList = state.incentiveFarmerObj.macMachineTypeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.incentiveFarmerObj.macBrandList = state.incentiveFarmerObj.macBrandList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
    },
    mutateCommonProperties (state, payload) {
      state.commonObj = Object.assign({}, state.commonObj, payload)
    },
    changeCommonDropdown (state, payload) {
      state.commonObj.divisionList = state.commonObj.divisionList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.districtList = state.commonObj.districtList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.upazilaList = state.commonObj.upazilaList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.unionList = state.commonObj.unionList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.officeTypeList = state.commonObj.officeTypeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.officeList = state.commonObj.officeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.fiscalYearList = state.commonObj.fiscalYearList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.designationList = state.commonObj.designationList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.gradeList = state.commonObj.gradeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
    },
    mutateDropdownCommonConfig (state, payload) {
      state.commonObjCommonConfig = Object.assign({}, state.commonObjCommonConfig, payload)
    },
    localizeDropdownCommonConfig (state, payload) {
      state.commonObjCommonConfig.bankList = state.commonObjCommonConfig.bankList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObjCommonConfig.branchList = state.commonObjCommonConfig.branchList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObjCommonConfig.cityCorporationList = state.commonObjCommonConfig.cityCorporationList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObjCommonConfig.pauroshobaList = state.commonObjCommonConfig.pauroshobaList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObjCommonConfig.wardList = state.commonObjCommonConfig.wardList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
    }
}
