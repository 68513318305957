const childRoutes = (prop) => [
    {
      path: 'manpower-management',
      name: prop + 'manpower_management',
      meta: { auth: true },
      component: () => import('../pages/manpower-management/List.vue')
    },
    {
      path: 'accommodation',
      name: prop + 'accommodation',
      meta: { auth: true },
      component: () => import('../pages/accommodation-management/List.vue')
    },
    {
      path: 'accommodation-bina',
      name: prop + 'accommodation_bina',
      meta: { auth: true },
      component: () => import('../pages/accommodation-management/ListBina.vue')
    },
    {
      path: 'accommodation-form',
      name: prop + 'accommodation_form',
      meta: { auth: true },
      component: () => import('../pages/accommodation-management/Form.vue')
    },
    {
      path: 'bill-generate',
      name: prop + 'bill_generate',
      meta: { auth: true },
      component: () => import('../pages/bill-generate/List.vue')
    },
    {
      path: 'bill-payment/:status?',
      name: prop + 'bill_payment',
      meta: { auth: true },
      component: () => import('../pages/bill-payment/List.vue')
    },
    {
      path: 'check-in-out-report',
      name: prop + 'check_in_out_report',
      meta: { auth: true },
      component: () => import('../pages/check-in-out-report/List.vue')
    },
    {
      path: 'room-type-setup',
      name: prop + 'room_type_setup',
      meta: { auth: true },
      component: () => import('../pages/room-type-setup/List.vue')
    },
    {
      path: 'room-rent-setup',
      name: prop + 'room_rent_setup',
      meta: { auth: true },
      component: () => import('../pages/room-rent-setup/List.vue')
    },
    {
      path: 'grade-setup',
      name: prop + 'grade_setup',
      meta: { auth: true },
      component: () => import('../pages/grade-setup/List.vue')
    },
    {
      path: 'resource-person-room-assign',
      name: prop + 'resource_person_room_assign',
      meta: { auth: true },
      component: () => import('../pages/resource-person-room-assign/List.vue')
    }
]

const routes = [
    {
      path: '/training-e-learning-service/accommodation-management',
      name: 'training_e_learning_service.accommodation_management',
      component: () => import('@/layouts/TrainingElearningLayout.vue'),
      meta: { auth: true },
      children: childRoutes('training_e_learning_service.accommodation_management')
    }
]

export default routes
