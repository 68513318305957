import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'
import Custom from './custom'

const state = {
  commonObj: {
    hasDropdownLoaded: false,
    perPage: 10,
    dateFormat: 'dd/mm/YYYY',
    timeFormat: 'h:m',
    loading: false,
    listReload: false,
    customListReload: false,
    enlistedUniversityList: [],
    educationLevelList: [],
    grantList: [],
    itemList: [],
    millTypeList: [],
    applicationList: [],
    millInfoList: [],
    subsidyTypeList: [],
    subsidyList: [],
    cultivationMethodList: [],
    projectList: [],
    regionInfoList: [],
    agMaterialTypeList: [],
    agMaterialList: [],
    circularInfoList: [],
    barcMsApplicationNameList: [],
    cropList: [],
    publicationTypeList: [],
    committeeList: [],
    committeeRollList: [],
    supplierList: [],
    blockList: [],
    agMaterialUnitList: [],
    allocationList: [],
    subzoneOfficeList: [],
    centerList: [],
    unitList: [],
    instrumentList: [],
    seasonSetupList: Custom.seasonSetupList,
    subsidyStatusList: Custom.subsidyStatusList,
    subsidyCircularTypeList: Custom.subsidyCircularTypeList,
    stockTypeList: Custom.stockTypeList,
    seasonList: Custom.seasonList,
    macMachineTypeList: [],
    macBrandList: []
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
