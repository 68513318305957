export const mutations = {
    mutateExternalTraneeTranierDropdown (state, payload) {
      state.traineeTranerObj = Object.assign(state.traineeTranerObj, payload)
    },
    mutateExternalTrainingDropdownLoader (state, payload) {
      state.static = Object.assign(state.static, payload)
    },
    localizeExternalTraneeTranierDropdown (state, payload) {
      state.traineeTranerObj.roomTypeList = state.traineeTranerObj.roomTypeList.map(item => {
          const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
          return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.roomRentList = state.traineeTranerObj.roomRentList.map(item => {
          const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
          return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.roomList = state.traineeTranerObj.roomList.map(item => {
          const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
          return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.floorList = state.traineeTranerObj.floorList.map(item => {
          const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
          return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.trainingTypeList = state.traineeTranerObj.trainingTypeList.map(item => {
          const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
          return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.trainingCategoryList = state.traineeTranerObj.trainingCategoryList.map(item => {
          const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
          return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.trainingTitleList = state.traineeTranerObj.trainingTitleList.map(item => {
          const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
          return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.courseList = state.traineeTranerObj.courseList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.courseModuleList = state.traineeTranerObj.courseModuleList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.courseDocumentSetupList = state.traineeTranerObj.courseDocumentSetupList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.trainerEvaluationList = state.traineeTranerObj.trainerEvaluationList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      // state.traineeTranerObj.personalInfoList = state.traineeTranerObj.personalInfoList.map(item => {
      //   const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      //   return Object.assign({}, item, tmp)
      // })
      state.traineeTranerObj.gradingStatus = state.traineeTranerObj.gradingStatus.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.jobTypeList = state.traineeTranerObj.jobTypeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.trainingExpertiseList = state.traineeTranerObj.trainingExpertiseList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.faqSetupList = state.traineeTranerObj.faqSetupList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.driverList = state.traineeTranerObj.driverList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.guestHouseList = state.traineeTranerObj.guestHouseList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.traineeTranerObj.accomodationSetupList = state.traineeTranerObj.accomodationSetupList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
    },
    getTrainerOrTraineeDetails (state, payload) {
      state.trainerOrTrainee = payload
    }
}
