export default {
    // Test
    addTestList ({ commit }, payload) {
        commit('addTestList', payload)
    },
    updateTestStatus ({ commit }, payload) {
        commit('updateTestStatus', payload)
    },
    // Directory
    addDirectoryList ({ commit }, payload) {
        commit('addDirectoryList', payload)
    },
    updateDirectoryStatus ({ commit }, payload) {
        commit('updateDirectoryStatus', payload)
    }
}
