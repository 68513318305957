const childRoute = (prop) => [
  {
    path: 'warehouse-service',
    name: prop + '.warehouse-service.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/warehouse-service/List.vue')
  },
  {
    path: 'bank-loan-facility',
    name: prop + '.bank-loan-facility.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/bank-loan-facility/List.vue')
  }
]

const routes = [
  {
    path: '/warehouse/information',
    name: 'warehouse.information',
    component: () => import('../../../../layouts/WarehouseLayout.vue'),
    meta: { auth: true },
    children: childRoute('warehouse.information')
  }
]

export default routes
