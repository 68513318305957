export default {
  // Pump Information
  addwaterTestingRequest ({ commit }, payload) {
    commit('addwaterTestingRequest', payload)
  },
  addwaterTestingRequestList ({ commit }, payload) {
    commit('addwaterTestingRequestList', payload)
  },
  updatewaterTestingRequest ({ commit }, payload) {
    commit('updatewaterTestingRequest', payload)
  },
  togglewaterTestingRequestStatus ({ commit }, payload) {
    commit('togglewaterTestingRequestStatus', payload)
  }
}
