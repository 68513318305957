import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'
import Custom from './custom'

const state = {
    traineeTranerObj: {
      hasDropdownLoaded: false,
      roomTypeList: [],
      roomRentList: [],
      roomList: [],
      floorList: [],
      trainingTypeList: [],
      trainingCategoryList: [],
      trainingTitleList: [],
      courseList: [],
      courseModuleList: [],
      courseDocumentSetupList: [],
      trainerEvaluationList: [],
      faqSetupList: [],
      // personalInfoList: [],
      gradingStatus: [],
      trainingReportHeadList: [],
      trainingExpertiseList: [],
      jobTypeList: Custom.jobTypeList,
      driverList: [],
      guestHouseList: [],
      accomodationSetupList: []
    },
    trainerOrTrainee: null,
    static: {
        perPage: 10,
        dateFormat: 'DD/MM/YYYY',
        fiscaleYear: 'YYYY-YYYY',
        timeFormat: 'h:m',
        loading: false,
        listReload: false,
        hasDropdownLoaded: false
    }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
