const childRoutes = (prop) => [
  {
    path: 'content-management',
    name: prop + 'content_management',
    meta: { auth: true },
    component: () => import('../pages/content-management/List.vue')
  }
]

const routes = [
    {
      path: '/training-e-learning-service/elearning-management',
      name: 'training_e_learning_service.elearning_management',
      component: () => import('@/layouts/TrainingElearningLayout.vue'),
      meta: { auth: true },
      children: childRoutes('training_e_service.barc.elearning-management')
    }
]

export default routes
