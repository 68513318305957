import Store from '../../../../store'

const authSignupRoutes = (prop) => [
  {
    path: 'sign-up',
    name: prop + '.sign_up',
    meta: { auth: false },
    component: () => import('../pages/Sign-up-old.vue')
  },
  {
    path: 'nothi-login',
    name: prop + '.nothi_login',
    meta: { auth: false },
    component: () => import('../pages/NothLogin.vue')
  },
  {
    path: 'nothi-login-process',
    name: prop + '.nothi_login_process',
    meta: { auth: false },
    component: () => import('../pages/NothiLoginProcess.vue')
  },
  {
    path: 'frms-login-process',
    name: prop + '.frms_login_process',
    meta: { auth: false },
    component: () => import('../pages/FrmsLoginProcess.vue')
  },
  {
    path: 'forgot-password/otp-sending',
    name: prop + '.forgot_password.otp_sending',
    meta: { auth: false },
    component: () => import('../pages/ForgotPasswordOtp.vue')
  },
  {
    path: 'forgot-password/otp-verification',
    name: prop + '.forgot_password.otp_verification',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/ForgotPasswordOtpVerification.vue')
  },
  {
    path: 'forgot-password/reset-password',
    name: prop + '.forgot_password.reset_password',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/ForgotPasswordResetPassword.vue')
  },
  {
    path: 'otp/:id',
    name: prop + '.reg-otp',
    meta: { auth: false },
    component: () => import('../pages/Otp.vue')
  },
  {
    path: 'sign-up-success',
    name: prop + '.sign_up_success',
    meta: { auth: false },
    component: () => import('../pages/Sign-up-success.vue')
  },
  {
    path: 'error',
    name: prop + '.error',
    meta: { auth: false },
    component: () => import('../pages/Error.vue')
  }
]
const authChildRoutes = (prop) => [
  {
    path: 'login',
    name: prop + '.login',
    meta: { auth: false },
    component: () => import('../pages/Login.vue'),
    beforeEnter: async (to, from, next) => {
      const isLoggedIn = Store.state.Auth.authUser
      const accessToken = Store.state.Auth.accessToken

      if (isLoggedIn && isLoggedIn.office_detail && accessToken) {
          return next('/auth/dashboard')
      } else if (isLoggedIn && !isLoggedIn.office_detail && accessToken) {
        const serviceUrl = Store.state.Portal.serviceUrl.externalPanelServiceUrl
        if (serviceUrl && serviceUrl !== undefined) {
          Store.dispatch('Portal/setServiceUrl', { externalPanelServiceUrl: '' })
          return next(serviceUrl)
        } else {
          return next('/common/dashboard')
        }
      } else {
        next()
      }
    }
  },
  {
    path: 'e-registration',
    name: prop + '.e_registration',
    meta: { auth: false },
    component: () => import('../pages/E-Registration.vue')
  }
]
const authAdminRoutes = (prop) => [
  {
    path: 'role-select',
    name: prop + '.role_select',
    meta: { auth: false },
    component: () => import('../pages/RoleSelect.vue')
  },
  {
    path: 'switch-role',
    name: prop + '.switch_role',
    meta: { auth: false },
    component: () => import('../pages/SwitchRole.vue')
  },
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    props: true,
    component: () => import('../pages/Dashboard.vue')
  }
]

const passwordChangeChildRoutes = (prop) => [
  {
    path: 'password/change',
    name: prop + '.password_change',
    meta: { auth: true },
    component: () => import('../pages/security/ChangePassword.vue')
  }
]
const secertQuestionRoutes = (prop) => [
  {
    path: 'secret-question',
    name: prop + '.secret_question',
    meta: { auth: true },
    component: () => import('../pages/secret-question/List.vue')
  }
]
const movementRoutes = (prop) => [
  {
    path: 'movement-summary',
    name: prop + '.movement_summary',
    meta: { auth: true },
    component: () => import('../pages/movement-summary/List.vue')
  }
]
const ministryRoutes = (prop) => [
  {
    path: 'dashboard/:dashboard_name',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../pages/ministry/Landing.vue')
  }
]

const routes = [
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/layouts/auth-layouts/AuthLayout'),
    meta: { auth: false },
    children: authChildRoutes('auth')
  },
  {
    path: '/auth',
    name: 'authSignUp',
    component: () => import('@/layouts/auth-layouts/AuthSignupLayout'),
    meta: { auth: false },
    children: authSignupRoutes('authSignUp'),
    beforeEnter: async (to, from, next) => {
      const isLoggedIn = Store.state.Auth.authUser
      const accessToken = Store.state.Auth.accessToken

      if (isLoggedIn && isLoggedIn.office_detail && accessToken) {
          return next('/auth/dashboard')
      } else if (isLoggedIn && !isLoggedIn.office_detail && accessToken) {
          return next('/common/dashboard')
      } else {
        next()
      }
    }
  },
  {
    path: '/auth',
    name: 'authSignUp',
    component: () => import('@/layouts/auth-layouts/AuthAdminLayout'),
    meta: { auth: false },
    children: authAdminRoutes('authSignUp')
  },
  {
    path: '/ministry',
    name: 'ministry',
    component: () => import('@/layouts/MinistryUserLayout'),
    meta: { auth: false },
    children: ministryRoutes('ministry')
  },
  {
    path: '/security',
    name: 'security',
    component: () => import('@/layouts/VerticleLayout'),
    meta: { auth: true },
    children: passwordChangeChildRoutes('security')
  },
  {
    path: '/movement',
    name: 'movement',
    component: () => import('@/layouts/VerticleLayout'),
    meta: { auth: true },
    children: movementRoutes('movement')
  },
  {
    path: '/common-service/user-management',
    name: 'common_service.secret_questions',
    component: () => import('@/layouts/VerticleLayout'),
    meta: { auth: true },
    children: secertQuestionRoutes('common_service.secret_questions')
  }
]

export default routes
