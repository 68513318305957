const childRoutes = (prop) => [
    {
      path: 'district-allocation',
      name: prop + 'district_allocation',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/district-allocation/List.vue')
    },
    {
      path: 'district-allocation-form',
      name: prop + 'district_allocation_form',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/district-allocation/Form.vue')
    },
    {
      path: 'district-allocation-request',
      name: prop + 'district_allocation_request',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/district-allocation-request/List.vue')
    },
    {
      path: 'district-allocation-approved',
      name: prop + 'district_allocation_approved',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/district-allocation-approved/List.vue')
    },
    {
      path: 'upazila-allocation',
      name: prop + 'upazila_allocation',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/upazila-allocation/List.vue')
    },
    {
      path: 'upazila-allocation-form',
      name: prop + 'upazila_allocation_form',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/upazila-allocation/Form.vue')
    },
    {
      path: 'upazila-allocation-request',
      name: prop + 'upazila_allocation_request',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/upazila-allocation-request/List.vue')
    },
    {
      path: 'upazila-allocation-approved',
      name: prop + 'upazila_allocation_approved',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/upazila-allocation-approved/List.vue')
    }
]

const routes = [
    {
        path: '/incentive-grant-service/dae/allocation',
        name: 'incentive_grant_service.dae.allocation',
        component: () => import('@/layouts/IncentiveGrantLayout.vue'),
        meta: { auth: true },
        children: childRoutes('incentive_grant_service.dae.allocation')
    }
]

export default routes
