import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'
import Custom from './custom'

const state = {
    incentiveFarmerObj: {
      hasDropdownLoaded: false,
      educationLevelList: [],
      cropList: [],
      circularList: [],
      instrumentList: [],
      supplierList: [],
      subsidyCircularTypeList: Custom.subsidyCircularTypeList,
      enlistedUniversityList: [],
      seasonSetupList: Custom.seasonList,
      projectList: [],
      agMaterialTypeList: [],
      macMachineTypeList: [],
      macBrandList: [],
      agMaterialList: []
    },
    static: {
      perPage: 10,
      dateFormat: 'DD/MM/YYYY',
      fiscaleYear: 'YYYY-YYYY',
      timeFormat: 'h:m',
      loading: false,
      listReload: false,
      hasDropdownLoaded: false
    },
    commonObj: {
      hasDropdownLoaded: false,
      divisionList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      officeTypeList: [],
      officeList: [],
      designationList: [],
      gradeList: [],
      fiscalYearList: []
    },
    commonObjCommonConfig: {
      hasDropdownLoaded: false,
      bankList: [],
      cityCorporationList: [],
      pauroshobaList: [],
      branchList: [],
      wardList: []
    }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
