const childRoute = (prop) => [
  {
    path: 'notification-type-list',
    name: 'notification-type-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/notification-type/List.vue')
  },
  {
    path: 'notification-setting-list',
    name: 'notification-setting-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/notification-setting/List.vue')
  },
  {
    path: 'notification-template-list',
    name: 'notification-template-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/notification-template/List.vue')
  },
  {
    path: 'circulate-notice-list',
    name: 'circulate-notice-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/circulate-notice/List.vue')
  },
  {
    path: 'message-template',
    name: 'message_template',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/message-template/List.vue')
  },
  {
    path: 'notification-setting',
    name: 'notification_setting',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/notification-setting-list/List.vue')
  }
]
const receivedNotificationRoute = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/Notifications.vue')
  }
]

const routes = [
  {
    path: '/notification',
    name: 'notification',
    component: () => import('../../../../layouts/VerticleLayout.vue'),
    meta: { auth: true },
    children: childRoute('notification')
  },
  {
    path: '/common-service/received-notifications',
    name: 'common_servie.received_notifications',
    component: () => import('../../../../layouts/VerticleLayout.vue'),
    meta: { auth: true },
    children: receivedNotificationRoute('common_servie.received_notifications')
  }
]

export default routes
