const childRoutes = (prop) => [
    {
      path: 'subsidy-wise-allocation-report',
      name: prop + 'subsidy_wise_allocation_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/subsidy-wise-allocation-report/List.vue')
    },
    {
      path: 'approve-beneficiary',
      name: prop + 'approve_beneficiary',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/approve-beneficiary/List.vue')
    },
    {
      path: 'stock-report',
      name: prop + 'stock_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/stock-report/List.vue')
    },
    {
      path: 'distribution-report',
      name: prop + 'distribution_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/distribution-report/List.vue')
    }
]

const routes = [
  {
    path: '/incentive-grant-service/dae/reports',
    name: 'incentive_grant_service.dae.reports',
    component: () => import('@/layouts/IncentiveGrantLayout.vue'),
    meta: { auth: true },
    children: childRoutes('incentive_grant_service.dae.reports')
  }
]

export default routes
