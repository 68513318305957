const childRoutes = (prop) => [
  {
    path: 'pest-information',
    name: prop + '.pest_information',
    component: () => import('../pages/pest-information/List')
  },
  {
    path: 'soil-information',
    name: prop + '.soil-information',
    component: () => import('../pages/soil-information/List')
  },
  {
    path: 'crop-information',
    name: prop + '.crop_information',
    component: () => import('../pages/crop-information/List')
  },
  {
    path: 'museum',
    name: prop + '.museum',
    component: () => import('../pages/museum-information/List')
  }
]
const routes = [
  {
    path: '/virtual-museum-service/museum-information-management/',
    name: 'virtual_museum_service.museum_information_management',
    component: () => import('@/layouts/VirtualMuseumLayout.vue'),
    children: childRoutes('virtual_museum_service.museum_information_management'),
    meta: { auth: true }
  }
]

export default routes
