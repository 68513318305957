const childRoutes = (prop) => [
  {
    path: 'service-category',
    name: 'service-category',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/service-category/List.vue')
  },
  {
    path: 'service-customer-type',
    name: 'service-customer-type',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/service-customer-type/List.vue')
  },
  {
    path: 'service',
    name: 'service',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/service/List.vue')
  },
  {
    path: 'portal-header',
    name: 'portal-header',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/header/List.vue')
  },
  {
    path: 'news',
    name: 'News',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/News/List.vue')
   },
   {
    path: 'faq',
     name: 'Faq',
     meta: { auth: true, name: 'Editable' },
     component: () => import('../pages/Faq/List.vue')
   },
   {
    path: 'notice',
     name: 'notice',
     meta: { auth: true, name: 'Editable' },
     component: () => import('../pages/notice/List.vue')
   },
   {
    path: 'social-media-links',
     name: 'social-media-links',
     meta: { auth: true, name: 'Editable' },
     component: () => import('../pages/social-media-links/List.vue')
   },
   {
    path: 'about-service-portal',
     name: 'about_service_portal',
     meta: { auth: true, name: 'Editable' },
     component: () => import('../pages/about/List.vue')
   }
]
const routes = [
  {
    path: '/portal-service',
    name: 'Portal',
    component: () => import('../../../../layouts/VerticleLayout.vue'),
    meta: { auth: true },
    children: childRoutes('portal-service')
  }
]

export default routes
