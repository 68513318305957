import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'

const statusList = [
  {
    value: 1,
    text_en: 'Pending',
    text_bn: 'অপেক্ষমান'
  },
  {
    value: 2,
    text_en: 'Closed',
    text_bn: 'বন্ধ'
  },
  {
    value: 3,
    text_en: 'Reviewed',
    text_bn: 'পুনরায় যাচাই'
  },
  {
    value: 4,
    text_en: 'Required Maintenance',
    text_bn: 'প্রয়োজনীয় রক্ষণাবেক্ষণ'
  },
  {
    value: 5,
    text_en: 'Maintenance Task',
    text_bn: 'রক্ষণাবেক্ষণের কাজ'
  },
  {
    value: 6,
    text_en: 'Requisition',
    text_bn: 'চাহিদা'
  },
  {
    value: 7,
    text_en: 'Complete',
    text_bn: 'সম্পন্ন'
  }
]
const state = {
    tests: [],
    directorys: [],
    statusList: statusList
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
