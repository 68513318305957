export const mutations = {
    // test
    addTestList (state, payload) {
        state.tests.push(payload)
    },
    updateTestStatus (state, payload) {
        state.tests = state.test.map(function (item) {
            if (item.id === payload.id) {
                payload.status = !payload.status
                return payload
            }

            return item
        })
    },
    // Directory
    addDirectoryList (state, directorys) {
        state.directorys = directorys
    },
    updateDirectoryStatus (state, payload) {
        state.directorys = state.directorys.map(function (item) {
            if (item.id === payload.id) {
                payload.status = !payload.status
                return payload
            }

            return item
        })
    }
}
