const authSignupRoutes = (prop) => [
  {
    path: 'home',
    name: prop + '.home',
    meta: { auth: false },
    component: () => import('../pages/Home.vue')
  },
  {
    path: 'services',
    name: prop + '.services',
    meta: { auth: false },
    component: () => import('../pages/Services.vue')
  },
  {
    path: 'service-details',
    name: prop + '.service_details',
    meta: { auth: false },
    component: () => import('../pages/ServiceDetails.vue')
  },
  {
    path: 'water-testing-badc',
    redirect: { path: '/portal/service-details', query: { service_type: 'allService', service_id: 26 } }
  },
  {
    path: 'irrigation-scheme-badc',
    redirect: { path: '/portal/service-details', query: { service_type: 'orgList', org_id: 3, service_id: 27 } }
  },
  {
    path: 'irrigation-pump-new',
    redirect: { path: '/portal/service-details', query: { service_type: 'orgList', org_id: 3, service_id: 74 } }
  },
  {
    path: 'irrigation-pump-existing',
    redirect: { path: '/portal/service-details', query: { service_type: 'orgList', org_id: 3, service_id: 85 } }
  },
  {
    path: 'product-details',
    name: prop + '.product_details',
    meta: { auth: false },
    component: () => import('../pages/ProductDetails.vue')
  },
  {
    path: 'about-service-portal',
    name: prop + '.about_service_portal',
    meta: { auth: false },
    component: () => import('../pages/AboutPortal.vue')
  },
  {
    path: 'all-services',
    name: prop + '.all_services',
    meta: { auth: false },
    component: () => import('../pages/AllServices.vue')
  },
  {
    path: 'faq',
    name: prop + '.faq',
    meta: { auth: false },
    component: () => import('../pages/FAQ.vue')
  },
  {
    path: 'notice',
    name: prop + '.notice',
    meta: { auth: false },
    component: () => import('../pages/Notice.vue')
  },
  {
    path: 'news',
    name: prop + '.news',
    meta: { auth: false },
    component: () => import('../pages/News.vue')
  },
  {
    path: 'news-details/:id',
    name: prop + '.news_details',
    meta: { auth: false },
    component: () => import('../pages/NewsDetails.vue')
  },
  {
    path: 'policy',
    name: prop + '.policy',
    meta: { auth: false },
    component: () => import('../pages/Policy.vue')
  },
  {
    path: 'terms',
    name: prop + '.terms',
    meta: { auth: false },
    component: () => import('../pages/Terms.vue')
  },
  {
    path: 'subsidy-circular-list',
    name: prop + '.circular_list',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/incentive-subsidy-circular/List.vue')
  },
  {
    path: 'incentive-grant-circular-list',
    name: prop + '.grant_circular_list',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/incentive-grant-circular/List.vue')
  },
  {
    path: 'venue-reservation',
    name: prop + '.venue_reservation',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/venue-reservation/List.vue')
  },
  {
    path: 'germplasm-request',
    name: prop + '.germplasm_request',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/germplasm-request/Form.vue')
  },
  {
    path: 'venue-reservation-details',
    name: prop + '.venue_reservation_details',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/venue-reservation/VenueReservationDetails.vue')
  },
  {
    path: 'research-proposal-circular-list',
    name: prop + '.venue_reservation',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/research-proposal-circular-list/List.vue')
  },
  {
    path: 'boro-import-application',
    redirect: { path: '/portal/service-details', query: { service_type: 'orgList', org_id: 1, service_id: 86 } }
  },
  {
    path: 'seed-dealer-registration',
    redirect: { path: '/portal/service-details', query: { service_type: 'orgList', org_id: 1, service_id: 80 } }
  },
  {
    path: 'crop-seed-import-registration',
    redirect: { path: '/portal/service-details', query: { service_type: 'orgList', org_id: 1, service_id: 79 } }
  },
  {
    path: 'crop-seed-export-registration',
    redirect: { path: '/portal/service-details', query: { service_type: 'orgList', org_id: 1, service_id: 83 } }
  },
  {
    path: 'crop-variety-registration',
    redirect: { path: '/portal/service-details', query: { service_type: 'orgList', org_id: 1, service_id: 78 } }
  },
  {
    path: 'fertilizer-import-reg',
    redirect: { path: '/portal/service-details', query: { service_type: 'orgList', org_id: 2, service_id: 55 } }
  },
  {
    path: 'fertilizer-production-reg',
    redirect: { path: '/portal/service-details', query: { service_type: 'orgList', org_id: 2, service_id: 56 } }
  },
  {
    path: 'fertilizer-distribution-reg',
    redirect: { path: '/portal/service-details', query: { service_type: 'orgList', org_id: 2, service_id: 57 } }
  },
  {
    path: 'seed-testing-without-field-certification',
    redirect: { path: '/portal/service-details', query: { service_type: 'orgList', org_id: 14, service_id: 52 } }
  }
]
const licenseSignupRoutes = (prop) => [
  {
    path: 'fertilizer-dealer',
    redirect: { path: '/portal/service-details', query: { service_type: 'orgList', org_id: 3, service_id: 75 } }
  }
]
const publicationRoutes = (prop) => [
  {
    path: 'magazine',
    name: prop + '.magazine',
    meta: { auth: false },
    component: () => import('../pages/publication-management/Magazine.vue')
  },
  {
    path: 'magazine-details',
    name: prop + '.magazine-details',
    meta: { auth: false },
    component: () => import('../pages/publication-management/MagazineDetails.vue')
  },
  {
    path: 'content-details',
    name: prop + '.content-details',
    meta: { auth: false },
    component: () => import('../pages/publication-management/ContentDetails.vue')
  },
  {
    path: 'research-report-details',
    name: prop + '.research-report-details',
    meta: { auth: false },
    component: () => import('../pages/publication-management/ResearchReportDetails.vue')
  },
  {
    path: '/',
    name: prop + '.common',
    meta: { auth: false },
    component: () => import('../pages/publication-management/Common.vue')
  },
  {
    path: 'publication-circular-list',
    name: prop + '.publication_circular_list',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/publication-management/circular/List.vue')
  },
  {
    path: 'research-circular-list',
    name: prop + '.research_circular_list',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/publication-management/research-circular/List.vue')
  }
]
const training = (prop) => [
  {
    path: 'bsri-training-circular-list',
    name: prop + '.bsri_training_circular_list',
    meta: { auth: false },
    component: () => import('../pages/training/bsri-circular/List.vue')
  },
  {
    path: 'bina-training-circular-list',
    name: prop + '.bina_training_circular_list',
    meta: { auth: false },
    component: () => import('../pages/training/bina-circular/List.vue')
  },
  {
    path: 'badc-training-circular-list',
    name: prop + '.badc_training_circular_list',
    meta: { auth: false },
    component: () => import('../pages/training/badc-circular/List.vue')
  },
  {
    path: 'srdi-training-circular-list',
    name: prop + '.srdi_training_circular_list',
    props: true,
    meta: {
        auth: false,
        org_id: 17,
        name: 'Editable'
    },
    component: () => import('../pages/training/circular-list/List.vue')
  },
  {
    path: 'barc-training-circular-list',
    name: prop + '.barc_training_circular_list',
    props: true,
    meta: {
        auth: false,
        org_id: 4,
        name: 'Editable'
    },
    component: () => import('../pages/training/circular-list/List.vue')
  },
  {
    path: 'cdb-training-circular-list',
    name: prop + '.cdb_training_circular_list',
    props: true,
    meta: {
        auth: false,
        org_id: 10,
        name: 'Editable'
    },
    component: () => import('../pages/training/circular-list/List.vue')
  },
  {
    path: 'bwmri-training-circular-list',
    name: prop + '.bwmri_training_circular_list',
    meta: {
        auth: false,
        org_id: 19,
        name: 'Editable'
    },
    component: () => import('../pages/training/circular-list/List.vue')
  },
  {
    path: 'nata-training-circular-list',
    name: prop + '.nata_training_circular_list',
    meta: {
        auth: false,
        org_id: 12,
        name: 'Editable'
    },
    component: () => import('../pages/training/circular-list/List.vue')
  },
  {
    path: 'bjri-training-circular-list',
    name: prop + '.bjri_training_circular_list',
    meta: {
        auth: false,
        org_id: 20,
        name: 'Editable'
    },
    component: () => import('../pages/training/circular-list/List.vue')
  },
  {
    path: 'training-circular-list',
    name: prop + '.training_circular_list',
    meta: { auth: false },
    component: () => import('../pages/training-circular-list/List.vue')
  },
  {
    path: 'invitation-update/:status/:hash_code',
    name: prop + '.invitation_update',
    meta: { auth: false },
    component: () => import('../pages/training-circular-list/InvitationUpdate.vue')
  },
  {
    path: 'duplicate-certificate',
    name: prop + '.duplicate-certificate',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/duplicate-certificate/List.vue')
  },
  {
    path: 'sponsored-training',
    name: prop + '.sponsored_training',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/training-circular-list/SponsoredTraining.vue')
  },
  {
    path: 'certificate-request-preview/:id',
    name: prop + '.certificate_request_preview',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/duplicate-certificate/Preview.vue')
  },
  {
      path: 'duplicate-certificate/cancel',
      name: prop + '.duplicate_certificate.cancel',
      meta: { auth: false },
      component: () => import('../pages/duplicate-certificate/Cancel.vue')
  },
  {
      path: 'duplicate-certificate/decline',
      name: prop + '.duplicate_certificate.decline',
      meta: { auth: false },
      component: () => import('../pages/duplicate-certificate/Decline.vue')
  },
  {
      path: 'duplicate-certificate/success',
      name: prop + '.duplicate_certificate.success',
      meta: { auth: false },
      component: () => import('../pages/duplicate-certificate/Success.vue')
  },
  {
    path: 'sponsored-training-details',
    name: prop + '.sponsored_training_details',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/training-circular-list/SponsoredTrainingDetails.vue')
  },
  {
    path: 'accommodation-service',
    name: prop + '.accommodation-service',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/training/accommodation-service/List.vue')
  },
  {
    path: 'accommodation-service-details',
    name: prop + '.accommodation_service_details',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/training/accommodation-service/Details.vue')
  },
  {
    path: 'resource-person-list',
    name: prop + '.resource_person_list',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/training/resource-person/List.vue')
  },
  {
    path: 'training-calendar',
    name: prop + '.training_calendar',
    meta: { auth: false },
    props: true,
    component: () => import('../pages/training/training-calendar/List.vue')
  }
]
const license = (prop) => [
  {
    path: 'seed-dealer-circular',
    name: prop + '.seed_dealer_circular',
    meta: { auth: false },
    component: () => import('../pages/seed-dealer-circular/List.vue')
  }
]
const marketDirectory = (prop) => [
    {
        path: 'market-directory-report',
        name: prop + '.market-directory-report',
        meta: { auth: false },
        component: () => import('../pages/market-directory/market-directory-report/List.vue')
    },
    {
        path: 'product-details',
        name: prop + '.product_details',
        meta: { auth: false },
        component: () => import('../pages/ProductDetails.vue')
    },
    {
        path: 'market-daily-price-report',
        name: prop + '.market_daily_price_report',
        meta: { auth: false },
        component: () => import('../pages/market-directory/market-daily-price-report/List.vue')
    },
    {
        path: 'product-wise-market-price-report',
        name: prop + '.product_wise_market_price_report',
        meta: { auth: false },
        component: () => import('../pages/market-directory/product-wise-market-price-report/List.vue')
    },
    {
        path: 'weekly-market-wise-price-report',
        name: prop + '.weekly_market_wise_price_report',
        meta: { auth: false },
        component: () => import('../pages/market-directory/weekly-market-wise-price-report/List.vue')
    },
    {
        path: 'yearly-average-market-price-report',
        name: prop + '.yearly_average_market_price_report',
        meta: { auth: false },
        component: () => import('../pages/market-directory/yearly-average-market-price-report/List.vue')
    },
    {
        path: 'mothly-average-market-price-report',
        name: prop + '.mothly_average_market_price_report',
        meta: { auth: false },
        component: () => import('../pages/market-directory/mothly-average-market-price-report/List.vue')
    },
    {
        path: 'weekly-average-price-report',
        name: prop + '.weekly_average_price_report',
        meta: { auth: false },
        component: () => import('../pages/market-directory/weekly-average-price-report/List.vue')
    },
    {
        path: 'division-wise-average-market-price-report',
        name: prop + '.division_wise-average_market_price_report',
        meta: { auth: false },
        component: () => import('../pages/market-directory/division-wise-average-market-price-report/List.vue')
    },
    {
        path: 'grower-avg-price-report',
        name: prop + '.grower_avg_price_report',
        meta: { auth: false },
        component: () => import('../pages/market-directory/grower-avg-price-report/List.vue')
    },
    {
        path: 'comparative-market-price',
        name: prop + '.comparative_market_price',
        meta: { auth: false },
        component: () => import('../pages/market-directory/comparative-market-price/List.vue')
    },
    {
        path: 'price-graphical-report',
        name: prop + '.price_graphical_report',
        meta: { auth: false },
        component: () => import('../pages/market-directory/price-graphical-report/List.vue')
    },
    {
      path: 'seed-dealer-circular',
      name: prop + '.seed_dealer_circular',
      meta: { auth: false },
      component: () => import('../pages/seed-dealer-circular/List.vue')
    }
]

const routes = [
  {
    path: '/privacy-policy',
    name: 'privacy_policy',
    component: () => import('../pages/privacy-policy/List.vue')
  },
  {
    path: '/portal',
    name: 'portal',
    component: () => import('@/layouts/auth-layouts/PortalLayout'),
    meta: { auth: false },
    children: authSignupRoutes('portal')
  },
  {
    path: '/license',
    name: 'license',
    component: () => import('@/layouts/auth-layouts/PortalLayout'),
    meta: { auth: false },
    children: licenseSignupRoutes('portal')
  },
  {
    path: '/market-directory',
    name: 'external_user.market_directory',
    component: () => import('@/layouts/auth-layouts/PortalLayoutDam'),
    meta: { auth: false },
    children: marketDirectory('external_user.market_directory')
  },
  {
    path: '/portal/publication',
    name: 'portal.publication',
    component: () => import('@/layouts/auth-layouts/PortalLayout'),
    meta: { auth: false },
    children: publicationRoutes('portal.publication')
  },
  {
    path: '/training',
    name: 'external_user.training',
    component: () => import('@/layouts/auth-layouts/PortalLayoutTrain'),
    meta: { auth: false },
    children: training('external_user.training')
  },
  {
    path: '/license',
    name: 'portal.license',
    component: () => import('@/layouts/auth-layouts/PortalLayoutLicense'),
    meta: { auth: false },
    children: license('portal.lincese')
  }
]

export default routes
