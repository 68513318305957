import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'

const state = {
  tests: [],
  warehouseTypes: [],
  wareHouseGroups: [],
  commodityNames: [],
  subgroups: [],
  subGroupsStatus: [],
  warehouseDesignations: [],
  warehouseMarketPrices: [],
  warehouseInformations: [],
  warehouseFiscals: [],
  loanRates: [],
  regions: [],
  loanCalculators: [],
  warehouseLevels: [],
  warehouseServices: [],
  warehouseBankLoanFacilitys: [],
  warehouseRents: [],
  openingBalances: [],
  reportHeaders: [],
  warehouseCategories: [],
  marketInfos: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
