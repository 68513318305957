const routes = [{
    path: '/agri-marketing-service/e-pusti/birtan',
    name: 'agri_marketing_service.e_pusti_birtan',
    component: () =>
        import('@/layouts/AgriMarketingLayout.vue'),
    meta: { auth: true },
    children: [{
            path: 'campaign-schedule-assign',
            name: 'campaign_schedule_assign',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/campaign-schedule-assign/List.vue')
        },
        {
            path: 'manage-campaign-event',
            name: 'manage_campaign_event',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/manage_campaign_event/List.vue')
        },
        {
            path: 'campaign-request-manage',
            name: 'campaign_request_manage',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/campaign-request-manage/List.vue')
        },
        {
            path: 'campaign-calendar',
            name: 'campaign_calendar',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/campaign-calendar/List.vue')
        },
        {
            path: 'campaign-attendance',
            name: 'campaign_attendance',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/campaign-attendance/List.vue')
        },
        {
            path: 'campaign-feedback',
            name: 'campaign_feedback',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/campaign-feedback/List.vue')
        },
        {
            path: 'campaign-material',
            name: 'campaign_material',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/campaign-material/List.vue')
        },
        {
            path: 'campaign-dashboard',
            name: 'campaign_dashboard',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/campaign-dashboard/index.vue')
        }
    ]
}]

export default routes
