// monthList
const monthList = [
    { value: 1, text_en: 'January', text_bn: 'জানুয়ারি' },
    { value: 2, text_en: 'February', text_bn: 'ফেব্রুয়ারি' },
    { value: 3, text_en: 'March', text_bn: 'মার্চ' },
    { value: 4, text_en: 'April', text_bn: 'মে' },
    { value: 5, text_en: 'May', text_bn: 'জুন' },
    { value: 6, text_en: 'June', text_bn: 'জুলাই' },
    { value: 7, text_en: 'July', text_bn: 'আগস্ট' },
    { value: 8, text_en: 'August', text_bn: 'সেপ্টেম্বর' },
    { value: 9, text_en: 'September', text_bn: 'অক্টোবর' },
    { value: 10, text_en: 'October', text_bn: 'নভেম্বর' },
    { value: 11, text_en: 'November', text_bn: 'ডিসেম্বর' },
    { value: 12, text_en: 'December', text_bn: 'জানুয়ারি' }
]

const genderList = [
    { value: 1, text_en: 'Male', text_bn: 'পুরুষ' },
    { value: 2, text_en: 'Female', text_bn: 'মহিলা' },
    { value: 3, text_en: 'Other', text_bn: 'অন্যান্য' }
]

const religionList = [
    { value: 1, text_en: 'Islam', text_bn: 'ইসলাম' },
    { value: 2, text_en: 'Hindu', text_bn: 'হিন্দু' },
    { value: 3, text_en: 'Christain', text_bn: 'খ্রিস্টান' },
    { value: 4, text_en: 'Buddhist', text_bn: 'বৌদ্ধ' }
]

export default {
    monthList,
    genderList,
    religionList
}
