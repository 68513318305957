const childRoute = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/Dashboard.vue')
  },
  {
    path: 'pump-operator-list',
    name: prop + '.pump_operator_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/pump-operator/List.vue')
  },
  {
    path: 'importer-pump-operator',
    name: prop + '.importer_pump_operator_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/wrong-pump-operator/List.vue')
  },
  {
    path: 'importer-farmer-information',
    name: prop + '.importer_farmer_information_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/wrong-farmer-information/List.vue')
  },
  {
    path: 'pump-operator-report',
    name: prop + '.pump-operator-report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/pump-operator-report/List.vue')
  },
  {
    path: 'pump-info-list',
    name: prop + '.pump_information_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/pump-information/List.vue')
  },
  {
    path: 'importer-pump-info',
    name: prop + '.importer_pump_info',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/wrong-pump-information/List.vue')
  },
  {
    path: 'pump-report-entry',
    name: prop + '.pump_report_entry',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/pump-report-entry/List.vue')
  },
  {
    path: 'pump-report-entry-form',
    name: prop + '.pump_report_entry_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/pump-report-entry/EntryForm.vue')
  },
  // {
  //   path: 'pump-report-entry-form/:id',
  //   name: prop + '.pump_report_entry_form',
  //   meta: { auth: true, name: 'Editable' },
  //   component: () => import('../pages/pump-report-entry/EntryForm.vue')
  // },
  // {
  //   path: 'pump-report-entry-form/:id',
  //   name: prop + '.pump_report_entry_form',
  //   meta: { auth: true, name: 'Editable' },
  //   component: () => import('../pages/pump-report-entry/EntryForm.vue')
  // },
  {
    path: 'pump-scheduler-list',
    name: prop + '.pump_scheduler_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/pump-scheduler/List.vue')
  },
  {
    path: 'pump-report-list',
    name: prop + '.pump_report_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/pump-report/List.vue')
  },
  {
    path: 'pump-operator-suspension-list',
    name: prop + '.operator_suspension_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/pump-operator-suspension/List.vue')
  },
  {
    path: 'drilling-log',
    name: prop + '.drilling_log',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/drilling-log/List.vue')
  },
  {
    path: 'drilling-log-entry-form',
    name: prop + '.drilling_log_entry_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/drilling-log/EntryForm.vue')
  },
  {
    path: 'drilling-log-entry-form/:id',
    name: prop + '.drilling_log_entry_form.id',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/drilling-log/EntryForm.vue')
  },
  {
    path: 'construction-details',
    name: prop + '.construction_details',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/construction-details/List.vue')
  },
  {
    path: 'construction-details-entry-form',
    name: prop + '.construction_details_entry_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/construction-details/EntryForm.vue')
  },
  {
    path: 'construction-details-entry-form/:id',
    name: prop + '.construction_details_entry_form.id',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/construction-details/EntryForm.vue')
  },
  {
    path: 'bmda-report',
    name: prop + '.bmda_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/bmda-report/List.vue')
  },
  {
    path: 'drilling-log-report',
    name: prop + '.drilling_log_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/drilling-log-report/List.vue')
  },
  {
    path: 'construction-details-report',
    name: prop + '.construction_details_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/construction-details-report/List.vue')
  }
]

const routes = [
  {
    path: '/irrigation/pump-information',
    name: 'irrigation.pump_information',
    component: () => import('@/layouts/IrrigationSchemeLayout.vue'),
    meta: { auth: true },
    children: childRoute('irrigation.pump_information')
  }
]

export default routes
