const childRoutes = (prop) => [
  {
    path: 'pending-venue-reservation',
    name: prop + 'pending_venue_reservation',
    meta: { auth: true },
    component: () => import('../pages/pending-venue-reservation/List.vue')
  },
  {
    path: 'forward-venue-reservation',
    name: prop + 'forward_venue_reservation',
    meta: { auth: true },
    component: () => import('../pages/forward-venue-reservation/List.vue')
  },
  {
    path: 'review-venue-reservation',
    name: prop + 'review_venue_reservation',
    meta: { auth: true },
    component: () => import('../pages/review-venue-reservation/List.vue')
  },
  {
    path: 'approved-venue-reservation',
    name: prop + 'approved_venue_reservation',
    meta: { auth: true },
    component: () => import('../pages/approved-venue-reservation/List.vue')
  },
  {
    path: 'bill-generate',
    name: prop + 'bill_generate',
    meta: { auth: true },
    component: () => import('../pages/bill-generate/List.vue')
  },
  {
    path: 'bill-generate/:status',
    name: prop + '.bill_generate.status',
    meta: { auth: true },
    component: () => import('../pages/bill-generate/List.vue')
  },
  {
    path: 'reject-venue-reservation',
    name: prop + 'reject_venue_reservation',
    meta: { auth: true },
    component: () => import('../pages/reject-venue-reservation/List.vue')
  },
  {
    path: 'venue-reservation',
    name: prop + 'venue_reservation',
    meta: { auth: true },
    component: () => import('../pages/venue-reservation/List.vue')
  },
  {
    path: 'venue-reservation-form',
    name: prop + 'venue_reservation_form',
    meta: { auth: true },
    component: () => import('../pages/venue-reservation/Form.vue')
  }
]

const routes = [
    {
      path: '/training-e-learning-service/venue',
      name: 'training_e_learning_service.venue',
      component: () => import('@/layouts/TrainingElearningLayout.vue'),
      meta: { auth: true },
      children: childRoutes('training_e_service.barc.venue')
    }
]

export default routes
