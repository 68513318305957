import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'
import custom from '../../../../store/custom'
import Custom from './custom'

const state = {
    activePanel: null,
    panelObj: {
        irriUserLoader: false,
        irriUserStatus: 0,
        lrcpnUserLoader: false,
        lrcpnUserStatus: 0,
        ginnerGrowerLoader: false,
        ginnerGrowerMessage: '',
        divisionalHeadLoader: false,
        divisionalHeadMessage: '',
        dealerLoader: false,
        dealerMessage: '',
        trainigLoader: false,
        trainingType: 0,
        isTraner: 0,
        trainingMessage: '',
        incentiveFarmerLoader: false,
        incentiveFarmerMesage: '',
        researchFarmerLoader: false,
        profileStatus: 2
    },
    commonProfile: {
        user_id: '',
        name: '',
        name_bn: '',
        mobile_no: '',
        email: '',
        father_name: '',
        father_name_bn: '',
        mother_name: '',
        mother_name_bn: '',
        gender: '',
        region_id: '',
        division_id: '',
        district_id: '',
        upazila_id: '',
        union_id: '',
        nid: '',
        image: ''
    },
    commonObj: {
        countryList: [],
        divisionList: [],
        districtList: [],
        upazilaList: [],
        unionList: [],
        pauroshobaList: [],
        wardList: [],
        cityCorporationList: [],
        designationList: [],
        masterDesignationList: [],
        gradeList: [],
        fiscalYearList: [],
        fertilizerTypeList: [],
        fertilizerNameList: [],
        allocationTypeList: [],
        monthList: Custom.monthList,
        monthListfiscalYear: Custom.monthListfiscalYear,
        officeTypeList: [],
        officeList: [],
        organizationProfileList: [],
        orgComponentList: [],
        orgList: []
    },
    static: {
        perPage: 10,
        dateFormat: 'DD/MM/YYYY',
        fiscaleYear: 'YYYY-YYYY',
        timeFormat: 'h:m',
        loading: false,
        listReload: false,
        hasDropdownLoaded: false
    },
    warehouseInfoList: [],
    regionList: [],
    marketList: [],
    regionDistrictList: [],
    affidavitList: [],
    orgList: [],
    schemeTypeList: [],
    subSchemeTypeList: [],
    pumpCapacityList: [],
    waterTestingParameterList: [],
    orgComponentList: [],
    schemeApplicationLists: null,
    pumpOptApplicationLists: null,
    smartCardApplicationLists: null,
    waterTestingRequests: [],
    farmerBasicInfo: null,
    ginnerGrowerDetails: null,
    reissueStatus: 1,
    pumpList: [],
    pumpTypeList: [],
    warehouse: {
        hasDropdownLoaded: false,
        perPage: 10,
        dateFormat: 'dd/mm/YYYY',
        timeFormat: 'h:m',
        loading: false,
        listReload: false,
        commodityGroupList: [],
        commodityNameList: [],
        fiscalYearList: [],
        regionList: [],
        warehouseDesignationList: [],
        warehouseTypeList: [],
        warehouseInfoList: [],
        warehouseLevelList: [],
        marketInfoList: []
    },
    farmerBasicInfoStatus: [
        {
            value: 1,
            text: 'Draft',
            text_bn: 'ড্রাফট'
        },
        {
            value: 2,
            text: 'Saved',
            text_bn: 'সংরক্ষিত'
        }
    ],
    warehouseFarmerProfiles: [],
    ratinglists: [],
    genderList: custom.genderList,
    farmerWareInfo: null,
    reissueData: null,
    schemeFarmerList: [],
    schemeAppAddedFarmer: false,
    circleAreaList: [],
    pumpOptDetails: {},
    agriMarketing: {
        hasDropdownLoaded: false,
        perPage: 10,
        dateFormat: 'dd/mm/YYYY',
        timeFormat: 'h:m',
        loading: false,
        listReload: false,
        regionList: [],
        zoneList: [],
        unitList: [],
        hatList: [],
        seasonList: [],
        cottonVaritiesList: [],
        cottonNameList: []
    },
    incentiveGrant: {
        hasDropdownLoaded: false,
        perPage: 10,
        dateFormat: 'dd/mm/YYYY',
        timeFormat: 'h:m',
        loading: false,
        listReload: false,
        appGeneralInfoID: null,
        applicantDetails: null,
        trainingTypeList: Custom.trainingTypeList,
        trainingTitleList: Custom.trainingTitleList,
        authorTypeList: Custom.authorTypeList,
        publicationTypeList: Custom.publicationTypeList
    }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
