const childRoute = (prop) => [
  {
    path: 'org-wise-seed-allocation',
    name: prop + '.org-wise-seed-allocation',
    meta: { auth: true },
    component: () => import('../pages/org-wise-seed-allocation/List.vue')
  },
  {
    path: 'dealer-wise-seed-allocation',
    name: prop + '.dealer-wise-seed-allocation',
    meta: { auth: true },
    component: () => import('../pages/dealer-wise-seed-allocation/List.vue')
  },
  {
    path: 'overall-stock-report',
    name: prop + '.overall-stock-report',
    meta: { auth: true },
    component: () => import('../pages/overall-stock-report/List.vue')
  },
  {
    path: 'overall-disburse-report',
    name: prop + '.overall-disburse-report',
    meta: { auth: true },
    component: () => import('../pages/overall-disburse-report/List.vue')
  },
  {
    path: 'office-wise-stock-report',
    name: prop + '.office_wise_stock_report',
    meta: { auth: true },
    component: () => import('../pages/office-wise-stock-report/List.vue')
  },
  {
    path: 'office-wise-sales-report',
    name: prop + '.office_wise_sales_report',
    meta: { auth: true },
    component: () => import('../pages/office-wise-sales-report/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/seeds-reports',
    name: 'seeds_fertilizer_service.seeds-reports',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.seeds-reports')
  }
]

export default routes
