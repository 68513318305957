const routes = [
    {
    path: '/agri-marketing/cotton/dashboard',
    name: 'agri-marketing.cotton.dashboard',
    component: () => import('@/layouts/AgriMarketingLayout.vue'),
    meta: { auth: true },
    children: [
            {
                path: '/',
                name: 'cotton_dashboard',
                meta: { auth: true, name: 'Editable' },
                component: () => import('../pages/cotton-dashboard/Dashboard.vue')
            }
        ]
    },
    {
    path: '/agri-marketing/cotton/reports',
    name: 'agri-marketing.cotton.reports',
    component: () => import('@/layouts/AgriMarketingLayout.vue'),
    meta: { auth: true },
    children: [
            {
                path: 'cotton-production-report',
                name: 'cotton_production_report',
                meta: {
                    auth: true,
                    name: 'Editable'
                },
                component: () => import('../pages/cotton-production-report/List.vue')
            },
            {
                path: 'fiscal-year-wise-cotton-sell-report',
                name: 'fiscal_year_wise_cotton_sell_report',
                meta: {
                    auth: true,
                    name: 'Editable'
                },
                component: () => import('../pages/fiscal-year-wise-cotton-sell-report/List.vue')
            },
            {
                path: 'grower-information-report',
                name: 'grower_information_report',
                meta: { auth: true, name: 'Editable' },
                component: () => import('../pages/grower-information-report/List.vue')
            },
            {
                path: 'ginner-information-report',
                name: 'ginner_information_report',
                meta: { auth: true, name: 'Editable' },
                component: () => import('../pages/ginner-information-report/List.vue')
            },
            {
                path: 'cotton-stock-report',
                name: 'cotton_stock_report',
                meta: { auth: true, name: 'Editable' },
                component: () => import('../pages/cotton-stock-report/List.vue')
            },
            {
              path: 'ginner-wise-purchase-report',
              name: 'ginner_wise_purchase_report',
              meta: {
                auth: true,
                name: 'Editable'
              },
              component: () => import('../pages/ginner-wise-purchase-report/List.vue')
            }
        ]
    }
]

export default routes
