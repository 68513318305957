export default {
  userFind: state => state.tests,
  testFind: state => (id) => state.tests.find(item => item.id === id),
  groupFind: state => (id) => state.groups.find(item => item.id === id),
  subgroupFind: state => (id) => state.subgroups.find(item => item.id === id),
  designationFind: state => (id) => state.warehouseDesignations.find(item => item.id === id),
  marketPriceFind: state => (id) => state.warehouseMarketPrices.find(warehouseMarketPrice => warehouseMarketPrice.id === id),
  fiscalFind: state => (id) => state.warehouseFiscals.find(warehouseFiscal => warehouseFiscal.id === id),
  serviceFind: state => (id) => state.warehouseServices.find(warehouseService => warehouseService.id === id),
  bankLoanFacilityFind: state => (id) => state.warehouseBankLoanFacilitys.find(warehouseBankLoanFacility => warehouseBankLoanFacility.id === id),
  RentFind: state => (id) => state.warehouseRents.find(item => item.id === id),
  openingBalanceFind: state => (id) => state.openingBalances.find(item => item.id === id),
  reportHeaderFind: state => (id) => state.reportHeaders.find(item => item.id === id)
}
