const incentiveFarmerPanel = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../pages/Dashboard.vue')
  },
  {
    path: 'profile',
    name: prop + '.profile',
    meta: { auth: true },
    component: () => import('../pages/profile/Form.vue')
  },
  {
    path: 'circular-list',
    name: prop + '.circular_list',
    meta: { auth: true },
    component: () => import('../pages/circular/List.vue')
  },
  {
    path: 'subsidy-application',
    name: prop + '.subsidy_application',
    meta: { auth: true },
    component: () => import('../pages/subsidy-application/List.vue')
  },
  {
    path: 'subsidy-application-form',
    name: prop + '.subsidy_application_form',
    meta: { auth: true },
    component: () => import('../pages/subsidy-application/Form.vue')
  }
]

const routes = [
  {
    path: '/incentive-farmer',
    name: 'external_user.incentive_farmer',
    component: () => import('@/layouts/ExternalUserLayout.vue'),
    meta: { auth: true },
    children: incentiveFarmerPanel('external_user.incentive_farmer')
  }
]

export default routes
