const childRoutes = (prop) => [
    {
      path: 'enlistment-list',
      name: prop + 'enlistment_list',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/enlistment-list/List.vue')
    }
  ]

const routes = [
    {
        path: '/license-registration-service/foreign-enlistment/',
        name: 'license-registration-service.foreign_enlistment',
        component: () => import('@/layouts/LicenseRegistrationLayout.vue'),
        meta: { auth: true },
        children: childRoutes('license_registration_service.foreign-enlistment')
    }
]

export default routes
