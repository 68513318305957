export const mutations = {
  statusUpdate (state, payload) {
    state.applications = state.applications.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  stockInList (state, stockInList) {
    state.stockInList = stockInList
  },
  stockOutList (state, stockOutList) {
    state.stockOutList = stockOutList
  },
  stockOutApprove (state, payload) {
    state.stockOutList = state.stockOutList.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  stockInToggleStatus (state, payload) {
    state.stockInList = state.stockInList.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  updateRequisition (state, applications) {
    state.requistion_applications = state.requistion_applications.map(function (item) {
      if (item.id === applications.id) {
        return applications
      }
      return item
    })
  },
    addFarmerDetails (state, payload) {
        state.farmerDetails = payload
    }
}
