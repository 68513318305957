const childRoutes = (prop) => [
    {
      path: 'fund-request',
      name: prop + 'fund_request',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/fund-request/List.vue')
    },
    {
      path: 'fund-distribution',
      name: prop + 'fund_distribution',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/fund-distribution/List.vue')
    },
    {
      path: 'university-deputation',
      name: prop + 'university_deputation',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/university-deputation/List.vue')
    },
    {
      path: 'evaluation-selection-committee',
      name: prop + 'evaluation_selection_committee',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/evaluation-selection-committee/List.vue')
    }
]

const routes = [
    {
        path: '/incentive-grant-service/barc/grant-distribution',
        name: 'incentive_grant_service.barc.grant_distribution',
        component: () => import('@/layouts/IncentiveGrantLayout.vue'),
        meta: { auth: true },
        children: childRoutes('incentive_grant_service.barc.grant_distribution')
    }
]

export default routes
