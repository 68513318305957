const routes = [{
    path: '/agri-marketing/cotton/ginner-grower',
    name: 'agri-marketing.cotton_ginner_grower',
    component: () => import('@/layouts/AgriMarketingLayout.vue'),
    meta: { auth: true },
    children: [
            {
                path: 'hat-manage',
                name: 'hat_manage',
                meta: {
                    auth: true,
                    name: 'Editable'
                },
                component: () => import('../pages/hat-manage/List.vue')
            },
            {
                path: 'manage-schedule',
                name: 'manage_schedule',
                meta: {
                    auth: true,
                    name: 'Editable'
                },
                component: () => import('../pages/manage-schedule/List.vue')
            },
            {
                path: 'approve-schedule',
                name: 'approve_schedule',
                meta: {
                    auth: true,
                    name: 'Editable'
                },
                component: () => import('../pages/approve-schedule/List.vue')
            },
            {
                path: 'profile',
                name: 'ginner_grower_profile',
                meta: { auth: true },
                component: () => import('../pages/profile/List.vue')
            },
            {
                path: 'production-achievement',
                name: 'production_achievement',
                meta: { auth: true },
                component: () => import('../pages/production-achievement/List.vue')
            },
            {
                path: 'sell-entry',
                name: 'sell-entry',
                meta: { auth: true },
                component: () => import('../pages/sell-entry/List.vue')
            }
        ]
    }
]

export default routes
