const childRoute = (prop) => [
    {
        path: 'divisional-office',
        name: prop + 'divisional-office',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/divisional-office/List.vue')
    },
    {
        path: 'campaign',
        name: prop + 'campaign',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/campaign/List.vue')
    }
  ]

  const routes = [
    {
        path: '/agri-marketing-service/e-pusti/configuration',
        name: 'agri_marketing_service.e_pusti_configuration',
        component: () => import('@/layouts/AgriMarketingLayout.vue'),
        meta: { auth: true },
        children: childRoute('configuration')
    },
    {
        path: '/agri-marketing-service/e-pusti/dashboard',
        name: 'agri-marketing_service.e_pusti.dashboard',
        component: () => import('@/layouts/AgriMarketingLayout.vue'),
        meta: { auth: true },
        children: [
            {
                path: '/',
                name: 'e_pusti_dashboard',
                meta: { auth: true, name: 'Editable' },
                component: () => import('../pages/Dashboard.vue')
            }
        ]
    }
  ]

  export default routes
