const childRoutes = (prop) => [
  {
    path: 'database-backup',
    name: prop + '.form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/db-backup/List.vue')
  }
]
const routes = [
  {
    path: '/common-service/data-archive',
    name: 'common_service.data_archive',
    component: () => import('../../../../layouts/VerticleLayout.vue'),
    meta: { auth: true },
    children: childRoutes('common_service.data_archive')
  }
]

export default routes
