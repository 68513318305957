const childRoute = (prop) => [
  {
    path: 'stock-manage',
    name: prop + '.stock-manage',
    meta: { auth: true },
    component: () => import('../pages/stock-manage/List.vue')
  },
  {
    path: 'product-stock-report',
    name: prop + '.product_stock_report',
    meta: { auth: true },
    component: () => import('../pages/product-stock-report/List.vue')
  },
  {
    path: 'application-request',
    name: prop + '.application_request',
    meta: { auth: true },
    component: () => import('../pages/application-request/List.vue')
  },
  {
    path: 'dealer-wise-allocation',
    name: prop + '.dealer_wise_allocation',
    meta: { auth: true },
    component: () => import('../pages/dealer-wise-allocation/List.vue')
  },
  {
    path: 'application-allocation',
    name: prop + '.application-allocation',
    meta: { auth: true },
    component: () => import('../pages/application-allocation/List.vue')
  },
  {
    path: 'office-wise-allocation',
    name: prop + '.application-list',
    meta: { auth: true },
    component: () => import('../pages/office-wise-allocation/List.vue')
  },
  {
    path: 'office-wise-disburse',
    name: prop + '.office-wise-disburse',
    meta: { auth: true },
    component: () => import('../pages/office-wise-disburse/List.vue')
  },
  {
    path: 'company-farmer-disburse',
    name: prop + '.company-farmer-disburse',
    meta: { auth: true },
    component: () => import('../pages/company-farmer-disburse/List.vue')
  },
  {
    path: 'dealer-wise-disburse',
    name: prop + '.dealer_wise_disburse',
    meta: { auth: true },
    component: () => import('../pages/dealer-wise-disburse/List.vue')
  },
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../pages/dashboard/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/seeds-seeds',
    name: 'seeds_fertilizer_service.seeds-seeds',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.seeds-seeds')
  }
]

export default routes
