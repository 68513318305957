export const mutations = {
  mutateTrainingElearningCommonProperties (state, payload) {
    state.commonObj = Object.assign({}, state.commonObj, payload)
  },
  localizeCommonDropdownTrainingElearningService (state, payload) {
    state.commonObj.trainingTypeList = state.commonObj.trainingTypeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.trainingCategoryList = state.commonObj.trainingCategoryList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })

    state.commonObj.trainingTitleList = state.commonObj.trainingTitleList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })

    state.commonObj.roomTypeList = state.commonObj.roomTypeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })

    state.commonObj.guestHouseList = state.commonObj.guestHouseList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })

    // state.commonObj.roomRentList = state.commonObj.roomRentList.map(item => {
    //   const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
    //   return Object.assign({}, item, tmp)
    // })

    state.commonObj.courseDocumentSetupList = state.commonObj.courseDocumentSetupList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })

    state.commonObj.accomodationSetupList = state.commonObj.accomodationSetupList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })

    state.commonObj.faqSetupList = state.commonObj.faqSetupList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })

    state.commonObj.otherTraineeTypeList = state.commonObj.otherTraineeTypeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })

    state.commonObj.trainingQuarterSetupList = state.commonObj.trainingQuarterSetupList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })

    state.commonObj.foodPackageList = state.commonObj.foodPackageList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })

    state.commonObj.traineeEvaluationList = state.commonObj.traineeEvaluationList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.venus = state.commonObj.venus.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.courseList = state.commonObj.courseList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.courseModuleList = state.commonObj.courseModuleList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.trainerEvaluationList = state.commonObj.trainerEvaluationList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    // state.commonObj.personalInfoList = state.commonObj.personalInfoList.map(item => {
    //   const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
    //   return Object.assign({}, item, tmp)
    // })
    state.commonObj.paymentType = state.commonObj.paymentType.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.jobTypeList = state.commonObj.jobTypeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.userPriceTypeList = state.commonObj.userPriceTypeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.userRentTypeList = state.commonObj.userRentTypeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.subCenterUserRentTypeList = state.commonObj.subCenterUserRentTypeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.genderList = state.commonObj.genderList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.roomUserTypeList = state.commonObj.roomUserTypeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.roomList = state.commonObj.roomList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.floorList = state.commonObj.floorList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.gradingStatus = state.commonObj.gradingStatus.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.trainingScheduleSetup = state.commonObj.trainingScheduleSetup.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.sessonType = state.commonObj.sessonType.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.trainingExpertiseList = state.commonObj.trainingExpertiseList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.sheduleType = state.commonObj.sheduleType.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.committeeType = state.commonObj.committeeType.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.duplicateCertificateFeeList = state.commonObj.duplicateCertificateFeeList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.roomTypeSetupList = state.commonObj.roomTypeSetupList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
    state.commonObj.roomRentSetupList = state.commonObj.roomTypeSetupList.map(item => {
      const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
      return Object.assign({}, item, tmp)
    })
  },
  setCurrentFiscalYearId (state, payload) {
    state.currentFiscalYearId = payload
  }
}
