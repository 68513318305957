import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'
import Custom from './custom'

const state = {
    lrcpnObj: {
      lrcpnUserStatus: '',
      hasDropdownLoaded: false,
      feeNamesList: [],
      serviceNamesList: [],
      stepNamesList: [],
      menuWiseStepList: [],
      dynamicFormList: [],
      serviceSteps: [],
      serviceStepForm: [],
      dynamicButtonList: [],
      lrcpnUserDetails: {},
      profileLabel: {},
      seviceStepWiseButtonList: [],
      menuInfoList: [],
      autoGenNumTypeList: [],
      reportMenuList: [],
      labList: [],
      activeIngredientList: [],
      agricultureProductList: [],
      agricultureProductTypeList: [],
      countryList: [],
      fertilizerName: [],
      preconditionList: [],
      cropList: []
    },
    static: {
      perPage: 10,
      dateFormat: 'DD/MM/YYYY',
      fiscaleYear: 'YYYY-YYYY',
      timeFormat: 'h:m',
      loading: false,
      listReload: false,
      hasDropdownLoaded: false
    },
    commonObj: {
      hasDropdownLoaded: false,
      divisionList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      officeTypeList: [],
      officeList: [],
      designationList: [],
      gradeList: [],
      fiscalYearList: [],
      genderList: Custom.genderList,
      religionList: Custom.religionList,
      orgList: [],
      listLoad: false
    },
    commonObjCommonConfig: {
      hasDropdownLoaded: false,
      bankList: [],
      cityCorporationList: [],
      pauroshobaList: [],
      branchList: [],
      wardList: [],
      countryList: []
    }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
