const campaignTypeList = [
    {
        value: 1,
        text: 'Admin',
        text_bn: 'প্রশাসন'
    },
    {
        value: 2,
        text: 'Request',
        text_bn: 'অনুরোধ'
    },
    {
        value: 3,
        text: 'Divisional Head',
        text_bn: 'বিভাগীয় প্রধান'
    }
]
const priceEntryTypeList = [
    {
        value: 'Market',
        text_en: 'Market',
        text_bn: 'বাজার'
    },
    {
        value: 'Grower',
        text_en: 'Grower',
        text_bn: 'চাষী'
    }
]
const priceTypeList = [
    {
        entry_type: 'Market',
        id: 1,
        value: 'Wholesale',
        text_en: 'Wholesale',
        text_bn: 'পাইকারি'
    },
    {
        entry_type: 'Market',
        id: 2,
        value: 'Retail',
        text_en: 'Retail',
        text_bn: 'খুচরা'
    },
    {
        entry_type: 'Grower',
        id: 3,
        value: 'Grower',
        text_en: 'Grower',
        text_bn: 'উৎপাদক'
    }
]
const timePreiodList = [
    {
        value: 'Daily',
        text_en: 'Daily',
        text_bn: 'দৈনিক'
    },
    {
        value: 'Weekly',
        text_en: 'Weekly',
        text_bn: 'সাপ্তাহিক'
    }
]

const dayList = [
    {
        value: 1,
        text_en: 'Daily',
        text_bn: 'প্রতিদিন'
    },
    {
        value: 2,
        text_en: 'Friday',
        text_bn: 'শুক্রবার'
    },
    {
        value: 3,
        text_en: 'Saturday',
        text_bn: 'শনিবার'
    },
    {
        value: 4,
        text_en: 'Sunday',
        text_bn: 'রবিবার'
    },
    {
        value: 5,
        text_en: 'Monday',
        text_bn: 'সোমবার'
    },
    {
        value: 6,
        text_en: 'Tuesday',
        text_bn: 'মঙ্গলবার'
    },
    {
        value: 7,
        text_en: 'Wednesday',
        text_bn: 'বুধবার'
    },
    {
        value: 8,
        text_en: 'Thursday',
        text_bn: 'বৃহস্পতিবার'
    }
]

const weekList = [
    {
        value: '1',
        text_en: '1',
        text_bn: '১'
    },
    {
        value: '2',
        text_en: '2',
        text_bn: '২'
    },
    {
        value: '3',
        text_en: '3',
        text_bn: '৩'
    },
    {
        value: '4',
        text_en: '4',
        text_bn: '৪'
    },
    {
        value: '5',
        text_en: '5',
        text_bn: '৫'
    }
]

function generateYearList () {
    const toBn = n => n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
    var max = new Date().getFullYear()
    var min = 2010
    var years = []
    for (var i = max; i >= min; i--) {
        const yearData = {}
        yearData.value = i
        yearData.text = i
        yearData.text_en = i
        yearData.text_bn = toBn(String(i))
        years.push(yearData)
    }
    return years
}

const yearList = generateYearList()
export default {
    campaignTypeList, priceEntryTypeList, timePreiodList, weekList, yearList, priceTypeList, dayList
}
