const routes = [{
    path: '/agri-marketing-service/market-linkage/config',
    name: 'agri-marketing.market_linkage_config',
    component: () => import('@/layouts/AgriMarketingLayout.vue'),
    meta: { auth: true },
    children: [
        {
            path: 'commodity-type',
            name: 'commodity_type',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/commodity-type/List.vue')
        }
    ]
  }
]
export default routes
