const childRoutes = (prop) => [
  {
    path: 'country-edit/:id',
    name: 'country_edit',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/country/Edit.vue')
  },
  {
    path: 'country-list',
    name: 'country-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/country/List.vue')
  },
  {
    path: 'division-list',
    name: 'division-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/division/List.vue')
  },
  {
    path: 'district-list',
    name: 'district-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/district/List.vue')
  },
  {
    path: 'upazila-list',
    name: 'upazila-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/upazila/List.vue')
  },
  {
    path: 'union-list',
    name: 'union-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/union/List.vue')
  },
  {
    path: 'city-corporation-list',
    name: 'city_corporation_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/city-corporation/List.vue')
  },
  {
    path: 'pauroshoba-list',
    name: 'pauroshoba_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/pauroshoba/List.vue')
  },
  {
    path: 'ward-list',
    name: 'ward_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ward/List.vue')
  },
  {
    path: 'component-list',
    name: 'component-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/component/List.vue')
  },
  {
    path: 'module-list',
    name: 'module-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/module/List.vue')
  },
  {
    path: 'menu-list',
    name: 'menu-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/menu/List.vue')
  },
  {
    path: 'office-type-list',
    name: 'office-type-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/office-type/List.vue')
  },
  {
    path: 'service-list',
    name: 'service-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/service-info/List.vue')
  },
  {
    path: 'organizaion-profile-list',
    name: 'organizaion-profile-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/organization-profile/List.vue')
  },
  {
    path: 'ministry-dashboard-menu',
    name: 'ministry-dashboard-menu',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ministry-dashboard-menu/List.vue')
  },
  {
    path: 'office-list',
    name: 'office-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/office-entry/List.vue')
  },
  {
    path: 'bank-list',
    name: 'bank-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/bank/List.vue')
  },
  {
    path: 'branch-list',
    name: 'branch-list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/branch/List.vue')
  },
  {
    path: 'dialogue-info-list',
    name: 'dialogue_info_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dialogue-info/List.vue')
  },
  {
    path: 'fiscal-year-list',
    name: 'fiscal_year_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fiscal-year/List.vue')
  }
]
const settingsRoutes = (prop) => [
  {
    path: 'site-preference',
    name: prop + '.site_preference',
    meta: { auth: true },
    component: () => import('../pages/SitePreference.vue')
  }
]
const dashboardRoutes = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true },
    component: () => import('../pages/dashboard.vue')
  }
]
const routes = [
  {
    path: '/org-profile',
    name: 'orgProfile',
    component: () => import('../../../../layouts/VerticleLayout.vue'),
    meta: { auth: true },
    children: childRoutes('orgProfile')
  },
  {
    path: '/common-service/settings',
    name: 'common_service.settings',
    component: () => import('../../../../layouts/VerticleLayout.vue'),
    meta: { auth: true },
    children: settingsRoutes('common_service.settings')
  },
  {
    path: '/common-service',
    name: 'common_service.dashboard',
    component: () => import('../../../../layouts/VerticleLayout.vue'),
    meta: { auth: true },
    children: dashboardRoutes('common_service.dashboard')
  }
]

export default routes
