const childRoute = (prop) => [
  {
    path: 'test',
    name: prop + '.test',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/test/List.vue')
  },
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/index.vue')
  },
  {
    path: 'space-management-report',
    name: prop + '.spaceManagementReport',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/space-management-report/index.vue')
  },
  {
    path: 'warehouse-report',
    name: prop + '.warehouseReport',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/warehouse-report/index.vue')
  }
]

const routes = [
  {
    path: '/warehouse/warehouse-management',
    name: 'warehouse.warehouse_management',
    component: () => import('../../../../layouts/WarehouseLayout.vue'),
    meta: { auth: true },
    children: childRoute('warehouse.warehouse_management')
  }
]

export default routes
