const mainRoutes = (prop) => [
    {
        path: 'proposal-invitation',
        name: prop + 'proposal_invitation',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/proposal-invitation/List.vue')
    },
    {
        path: 'proposal-technical-evaluation',
        name: prop + 'proposal_technical_evaluation',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/proposal-technical-evaluation/List.vue')
    },
    {
        path: 'proposal-technical-evaluation-brri',
        name: prop + 'proposal_technical_evaluation',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/proposal-technical-evaluation/BiriList.vue')
    },
    {
        path: 'proposal-technical-evaluation-brri-forward',
        name: prop + 'proposal_technical_evaluation',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/proposal-technical-evaluation/BiriForwardList.vue')
    },
    {
        path: 'divisional-proposal-approval-pi',
        name: prop + 'divisional_proposal_approval_pi',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/divisional-proposal-approval-pi/List.vue')
    },
    {
        path: 'divisional-proposal-approval-oi',
        name: prop + 'divisional_proposal_approval_oi',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/divisional-proposal-approval-oi/List.vue')
    },
    {
        path: 'divisional-proposal-approval-rc',
        name: prop + 'divisional_proposal_approval_rc',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/divisional-proposal-approval-rc/List.vue')
    },
    {
        path: 'proposal-report',
        name: prop + 'proposal_report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/proposal-report/report.vue')
    },
    {
        path: 'research-director',
        name: prop + 'research_director',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/research-director/List.vue')
    },
    {
        path: 'proposal-approval',
        name: prop + 'proposal_approval',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/proposal-approval/List.vue')
    },
    {
        path: 'proposal-approval-brri',
        name: prop + 'proposal_approval_brri',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/proposal-approval/BrriList.vue')
    },
    {
        path: 'research-report-approval',
        name: prop + 'research_report_approval',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/report-approval/List.vue')
    },
    {
        path: 'periodic-activity-monitoring',
        name: prop + 'periodic_activity_monitoring',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/periodic-activity-monitoring/List.vue')
    },
    {
        path: 'periodic-activity-monitoring-details',
        name: prop + 'periodic_activity_monitoring_details',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/periodic-activity-monitoring/Details.vue')
    },
    {
        path: 'fund-request',
        name: prop + 'fund_request',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/fund-request/List.vue')
    },
    {
        path: 'fund-request-disburse',
        name: prop + 'fund_request_disburse',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/fund-request-disburse/List.vue')
    },
    {
        path: 'periodic-activity-monitoring-gantt-chart',
        name: prop + 'periodic_activity_monitoring_gantt_chart',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/periodic-activity-monitoring/GanttChart.vue')
    },
    {
        path: 'research-budget-ledger',
        name: prop + 'research_budget_ledger',
        meta: { auth: true },
        component: () => import('../pages/research-budget-ledger/List.vue')
    },
    {
        path: 'research-project-report',
        name: prop + 'research_project_report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/research-project-report/report.vue')
    },
    {
        path: 'dashboard',
        name: prop + 'dashboard',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/dashboard/Dashboard.vue')
    },
    {
        path: 'activity-progress-report',
        name: prop + 'activity_progress_report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/activity-report/List.vue')
    },
    {
        path: 'monitoring-report',
        name: prop + 'monitoring_report',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/monitoring-report/List.vue')
    }
]

const childRoutes = (prop) => [
    {
        path: 'thematic-area',
        name: prop + 'thematic-area',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/thematic-area/List.vue')
    },
    {
        path: 'division-thematic-area',
        name: prop + 'division-thematic-area',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/division-thematic-area/List.vue')
    },
    {
        path: 'sector-info',
        name: prop + 'sector-info',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/sector-info/List.vue')
    },
    {
        path: 'sub-sector-info',
        name: prop + 'sub-sector-info',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/sub-sector-info/List.vue')
    },
    {
        path: 'component-organization',
        name: prop + 'component-organization',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/component-organization/List.vue')
    },
    {
        path: 'component-organization-form',
        name: prop + 'component-organization_form',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/component-organization/Form.vue')
    },
    {
        path: 'component-organization-detail',
        name: prop + 'component-detail',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/component-organization/Detail.vue')
    },
    {
        path: 'project-info',
        name: prop + 'project-info',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/project-info/List.vue')
    },
    {
        path: 'sub-project-info',
        name: prop + 'sub-project-info',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/sub-project-info/List.vue')
    },
    {
        path: 'program-info',
        name: prop + 'program-info',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/program-info/List.vue')
    },
    {
        path: 'component-division',
        name: prop + 'component-division',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/component-division/List.vue')
    },
    {
        path: 'research-program',
        name: prop + 'research-program',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/research-program/List.vue')
    },
    {
        path: 'research-division-entry',
        name: prop + 'research-division-entry',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/research-division-entry/List.vue')
    },
    {
        path: 'committee-type-setup',
        name: prop + 'committee-type-setup',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/committee-type-setup/List.vue')
    },
    {
        path: 'budget-type',
        name: prop + 'budget_type',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/budget-type/List.vue')
    },
    {
        path: 'budget-head',
        name: prop + 'budget_head',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/budget-head/List.vue')
    },
    {
        path: 'committee-setup',
        name: prop + 'committee-setup',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/committee-setup/List.vue')
    },
    {
        path: 'committee-user-setup',
        name: prop + 'committee-user-setup',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/committee-user-setup/List.vue')
    },
    {
        path: 'subject-expert-registration',
        name: prop + 'subject-expert-registration',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/subject-expert-registration/List.vue')
    },
    {
        path: 'role-information',
        name: prop + 'role-information',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/role-information/List.vue')
    },
    {
        path: 'project-setup',
        name: prop + 'project_setup',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/project-setup/List.vue')
    },
    {
        path: 'sub-project-setup',
        name: prop + 'sub_project_setup',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/sub-project-setup/List.vue')
    }
]

const childMonitoringRoutes = (prop) => [
    {
        path: 'monitoring-team',
        name: prop + 'monitoring-team',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/monitoring/monitoring-team/List.vue')
    },
    {
        path: 'monitoring-team-member-information',
        name: prop + 'monitoring_team_member_information',
        meta: { auth: true },
        component: () => import('../pages/monitoring/monitoring-team-member-information/List.vue')
    },
    {
        path: 'monitoring-schedule',
        name: prop + 'monitoring_schedule',
        meta: { auth: true },
        component: () => import('../pages/monitoring/monitoring-schedule/List.vue')
    },
    {
        path: 'monitoring-schedule-projects',
        name: prop + 'monitoring_schedule_projects',
        meta: { auth: true },
        component: () => import('../pages/monitoring/monitoring-schedule/ProjectList.vue')
    },
    {
        path: 'assign-project',
        name: prop + 'assign_project',
        meta: { auth: true },
        component: () => import('../pages/monitoring/assign-project/List.vue')
    }
]

const routes = [
    {
        path: '/agri-research-service/research-management',
        name: 'agri_research_service.research_management',
        component: () => import('@/layouts/AgriResearchLayout.vue'),
        meta: { auth: true },
        children: mainRoutes('agri_research_service.research_management')
    },
    {
        path: '/agri-research-service/research-management/configuration',
        name: 'agri_research_service.research_management.configuration',
        component: () => import('@/layouts/AgriResearchLayout.vue'),
        meta: { auth: true },
        children: childRoutes('agri_research_service.research_management.configuration')
    },
    {
        path: '/agri-research-service/research-management/monitoring',
        name: 'agri_research_service.research_management.monitoring',
        component: () => import('@/layouts/AgriResearchLayout.vue'),
        meta: { auth: true },
        children: childMonitoringRoutes('agri_research_service.research_management.monitoring')
    }
]
export default routes
