const childRoute = (prop) => [
  {
    path: 'water-testing-request-list',
    name: prop + '.water_testing_request_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/water-testing-request/List.vue')
  }
]

const routes = [
  {
    path: '/irrigation/farmer-operator-panel',
    name: 'irrigation.farmer_operator_panel',
    component: () => import('@/layouts/IrrigationSchemeLayout.vue'),
    meta: { auth: true },
    children: childRoute('irrigation.farmer_operator_panel')
  }
]

export default routes
