export default {
  testList: state => state.tests,
  testFind: state => (id) => state.tests.find(item => item.id === id),
  IrrigationWaterTestingRequestFind: (state) => (id) => state.IrrigationWaterTestingRequests.find(irrigationWaterTestingRequest => irrigationWaterTestingRequest.id === id),
  DrinkingWaterTestingRequestFind: (state) => (id) => state.DrinkingWaterTestingRequests.find(drinkingWaterTestingRequest => drinkingWaterTestingRequest.id === id),
  IndustrialWaterTestingRequestFind: (state) => (id) => state.IndustrialWaterTestingRequests.find(IndustrialWaterTestingRequest => IndustrialWaterTestingRequest.id === id),
  PendingWaterTestingRequestFind: (state) => (id) => state.PendingWaterTestingRequests.find(PendingWaterTestingRequest => PendingWaterTestingRequest.id === id),
  ReportedWaterTestingRequestFind: (state) => (id) => state.ReportedWaterTestingRequests.find(ReportedWaterTestingRequest => ReportedWaterTestingRequest.id === id),
  PublishWaterTestingRequestFind: (state) => (id) => state.PublishWaterTestingRequests.find(PublishWaterTestingRequest => PublishWaterTestingRequest.id === id)
}
