export default {
    mutateExternalCommonProperties ({ commit }, payload) {
        commit('mutateExternalCommonProperties', payload)
    },
    localizeExternalDropdown ({ commit }, payload) {
        commit('localizeExternalDropdown', payload)
    },
    mutateAgriResearchExternalDropdown ({ commit }, payload) {
        commit('mutateAgriResearchExternalDropdown', payload)
    }
}
