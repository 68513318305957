import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'

const state = {
    tests: [],
    IrrigationWaterTestingRequests: [],
    DrinkingWaterTestingRequests: [],
    IndustrialWaterTestingRequests: [],
    PendingWaterTestingRequests: [],
    ReportedWaterTestingRequests: [],
    PublishWaterTestingRequests: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
