import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'

const state = {
  notificationTypes: [],
  notificationSettings: [],
  notificationTemplates: [],
  circulateNotices: []
}
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
