export const mutations = {
  // Water Testing Request
  addwaterTestingRequest (state, waterTestingRequest) {
    state.waterTestingRequests.push(waterTestingRequest)
  },
  addwaterTestingRequestList (state, waterTestingRequests) {
    state.waterTestingRequests = waterTestingRequests
  },
  updatewaterTestingRequest (state, waterTestingRequest) {
    state.waterTestingRequests = state.waterTestingRequests.map(function (item) {
      if (item.id === waterTestingRequest.id) {
        return waterTestingRequest
      }

      return item
    })
  },
  togglewaterTestingRequestStatus (state, payload) {
    state.waterTestingRequests = state.waterTestingRequests.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  }
}
