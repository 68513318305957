const childRoutes = (prop) => [
    {
        path: 'commodity-group',
        name: prop + 'commodity_group',
        meta: {
            auth: false,
            name: 'Editable'
        },
        component: () => import('../pages/commodity-group/List.vue')
    },
    {
        path: 'commodity-sub-group',
        name: prop + 'commodity_sub_group',
        meta: {
            auth: false,
            name: 'Editable'
        },
        component: () => import('../pages/commodity-sub-group/List.vue')
    },
    {
        path: 'commodity-name',
        name: prop + 'commodity_name',
        meta: {
            auth: false,
            name: 'Editable'
        },
        component: () => import('../pages/commodity-name/List.vue')
    },
    {
        path: 'price-type',
        name: prop + 'price_type',
        meta: {
            auth: false,
            name: 'Editable'
        },
        component: () => import('../pages/price-type/List.vue')
    },
    {
        path: 'measurement-unit',
        name: prop + 'measurement_unit',
        meta: {
            auth: true,
            name: 'Editable'
        },
        component: () => import('../pages/measurement-unit/List.vue')
    },
    {
        path: 'alert-percentage',
        name: prop + 'alert_percentage',
        meta: {
            auth: true,
            name: 'Editable'
        },
        component: () => import('../pages/alert-percentage/List.vue')
    },
    {
        path: 'price-collector-profile',
        name: prop + 'price_collector_profile',
        meta: {
            auth: true,
            name: 'Editable'
        },
        component: () => import('../pages/price-collector-profile/List.vue')
    },
    {
        path: 'market',
        name: prop + 'market',
        meta: {
            auth: true,
            name: 'Editable'
        },
        component: () => import('../pages/market/List.vue')
    },
    {
        path: 'market-directory',
        name: prop + 'market_directory',
        meta: {
            auth: true, name: 'Editable'
        },
        component: () => import('../pages/market/Form.vue')
    },
    {
        path: 'user-privilege-form',
        name: prop + 'user_privilege_form',
        meta: {
            auth: true, name: 'Editable'
        },
        component: () => import('../pages/user-privilege/Form.vue')
    },
    {
        path: 'price-entry',
        name: prop + 'price_entry',
        meta: {
            auth: true,
            name: 'Editable'
        },
        component: () => import('../pages/price-entry/List.vue')
    },
    {
        path: 'price-entry-check-report',
        name: prop + 'price_entry_check_report',
        meta: {
            auth: true,
            name: 'Editable'
        },
        component: () => import('../pages/price-entry-check-report/Form.vue')
    },
    {
        path: 'price-entry-status-change',
        name: prop + 'price_entry_status_change',
        meta: {
            auth: true,
            name: 'Editable'
        },
        component: () => import('../pages/price-entry-status-change/Form.vue')
    },
    {
        path: 'price-entry-form',
        name: prop + 'price_entry_form',
        meta: {
            auth: true,
            name: 'Editable'
        },
        component: () => import('../pages/price-entry/Form.vue')
    },
    {
        path: 'price-entry-approve',
        name: prop + 'price_entry_approve',
        meta: {
            auth: true,
            name: 'Editable'
        },
        component: () => import('../pages/price-entry/Approve.vue')
    },
    {
        path: 'price-entry-view',
        name: prop + 'price_entry_view',
        meta: {
            auth: true,
            name: 'Editable'
        },
        component: () => import('../pages/price-entry/ApproveDetails.vue')
    },
    {
        path: 'comment-entry',
        name: prop + 'comment_entry',
        meta: {
            auth: true,
            name: 'Editable'
        },
        component: () => import('../pages/comment-entry/List.vue')
    }
]
const routes = [{
    path: '/agri-marketing/crop-price-info/configuration',
    name: 'agri-marketing.crop_price_info.configuration',
    component: () => import('@/layouts/AgriMarketingLayout.vue'),
    meta: { auth: false },
    children: childRoutes('agri-marketing.crop_price_info.configuration')
}]

export default routes
