export default {
  addCategoryDocumentList ({ commit }, payload) {
    commit('addCategoryDocumentList', payload)
  },
  toggleCategoryDocumentStatus ({ commit }, payload) {
    commit('toggleCategoryDocumentStatus', payload)
  },
  updateCategoryDocumentType ({ commit }, payload) {
    commit('updateCategoryDocumentType', payload)
  },
  addDocumentTypeList ({ commit }, payload) {
    commit('addDocumentTypeList', payload)
  },
  toggleDocumentStatus ({ commit }, payload) {
    commit('toggleDocumentStatus', payload)
  },
  ArchivetoggleDocumentStatus ({ commit }, payload) {
    commit('ArchivetoggleDocumentStatus', payload)
  },
  updateDocumentType ({ commit }, payload) {
    commit('updateDocumentType', payload)
  },
  addArchivedTypeList ({ commit }, payload) {
    commit('addArchivedTypeList', payload)
  },
  addCategoryDocumentShow ({ commit }, payload) {
    commit('addCategoryDocumentShow', payload)
  }
}
