export default {
  mutateCommonObj ({ commit }, payload) {
    commit('mutateCommonObj', payload)
  },
  changeCommonObjDropdown ({ commit }, payload) {
    commit('changeCommonObjDropdown', payload)
  },
  addReportHeading ({ commit }, payload) {
    commit('addReportHeading', payload)
  },
  addReportHeadingList ({ commit }, payload) {
    commit('addReportHeadingList', payload)
  },
  toggleReportHeadingStatus ({ commit }, payload) {
    commit('toggleReportHeadingStatus', payload)
  },
  updateReportHeading ({ commit }, payload) {
    commit('updateReportHeading', payload)
  },
  // Equipment Type
  addEquipmentType ({ commit }, payload) {
    commit('addEquipmentType', payload)
  },
  updateEquipmentType ({ commit }, payload) {
    commit('updateEquipmentType', payload)
  },
  addEquipmentTypeList ({ commit }, payload) {
    commit('addEquipmentTypeList', payload)
  },
  toggleEquipmentTypeStatus ({ commit }, payload) {
    commit('toggleEquipmentTypeStatus', payload)
  },
  // Scheme Type
  addSchemeType ({ commit }, payload) {
    commit('addSchemeType', payload)
  },
  updateSchemeType ({ commit }, payload) {
    commit('updateSchemeType', payload)
  },
  addSchemeTypeList ({ commit }, payload) {
    commit('addSchemeTypeList', payload)
  },
  toggleSchemeTypeStatus ({ commit }, payload) {
    commit('toggleSchemeTypeStatus', payload)
  },
  // Item Category
  addCategory ({ commit }, payload) {
    commit('addCategory', payload)
  },
  updateCategory ({ commit }, payload) {
    commit('updateCategory', payload)
  },
  addCategoryList ({ commit }, payload) {
    commit('addCategoryList', payload)
  },
  toggleCategoryStatus ({ commit }, payload) {
    commit('toggleCategoryStatus', payload)
  },
  // Item Sub-Category
  addSubCategory ({ commit }, payload) {
    commit('addSubCategory', payload)
  },
  updateSubCategory ({ commit }, payload) {
    commit('updateSubCategory', payload)
  },
  addSubCategoryList ({ commit }, payload) {
    commit('addSubCategoryList', payload)
  },
  toggleSubCategoryStatus ({ commit }, payload) {
    commit('toggleSubCategoryStatus', payload)
  },
  // Store Item
  addStoreItem ({ commit }, payload) {
    commit('addStoreItem', payload)
  },
  updateStoreItem ({ commit }, payload) {
    commit('updateStoreItem', payload)
  },
  addStoreItemList ({ commit }, payload) {
    commit('addStoreItemList', payload)
  },
  toggleStoreItemStatus ({ commit }, payload) {
    commit('toggleStoreItemStatus', payload)
  },
  // Pump Progress Type
  addPumpProgressType ({ commit }, payload) {
    commit('addPumpProgressType', payload)
  },
  addPumpProgressTypeList ({ commit }, payload) {
    commit('addPumpProgressTypeList', payload)
  },
  updatePumpProgressType ({ commit }, payload) {
    commit('updatePumpProgressType', payload)
  },
  updatePumpProgressTypeStatus ({ commit }, payload) {
    commit('updatePumpProgressTypeStatus', payload)
  },
  // Laboratory
  addLaboratory ({ commit }, payload) {
    commit('addLaboratory', payload)
  },
  updateLaboratory ({ commit }, payload) {
    commit('updateLaboratory', payload)
  },
  addLaboratoryList ({ commit }, payload) {
    commit('addLaboratoryList', payload)
  },
  toggleLaboratoryStatus ({ commit }, payload) {
    commit('toggleLaboratoryStatus', payload)
  },
  addPaymentTypeList ({ commit }, payload) {
    commit('addPaymentTypeList', payload)
  },
  togglePaymentTypeStatus ({ commit }, payload) {
    commit('togglePaymentTypeStatus', payload)
  },
  updatePaymentType ({ commit }, payload) {
    commit('updatePaymentType', payload)
  },
  addPayment ({ commit }, payload) {
    commit('addPayment', payload)
  },
  addPaymentList ({ commit }, payload) {
    commit('addPaymentList', payload)
  },
  togglePaymentStatus ({ commit }, payload) {
    commit('togglePaymentStatus', payload)
  },
  // SchemeFormAffidavit
  addSchemeFormAffidavit ({ commit }, payload) {
    commit('addSchemeFormAffidavit', payload)
  },
  addSchemeFormAffidavitList ({ commit }, payload) {
    commit('addSchemeFormAffidavitList', payload)
  },
  updateSchemeFormAffidavit ({ commit }, payload) {
    commit('updateSchemeFormAffidavit', payload)
  },
  toggleSchemeFormAffidavitStatus ({ commit }, payload) {
    commit('toggleSchemeFormAffidavitStatus', payload)
  },
  // Measurement Unit
  addMeasurementUnit ({ commit }, payload) {
    commit('addMeasurementUnit', payload)
  },
  addMeasurementUnitList ({ commit }, payload) {
    commit('addMeasurementUnitList', payload)
  },
  updateMeasurementUnit ({ commit }, payload) {
    commit('updateMeasurementUnit', payload)
  },
  updateMeasurementUnitStatus ({ commit }, payload) {
    commit('updateMeasurementUnitStatus', payload)
  },
  // Horse Power
  addHorsePower ({ commit }, payload) {
    commit('addHorsePower', payload)
  },
  addHorsePowerList ({ commit }, payload) {
    commit('addHorsePowerList', payload)
  },
  updateHorsePower ({ commit }, payload) {
    commit('updateHorsePower', payload)
  },
  updateHorsePowerStatus ({ commit }, payload) {
    commit('updateHorsePowerStatus', payload)
  },
  // circuleArea
  addCirculerArea ({ commit }, payload) {
    commit('addCirculerArea', payload)
  },
  addCirculerAreaList ({ commit }, payload) {
    commit('addCirculerAreaList', payload)
  },
  updateCircleArea ({ commit }, payload) {
    commit('updateCircleArea', payload)
  },
  CircleToggleStatus ({ commit }, payload) {
    commit('CircleToggleStatus', payload)
  }
}
