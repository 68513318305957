const childRoute = (prop) => [
  {
    path: 'document-category-list',
    name: prop + '.document-category.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/document-category/List.vue')
  },
  {
    path: 'add-document-list',
    name: prop + '.add-document.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/add-document/List.vue')
  },
  {
    path: 'document-indexing-list',
    name: prop + '.document-indexing.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/document-indexing/List.vue')
  },
  {
    path: 'document-archived-list',
    name: prop + '.document-archived.list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/document-archived/List.vue')
  }
]
const routes = [
  {
    path: '/document',
    name: 'document',
    component: () => import('../../../../layouts/VerticleLayout.vue'),
    meta: { auth: true },
    children: childRoute('document')
  }
]

export default routes
