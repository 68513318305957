const childRoute = (prop) => [
  {
    path: 'lc-create',
    name: prop + '.lc-create',
    meta: { auth: true },
    component: () => import('../pages/lc-create/List.vue')
  },
  {
    path: 'purchase-requisition',
    name: prop + '.purchase-requisition',
    meta: { auth: true },
    component: () => import('../pages/purchase-requisition/List.vue')
  },
  {
    path: 'purchase-requisition-approve',
    name: prop + '.purchase-requisition-approve',
    meta: { auth: true },
    component: () => import('../pages/purchase-requisition-approve/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/fertilizer-procurement',
    name: 'seeds_fertilizer_service.fertilizer_procurement',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.fertilizer-procurement')
  }
]

export default routes
