export default {
  // create Committee
  addCreateCommittee ({ commit }, payload) {
    commit('addCreateCommittee', payload)
  },
  updateCreateCommittee ({ commit }, payload) {
    commit('updateCreateCommittee', payload)
  },
  addCreateCommitteeList ({ commit }, payload) {
    commit('addCreateCommitteeList', payload)
  },
  toggleCreateCommitteeStatus ({ commit }, payload) {
    commit('toggleCreateCommitteeStatus', payload)
  },
  // Manage Agenda
  addManageAgenda ({ commit }, payload) {
    commit('addManageAgenda', payload)
  },
  updateManageAgenda ({ commit }, payload) {
    commit('updateManageAgenda', payload)
  },
  addManageAgendaList ({ commit }, payload) {
    commit('addManageAgendaList', payload)
  },
  deleteManageAgenda ({ commit }, payload) {
    commit('deleteManageAgenda', payload)
  },
  // Meeting Minute
  addMeetingMinute ({ commit }, payload) {
    commit('addMeetingMinute', payload)
  },
  updateMeetingMinute ({ commit }, payload) {
    commit('updateMeetingMinute', payload)
  },
  addMeetingMinuteList ({ commit }, payload) {
    commit('addMeetingMinuteList', payload)
  },
  deleteMeetingMinute ({ commit }, payload) {
    commit('deleteMeetingMinute', payload)
  },
  // Manage Expenses
  addManageExpenses ({ commit }, payload) {
    commit('addManageExpenses', payload)
  },
  updateManageExpenses ({ commit }, payload) {
    commit('updateManageExpenses', payload)
  },
  addManageExpensesList ({ commit }, payload) {
    commit('addManageExpensesList', payload)
  },
  deleteManageExpenses ({ commit }, payload) {
    commit('deleteManageExpenses', payload)
  }
}
