const childRoutes = (prop) => [
    {
        path: 'purchase-register',
        name: prop + 'purchase_register',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/purchase-register/List.vue')
    },
    {
        path: 'purchase-register-form',
        name: prop + 'purchase_register_form',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/purchase-register/Form.vue')
    },
    {
        path: 'purchase-request',
        name: prop + 'purchase_request',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/purchase-request/List.vue')
    },
    {
        path: 'purchase-approve',
        name: prop + 'purchase_approve',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/purchase-approve/List.vue')
    },
    {
        path: 'stock-register',
        name: prop + 'stock_register',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/stock-register/List.vue')
    },
    {
        path: 'stock-list',
        name: prop + 'stock_list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/stock-list/List.vue')
    }
]

const routes = [
    {
        path: '/incentive-grant-service/dae/purchase-inventory',
        name: 'incentive_grant_service.dae.purchase_inventory',
        component: () => import('@/layouts/IncentiveGrantLayout.vue'),
        meta: { auth: true },
        children: childRoutes('incentive_grant_service.dae.purchase_inventory')
    }
]

export default routes
