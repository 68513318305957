import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'

const state = {
    NewApplicationRequests: [],
    ReissueApplicationRequests: [],
    SmartCardApproves: [],
    SmartCards: [],
    smartCardApplicationLists: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
