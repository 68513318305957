const childRoutes = (prop) => [
    {
      path: 'expertise',
      name: prop + 'expertise',
      meta: { auth: true },
      component: () => import('../pages/expertise/List.vue')
    },
    {
      path: 'training-type',
      name: prop + 'training_type',
      meta: { auth: true },
      component: () => import('../pages/training-type/List.vue')
    },
    {
      path: 'training-category',
      name: prop + 'training_category',
      meta: { auth: true },
      component: () => import('../pages/training-category/List.vue')
    },
    {
      path: 'venue-management-list',
      name: prop + 'venue_management_list',
      meta: { auth: true },
      component: () => import('../pages/venue-management-list/List.vue')
    },
    {
      path: 'training-title',
      name: prop + 'training_title',
      meta: { auth: true },
      component: () => import('../pages/training-title/List.vue')
    },
    {
      path: 'guest-house',
      name: prop + 'guest_house',
      meta: { auth: true },
      component: () => import('../pages/guest-house/List.vue')
    },
    {
      path: 'room-type',
      name: prop + 'room_type',
      meta: { auth: true },
      component: () => import('../pages/room-type/List.vue')
    },
    {
      path: 'room-rent',
      name: prop + 'room_rent',
      meta: { auth: true },
      component: () => import('../pages/room-rent/List.vue')
    },
    {
      path: 'pay-grade-setup',
      name: prop + 'pay_grade_setup',
      meta: { auth: true },
      component: () => import('../pages/pay-grade-setup/List.vue')
    },
    {
      path: 'course-document-setup',
      name: prop + 'course_document_setup',
      meta: { auth: true },
      component: () => import('../pages/course-document-setup/List.vue')
    },
    {
      path: 'room-no-setup',
      name: prop + 'room_no_setup',
      meta: { auth: true },
      component: () => import('../pages/room-no-setup/List.vue')
    },
    {
      path: 'faq-setup',
      name: prop + 'faq_setup',
      meta: { auth: true },
      component: () => import('../pages/faq-setup/List.vue')
    },
    {
      path: 'training-quarter-setup',
      name: prop + 'training_quarter_setup',
      meta: { auth: true },
      component: () => import('../pages/training-quarter-setup/List.vue')
    },
    {
      path: 'food-package',
      name: prop + 'food_package',
      meta: { auth: true },
      component: () => import('../pages/food-package/List.vue')
    },
    {
      path: 'trainee-evaluation',
      name: prop + 'trainee_evaluation',
      meta: { auth: true },
      component: () => import('../pages/trainee-evaluation/List.vue')
    },
    {
      path: 'trainer-evaluation',
      name: prop + 'trainer_evaluation',
      meta: { auth: true },
      component: () => import('../pages/trainer-evaluation/List.vue')
    },
    {
      path: 'report-heading',
      name: prop + 'report_heading',
      meta: { auth: true },
      component: () => import('../pages/report-heading/List.vue')
    },
    {
      path: 'grading-status',
      name: prop + 'grading_status',
      meta: { auth: true },
      component: () => import('../pages/grading-status/List.vue')
    },
    {
      path: 'received-notifications',
      name: prop + 'received_notifications',
      meta: { auth: true },
      component: () => import('../pages/received-notifications/List.vue')
    },
    {
      path: 'schedule-setup',
      name: prop + 'schedule_setup',
      meta: { auth: true },
      component: () => import('../pages/schedule-setup/List.vue')
    },
    {
      path: 'venue-condition',
      name: prop + 'venue_condition',
      meta: { auth: true },
      component: () => import('../pages/venue-condition/List.vue')
    },
    {
      path: 'committee',
      name: prop + 'committee',
      meta: { auth: true },
      component: () => import('../pages/committee/List.vue')
    },
    {
      path: 'training-calendar',
      name: prop + 'committee',
      meta: { auth: true },
      component: () => import('../pages/training-calendar/List.vue')
    },
    {
      path: 'training-calendar-form',
      name: prop + 'committee',
      meta: { auth: true },
      component: () => import('../pages/training-calendar/Form.vue')
    },
    {
      path: 'guest-trainer',
      name: prop + 'guest_trainer',
      meta: { auth: true },
      component: () => import('../pages/guest-trainer/List.vue')
    },
    {
      path: 'driver',
      name: prop + 'driver',
      meta: { auth: true },
      component: () => import('../pages/driver/List.vue')
    },
    {
      path: 'driver-form',
      name: prop + 'driver',
      meta: { auth: true },
      component: () => import('../pages/driver/Form.vue')
    },
    {
      path: 'duplicate-certificate-fee',
      name: prop + 'duplicate_certificate_fee',
      meta: { auth: true },
      component: () => import('../pages/duplicate-certificate-fee/List.vue')
    },
    {
      path: 'other-trainee-type',
      name: prop + 'other_trainee_type',
      meta: { auth: true },
      component: () => import('../pages/other-trainee-type/List.vue')
    }
]

const routes = [
    {
      path: '/training-e-learning-service/configuration',
      name: 'training_e_learning_service.configuration',
      component: () => import('@/layouts/TrainingElearningLayout.vue'),
      meta: { auth: true },
      children: childRoutes('training_e_service.barc.configuration')
    }
]

export default routes
