const mainRoutes = (prop) => [
  {
    path: 'dashboard',
    name: prop + 'dashboard',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/Dashboard.vue')
  },
  {
      path: 'content-review-and-sorting',
      name: prop + 'content_review_and_sorting',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/content-review-and-sorting/List.vue')
  },
  {
    path: 'subscriber-list',
    name: prop + 'subscriber_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/subscriber-list/List.vue')
  },
  {
    path: 'circular-publication',
    name: prop + 'circular_publication',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/circular-publication/List.vue')
  },
  {
    path: 'advertisement-content',
    name: prop + 'advertisement_content',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/advertisement-content/List.vue')
  },
  {
    path: 'content-publication-approval',
    name: prop + 'content_publication_approval',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/content-publication-approval/List.vue')
  },
  {
    path: 'publication-library',
    name: prop + 'publication_library',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/publication-library/List.vue')
  },
  {
    path: 'publication-upload',
    name: prop + 'publication_upload',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/publication-upload/List.vue')
  },
  {
    path: 'agencies-stakeholder-enlistment',
    name: prop + 'agencies_stakeholder_enlistment',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/agencies-stakeholder-enlistment/List.vue')
  }
]

const childConfigRoutes = (prop) => [
    {
        path: 'ais-region',
        name: prop + 'ais_region',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/ais-region/List.vue')
    },
    {
      path: 'publication-type',
      name: prop + 'publication_type',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/configuration/publication-type/List.vue')
  },
    {
        path: 'publication-title',
        name: prop + 'publication_title',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/configuration/publication-title/List.vue')
    },
    {
      path: 'content-area',
      name: prop + 'content_area',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/configuration/content-area/List.vue')
    },
    {
      path: 'publication-fee',
      name: prop + 'publication_fee',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/configuration/publication-fee/List.vue')
    }
]

const childReportRoutes = (prop) => [
  {
      path: 'honorariam-report',
      name: prop + 'honorariam_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/reports/honorariam-report/List.vue')
  },
  {
    path: 'subscriber-report',
    name: prop + 'subscriber_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/reports/subscriber-report/List.vue')
  },
  {
    path: 'agency-stakeholder-report',
    name: prop + 'agency_stakeholder_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/reports/agency-stakeholder-report/List.vue')
  },
  {
    path: 'publication-report',
    name: prop + 'publication_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/reports/publication-report/List.vue')
  }
]
const routes = [
  {
    path: '/agri-research-service/publication-management/configuration',
    name: 'agri_research_service.publication_management.configuration',
    component: () => import('@/layouts/AgriResearchLayout.vue'),
    meta: { auth: true },
    children: childConfigRoutes('agri_research_service.publication_management.configuration')
  },
  {
    path: '/agri-research-service/publication-management/report',
    name: 'agri_research_service.publication_management.report',
    component: () => import('@/layouts/AgriResearchLayout.vue'),
    meta: { auth: true },
    children: childReportRoutes('agri_research_service.publication_management.report')
  },
  {
    path: '/agri-research-service/publication-management',
    name: 'agri_research_service.publication_management',
    component: () => import('@/layouts/AgriResearchLayout.vue'),
    meta: { auth: true },
    children: mainRoutes('agri_research_service.publication_management')
  }
]

export default routes
