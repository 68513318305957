import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'
import Custom from './custom'

const state = {
  commonObj: {
    hasDropdownLoaded: false,
    perPage: 10,
    dateFormat: 'dd/mm/YYYY',
    timeFormat: 'h:m',
    loading: false,
    listReload: false,
    test: [],
    informationTypeList: [],
    cropTypeList: [],
    cropList: [],
    characteristicsTypeList: [],
    characteristicsList: [],
    soilTypeList: [],
    insectList: [],
    diseaseList: [],
    tourOperator: [],
    pestList: [],
    organizationProfileList: [],
    pestType: Custom.pestType
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
