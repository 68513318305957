import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import createPersistedState from 'vuex-persistedstate'
import Setting from './Setting/index'
import Custom from './custom'
import Auth from '../modules/auth-service/auth/store'
import Portal from '../modules/portal/store'
// import PortalMarket from '../modules/portal/pages/market-directory/store'
import Complain from '../modules/common-config/complain/store'
import Notification from '../modules/common-config/notification/store'
import IrriConfig from '../modules/irrigation-scheme-service/configuration/store'
import ExternalUserIrrigation from '../modules/external-user-service/irrigation/store'
import ExternalUserAuth from '../modules/external-user-service/auth/store'
import IrriPumpInstall from '../modules/irrigation-scheme-service/pump-installation/store'
import IrriPumpInoformation from '../modules/irrigation-scheme-service/pump-information/store'
import FarmerOperatorPanel from '../modules/irrigation-scheme-service/farmer-operator-panel/store'
import IrriTask from '../modules/irrigation-scheme-service/task/store'
import IrriPumpMaintenance from '../modules/irrigation-scheme-service/pump-maintenance/store'
import IrriWaterTesting from '../modules/irrigation-scheme-service/water-testing/store'
import Document from '../modules/common-config/document/store'
import WareHouseConfig from '../modules/warehouse-service/configuration/store'
import WarehouseInfo from '../modules/warehouse-service/information/store'
import WarehouseServicePerformance from '../modules/warehouse-service/service-performance/store'
import WarehouseInfoService from '../modules/warehouse-service/info-service/store'
import WarehouseWarehouseManagement from '../modules/warehouse-service/warehouse-management/store'
import WarehouseService from '../modules/warehouse-service/ware-service/store'
import WarehouseReport from '../modules/warehouse-service/report/store'
import Committee from '../modules/common-config/committee/store'
import CardPayment from '../modules/irrigation-scheme-service/card-payment/store'
import SeedsFertilizer from '../modules/seeds-fertilizer-service/store'

// agri marketing Service Started from here
import agriMarketing from '../modules/agri-marketing/store'
// Incentive Grant Service Started from here
import incentiveGrant from '../modules/incentive-grant-service/store'
import TrainingElearning from '../modules/training-e-learning-service/store'
import VirtualMuseum from '../modules/virtual-museum-service/store'
import ExternalUserTraining from '../modules/external-user-service/training/store'
// License Registration Service Started from here
import licenseRegistration from '../modules/license-registration-service/store'
import ExternalAgriResearch from '../modules/external-user-service/agri-research/store'
import ExternalIncentiveFarmer from '../modules/external-user-service/incentive/store'
import ExternalLrcpn from '../modules/external-user-service/lrcpn/store'
import AgriResearch from '../modules/agri-research/store'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Setting,
    Portal,
    // PortalMarket,
    Auth,
    Complain,
    Notification,
    IrriConfig,
    IrriTask,
    IrriWaterTesting,
    ExternalUserIrrigation,
    ExternalUserAuth,
    IrriPumpInstall,
    Document,
    WareHouseConfig,
    Committee,
    CardPayment,
    IrriPumpInoformation,
    IrriPumpMaintenance,
    FarmerOperatorPanel,
    WarehouseInfo,
    WarehouseService,
    WarehouseReport,
    WarehouseServicePerformance,
    WarehouseInfoService,
    WarehouseWarehouseManagement,
    SeedsFertilizer,
    agriMarketing,
    incentiveGrant,
    TrainingElearning,
    VirtualMuseum,
    ExternalUserTraining,
    licenseRegistration,
    ExternalIncentiveFarmer,
    ExternalLrcpn,
    AgriResearch,
    ExternalAgriResearch
  },
  state: {
    list: [],
    notificationList: [],
    totalNotification: 0,
    isPushNotification: [],
    toggleNotification: false,
    currentFiscalYearId: 0,
    commonObj: {
      hasDropdownLoaded: false,
      perPage: 10,
      dateFormat: 'DD/MM/YYYY',
      fiscaleYear: 'YYYY-YYYY',
      timeFormat: 'h:m',
      unitOfTime: 'day',
      loading: false,
      listReload: false,
      countryList: [],
      divisionList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      cityCorporationList: [],
      pauroshobaList: [],
      wardList: [],
      organizationProfileList: [],
      portalServiceCategoryList: [],
      portalServiceCustomerTypeList: [],
      moduleList: [],
      officeTypeList: [],
      noticeforList: Custom.noticeForList,
      comStatusList: Custom.statusList,
      refundByList: Custom.refundByList,
      paymentTypeList: Custom.paymentTypeList,
      testingTypeList: Custom.testingTypeList,
      waterTestingStatusList: Custom.waterTestingStatusList,
      waterTestingParameters: Custom.waterTestingParameters,
      irrigationTaskTypeList: Custom.irrigationTaskTypeList,
      maritalStatus: Custom.maritalStatus,
      genderList: Custom.genderList,
      genderListEx: Custom.genderListEx,
      smartCardStatus: Custom.smartCardStatus,
      reissueStatus: Custom.reissueStatus,
      paymentApplicationTypeList: Custom.paymentApplicationTypeList,
      paymentSchemeBADCPaymentTypeList: Custom.paymentSchemeBADCPaymentTypeList,
      paymentSchemeBMDAPaymentTypeList: Custom.paymentSchemeBMDAPaymentTypeList,
      schemePaymentList: Custom.schemePaymentList,
      paymetPumpOperationPaymentTypeList: Custom.paymetPumpOperationPaymentTypeList,
      paymetSmartCardPaymentTypeList: Custom.paymetSmartCardPaymentTypeList,
      paymetWaterTestingPaymentTypeList: Custom.paymetWaterTestingPaymentTypeList,
      smartCardStatusList: Custom.smartCardStatusList,
      smartCardApplicationTypeList: Custom.smartCardApplicationTypeList,
      directoryTypeList: Custom.directoryTypeList,
      wardTypeList: Custom.wardTypeList,
      activeIntactive: Custom.activeIntactive,
      pumpOptAppStatus: Custom.pumpOptAppStatus,
      officeList: [],
      designationList: [],
      masterDesignationList: [],
      gradeList: [],
      bankObj: { componentId: null, bankList: [], branchList: [] },
      notifications: [],
      fiscalYearList: [],
      growerBuyer: Custom.growerBuyer,
      monthList: Custom.monthList,
      yearList: Custom.yearList,
      banglaMonthList: Custom.banglaMonthList
    },
    commonObjCommonConfig: {
      hasDropdownLoaded: false,
      componentList: [],
      reportTypes: Custom.reportTypes,
      formTypes: Custom.formTypes,
      moduleList: [],
      serviceList: [],
      serviceComList: [],
      menuList: [],
      notificationTypeList: [],
      cmtCommitteeList: [],
      cmtAgendaList: [],
      bankList: [],
      cityCorporationList: [],
      pauroshobaList: [],
      documentCategoryList: [],
      branchList: [],
      wardList: [],
      serviceEligibiltyList: [],
      buttonTypeList: Custom.buttonTypeList,
      mediaList: Custom.mediaList,
      recipientList: Custom.recipientList,
      messageTemplateList: []
    },
    warehouse: {
        hasDropdownLoaded: false,
        perPage: 10,
        dateFormat: 'dd/mm/YYYY',
        timeFormat: 'h:m',
        loading: false,
        listReload: false,
        commodityGroupList: [],
        commodityNameList: [],
        fiscalYearList: [],
        regionList: [],
        warehouseDesignationList: [],
        warehouseTypeList: [],
        warehouseInfoList: [],
        warehouseLevelList: [],
        warehouseCategoryList: [],
        serviceTypeList: [],
        marketInfoList: []
    },
    orgList: [],
    orgComponentList: [],
    dataFilters: {
      orgId: 0,
      officeTypeId: 0,
      officeId: 0
    }
  },
  actions,
  mutations,
  getters,
  plugins: [createPersistedState()],
  strict: debug
})
