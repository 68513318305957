const childRoute = (prop) => [
  {
    path: 'test',
    name: prop + '.test',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/test/List.vue')
  },
  {
    path: 'ware-information-report',
    name: prop + '.ware-information-report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ware-information-report/List.vue')
  },
  {
    path: 'Warehouse-Wise-Product-Received-And-Delivery-Report',
    name: prop + '.ware-product-rec-del-report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ware-product-rec-del-report/List.vue')
  },
  {
    path: 'ware-product-storage-report',
    name: prop + '.ware-product-storage-report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ware-product-storage-report/List.vue')
  },
  // yearly product recieve and delivery report
  {
    path: 'Yearly-Product-Received-And-Delivery-Report',
    name: prop + '.yearly-product-rec-del-report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/yearly-product-rec-del-report/List.vue')
  },
  // month wise product recieve report
  {
    path: 'Month-Wise-Product-Received-Report',
    name: prop + '.month-wise-product-recieve-report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/month-wise-product-recieve-report/List.vue')
  },
  // warehouse wise farmer information report
  {
    path: 'Warehouse-Wise-Farmer-Information-Report',
    name: prop + '.warehouse-wise-farmer-information-report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/warehouse-wise-farmer-information-report/List.vue')
  },
  // Date Wise Farmer’s Stock/Release Report
  {
    path: 'date-wise-farmer-stock-release-report',
    name: prop + '.date-wise-farmer-stock-release-report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/date-wise-farmer-stock-release-report/List.vue')
  },
  // Warehouse Rent Collection Report
  {
    path: 'warehouse-rent-collection-report',
    name: prop + '.wareHouseRentCollectionReport',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/warehouse-rent-collection-report/index.vue')
  },
  {
    path: 'ware-details-report',
    name: prop + '.ware-details-report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ware-details-report/List.vue')
  },
  {
    path: 'product-stock-report',
    name: prop + '.productStockReport',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/product-stock-report/index.vue')
  }
]

const routes = [
  {
    path: '/warehouse/report',
    name: 'warehouse.report',
    component: () => import('../../../../layouts/WarehouseLayout.vue'),
    meta: { auth: true },
    children: childRoute('warehouse.report')
  }
]

export default routes
