const routes = [
  {
    path: '/organogram',
    name: 'organogram',
    component: () => import('../../../../layouts/VerticleLayout.vue'),
    meta: { auth: true },
    children: [
      {
        path: 'assign-designation-list',
        name: 'assign-designation-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/assign-designation/List.vue')
      },
      {
        path: 'designation-list',
        name: 'designation-list',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/designation/List.vue')
      },
      {
        path: 'organogram-list',
        name: 'organogram-list',
        meta: { auth: false, name: 'Editable' },
        component: () => import('../pages/Organogram/List.vue')
      }
    ]
  }
]

export default routes
