export default {
  mutateCommonObj ({ commit }, payload) {
    commit('mutateCommonObj', payload)
  },
  mutateAgriMarketingProperties ({ commit }, payload) {
      commit('mutateAgriMarketingProperties', payload)
  },
  changeAgriMarketingDropdown ({ commit }, payload) {
      commit('changeAgriMarketingDropdown', payload)
  },
  mutateDataDamLocale ({ commit }, payload) {
    commit('mutateDataDamLocale', payload)
  }
}
