export const mutations = {
  // test
  addTestList (state, payload) {
    state.tests.push(payload)
  },
  updateTestStatus (state, payload) {
    state.tests = state.test.map(function (item) {
      if (item.id === payload.id) {
        payload.status = !payload.status
        return payload
      }

      return item
    })
  },
  addWarehouseServiceList (state, warehouseService) {
    state.warehouseServices = warehouseService
  },
  updateWarehousServiceStatus (state, warehouseService) {
    state.warehouseServices = state.warehouseServices.map(function (item) {
      if (item.id === warehouseService.id) {
        warehouseService.status = !warehouseService.status
        return warehouseService
      }

      return item
    })
  },
  addWarehouseBankLoanFacilityList (state, warehouseBankLoanFacility) {
    state.warehouseBankLoanFacilitys = warehouseBankLoanFacility
  },
  updateWarehousBankLoanFacilityStatus (state, warehouseBankLoanFacility) {
    state.warehouseBankLoanFacilitys = state.warehouseBankLoanFacilitys.map(function (item) {
      if (item.id === warehouseBankLoanFacility.id) {
        warehouseBankLoanFacility.status = !warehouseBankLoanFacility.status
        return warehouseBankLoanFacility
      }

      return item
    })
  }
}
