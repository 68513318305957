const routes = [{
    path: '/license-registration-service/configuration/',
    name: 'license-registration_service.configuration',
    component: () => import('@/layouts/LicenseRegistrationLayout.vue'),
    meta: { auth: true },
    children: [
        {
            path: 'fee-name',
            name: 'fee_name',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/fee-name/List.vue')
        },
        {
            path: 'crop-entry',
            name: 'crop-entry',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/crop-entry/List.vue')
        },
        {
            path: 'market',
            name: 'market',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/market-name/List.vue')
        },
        {
            path: 'service-name',
            name: 'service_name',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/service-name/List.vue')
        },
        {
            path: 'region',
            name: 'region',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/region/List.vue')
        },
        {
            path: 'badc-region',
            name: 'badc_region',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/badc-region/List.vue')
        },
        {
            path: 'badc-fertilizer-region',
            name: 'badc_fertilizer_region',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/badc-fertilizer-region/List.vue')
        },
        {
            path: 'editable-datas',
            name: 'editable_datas',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/editable-datas/List.vue')
        },
        {
            path: 'step-name',
            name: 'step_name',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/step-name/List.vue')
        },
        {
            path: 'step-assign',
            name: 'step_assign',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/step-assign/List.vue')
        },
        {
            path: 'service-precondition',
            name: '/service_precondition',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/service-precondition/List.vue')
        },
        {
            path: 'service-precondition-form',
            name: '/service_precondition_form',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/service-precondition/Form.vue')
        },
        {
            path: 'step-fee-assign',
            name: 'step_fee_assign',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/step-fee-assign/List.vue')
        },
        {
            path: 'dynamic-form',
            name: 'dynamic_form',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/dynamic-form/List.vue')
        },
        {
            path: 'step-form-view',
            name: 'step_form_view',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/dynamic-form-view/List.vue')
        },
        {
            path: 'create-view-form',
            name: 'create_view_form',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/dynamic-form-view/createForm.vue')
        },
        {
            path: 'step-form-details',
            name: 'step_form_details',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/dynamic-form-view/Details.vue')
        },
        {
            path: 'create-form',
            name: 'create_form',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/dynamic-form/createForm.vue')
        },
        {
            path: 'create-form/:id',
            name: 'create_form',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/dynamic-form/createForm.vue')
        },
        {
            path: 'dynamic-form-view',
            name: 'dynamic_form_view',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/dynamic-form/viewForm.vue')
        },
        {
            path: 'service-wise-office-type',
            name: 'service_wise_office_type',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/service-wise-office-type/List.vue')
        },
        {
            path: 'application-form',
            name: 'application_form',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/application-form/List.vue')
        },
        {
            path: 'application-form-view',
            name: 'application_form_view',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/application-form/FormView.vue')
        },
        {
            path: 'form-assign',
            name: 'form_assign',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/form-assign/List.vue')
        },
        {
            path: 'menu-assign',
            name: 'menu_assign',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/menu-assign/List.vue')
        },
        {
            path: 'step-process',
            name: 'step_process',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/step-process/List.vue')
        },
        {
            path: 'step-process-form',
            name: 'step_process_form',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/step-process/Form.vue')
        },
        {
            path: 'report-process',
            name: 'report_process',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/report-process/List.vue')
        },
        {
            path: 'report-process-form',
            name: 'report_process_form',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/report-process/Form.vue')
        },
        {
            path: 'document-builder',
            name: 'document_builder',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/document-builder/List.vue')
        },
        {
            path: 'document-builder-create-form',
            name: 'document-builder_create_form',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/document-builder/createForm.vue')
        },
        {
            path: 'document-builder-create-form/:id',
            name: 'document-builder_create_form',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/document-builder/createForm.vue')
        },
        {
            path: 'document-builder-view',
            name: 'document_builder_view',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/document-builder/viewForm.vue')
        },
        {
            path: 'document-type',
            name: 'document_type',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/document-type/List.vue')
        },
        {
            path: 'auto-gen-num-type',
            name: 'auto_gen_num_type',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/auto-gen-num-type/List.vue')
        },
        {
            path: 'dashboard-card',
            name: 'dashboard_card',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/dashboard-card/List.vue')
        },
        {
            path: 'report-heading',
            name: 'report_heading',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/report-heading/List.vue')
        },
        {
            path: 'renew-lost-fee',
            name: 'renew_lost_fee',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/renew-lost-fee/List.vue')
        },
        {
            path: 'report-menu-assign',
            name: 'report_menu_assign',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/report-menu-assign/List.vue')
        },
        {
            path: 'lab-entry',
            name: 'lab_entry',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/lab-entry/List.vue')
        },
        {
            path: 'active-ingredient',
            name: 'active_ingredient',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/active-ingredient/List.vue')
        },
        {
            path: 'dam-config/agri-product-type',
            name: 'agri_product_type',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/agricultural-product-type/List.vue')
        },
        {
            path: 'dam-config/agri-product',
            name: 'agri_product_type',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/agricultural-product/List.vue')
        },
        {
            path: 'dam-config/fertilizer-name',
            name: 'fertilizer_name',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/dae-fertilizer-name/List.vue')
        },
        {
            path: 'org-notes',
            name: 'org_notes',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/org-notes/List.vue')
        },
        {
            path: 'form-field-assign',
            name: 'form_field_assign',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/form-field-assign/List.vue')
        },
        {
            path: 'form-field-assign-form',
            name: 'form_field_assign-form',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/form-field-assign/Form.vue')
        },
        {
            path: 'received-notifications',
            name: 'received_notifications',
            meta: { auth: true },
            component: () => import('../pages/received-notifications/List.vue')
        },
        {
            path: 'notice',
            name: 'notice',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/notice/List.vue')
        },
        {
            path: 'ekpay-manage',
            name: 'ekpay_manage',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/ekpay-manage/List.vue')
        },
        {
            path: 'dae-company-history',
            name: 'dae_company_history',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/dae-company-history/List.vue')
        },
        {
            path: 'ekpay-challan',
            name: 'ekpay_challan',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/ekpay-challan/List.vue')
        }
    ]
}]

export default routes
