const childRoutes = (prop) => [
  {
    path: 'enlisted-university',
    name: prop + 'enlisted_university',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/enlisted-university/List.vue')
  },
  {
    path: 'required-score-setup',
    name: prop + 'required_score_setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/required-score-setup/List.vue')
  },
  {
    path: 'approval-committee-setup',
    name: prop + 'approval_committee_setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/approval-committee-setup/List.vue')
  },
  {
    path: 'self-score-assessment-education',
    name: prop + 'self_score_assessment_education',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/self-score-assessment-education/List.vue')
  },
  {
    path: 'self-score-assessment-others',
    name: prop + 'self_score_assessment_others',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/self-score-assessment-others/List.vue')
  },
  {
    path: 'self-score-assessment-others-form',
    name: prop + 'self_score_assessment_others_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/self-score-assessment-others/Form.vue')
  },
  {
    path: 'self-score-assessment-others-form/:id',
    name: prop + 'self_score_assessment_others_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/self-score-assessment-others/Form.vue')
  },
  {
    path: 'user-setup-approval-committee',
    name: prop + 'user_setup_approval_committee',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/user-setup-approval-committee/List.vue')
  },
  {
    path: 'grant-setup',
    name: prop + 'grant_setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/grant-setup/List.vue')
  },
  {
    path: 'budget-policy-setup',
    name: prop + 'budget_policy_setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/budget-polict-setup/List.vue')
  },
  {
    path: 'publication-type',
    name: prop + 'publication-type',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/publication-type/List.vue')
  },
  {
    path: 'item-budget-policy-setup',
    name: prop + 'item-budget_policy_setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/item-budget-policy-setup/List.vue')
  },
  {
    path: 'application-name-setup',
    name: prop + 'application_name_setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/application-name-setup/List.vue')
  },
  {
    path: 'application-criteria-setup',
    name: prop + 'application_criteria_setup',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/application-criteria-setup/List.vue')
  },
  {
    path: 'circular-information',
    name: prop + 'circular_information',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/circular-information/List.vue')
  },
  {
    path: 'fund-sub-head',
    name: prop + 'fund_sub_head',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fund-sub-head/List.vue')
  },
  {
    path: 'nars-institute-info',
    name: prop + 'nars_institute_info',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/nars-institute-info/List.vue')
  }
]

const routes = [
  {
    path: '/incentive-grant-service/barc/configuration',
    name: 'incentive_grant_service.barc.configuration',
    component: () => import('@/layouts/IncentiveGrantLayout.vue'),
    meta: { auth: true },
    children: childRoutes('incentive_grant_service.barc.configuration')
  }
]

export default routes
