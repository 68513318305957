const childRoutes = (prop) => [
  {
    path: 'attachment',
    name: prop + 'attachment',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/attachment/List.vue')
  },
  {
    path: 'supplier-info',
    name: prop + 'supplier-info',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/supplier-info/List.vue')
  },
  {
    path: 'machine-type',
    name: prop + 'machine_type',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/machine-type/List.vue')
  },
  {
    path: 'circular-create',
    name: prop + 'circular)create',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/circular-setup/List.vue')
  },
  {
    path: 'brand-entry',
    name: prop + 'brand_entry',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/brand-entry/List.vue')
  },
  {
    path: 'farmer-info',
    name: prop + 'farmer_info',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/farmer-info/List.vue')
  },
  {
    path: 'distribution-import-existing',
    name: prop + 'distribution_import_existing',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/distribution-import-existing/List.vue')
  },
  {
    path: 'supplier-machine-assign',
    name: prop + 'supplier_machine_assign',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/supplier-machine-assign/List.vue')
  }
]

const routes = [
  {
    path: '/incentive-grant-service/farm-machinery/configuration',
    name: 'incentive_grant_service.farm_machinery.configuration',
    component: () => import('@/layouts/IncentiveGrantLayout.vue'),
    meta: { auth: true },
    children: childRoutes('incentive_grant_service.farm_machinery.configuration')
  }
]

export default routes
