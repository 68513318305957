const childRoutes = (prop) => [
    {
        path: 'visit-request',
        name: prop + '.visit_request',
        component: () => import('../pages/visit-request/List')
    },
    {
        path: 'visit-request-review',
        name: prop + '.visit_request_review',
        component: () => import('../pages/visit-request/Review-List')
    },
    {
        path: 'visit-request-approved',
        name: prop + '.visit_request_approved',
        component: () => import('../pages/visit-request/Approve-List')
    },
    {
        path: 'visit-request-visited',
        name: prop + '.visit_request_visited',
        component: () => import('../pages/visit-request/Visited-List')
    },
    {
        path: 'visit-request-rejected',
        name: prop + '.visit_request_rejected',
        component: () => import('../pages/visit-request/Reject-List')
    },
    {
        path: 'feedback-and-review',
        name: prop + '.feedback_and_review',
        component: () => import('../pages/feedback-and-review/List')
    },
    {
        path: 'reports',
        name: prop + '.reports',
        component: () => import('../pages/reports/List')
    }
]
const routes = [
    {
      path: '/virtual-museum-service/tour-management/',
      name: 'virtual_museum_service.tour_management',
      component: () => import('@/layouts/VirtualMuseumLayout.vue'),
      children: childRoutes('virtual_museum_service.tour_management'),
      meta: { auth: true }
    }
]

export default routes
