const childRoutes = (prop) => [
  // {
  //     path: 'test',
  //     name: prop + '.test',
  //     component: () => import('../pages/test/List')
  // },
  // {
  //     path: 'test',
  //     name: prop + '.test',
  //     component: () => import('../pages/test/List')
  // },
  {
    path: 'information-type',
    name: prop + '.information_type',
    component: () => import('../pages/information-type/List')
  },
  {
    path: 'virtual-krishibid',
    name: prop + '.virtual_krishibid',
    component: () => import('../pages/virtual-krishibid/List')
  },
  {
    path: 'visiting-hour',
    name: prop + '.visiting_hour',
    component: () => import('../pages/visiting-hour/List')
  },
  {
    path: 'land-and-soil-characteristics-type',
    name: prop + '.land-and-soil-characteristics-type',
    component: () => import('../pages/land-and-soil-characteristics-type/List')
  },
  {
      path: 'crop-type',
      name: prop + '.crop_type',
      component: () => import('../pages/crop-type/List')
  },
  {
    path: 'off-days',
    name: prop + '.off_days',
    component: () => import('../pages/off-days/List')
  },
  {
    path: 'crop',
    name: prop + '.crop',
    component: () => import('../pages/crop/List')
},
{
    path: 'tour-operator',
    name: prop + '.tour_operator',
    component: () => import('../pages/tour-operator/List')
  },
  {
    path: 'land-and-soil-characteristic',
    name: prop + '.land-and-soil-characteristic',
    component: () => import('../pages/land-and-soil-characteristic/List')
  },
  {
    path: 'information-menu',
    name: prop + '.information-menu',
    component: () => import('../pages/information-menu/List')
  },
  {
    path: 'report-heading-list',
    name: prop + '.report-heading-list',
    // meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/report-heading/List.vue')
  }
]
const routes = [
  {
    path: '/virtual-museum-service/configuration/',
    name: 'virtual_museum_service.configuration',
    component: () => import('@/layouts/VirtualMuseumLayout.vue'),
    children: childRoutes('virtual_museum_service.configuration'),
    meta: { auth: true }
  }
]

export default routes
