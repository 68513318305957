const childRoute = (prop) => [
  {
    path: 'tracing-service',
    name: prop + '.tracing-service',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tracing-service/List.vue')
  },
  // Warehouse Space Status
  {
    path: 'warehouse-space-status',
    name: prop + '.warehouse-space-status',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/warehouse-space-status/List.vue')
  },
{
    path: 'delivery-schedule',
    name: prop + '.delivery-schedule',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/delivery-schedule/List.vue')
  },
  {
    path: 'stock-product',
    name: prop + '.stock-product',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/stock-product/List.vue')
  }
]

const routes = [
  {
    path: '/warehouse/information-service',
    name: 'warehouse.info_service',
    component: () => import('../../../../layouts/WarehouseLayout.vue'),
    meta: { auth: true },
    children: childRoute('warehouse.info_service')
  }
]

export default routes
