const childRoute = (prop) => [
  {
    path: 'test',
    name: prop + '.test',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/test/List.vue')
  },
  {
    path: 'complain',
    name: prop + '.complain',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/complain/List.vue')
  },
  {
    path: 'service-monitoring',
    name: prop + '.service-monitoring',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/service-monitoring/List.vue')
  },
  {
    path: 'ware-kpi',
    name: prop + '.ware-kpi',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/kpi/List.vue')
  },
  {
    path: 'space-stock-dashboard',
    name: prop + '.space-stock-dashboard',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/space-stock-dashboard/List.vue')
  },
  {
    path: 'rating-entry',
    name: prop + '.rating-entry',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/rating-entry/List.vue')
  }
]

const routes = [
  {
    path: '/warehouse/service-performance',
    name: 'warehouse.service_performance',
    component: () => import('../../../../layouts/WarehouseLayout.vue'),
    meta: { auth: true },
    children: childRoute('warehouse.service_performance')
  }
]

export default routes
