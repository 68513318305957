const childRoute = (prop) => [
  {
    path: 'port-allocation',
    name: prop + '.port-allocation',
    meta: { auth: true },
    component: () => import('../pages/port-allocation/List.vue')
  },
  {
    path: 'c-and-f-agent-appointment-management',
    name: prop + '.c-and-f-agent-appointment-management',
    meta: { auth: true },
    component: () => import('../pages/c-and-f-agent-appointment-management/List.vue')
  },
  {
    path: 'c-and-f-agent-appointment-management-form',
    name: prop + '.c-and-f-agent-appointment-management-form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/c-and-f-agent-appointment-management/EntryForm.vue')
  },
  {
    path: 'inter-godown-program-management',
    name: prop + '.inter-godown-program-management',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/inter-godown-program-management/List.vue')
  },
  {
    path: 'inter-godown-program-management-form',
    name: prop + '.inter-godown-program-management-form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/inter-godown-program-management/EntryForm.vue')
  },
  {
    path: 'carrying-agent-wise-final-program',
    name: prop + 'carrying_agent_wise_final_program',
    meta: { auth: true },
    component: () => import('../pages/carrying-agent-wise-final-program/List.vue')
  },
  {
    path: 'program-management',
    name: prop + 'program-management',
    meta: { auth: true },
    component: () => import('../pages/program-management/List.vue')
  },
  {
    path: 'inter-godown-challan-receive',
    name: prop + 'inter_godown_challan_receive',
    meta: { auth: true },
    component: () => import('../pages/inter-godown-challan-receive/List.vue')
  },
  {
    path: 'lori-challan-recieve',
    name: prop + 'lori-challan-recieve',
    meta: { auth: true },
    component: () => import('../pages/lori-challan-recieve/List.vue')
  },
  {
    path: 'inter-godown-delivery',
    name: prop + 'inter-godown-delivery',
    meta: { auth: true },
    component: () => import('../pages/inter-godown-delivery/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/fertilizer-movement',
    name: 'seeds_fertilizer_service.fertilizer_movement',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.fertilizer_movement')
  }
]

export default routes
