export default {
    mutateExternalIncentiveFarmerDropdown ({ commit }, payload) {
        commit('mutateExternalIncentiveFarmerDropdown', payload)
    },
    localizeExternalIncentiveFarmerDropdown ({ commit }, payload) {
        commit('localizeExternalIncentiveFarmerDropdown', payload)
    },
    mutateCommonProperties ({ commit }, payload) {
        commit('mutateCommonProperties', payload)
    },
    changeCommonDropdown ({ commit }, payload) {
        commit('changeCommonDropdown', payload)
    },
    localizeDropdownCommonConfig ({ commit }, payload) {
        commit('localizeDropdownCommonConfig', payload)
    }
}
