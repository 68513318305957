const childRoutes = (prop) => [
    {
      path: 'mill-information',
      name: prop + 'mill_information',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/mill-information/List.vue')
    },
    {
      path: 'region-wise-mill-mapping',
      name: prop + 'region_wise_mill_mapping',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/region-wise-mill-mapping/List.vue')
    },
    {
      path: 'subsidy-type',
      name: prop + 'subsidy_type',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/subsidy-type/List.vue')
    },
    {
      path: 'crop-name',
      name: prop + 'crop_name',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/crop-name/List.vue')
    },
    {
      path: 'cultivation-method-setup',
      name: prop + 'cultivation_method_setup',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/cultivation-method-setup/List.vue')
    },
    {
      path: 'subsidy-setup',
      name: prop + 'subsidy_setup',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/subsidy-setup/List.vue')
    },
    {
      path: 'subsidy-policy-setup',
      name: prop + 'subsidy_policy_setup',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/subsidy-policy-setup/List.vue')
    },
    {
      path: 'project-setup',
      name: prop + 'project_setup',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/project-setup/List.vue')
    },
    {
      path: 'project-wise-subsidy-mapping',
      name: prop + 'project_wise_subsidy_mapping',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/project-wise-subsidy-mapping/List.vue')
    },
    {
      path: 'checklist',
      name: prop + 'checklist',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/checklist/List.vue')
    },
    {
      path: 'unit-info',
      name: prop + 'unit_info',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/unit-info/List.vue')
    },
    {
      path: 'subzone-office',
      name: prop + 'subzone-office',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/subzone-office-setup/List.vue')
    },
    {
      path: 'cda-mapping',
      name: prop + 'cda-mapping',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/cda-mapping/List.vue')
    },
    {
      path: 'cda-mapping-form',
      name: prop + 'cda_mapping_form',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/cda-mapping/Form.vue')
    },
    {
      path: 'center',
      name: prop + 'center',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/center-setup/List.vue')
    }
]

const routes = [
    {
        path: '/incentive-grant-service/bsri/configuration',
        name: 'incentive_grant_service.bsri.configuration',
        component: () => import('@/layouts/IncentiveGrantLayout.vue'),
        meta: { auth: true },
        children: childRoutes('incentive_grant_service.bsri.configuration')
    }
]

export default routes
