const childRoute = (prop) => [
  {
    path: 'bcic-create',
    name: prop + '.bcic_create',
    meta: { auth: true },
    component: () => import('../pages/bcic-create/List.vue')
  }
]
const routes = [
  {
    path: '/seeds-fertilizer-service/fertilizer-bcic',
    name: 'seeds_fertilizer_service.fertilizer_bcic',
    component: () => import('@/layouts/SeedsFertilizerLayout'),
    meta: { auth: true },
    children: childRoute('seeds_fertilizer_service.fertilizer-bcic')
  }
]

export default routes
