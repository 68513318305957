export default {
  // comlpain type
  addComplainType ({ commit }, payload) {
    commit('addComplainType', payload)
  },
  addComplainTypeList ({ commit }, payload) {
    commit('addComplainTypeList', payload)
  },
  updateComplainType ({ commit }, payload) {
    commit('updateComplainType', payload)
  },
  updateComplainTypeStatus ({ commit }, payload) {
    commit('updateComplainTypeStatus', payload)
  },
  // comlpain type end
  addComplainInfoList ({ commit }, payload) {
    commit('addComplainInfoList', payload)
  },
  addComplainInfo ({ commit }, payload) {
    commit('addComplainInfo', payload)
  },
  toggleComplainInfo ({ commit }, payload) {
    commit('toggleComplainInfo', payload)
  },
  // Complain Designation start
  addComplainDesignation ({ commit }, payload) {
    commit('addComplainDesignation', payload)
  },
  addComplainDesignationList ({ commit }, payload) {
    commit('addComplainDesignationList', payload)
  },
  updateComplainDesignation ({ commit }, payload) {
    commit('updateComplainDesignation', payload)
  },
  updateComplainDesignationStatus ({ commit }, payload) {
    commit('updateComplainDesignationStatus', payload)
  },
  // Complain Designation end
  // Complain Chain start
  addComplainChain ({ commit }, payload) {
    commit('addComplainChain', payload)
  },
  addComplainChainList ({ commit }, payload) {
    commit('addComplainChainList', payload)
  },
  updateComplainChain ({ commit }, payload) {
    commit('updateComplainChain', payload)
  },
  updateComplainChainStatus ({ commit }, payload) {
    commit('updateComplainChainStatus', payload)
  },
  // Complain request
  addComplainRequestList ({ commit }, payload) {
    commit('addComplainRequestList', payload)
  },
  // Complain Send
  addComplainSend ({ commit }, payload) {
    commit('addComplainSend', payload)
  },
  addComplainSendList ({ commit }, payload) {
    commit('addComplainSendList', payload)
  },
  updateComplainSend ({ commit }, payload) {
    commit('updateComplainSend', payload)
  },
  updateComplainSendStatus ({ commit }, payload) {
    commit('updateComplainSendStatus', payload)
  }
}
