export const mutations = {
  // Notification Type
  addNotificationTypes (state, notificationTypes) {
    state.notificationTypes.push(notificationTypes)
  },
  updateNotificationType (state, payload) {
    state.notificationTypes = state.notificationTypes.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }

      return item
    })
  },
  addNotificationTypeList (state, notificationType) {
    state.notificationTypes = notificationType
  },
  toggleNotificationTypeStatus (state, payload) {
    state.notificationTypes = state.notificationTypes.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  // Notification Setting
  addNotificationSettings (state, notificationSettings) {
    state.notificationSettings.push(notificationSettings)
  },
  updateNotificationSetting (state, payload) {
    state.notificationSettings = state.notificationSettings.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }

      return item
    })
  },
  addNotificationSettingList (state, notificationSetting) {
    state.notificationSettings = notificationSetting
  },
  toggleNotificationSettingStatus (state, payload) {
    state.notificationSettings = state.notificationSettings.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  // Notification Template
  addNotificationTemplates (state, notificationTemplates) {
    state.notificationTemplates.push(notificationTemplates)
  },
  updateNotificationTemplate (state, payload) {
    state.notificationTemplates = state.notificationTemplates.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }

      return item
    })
  },
  addNotificationTemplateList (state, notificationTemplate) {
    state.notificationTemplates = notificationTemplate
  },
  toggleNotificationTemplateStatus (state, payload) {
    state.notificationTemplates = state.notificationTemplates.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  },
  // Notification Template
  addCirculateNotices (state, circulateNotices) {
    state.circulateNotices.push(circulateNotices)
  },
  updateCirculateNotice (state, payload) {
    state.circulateNotices = state.circulateNotices.map(function (item) {
      if (item.id === payload.id) {
        return payload
      }

      return item
    })
  },
  addCirculateNoticeList (state, circulateNotice) {
    state.circulateNotices = circulateNotice
  },
  toggleCirculateNoticeStatus (state, payload) {
    state.circulateNotices = state.circulateNotices.map(function (item) {
      if (item.id === payload.id) {
        return Object.assign(item, { status: !item.status })
      }
      return item
    })
  }
}
