export default {
    mutateCommonObj ({ commit }, payload) {
      commit('mutateCommonObj', payload)
    },
    changeCommonObjDropdown ({ commit }, payload) {
      commit('changeCommonObjDropdown', payload)
    },
    // Service Criteria
    setCriteriaList ({ commit }, payload) {
        commit('setCriteriaList', payload)
    },
    resetCriteriaList ({ commit }, payload) {
        commit('resetCriteriaList', payload)
    },
    removeItemFromCriteriaList ({ commit }, payload) {
        commit('removeItemFromCriteriaList', payload)
    }
}
