export default {
    mutateExternalLrcpnDropdown ({ commit }, payload) {
        commit('mutateExternalLrcpnDropdown', payload)
    },
    localizeExternalLrcpnDropdown ({ commit }, payload) {
        commit('localizeExternalLrcpnDropdown', payload)
    },
    mutateCommonProperties ({ commit }, payload) {
        commit('mutateCommonProperties', payload)
    },
    changeCommonDropdown ({ commit }, payload) {
        commit('changeCommonDropdown', payload)
    },
    localizeDropdownCommonConfig ({ commit }, payload) {
        commit('localizeDropdownCommonConfig', payload)
    },
    setLrcpnUserDetails ({ commit }, payload) {
        commit('setLrcpnUserDetails', payload)
    },
    setLrcpnUserlabel ({ commit }, payload) {
        commit('setLrcpnUserlabel', payload)
    },
    setPanelObj ({ commit }, payload) {
        commit('setPanelObj', payload)
    },
    setLaodList ({ commit }, payload) {
        commit('setLaodList', payload)
    }
}
