export const mutations = {
    mutateExternalLrcpnDropdown (state, payload) {
      state.lrcpnObj = Object.assign(state.lrcpnObj, payload)
    },
    setLrcpnUserDetails (state, payload) {
      state.lrcpnObj.lrcpnUserDetails = payload
    },
    setLrcpnUserlabel (state, payload) {
      state.lrcpnObj.profileLabel = payload
    },
    setPanelObj (state, payload) {
      state.lrcpnObj.lrcpnUserStatus = payload
    },
    setLaodList (state, payload) {
      state.commonObj.listLoad === payload ? state.commonObj.listLoad = false : state.commonObj.listLoad = payload
    },
    localizeExternalLrcpnDropdown (state, payload) {
      state.lrcpnObj.feeNamesList = state.lrcpnObj.feeNamesList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.lrcpnObj.serviceNamesList = state.lrcpnObj.serviceNamesList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.lrcpnObj.stepNamesList = state.lrcpnObj.stepNamesList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.lrcpnObj.menuWiseStepList = state.lrcpnObj.menuWiseStepList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.lrcpnObj.dynamicFormList = state.lrcpnObj.dynamicFormList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.lrcpnObj.serviceSteps = state.lrcpnObj.serviceSteps.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.lrcpnObj.serviceStepForm = state.lrcpnObj.serviceStepForm.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.lrcpnObj.dynamicButtonList = state.lrcpnObj.dynamicButtonList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.lrcpnObj.stepFeeList = state.lrcpnObj.stepFeeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.lrcpnObj.countryList = state.lrcpnObj.countryList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.country_name, text_en: item.country_name, text_bn: item.country_name_bn, value: item.id } : { text: item.country_name_bn, text_en: item.country_name, text_bn: item.country_name_bn, value: item.id }
        return Object.assign({}, item, tmp)
      })
      state.lrcpnObj.foreignEnlishmentList = state.lrcpnObj.foreignEnlishmentList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.lrcpnObj.agricultureProductTypeList = state.lrcpnObj.agricultureProductTypeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.lrcpnObj.agricultureProductList = state.lrcpnObj.agricultureProductList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.lrcpnObj.marketList = state.lrcpnObj.marketList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.lrcpnObj.fertilizerName = state.lrcpnObj.fertilizerName.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
    },
    mutateCommonProperties (state, payload) {
      state.commonObj = Object.assign({}, state.commonObj, payload)
    },
    changeCommonDropdown (state, payload) {
      state.commonObj.divisionList = state.commonObj.divisionList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.districtList = state.commonObj.districtList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.upazilaList = state.commonObj.upazilaList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.unionList = state.commonObj.unionList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.officeTypeList = state.commonObj.officeTypeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.officeList = state.commonObj.officeList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.fiscalYearList = state.commonObj.fiscalYearList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObj.designationList = state.commonObj.designationList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
    },
    mutateDropdownCommonConfig (state, payload) {
      state.commonObjCommonConfig = Object.assign({}, state.commonObjCommonConfig, payload)
    },
    localizeDropdownCommonConfig (state, payload) {
      state.commonObjCommonConfig.bankList = state.commonObjCommonConfig.bankList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObjCommonConfig.branchList = state.commonObjCommonConfig.branchList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObjCommonConfig.cityCorporationList = state.commonObjCommonConfig.cityCorporationList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObjCommonConfig.pauroshobaList = state.commonObjCommonConfig.pauroshobaList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObjCommonConfig.wardList = state.commonObjCommonConfig.wardList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
      state.commonObjCommonConfig.countryList = state.commonObjCommonConfig.countryList.map(item => {
        const tmp = payload.value === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
    }
}
