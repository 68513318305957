export default {
  mutateIncentiveGrantServiceProperties ({ commit }, payload) {
    commit('mutateIncentiveGrantServiceProperties', payload)
  },
  localizeIncentiveGrantDropdown ({ commit }, payload) {
    commit('localizeIncentiveGrantDropdown', payload)
  },
  stateReload ({ commit }, payload) {
    commit('stateReload', payload)
  }
}
