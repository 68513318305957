const Configuration = [
  {
    value: 1,
    text: 'Fee Name List',
    text_bn: 'ফি নামের তালিকা',
    stateName: 'feeNamesList'
  }
]
const DAEDealerRegistration = [
  {
    value: 1,
    text: 'Lab List',
    text_bn: 'ল্যাব তালিকা',
    stateName: 'labList'
  },
  {
    value: 2,
    text: 'Active ingredient List',
    text_bn: 'সক্রিয় উপাদান তালিকা',
    stateName: 'activeIngredientList'
  }
]
const inputOptionList = [
    { item: 'text', text_en: 'Text', text_bn: 'টেক্সট' },
    { item: 'email', text_en: 'Email', text_bn: 'ইমেইল' },
    { item: 'number', text_en: 'Number', text_bn: 'নাম্বার' },
    { item: 'dropdown', text_en: 'Dropdown', text_bn: 'ড্রপডাউন' },
    { item: 'image', text_en: 'Image', text_bn: 'ছবি' }
  ]
const operatorList = [
  { text: '+', value: '+' },
  { text: '-', value: '-' },
  { text: 'x', value: 'x' },
  { text: '/', value: '/' }
]
const alignmentList = [
  { value: 1, text_en: 'Left', text_bn: 'বাম' },
  { value: 2, text_en: 'Right', text_bn: 'ডান' },
  { value: 3, text_en: 'Top', text_bn: 'উপর' },
  { value: 4, text_en: 'Bottom', text_bn: 'নীচে' },
  { value: 5, text_en: 'Center', text_bn: 'কেন্দ্র' }
]

const tmpLanguageTypeList = [
  { value: 'en', text_en: 'English', text_bn: 'ইংরেজি' },
  { value: 'bn', text_en: 'Bangla', text_bn: 'বাংলা' }
]
export default {
  Configuration,
  inputOptionList,
  operatorList,
  alignmentList,
  DAEDealerRegistration,
  tmpLanguageTypeList
}
