export const mutations = {
    // test
    addTestList (state, payload) {
        state.tests.push(payload)
    },
    updateTestStatus (state, payload) {
        state.tests = state.test.map(function (item) {
            if (item.id === payload.id) {
                payload.status = !payload.status
                return payload
            }

            return item
        })
    },
    // Task Assign Start
    addIrriTask (state, payload) {
        state.taskAssignList.push(payload)
    },
    irriTaskList (state, payload) {
        state.taskAssignList = payload
    },
    updateIrriTask (state, payload) {
        state.taskAssignList = state.taskAssignList.map(function (item) {
            if (item.id === payload.id) {
                return payload
            }

            return item
        })
    },
    updateIrriTaskStatus (state, payload) {
        state.taskAssignList = state.taskAssignList.map(function (item) {
            if (item.id === payload.id) {
                payload.status = !payload.status
                return payload
            }

            return item
        })
    },
    // Irrigation Task Tracker
    irriTaskTrackerList (state, payload) {
        state.taskTrackerList = payload
    },
    verifyTask (state, payload) {
        state.taskTrackerList = state.taskTrackerList.map((item) => {
            if (item.id === payload.id) {
                payload.is_verified = !payload.is_verified
                return payload
            }
            return item
        })
    },
    // Task Calendar List Start
    taskCalendarList (state, payload) {
        state.taskCalendarList = payload
    },
    // Task Calendar List Ends

    // Task Reports
    irriTaskReportsList (state, payload) {
        state.taskReportsList = payload
    },
    // Task Assign To User List
    addTaskAssignTo (state, payload) {
        state.taskAssignToUserList = payload
    },
    addTaskInfo (state, payload) {
        state.taskInfo = payload
    }
}
